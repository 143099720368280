import React from 'react';
import PropTypes from 'prop-types';

// Utils
import classNames from 'classnames';

// Styles
import classes from './MaterialTableText.module.scss';

function MaterialTableText({ children, variant, noWrap, className, fontWeight }) {
  const customization = classNames(classes.wrapper, {
    [classes.variantLight]: variant === 'light',
    [classes.variantRegular]: variant === 'regular',
    [classes.fontWeightBold]: fontWeight === 'bold',
    [classes.fontWeightNormal]: fontWeight === 'normal',
    [classes.noWrap]: noWrap,
  }, className);

  return <span className={ customization }>{children}</span>;
}

MaterialTableText.defaultProps = {
  variant: 'regular',
  className: '',
  noWrap: false,
  fontWeight: null,
};
MaterialTableText.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'regular']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]).isRequired,
  noWrap: PropTypes.bool,
  fontWeight: PropTypes.oneOf(['bold']),
};

export default MaterialTableText;
