import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

// Actions
import { useGetCampaignListMutation, useUpdateCampaignStatusMutation } from '../campaignApiSlice';

function materialQueryToParams(query) {
  const { orderBy, orderDirection, page, pageSize, search, advertiser, creative, status } = query;
  return {
    ...(page && { page }),
    ...(search && { search }),
    ...(pageSize && { size: pageSize }),
    ...(orderBy && { sortBy: orderBy.field }),
    ...(orderDirection && { sortOrder: orderDirection.toUpperCase() }),
    ...(status && { statuses: [status] }),
    advertisers: advertiser || [],
    creatives: creative || [],
  };
}

const useCampaigns = (filters) => {
  const { enqueueSnackbar } = useSnackbar();
  const [
    getCampaignList,
    {
      data: {
        payload: campaignList = [],
        _meta = {},
      } = {},
    }] = useGetCampaignListMutation();

  const [updateCampaignStatus] = useUpdateCampaignStatusMutation();

  const requestCampaigns = useCallback(async (query = {}) => {
    const { _meta: meta = {}, payload = [] } = await getCampaignList(materialQueryToParams({ ...query, ...filters })).unwrap();

    // due to https://github.com/mbrn/material-table/issues/1979
    const mutablePayload = payload.map((item) => Object.create(item));

    return {
      data: mutablePayload,
      page: meta.page,
      totalCount: meta.total,
    };
  }, [filters, getCampaignList]);

  const changeCampaignStatus = useCallback(async (values, status) => {
    try {
      if (values && values.creativesCount) {
        await updateCampaignStatus({ id: values.id, data: JSON.stringify(status) }).unwrap();
        enqueueSnackbar(`Campaign (id: ${values.id}) updated successfully`, { variant: 'success' });
      } else {
        enqueueSnackbar(`Campaign (id: ${values.id}) should have at least one creative added.`, { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    }
  }, [updateCampaignStatus, enqueueSnackbar]);

  return { requestCampaigns, campaignList, meta: _meta, changeCampaignStatus };
};

export default useCampaigns;
