import React from 'react';
import { FormHelperText } from '@mui/material';
import classes from './FormError.module.scss';

function FormError({ error }) {
  return (error && (
    <div className={ classes.erroeMessage }>
      <FormHelperText error>{error}</FormHelperText>
    </div>
  ));
}

export default FormError;
