import { createTheme } from '@mui/material/styles';
import { backgroundColorDarkGray, primaryColorText, basicRedColor } from 'constants/colors';

const pxToRem = (px, isImportant) => `${px / 16}rem ${isImportant ? '!important' : ''}`;

const palette = {
  mode: 'dark',
  primary: { main: primaryColorText },
  secondary: {
    main: basicRedColor,
    contrastText: '#FFFFFF',
  },
  error: {
    main: basicRedColor,
    contrastText: '#FFFFFF',
  },
};

const theme = createTheme({
  palette,
  typography: {
    fontFamily: ['Public Sans', 'sans-serif'].join(','),
    htmlFontSize: 16,
  },
  components: {
    MuiAppBar: { styleOverrides: { root: { backgroundColor: backgroundColorDarkGray } } },
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        root: {
          width: '70px',
          '&::after': { display: 'none' },
        },
      },
    },
    MuiStep: { styleOverrides: { root: { paddingLeft: pxToRem(20), paddingRight: pxToRem(20) } } },
    MuiDialogActions: { styleOverrides: { root: { justifyContent: 'center' } } },
    MuiInputBase: {
      styleOverrides: {
        root: { lineHeight: '1.375rem' },
        inputAdornedEnd: { paddingRight: '0 !important' },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: '#FFF', backgroundColor: backgroundColorDarkGray },
        outlined: {
          transform: 'translate(22px, 20px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(20px, -6px) scale(0.75)',
            color: 'white',
            textTransform: 'uppercase',
          },
          '&.Mui-error': { color: basicRedColor },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          borderRadius: pxToRem(6),
          height: pxToRem(57),
          '& legend': { marginLeft: '6px' },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColorText },
        },
        input: {
          fontSize: pxToRem(18),
          padding: pxToRem(17),
          paddingLeft: pxToRem(20),
          paddingRight: pxToRem(20),
          ':-webkit-autofill': {
            '-webkit-box-shadow':
          '0 0 0 100px #2F3349 inset;',
          },
        },
        notchedOutline: { borderWidth: '1px !important' },
        multiline: { height: 'unset' },
      },
    },
    MuiInputAdornment: { styleOverrides: { root: { marginLeft: 0 } } },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '100%',
          textTransform: 'none',
          marginTop: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 800,
          fontSize: pxToRem(30),
          lineHeight: pxToRem(36),
          textTransform: 'capitalize',
          fontStyle: 'normal',
          color: basicRedColor,
        },
        h2: {
          fontWeight: 800,
          fontSize: pxToRem(30),
          lineHeight: pxToRem(36),
          textTransform: 'capitalize',
          fontStyle: 'normal',
        },
        h3: {
          fontWeight: 700,
          fontSize: pxToRem(24),
          lineHeight: pxToRem(29),
        },
        h4: {
          fontWeight: 600,
          fontSize: pxToRem(20),
          lineHeight: pxToRem(24),
        },
        h5: {
          fontWeight: 500,
          fontSize: pxToRem(18),
          lineHeight: pxToRem(22),
        },
        h6: {
          fontWeight: 'bold',
          fontSize: pxToRem(14),
          lineHeight: pxToRem(17),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { textTransform: 'none' },
        outlined: { color: '#FFF' },
        text: { color: '#FFF' },
        sizeMedium: {
          padding: '12px 20px',
          fontWeight: 600,
          height: pxToRem(44),
          fontSize: pxToRem(16),
          lineHeight: pxToRem(20),
          borderRadius: pxToRem(6),
        },
        sizeLarge: {
          padding: '12px 20px',
          minWidth: '7.5rem',
          fontWeight: 600,
          height: pxToRem(57),
          fontSize: pxToRem(18),
          lineHeight: pxToRem(22),
          borderRadius: pxToRem(6),
        },
        containedPrimary: {
          color: '#FFF',
          borderRadius: pxToRem(6),
          backgroundColor: basicRedColor,
          textTransform: 'none',
          padding: '12px 20px',
          fontWeight: 600,
          fontSize: pxToRem(18),
          lineHeight: pxToRem(22),
          '&:hover': { backgroundColor: '#af2c25' },
        },
        containedSecondary: {
          color: '##FFF',
          backgroundColor: basicRedColor,
          borderRadius: pxToRem(6),
          textTransform: 'none',
          padding: '12px 20px',
          fontWeight: 600,
          fontSize: pxToRem(18),
          lineHeight: pxToRem(22),
          '&:hover': { backgroundColor: '#af2c25' },
        },
      },
    },
    MuiSelect: {
      styleOverrides:
        {
          root: { '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColorText } },
          select: { minHeight: '1.375rem' },
        },
    },
    MuiMenu: {
      styleOverrides: {
        paper: { borderRadius: '6px', boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.12)' },
        list: { padding: '20px 0' },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '30px',
          paddingRight: '30px',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
          '&:focus': { backgroundColor: 'transparent' },
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            fontWeight: 600,
            color: primaryColorText,
            '&:hover': { backgroundColor: 'transparent' },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides:
        {
          head: { fontSize: '1.25rem!important' },
          root: {
            ':first-child': { paddingLeft: '0!important' },
            ':last-child': { paddingRight: '0!important' },
          },
        },
    },
  },
});

export default theme;
