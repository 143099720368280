import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

// Components
import Auth from 'components/Auth/Auth';
import Main from 'components/Main';
import PublicRoute from './PublicRoute';
import Login from '../components/Auth/Login/Login';
import Signup from '../components/Auth/Signup/Signup';
import ForgotPassword from '../components/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../components/Auth/ResetPassword/ResetPassword';
import ProtectedRoute from './ProtectedRoute';
import Policies from '../components/TermsAndPolicies/Policies';
import Terms from '../components/TermsAndPolicies/Terms';
import ACP from '../components/TermsAndPolicies/ACP';
import Landing from '../components/Landing/Landing';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/get-started"
        element={ (
          <PublicRoute shouldRedirect={ false }>
            <Landing />
          </PublicRoute>
        ) }
      />
      <Route
        path="terms"
        element={ (
          <PublicRoute shouldRedirect={ false }>
            <Terms />
          </PublicRoute>
        ) }
      />
      <Route
        path="policies"
        element={ (
          <PublicRoute shouldRedirect={ false }>
            <Policies />
          </PublicRoute>
        ) }
      />
      <Route
        path="acp"
        element={ (
          <PublicRoute shouldRedirect={ false }>
            <ACP />
          </PublicRoute>
        ) }
      />
      <Route
        path="login"
        element={ (
          <PublicRoute shouldRedirect={ false }>
            <Auth>
              <Login />
            </Auth>
          </PublicRoute>
        ) }
      />
      <Route
        path="signup"
        element={ (
          <PublicRoute>
            <Auth>
              <Signup />
            </Auth>
          </PublicRoute>
        ) }
      />
      <Route
        path="forgot-password"
        element={ (
          <PublicRoute>
            <Auth>
              <ForgotPassword />
            </Auth>
          </PublicRoute>
        ) }
      />
      <Route
        path="reset-password"
        element={ (
          <PublicRoute>
            <Auth>
              <ResetPassword />
            </Auth>
          </PublicRoute>
        ) }
      />
      <Route
        path="/*"
        element={ (
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        ) }
      />
    </>,
  ),
);

function Router() {
  return (
    <RouterProvider router={ router } />
  );
}

export default Router;
