import React from 'react';

import useDynamicBackground from './useDynamicBackground';

import classes from './BackgroundImage.module.scss';

function BackgroundImage() {
  const img = useDynamicBackground();

  return (
    <img src={ img } alt="background" className={ classes.image } />
  );
}

export default BackgroundImage;
