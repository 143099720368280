import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterNavLink } from 'react-router-dom';

// Styles
import classes from './NavLink.module.scss';

function NavLink({ to, end, children }) {
  return (
    <RouterNavLink to={ to } end={ end } className={ ({ isActive }) => classes.link + (isActive ? ` ${classes.active}` : '') }>
      { children }
    </RouterNavLink>
  );
}

NavLink.defaultProps = { end: false };

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  end: PropTypes.bool,
};

export default NavLink;
