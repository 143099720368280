import React, { Suspense } from 'react';
import GlobalPreloader from 'modules/GlobalPreloader/GlobalPreloader';

// eslint-disable-next-line func-names
const lazyLoad = (Component) => function (props) {
  return (
    <Suspense fallback={ <GlobalPreloader show /> }>
      <Component { ...props } />
    </Suspense>
  );
};

export default lazyLoad;
