import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { get } from 'lodash';

// Modules
import Input from 'modules/_Factories/Input/Input';
import Select from 'modules/_Factories/Select/Select';
import StepContainer from 'modules/Steps/StepContainer';

// Constants
import { Statuses } from 'constants/index';
import { backgroundColorDarkGray } from 'constants/colors';

function General({ formik, permissionEdit }) {
  return (
    <StepContainer
      subtitle="Adding an advertiser allows you to run campaigns for multiple brands, and keep account balances and reporting separately"
    >
      <Grid container item spacing={ 3 } direction="column" sm={ 6 }>
        <Grid item>
          <Input
            name="name"
            label="Name"
            value={ get(formik.values, 'name') }
          // Options
            fullWidth
            required
            disabled={ !permissionEdit }
            labelBackgroundColor={ backgroundColorDarkGray }
          // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
          // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>
        <Grid item>
          <Select
            name="status"
            label="Status *"
            value={ get(formik.values, 'status') }
            itemList={ Statuses }
          // Options
            isFilter
            isFilterText="none"
            showNoneItem={ false }
            fullWidth
            required
            disabled={ !permissionEdit }
          // Events
            onChange={ formik.handleChange }
            setFieldTouched={ formik.setFieldTouched }
          // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>
        {/* <Grid item> */}
        {/*   <Select */}
        {/*     name="Industry type" */}
        {/*     label="Industry type" */}
        {/*     value="1" */}
        {/*     itemList={ [{ value: '1', id: 1 }, { value: '2', id: 2 }] } */}
        {/*   // Options */}
        {/*     isFilter */}
        {/*     isFilterText="none" */}
        {/*     fullWidth */}
        {/*     required */}
        {/*     disabled={ !permissionEdit } */}
        {/*   // Events */}
        {/*     onChange={ formik.handleChange } */}
        {/*     setFieldTouched={ formik.setFieldTouched } */}
        {/*   // Extra */}
        {/*     errors={ formik.errors } */}
        {/*     touched={ formik.touched } */}
        {/*   /> */}
        {/* </Grid> */}
      </Grid>
    </StepContainer>
  );
}

General.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      domain: PropTypes.string,
      status: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  permissionEdit: PropTypes.bool.isRequired,
};

export default memo(General);
