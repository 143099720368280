import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// Material
import { Button, Grid, Tooltip } from '@mui/material';

// Icons
import HelpIcon from '@mui/icons-material/Help';
import CatImage from 'assets/images/cat.png';

// Classes
import { AllowValuesItemClass } from 'classes/allowValuesItemClass';
import { AllowValuesClass } from 'classes/allowValuesClass';

// Modules
import FilledCheckbox from 'modules/_Factories/Checkbox/FilledCheckbox/FilledCheckbox';
import SearchInput from 'modules/_Factories/Input/SearchInput/SearchInput';
import Autocomplete from 'modules/_Factories/Autocomplete/Autocomplete';
import Chip from 'modules/_Factories/Chip/Chip';
import AllowOptions from 'modules/AllowOptions';
import { StepContainer } from 'modules/Steps';

// Hooks
import useGeography from './useGeography';

// Styles
import classes from './Geography.module.scss';
import classesContainer from '../../AddCampaign.module.scss';

function Geography({ formik, editable }) {
  const [zipCode, setZipCode] = useState('');
  const uploadFileRef = useRef();
  const { fields, targetingList, setTargetingList, parseFile } = useGeography(formik);

  const { setFieldValue } = formik;
  const zipCodes = formik.values.zips?.values || [];
  const selectedItems = fields.filter((field) => targetingList.some((item) => item === field.id));

  const handleChangeTargetingList = useCallback((event, checked) => {
    const { name } = event.target;

    const excludeItems = name === 'countries' ? ['countries', 'cities'] : [name];
    setTargetingList((list) => (checked ? [...list, name] : list.filter((listItem) => !excludeItems.includes(listItem))));

    if (!checked) {
      excludeItems.forEach((item) => {
        setFieldValue(`${item}.values`, []);
      });
    }
  }, [setFieldValue, setTargetingList]);

  const setZipCodesValues = () => {
    const newZipCode = zipCode.split(',').slice(-1)[0].trim();
    if (!newZipCode || zipCodes.some((code) => code.name === newZipCode)) return;
    formik.setFieldValue('zips.values', [...zipCodes, new AllowValuesItemClass({ id: newZipCode, name: newZipCode })]);
  };

  const handleChangeAllowTargeting = useCallback((name) => (allow) => {
    setFieldValue(name, allow);
  }, [setFieldValue]);

  const handleClearTargeting = useCallback((name) => () => {
    setFieldValue(name, []);
  }, [setFieldValue]);

  const addZipCode = (e) => {
    const isComma = e.key === ',';
    if (e.keyCode !== 13 && !isComma) return;
    setZipCodesValues();
  };

  const removeZipCode = (removedCode) => {
    const newCodes = zipCodes.filter((code) => code.id !== removedCode);
    formik.setFieldValue('zips.values', newCodes);
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    e.target.value = '';
    const data = await parseFile(file);
    const newZipCodes = data.filter((item) => item.trim());
    const prevData = zipCodes.map((zip) => zip.id);
    const uniqueValues = Array.from(new Set([...prevData, ...newZipCodes]));
    formik.setFieldValue('zips.values', [...(new AllowValuesClass({ values: uniqueValues }).values)]);
  };

  const getTargetingItem = (item) => {
    switch (item.id) {
      case 'zips':
        return (
          <div key={ item.id }>
            <Grid container alignItems="baseline">
              <SearchInput
                className={ classes.zipCode }
                name="zips"
                label="Zip Code(s)"
                placeholder="Zip Code(s)"
                onKeyDown={ addZipCode }
                onChange={ (e) => setZipCode(e.target.value) }
                onClear={ () => setZipCode('') }
                onBlur={ setZipCodesValues }
                value={ zipCode }
                disabled={ !editable }
                isShowStartAdornment={ false }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <input
                ref={ uploadFileRef }
                type="file"
                onChange={ uploadFile }
                className="d-none"
                accept="text/csv,.csv"
              />

              <Button
                color="primary"
                variant="outlined"
                size="small"
                className={ classes.uploadButton }
                onClick={ () => uploadFileRef?.current?.click() }
                onKeyPress={ () => uploadFileRef?.current?.click() }
              >
                Upload CSV
              </Button>
            </Grid>
            <Grid container>
              <AllowOptions
                allow={ get(formik, 'values.zips.allow') }
                onChangeAllow={ handleChangeAllowTargeting('zips.allow') }
                onClear={ handleClearTargeting('zips.values') }
              />
            </Grid>
            <Grid container wrap="wrap">
              {zipCodes.map((code) => (
                <Chip
                  key={ code.id }
                  labelKey="name"
                  valueKey="name"
                  option={ code }
                  onClick={ removeZipCode }
                  disabled={ !editable }
                />
              ))}
            </Grid>
          </div>
        );
      default: {
        const isDisabled = item.id === 'cities' &&
          (!targetingList.includes('countries') || !formik?.values?.countries?.values?.length);

        return (
          <Autocomplete
            key={ item.id }
            value={ formik.values[item.id].values }
            allowValue={ formik.values[item.id].allow }
            name={ item.name }
            placeholder={ `Search ${item.label}(s)` }
            options={ item.list }
            optionLabelKey="name"
            optionValueKey="id"
            loading={ item.loading }
            // Options
            disabled={ !editable || isDisabled }
            allowOptions
            // Events
            onChangeAllow={ (allow) => formik.setFieldValue(`${item.id}.allow`, allow) }
            onChange={ (event, newValue) => {
              formik.setFieldValue(item.name, newValue);

              if (item.id === 'countries') {
                const countries = newValue.map((value) => value.id);
                const { cities } = formik.values;
                const newCities = cities?.values?.filter((city) => countries.includes(city.countryAlpha2));
                formik.setFieldValue('cities.values', newCities || []);
              }
            } }
            onChangeSearchValue={ (e) => item.updateSearchParams({ filter: e.target.value }) }
          />
        );
      }
    }
  };

  const renderEmptyTargeting = () => (
    <div className={ classes.catWrapper }>
      <img src={ CatImage } alt="CatImage" />
      <div className="subtitle-text">
        Select a category
      </div>
    </div>
  );

  return (
    <StepContainer
      title="Geographical targeting"
      // eslint-disable-next-line
      subtitle="You can choose to target by country, state, DMA, city or congressional district as well as can upload a list of ZIP codes"
      containerClassName={ classesContainer.stepContainer }
    >
      <Grid container spacing={ 3 }>
        <Grid item xs={ 4 } sm={ 4 }>
          <div className={ classes.targeting }>
            {fields.map((field) => {
              const isDisabledCity = field.id === 'cities' && (
                !targetingList.includes('countries') ||
                !get(formik.values, 'countries.values.length')
              );
              const isDisabled = !editable || isDisabledCity;
              return field.visible && (
                <div key={ field.id } className={ classes.checkboxWrapper }>
                  <FilledCheckbox
                    simpleCheckbox
                    label={ field.label }
                    name={ field.id }
                    checked={ targetingList.some((targetItem) => targetItem === field.id) }
                    onChange={ handleChangeTargetingList }
                    disabled={ isDisabled }
                  />
                  {isDisabledCity && (
                    <Tooltip disableInteractive title="Please select at least 1 country">
                      <HelpIcon className={ classes.helpIcon } />
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
        </Grid>

        <Grid item xs={ 8 } sm={ 8 }>
          {selectedItems.length ? selectedItems.map(getTargetingItem) : renderEmptyTargeting() }
        </Grid>
      </Grid>
    </StepContainer>
  );
}

Geography.defaultProps = { };

Geography.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      zips: PropTypes.shape({
        values: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        ),
        allow: PropTypes.bool,
      }),
      countries: PropTypes.shape({ values: PropTypes.arrayOf(PropTypes.string), allow: PropTypes.bool }),
      cities: PropTypes.shape({ values: PropTypes.arrayOf(PropTypes.string), allow: PropTypes.bool }),
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

export default Geography;
