import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as MUIPagination } from '@mui/material';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import classes from './Pagination.module.scss';

function Pagination({ count, defaultPage, onChange, page }) {
  const customClasses = { root: classes.root };

  return (
    <MUIPagination
      classes={ customClasses }
      count={ count }
      defaultPage={ defaultPage }
      page={ page }
      onChange={ onChange }
      renderItem={ (item) => (
        <PaginationItem
          slots={ { previous: ArrowBackIcon, next: ArrowForwardIcon } }
          { ...item }
        />
      ) }
    />
  );
}

Pagination.defaultProps = { defaultPage: 1, page: 1 };
Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  defaultPage: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
