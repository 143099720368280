import { useCallback } from 'react';
import PAYMENT_METHODS from 'constants/dictionary/paymentMethodDictionary';

// Actions
import useAdvertiserBalance from 'modules/AdvertiserBalance/useAdvertiserBalance';
import { useGetAdvertiserByIdMutation } from '../../../advertiserApiSlice';

const useBalance = () => {
  const { fillAdvertiserBalance } = useAdvertiserBalance();

  const [getAdvertiserById] = useGetAdvertiserByIdMutation();

  const replenishAdvertiserBalance = useCallback(async (advertiserId, values) => {
    const navigation = {
      successUrl: `${window.location.origin}/advertisers`,
      cancelUrl: `${window.location.origin}/advertisers`,
    };
    const result = await fillAdvertiserBalance(advertiserId, values, navigation);
    if (result && values.paymentMethod === PAYMENT_METHODS.types.DEPOSIT) {
      getAdvertiserById(advertiserId);
    }
  }, [fillAdvertiserBalance, getAdvertiserById]);

  return { replenishAdvertiserBalance };
};

export default useBalance;
