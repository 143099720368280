import { useEffect, useCallback, useState } from 'react';
import periodDictionary from 'constants/dictionary/periodDictionary';
import {
  useGetStatsByStatesQuery,
  useGetAdvertisersActivityQuery,
  useGetTopAdvertisersQuery,
  useGetTopCampaignsQuery,
  useGetTopCreativesQuery,
} from 'api/apiSlice';

const useDashboard = (
  {
    period: defaultPeriod = periodDictionary.types.TODAY,
    advertisers: defaultAdvertisers = [],
    campaigns: defaultCampaigns = [],
  } = {},
  defaultThreshold = 10,
) => {
  const [period, setPeriod] = useState(defaultPeriod);
  const [advertisers, setAdvertisers] = useState(defaultAdvertisers);
  const [campaigns, setCampaigns] = useState(defaultCampaigns);
  const [threshold, setThreshold] = useState(defaultThreshold);

  const params = {
    period,
    advertiserId: advertisers,
    campaignId: campaigns,
  };

  const {
    data: statsByStates = [],
    isFetching: fetchingStatsByState,
    refetch: refetchStatsByState,
  } = useGetStatsByStatesQuery({ ...params, dimension: 'STATE' });
  const {
    data: advertisersActivity = [],
    isFetching: fetchingAdvertisersActivity,
    refetch: refetchAdvertisersActivity,
  } = useGetAdvertisersActivityQuery(params);
  const {
    data: topAdvertisers = [],
    isFetching: fetchingTopAdvertisers,
    refetch: refetchTopAdvertisers,
  } = useGetTopAdvertisersQuery({ period, threshold, dimension: 'ADVERTISER' });
  const {
    data: topCampaigns = [],
    isFetching: fetchingTopCampaigns,
    refetch: refetchTopCampaigns,
  } = useGetTopCampaignsQuery({ period, threshold, dimension: 'CAMPAIGN' });
  const {
    data: topCreatives = [],
    isFetching: fetchingTopCreatives,
    refetch: refetchTopCreatives,
  } = useGetTopCreativesQuery({ period, threshold, dimension: 'CREATIVE' });

  let mappedRows;
  let mappedAdvertisersActivity = {
    previousTotal: {
      impressions: 0,
      spend: 0,
      vastCompleteRate: 0,
      ecpm: 0,
    },
    total: {
      impressions: 0,
      spend: 0,
      vastCompleteRate: 0,
      ecpm: 0,
    },
    rows: [],
  };
  if (Object.keys(advertisersActivity).length !== 0 && advertisersActivity.constructor === Object) {
    mappedRows = advertisersActivity.rows.map((row) => ({
      item: row.time,
      metric: {
        ecpm: row.INT_GROSS_CPM,
        impressions: row.INT_IMPS,
        spend: row.INT_GROSS_IMP_PRICE,
        vastCompleteRate: row.INT_VAST_COMPLETE_RATE,
      },
    }));
    mappedAdvertisersActivity = {
      previousTotal: {
        impressions: advertisersActivity?.previousTotal?.INT_IMPS ?? 0,
        spend: advertisersActivity?.previousTotal?.INT_GROSS_IMP_PRICE ?? 0,
        vastCompleteRate: advertisersActivity?.previousTotal?.INT_VAST_COMPLETE_RATE ?? 0,
        ecpm: advertisersActivity?.previousTotal?.INT_GROSS_CPM ?? 0,
      },
      total: {
        impressions: advertisersActivity?.total?.INT_IMPS ?? 0,
        spend: advertisersActivity?.total?.INT_GROSS_IMP_PRICE ?? 0,
        vastCompleteRate: advertisersActivity?.total?.INT_VAST_COMPLETE_RATE ?? 0,
        ecpm: advertisersActivity?.total?.INT_GROSS_CPM ?? 0,
      },
      rows: [...mappedRows],
    };
  }

  const handleFilterChange = useCallback(async (fieldName, value) => {
    switch (fieldName) {
      case 'period':
        setPeriod(value);
        break;
      case 'advertisers':
        setAdvertisers(value);
        break;
      case 'campaigns':
        setCampaigns(value);
        break;
      case 'threshold':
        setThreshold(value);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Invalid fieldName');
    }
  }, [setPeriod, setAdvertisers, setCampaigns]);

  const resetFilters = useCallback(() => {
    setPeriod(periodDictionary.types.TODAY);
    setAdvertisers([]);
    setCampaigns([]);
  }, [setPeriod, setAdvertisers, setCampaigns]);

  const fetchData = useCallback(async () => {
    refetchStatsByState();
    refetchAdvertisersActivity();
    refetchTopAdvertisers();
    refetchTopCampaigns();
    refetchTopCreatives();
  }, [refetchAdvertisersActivity, refetchStatsByState, refetchTopAdvertisers, refetchTopCampaigns, refetchTopCreatives]);

  useEffect(
    () => {
      (async () => fetchData())();

      return () => {
        resetFilters();
      };
    },
    [fetchData, resetFilters],
  );

  return {
    statsByStates,
    advertisersActivity: mappedAdvertisersActivity,
    topAdvertisers,
    topCampaigns,
    topCreatives,
    period,
    advertisers,
    campaigns,
    threshold,
    handleFilterChange,
    resetFilters,
    fetchingStatsByState,
    fetchingAdvertisersActivity,
    fetchingTopCampaigns,
    fetchingTopAdvertisers,
    fetchingTopCreatives,
    fetchData,
  };
};

export default useDashboard;
