import React, { memo, useCallback } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import classes from './DaysTimeForm.module.scss';

function DayItem({ day, fullName, setFieldValue, values, error }) {
  const checked = () => values.checked;
  const start = () => dayjs(values.start, 'HH:mm:ss');
  const end = () => dayjs(values.end, 'HH:mm:ss');

  const handleCheckboxChange = useCallback((event, newValue) => {
    setFieldValue(`periods.${day}.checked`, newValue);
  }, [day, setFieldValue]);

  const handleTimeChange = useCallback((e, name) => {
    const date = dayjs(e).format('HH:mm:ss');
    setFieldValue(`periods.${day}.${name}`, date);
  }, [day, setFieldValue]);

  return (
    <Grid container className={ classes.day_container }>
      <Grid item md={ 6 }>
        <FormControlLabel
          control={ (
            <Checkbox
              color="secondary"
              name={ `periods.${day}.checked` }
              checked={ checked() }
              onChange={ handleCheckboxChange }
              checkedIcon={ <CheckBoxOutlinedIcon /> }
            />
) }
          label={ fullName }
        />
      </Grid>
      <Grid item md={ 6 }>
        <FormControl fullWidth className={ classes.time_container }>
          <TimePicker
            name={ `periods.${day}.start` }
            className={ classes.timepicker }
            views={ ['hours'] }
            disabled={ !checked() }
            onChange={ (event) => handleTimeChange(event, 'start') }
            value={ start() }
            maxTime={ end() }
            closeOnSelect={ false }
          />
          -
          <TimePicker
            name={ `periods.${day}.end` }
            className={ classes.timepicker }
            views={ ['hours'] }
            disabled={ !checked() }
            onChange={ (event) => handleTimeChange(event, 'end') }
            value={ end() }
            minTime={ start() }
            closeOnSelect={ false }
          />
          {error && (
          <FormHelperText
            error={ !!error }
            id={ `periods.${day}.start` }
            className={ classes.errorMessage }
          >
            {error}
          </FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default memo(DayItem);
