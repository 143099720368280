import { Typography } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import classesLink from '../../_Router/RouterLink/RouterLink.module.scss';
import FormikCheckbox from '../Checkbox/FormikCheckbox/FormikCheckbox';

function TermAndConditionsCheckbox({
  value,
  handleChange,
  errors,
  touched,
  name,
  required,
}) {
  return (
    <FormikCheckbox
      required={ required }
      name={ name }
      value={ value }
      onChange={ handleChange }
      errors={ errors }
      touched={ touched }
      label={ (
        <Typography>
          I agree to
          <a className={ classesLink.link } href="/terms" target="_blank">
            &nbsp;terms and conditions
          </a>
        </Typography>
      ) }
    />
  );
}

TermAndConditionsCheckbox.defaultProps = { required: false };

TermAndConditionsCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
};

export default TermAndConditionsCheckbox;
