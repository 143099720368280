import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MTableBody } from 'material-table';

// Modules
import MaterialTableTotal from '../MaterialTableTotal/MaterialTableTotal';

function MaterialTableBody(props) {
  const { columns, total, showTotal, pageSize, renderData } = props;
  const newPageSize = pageSize > renderData.length ? renderData.length : pageSize;
  return (
    <>
      <MTableBody { ...props } pageSize={ newPageSize } emptyDataSourceMessage={ "Sorry, we couldn't find any results matching." } />
      <MaterialTableTotal columns={ columns } total={ total } showTotal={ showTotal } />
    </>
  );
}

MaterialTableBody.defaultProps = {
  columns: [],
  total: {},
  showTotal: false,
};

MaterialTableBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({ field: PropTypes.string })),
  total: PropTypes.shape({}),
  showTotal: PropTypes.bool,
  renderData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default MaterialTableBody;
