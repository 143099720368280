import React from 'react';
import daysDictionary from 'constants/dictionary/dayPartTargetingDictionary';
import classes from './DaysTimeForm.module.scss';
import DayItem from './DayItem';

function DaysTimeForm({ formik }) {
  return (
    <div className={ classes.main }>
      {daysDictionary.dayDictionary.map(({ id, fullName }) => (
        <DayItem
          key={ id }
          day={ id }
          fullName={ fullName }
          setFieldValue={ formik.setFieldValue }
          values={ formik.values.periods[id] }
          error={ formik.errors.periods && formik.errors.periods[id] }
        />
      ))}
    </div>
  );
}

export default DaysTimeForm;
