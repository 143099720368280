import React from 'react';
import { Typography } from '@mui/material';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';
import classes from '../Auth.module.scss';
import useResetPassword from './useResetPassword';
import ResetPasswordExpired from './ResetPasswordExpired/ResetPasswordExpired';

function ResetPassword() {
  const { isValidToken, token } = useResetPassword();

  return (
    <>
      <Typography variant="h2" className={ classes.title }>
        {isValidToken ? 'New Password' : 'Reset Password'}
      </Typography>

      {isValidToken ?
        <ResetPasswordForm token={ token } className={ classes.form } /> :
        <ResetPasswordExpired />}
    </>
  );
}

export default ResetPassword;
