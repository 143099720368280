import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import classes from './PreviewCreative.module.scss';
import VideoCreative from './VideoCreative';
import ImageCreative from './ImageCreative';

function PreviewCreative({ url, label, checked, className, type }) {
  return (
    <>
      {type === 'image' ? (
        <ImageCreative
          className={ classNames(classes.creative, className, { [classes.checked]: checked }) }
          url={ url }
        />
      )
        : (
          <VideoCreative
            className={ classNames(classes.creative, className, { [classes.checked]: checked }) }
            url={ url }
          />
        )}
      {label && <div className={ classes.label }>{ label }</div>}
    </>
  );
}

PreviewCreative.defaultProps = {
  checked: false,
  label: null,
  className: null,
  type: 'video',
};

PreviewCreative.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['image', 'video']),
  label: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(PreviewCreative);
