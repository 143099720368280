import AssetBaseClass from './assetBaseClass';

export default class TitleAssetClass extends AssetBaseClass {
  type = 'TITLE';
  text = '';

  constructor(item = {}) {
    super(item);
    this.text = item.text || '';
  }
}
