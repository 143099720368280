import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import classNames from 'classnames';
import Link from '../../../../modules/_Router/RouterLink/RouterLink';
import classes from './ResetPasswordExpired.module.scss';

function ResetPasswordExpired() {
  return (
    <Grid container>
      <Grid item md={ 12 } className={ classNames('mb-6') }>
        <Typography
          variant="h5"
          className={ classes.message }
        >
          Either the link had already expired or this link was already used to change password.
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item md={ 12 } className="mb-4">
          <Link to="/login"><Button variant="contained" color="secondary" size="large" fullWidth>BACK TO LOGIN</Button></Link>
        </Grid>
        <Grid item md={ 12 }>
          <Link to="/forgot-password"><Button variant="contained" color="secondary" size="large" fullWidth>RESET PASSWORD</Button></Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ResetPasswordExpired;
