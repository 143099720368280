import React, { useCallback, useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';

// Libraries
import { format } from 'date-fns';
import { get } from 'lodash';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  BarChart,
  Bar,
} from 'recharts';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components
import { basicRedColor } from 'constants/colors';
import periodDictionary from 'constants/dictionary/periodDictionary';
import dashboardMetrics from 'constants/dashboard-metrics';
import CustomTooltip from './Tooltip/Tooltip';
import XTick from './XTick/XTick';

function ActivityLineChart({ period, metric, data, xDataKey, yDataKey }) {
  const palette = useMemo(() => ({
    gradientColor: '#BFE3F7',
    gridColor: '#F9F9F9',
    referenceLine: '#EBEBEB',
    axisColor: '#BBBBBB',
    monthLabelColor: '#BBBBBB',
    cursorColor: '#0092DD',
    dotColor: basicRedColor,
    lineColor: basicRedColor,
  }), []);

  const minWidth1380 = useMediaQuery('(min-width:1380px)');

  const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);

  const monthLabel = useMemo(() => {
    const firstElement = data[0];
    const lastElement = data[data.length - 1];
    if (!firstElement && !lastElement) return null;

    const startMonth = format(firstElement.item, 'MMM');
    const endMonth = format(lastElement.item, 'MMM');

    if (startMonth === endMonth) return startMonth;

    return `${startMonth} - ${endMonth}`;
  }, [data]);

  const getDataKeyMin = useCallback((dataKey) => {
    if (!data.length) return 0;

    const minValue = Math.min(...data.map((item) => get(item, dataKey)));
    const decreaseIndex = 0.9;

    return minValue * decreaseIndex;
  }, [data]);

  const getDataKeyMax = useCallback((dataKey) => {
    if (!data.length) return 1;

    const maxValue = Math.max(...data.map((item) => get(item, dataKey)));
    const increaseIndex = 1.1;

    return maxValue * increaseIndex;
  }, [data]);

  const isTimePeriod = useMemo(
    () => [periodDictionary.types.TODAY, periodDictionary.types.YESTERDAY].includes(period),
    [period],
  );

  const tooltipTextFormat = useMemo(() => {
    switch (metric) {
      case dashboardMetrics.SPEND:
      case dashboardMetrics.ECPM:
        return 'currency';
      case dashboardMetrics.ENGAGEMENT_RATE:
        return 'percent';
      case dashboardMetrics.IMPRESSIONS:
        return 'number';
      default:
        return 'number';
    }
  }, [metric]);

  return (
    <ResponsiveContainer height={ 350 } width="100%">
      <BarChart
        width="100%"
        height={ 250 }
        margin={ { top: 5, right: 5, bottom: 5, left: 5 } }
        data={ data }
        onMouseMove={ (e) => setActiveTooltipIndex(e.activeTooltipIndex) }
        onMouseLeave={ () => setActiveTooltipIndex(null) }
      >
        <CartesianGrid strokeDasharray="5 2" vertical={ false } />
        <XAxis
          domain={ [getDataKeyMin(xDataKey), getDataKeyMax(xDataKey)] }
          dataKey={ xDataKey }
          tickLine={ false }
          stroke={ palette.axisColor }
          interval={ 0 }
          height={ 50 }
          tick={ <XTick activeTooltipIndex={ activeTooltipIndex } stroke={ palette.axisColor } isTimePeriod={ isTimePeriod } /> }
        >
          <Label
            value={ monthLabel }
            position="left"
            offset={ 10 }
            fill={ palette.monthLabelColor }
            width={ 110 }
          />
        </XAxis>
        <YAxis stroke={ palette.axisColor } />
        <Tooltip
          content={ CustomTooltip }
          tooltipTextFormat={ tooltipTextFormat }
          cursor={ {
            stroke: palette.axisColor,
            fill: 'transparent',
          } }
        />
        <Bar dataKey={ yDataKey } fill={ basicRedColor } barSize={ minWidth1380 ? 60 : 30 } radius={ [10, 10, 0, 0] } />
      </BarChart>
    </ResponsiveContainer>
  );
}

ActivityLineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.number,
      metrics: PropTypes.shape({
        impressions: PropTypes.number,
        spend: PropTypes.number,
        vastCompleteRate: PropTypes.number,
        ecpm: PropTypes.number,
      }),
    }),
  ).isRequired,
  xDataKey: PropTypes.string.isRequired,
  yDataKey: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
};

export default memo(ActivityLineChart);
