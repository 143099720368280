import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, FormHelperText } from '@mui/material';
import classes from './InputFile.module.scss';

function InputFile({ name, label, labelButton, accept, value, onChange, errors, touched, disabled }) {
  const refInputFile = useRef();
  const error = errors[name];
  const hasError = Boolean(touched[name] && error);

  return (
    <FormControl variant="standard" fullWidth>
      <div className={ classes.wrapper }>
        <input
          ref={ refInputFile }
          type="file"
          className="d-none"
          name={ name }
          accept={ accept }
          onChange={ onChange }
          disabled={ disabled }
        />
        <div className={ classes.filename }>
          <span className={ classes.label }>{label}</span>
          <span>{value?.name || ''}</span>
        </div>
        {!disabled && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={ classes.button }
            classes={ { label: classes.buttonLabel } }
            onClick={ () => refInputFile.current?.click() }
            sx={ { minWidth: 70 } }
          >
            {value ? 'Change File' : labelButton}
          </Button>
        )}
      </div>
      { hasError && <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText> }
    </FormControl>
  );
}

InputFile.defaultProps = {
  label: 'Filename:',
  labelButton: 'Upload File',
  accept: '*',
  value: null,
  disabled: false,
  name: '',
  onChange: Function.prototype,
  errors: {},
  touched: {},
};

InputFile.propTypes = {
  label: PropTypes.string,
  labelButton: PropTypes.string,
  accept: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ name: PropTypes.string })]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
};

export default memo(InputFile);
