import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './itemsPerPage.module.scss';

function ItemsPerPage({ possibleValuesPerPage, activePerPage, onClickPerPage }) {
  return (
    <div>
      <span className="mr-3">Items per page:</span>
      <div className={ classes.pages }>
        {possibleValuesPerPage.map((page) => (
          <span
            key={ page }
            role="button"
            tabIndex={ 0 }
            className={ classNames(classes.pagesItem, { [classes.active]: page === activePerPage }) }
            onClick={ () => onClickPerPage(page) }
            onKeyPress={ () => onClickPerPage(page) }
          >
            {page}
          </span>
        ))}
      </div>
    </div>
  );
}

ItemsPerPage.defaultProps = {
  possibleValuesPerPage: [],
  activePerPage: null,
  onClickPerPage: Function.prototype,
};

ItemsPerPage.propTypes = {
  possibleValuesPerPage: PropTypes.arrayOf(PropTypes.number),
  activePerPage: PropTypes.number,
  onClickPerPage: PropTypes.func,
};

export default memo(ItemsPerPage);
