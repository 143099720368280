import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../_Table/Pagination/Pagination';
import classes from './MaterialTableAjaxPagination.module.scss';

function MaterialTableAjaxPagination({ count, page, rowsPerPage, onPageChange, pageRef }) {
  const countPages = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);
  const showPagination = useMemo(() => countPages > 1, [countPages]);

  const onChangeHandler = useCallback((event, value) => {
    if (pageRef) {
      pageRef.current = value - 1;
    }
    onPageChange(event, value - 1);
  }, [onPageChange, pageRef]);

  return (
    <td className={ classes.wrapper }>
      { showPagination && <Pagination count={ countPages } page={ page + 1 } onChange={ onChangeHandler } /> }
    </td>
  );
}

MaterialTableAjaxPagination.defaultProps = { rowsPerPage: null };

MaterialTableAjaxPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

export default memo(MaterialTableAjaxPagination);
