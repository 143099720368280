const types = {
  PAY_BY_CARD: 'PAY_BY_CARD',
  DEPOSIT: 'DEPOSIT',
  REQUEST_DEPOSIT: 'REQUEST_DEPOSIT',
};

const dictionary = [
  { id: types.PAY_BY_CARD, name: 'Pay By Card' },
  { id: types.DEPOSIT, name: 'Manual Balance Refill' },
  // TODO: enable later
  // { id: types.REQUEST_DEPOSIT, name: 'Request Deposit' },
];

export default {
  types,
  dictionary,
};
