import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function VideoCreative({ url, className }) {
  const videoRef = useRef();

  // https://stackoverflow.com/a/47382850
  useEffect(() => {
    videoRef.current?.load();
  }, [url]);

  return (
    <video
      ref={ videoRef }
      controls
      width="100%"
      height="100%"
      className={ className }
    >
      <source src={ url } />
      <track src="" kind="captions" srcLang="en" label="english_captions" />
      Browser not supported
    </video>
  );
}

VideoCreative.defaultProps = { className: null };

VideoCreative.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default memo(VideoCreative);
