import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Button, Grid } from '@mui/material';

import PageTitle from 'modules/PageTitle/PageTitle';
import useFormCreative from 'components/Creatives/FormCreative/useFormCreative';
import CreativeForm from '../CreativeForm/CreativeForm';

function AddCreative({ addCreative, creativeInitialValues }) {
  const [isShowForm, setIsShowForm] = useState(false);
  const formikUtils = useRef(null);

  const handleSubmit = useCallback(async (values, callbackFinally) => {
    const result = await addCreative(values, () => {
      callbackFinally();
    });

    if (result) {
      setIsShowForm(false);
      formikUtils.current.resetForm();
    }
  }, [addCreative]);

  const { formik } = useFormCreative({ initialValues: creativeInitialValues, handleSubmit });
  const { resetForm, validateForm } = formik;

  useEffect(() => {
    formikUtils.current = { resetForm };
  }, [resetForm]);

  const toggleShowForm = useCallback(() => {
    setIsShowForm((prev) => !prev);
    if (!isShowForm) {
      validateForm();
    }
  }, [validateForm, isShowForm]);

  return (
    <Grid item container xs={ 12 }>
      <Grid item container justifyContent="space-between" xs={ 12 }>
        <Grid item xs={ 12 } md={ 6 }>
          <PageTitle title="Add Creative" />
        </Grid>
        <Grid container item xs={ 12 } md={ 6 } justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={ toggleShowForm }>
            {isShowForm ? 'Hide' : 'Show'} Creative Form
          </Button>
        </Grid>
      </Grid>
      {isShowForm && (
        <Grid container className="mt-2">
          <Grid item xs={ 12 }>
            <CreativeForm formik={ formik } />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

AddCreative.defaultProps = {
  addCreative: Function.prototype,
  creativeInitialValues: undefined,
};

AddCreative.propTypes = {
  addCreative: PropTypes.func,
  creativeInitialValues: PropTypes.shape(),
};
export default memo(AddCreative);
