import React, { memo, useCallback, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ImageAssetClass from 'classes/asset/imageAssetClass';
import DataAssetClass from 'classes/asset/dataAssetClass';
import TitleAssetClass from 'classes/asset/titleAssetClass';
import Input from 'modules/_Factories/Input/Input';
import CustomNativeCreative from 'modules/PreviewCreative/CustomNativeCreative';
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';
import { findAssetByTemplateStart, getValueByTypeof } from 'libs/creative/creativesUtils';
import UploadFile from '../../UploadFile';

const getAssetUrl = (asset) => {
  if (!asset) return null;
  return asset.file ? URL.createObjectURL(asset.file) : asset.url;
};

const MAIN_IMAGE_SIZE = CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.SIZE;
const ASSET_TYPES = CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS;

function CustomNativeAd({ assets, onChangeAssets, disabled, formik }) {
  const mainAsset = findAssetByTemplateStart(assets, ASSET_TYPES.MAIN.TEMPLATE) || {};
  const logoAsset = findAssetByTemplateStart(assets, ASSET_TYPES.LOGO.TEMPLATE) || {};
  const titleAsset = findAssetByTemplateStart(assets, ASSET_TYPES.TITLE.TEMPLATE) || {};
  const descriptionAsset = findAssetByTemplateStart(assets, ASSET_TYPES.DESCRIPTION.TEMPLATE) || {};
  const ctaAsset = findAssetByTemplateStart(assets, ASSET_TYPES.CTA.TEMPLATE) || {};
  const attributionAsset = findAssetByTemplateStart(assets, ASSET_TYPES.ATTRIBUTION.TEMPLATE) || {};

  const mainUrl = getAssetUrl(mainAsset);
  const logoUrl = getAssetUrl(logoAsset);

  const updateAsset = useCallback((prevValue, currentValue) => {
    const updatedAssets = assets.map((item) => (item === prevValue ? currentValue : item));
    onChangeAssets(updatedAssets);
  }, [assets, onChangeAssets]);

  const onChangeMain = (value, inputType) => {
    formik.setFieldTouched('customNative.main');
    const newValue = inputType === CREATIVE_DATA_TYPES.types.CANVA ?
      value :
      getValueByTypeof(value);

    updateAsset(
      mainAsset,
      {
        ...mainAsset,
        ...newValue,
        inputType,
      },
    );
  };

  const onChangeLogo = (value, inputType) => {
    formik.setFieldTouched('customNative.logo');
    const newValue = inputType === CREATIVE_DATA_TYPES.types.CANVA ?
      value :
      getValueByTypeof(value);

    updateAsset(
      logoAsset,
      {
        ...logoAsset,
        ...newValue,
        inputType,
      },
    );
  };

  const allowEditMain = useMemo(
    () => !mainAsset.url?.includes('cloudfront') && formik.errors[ASSET_TYPES.MAIN.TEMPLATE] !== 'URL is invalid',
    [mainAsset.url, formik.errors],
  );

  const allowEditLogo = useMemo(
    () => !logoAsset.url?.includes('cloudfront') && formik.errors[ASSET_TYPES.LOGO.TEMPLATE] !== 'URL is invalid',
    [logoAsset.url, formik.errors],
  );

  return (
    <Grid container spacing={ 4 }>
      <Grid container item xs={ 8 } spacing={ 2 }>
        <Grid item xs={ 12 }>
          <UploadFile
            title={ `Main image asset - 
            ${MAIN_IMAGE_SIZE.width}x${MAIN_IMAGE_SIZE.height}` }
            value={ mainAsset.file || mainAsset.url }
            expectedFileSize={ MAIN_IMAGE_SIZE }
            disabled={ disabled }
            allowEditImage={ allowEditMain }
            fileExtensions={ CREATIVE_DATA_TYPES.allowedImageTypes }
            error={
            formik.touched?.customNative?.main &&
              formik.errors[ASSET_TYPES.MAIN.TEMPLATE]
          }
            onChangeValue={ onChangeMain }
            templateForCanva={ ASSET_TYPES.MAIN.TEMPLATE }
            advertiser={ formik.values.advertiser }
          />
          {/* {!disabled && <CanvaButton size={ MAIN_IMAGE_SIZE } />} */}
        </Grid>
        <Grid item xs={ 12 }>
          <UploadFile
            title="Logo image asset"
            value={ logoAsset.file || logoAsset.url || '' }
            expectedFileSize={ logoAsset.expectedSize }
            disabled={ disabled }
            allowEditImage={ allowEditLogo }
            fileExtensions={ CREATIVE_DATA_TYPES.allowedImageTypes }
            error={
            formik.touched?.customNative?.logo &&
              formik.errors[ASSET_TYPES.LOGO.TEMPLATE]
          }
            onChangeValue={ onChangeLogo }
            templateForCanva={ ASSET_TYPES.LOGO.TEMPLATE }
            advertiser={ formik.values.advertiser }
          />
          {/* {!disabled && <CanvaButton size={ { width: 100, height: 100 } } />} */}
        </Grid>
        <Grid item xs={ 12 }>
          <Typography variant="h6">Ad title, description, call to action and attribution assets</Typography>
        </Grid>
        <Grid item xs={ 12 } className="mb-3">
          <Input
            name="customNative.title"
            label="Title"
            value={ titleAsset.text || '' }
            disabled={ disabled }
            err={ formik.errors[ASSET_TYPES.TITLE.TEMPLATE] }
            touched={ formik.touched }
            // Options
            fullWidth
            // Events
            onChange={ (e) => {
              formik.setFieldTouched('customNative.title');
              updateAsset(
                titleAsset,
                { ...titleAsset, text: e.target.value },
              );
            } }
          />
        </Grid>
        <Grid item xs={ 12 } className="mb-3">
          <Input
            name="customNative.description"
            label="Description"
            value={ descriptionAsset.value || '' }
            // Options
            disabled={ disabled }
            err={ formik.errors[ASSET_TYPES.DESCRIPTION.TEMPLATE] }
            touched={ formik.touched }
            fullWidth
            // Events
            onChange={ (e) => {
              formik.setFieldTouched('customNative.description');
              updateAsset(
                descriptionAsset,
                { ...descriptionAsset, value: e.target.value },
              );
            } }
          />
        </Grid>
        <Grid item xs={ 12 } className="mb-3">
          <Input
            name="customNative.cta"
            label="Call To Action"
            value={ ctaAsset.value || '' }
            // Options
            disabled={ disabled }
            err={ formik.errors[ASSET_TYPES.CTA.TEMPLATE] }
            touched={ formik.touched }
            fullWidth
            // Events
            onChange={ (e) => {
              formik.setFieldTouched('customNative.cta');
              updateAsset(
                ctaAsset,
                { ...ctaAsset, value: e.target.value },
              );
            } }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <Input
            name="customNative.attribution"
            label="Attribution"
            value={ attributionAsset.value || '' }
            // Options
            disabled={ disabled }
            err={ formik.errors[ASSET_TYPES.ATTRIBUTION.TEMPLATE] }
            touched={ formik.touched }
            fullWidth
            // Events
            onChange={ (e) => {
              formik.setFieldTouched('customNative.attribution');
              updateAsset(
                attributionAsset,
                { ...attributionAsset, value: e.target.value },
              );
            } }
          />
        </Grid>
      </Grid>
      <Grid item xs={ 4 }>
        <CustomNativeCreative
          mainUrl={ mainUrl }
          logoUrl={ logoUrl }
          title={ titleAsset.text || 'Title will be here' }
          description={ descriptionAsset.value || 'Description will be here' }
          buttonText={ ctaAsset.value || 'CTA will be here' }
          attributionText={ attributionAsset.value || 'Attribution will be here' }
        />
      </Grid>
    </Grid>
  );
}

CustomNativeAd.defaultProps = {
  onChangeAssets: Function.prototype,
  disabled: false,
  assets: [],
};

CustomNativeAd.propTypes = {
  onChangeAssets: PropTypes.func,
  disabled: PropTypes.bool,
  assets: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.instanceOf(ImageAssetClass),
    PropTypes.instanceOf(DataAssetClass),
    PropTypes.instanceOf(TitleAssetClass),
  ])),
};

export default memo(CustomNativeAd);
