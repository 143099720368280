import React, { memo, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { transformCreativeValues } from 'libs/creative/creativeModelUtils';
import FormCreative from '../../FormCreative';
import { useCreateCreativeMutation } from '../../creativeApiSlice';
import { emitGoogleEvent } from '../../../../libs/gtag';
import EVENTS from '../../../../constants/analitics';
import CREATIVE_DATA_TYPES from '../../../../constants/dictionary/creativeDataTypesDictionary';
import CREATIVE_TYPES from '../../../../constants/dictionary/creativeTypesDictionary';

function AddCreative() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [createCreative] = useCreateCreativeMutation();

  /*
  when creating native creative should create asset immediately and then attached it to creative
  */
  const handleSubmit = useCallback(async (values, callbackFinally) => {
    try {
      const formData = await transformCreativeValues(values);
      const creative = await createCreative(formData).unwrap();
      navigate('/creatives');
      enqueueSnackbar(`Creative (id: ${creative.id}) created successfully`, { variant: 'success' });

      const method = values.type === CREATIVE_TYPES.types.VIDEO ?
        CREATIVE_DATA_TYPES.analiticsDictionary[values.dataType]
        : values.assets.map((asset) => CREATIVE_DATA_TYPES.analiticsDictionary[asset.inputType]);

      emitGoogleEvent(EVENTS.addCreative, {
        creative_id: creative.id,
        creative_file_name: creative.name,
        ad_unit_data_type: creative.subType,
        creative_method: method,
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong! Please, try again.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      callbackFinally();
    }
  }, [createCreative, enqueueSnackbar, navigate]);

  return (
    <FormCreative handleSubmit={ handleSubmit } />
  );
}

export default memo(AddCreative);
