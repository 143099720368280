import React, { useMemo } from 'react';

// Material
import { Grid } from '@mui/material';

// Constants
import { NavLinks } from 'constants/index';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Modules
import NavLink from './NavLink/NavLink';

import classes from './Nav.module.scss';

const Nav = () => {
  const links = useMemo(() => (
    NavLinks.filter((link) => hasPermission(link.permissions))
  ), []);

  return (
    links.map((link) => (
      <Grid item xs="auto" sm="auto" key={ link.id }>
        <NavLink to={ link.id }>
          <div className={ classes.navItem }>
            <span className={ classes.navIcon }>{link.icon && <link.icon />}</span>
            { link.name }
          </div>
        </NavLink>
      </Grid>
    ))
  );
};

export default Nav;
