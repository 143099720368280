import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { Button, Typography } from '@mui/material';

// Formik
import { Formik, Field } from 'formik';

// Modules
import ClearableInput from 'modules/_Factories/Input/ClearableInput/ClearableInput';
import InputPassword from 'modules/_Factories/Input/InputPassword/InputPassword';
import Link from 'modules/_Router/RouterLink/RouterLink';

// Hooks
import useLoginForm from './useLoginForm';

// Styles
import classes from './LoginForm.module.scss';
import classesGeneral from '../../Auth.module.scss';

function LoginForm({ className, login }) {
  const { initialValues, validate, submitHandler } = useLoginForm(login);

  return (
    <Formik initialValues={ initialValues } validate={ validate } onSubmit={ submitHandler }>
      {({ handleSubmit, isSubmitting, errors, touched, setFieldValue }) => (
        <form onSubmit={ handleSubmit } className={ classNames(className) }>
          <div className={ classes.fieldWrapper }>
            <Typography className={ classesGeneral.inputName }>Email</Typography>
            <Field
              errors={ errors }
              touched={ touched }
              name="email"
              as={ ClearableInput }
              labelBackgroundColor="#fff"
              onClear={ () => setFieldValue('email', '') }
              fullWidth
              placeholder="john.doe@gmail.com"
            />
            <Typography className={ classesGeneral.inputName }>Password</Typography>
            <Field
              errors={ errors }
              touched={ touched }
              name="password"
              as={ InputPassword }
              labelBackgroundColor="#fff"
              fullWidth
              placeholder={ '∗'.repeat(8) }
            />
            <div className={ classes.forgotPasswordWrapper }>
              <Link to="/forgot-password" className={ classes.forgotPassword }>
                Forgot password?
              </Link>
            </div>
          </div>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            size="large"
            disabled={ isSubmitting }
          >
            SIGN IN
          </Button>
        </form>
      )}
    </Formik>
  );
}

LoginForm.defaultProps = { className: '' };

LoginForm.propTypes = {
  className: PropTypes.string,
  login: PropTypes.func.isRequired,
};

export default LoginForm;
