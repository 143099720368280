import { useCallback } from 'react';
import { useFormik } from 'formik';
import { validateRequired, validateEmail, validateDomain, validateLength } from 'libs/validateErrors';
import PAYMENT_METHODS from '../../../constants/dictionary/paymentMethodDictionary';

const useFormAdvertiser = ({ initialValues, handleSubmit, isTest = false }) => {
  const validate = useCallback((values) => {
    const name = validateRequired(values.name, 'Name') || validateLength(values.name, 'Name', 1, 30);
    const domain = validateDomain(values.domain, 'Domain');
    const status = validateRequired(values.status, 'Status');
    const email = validateEmail(values.contacts.email, 'Email');

    return {
      ...(name && { name }),
      ...(domain && { domain }),
      ...(status && { status }),
      ...(email && { contacts: { email } }),
    };
  }, []);

  const formik = useFormik({
    initialValues: { paymentMethod: PAYMENT_METHODS.types.PAY_BY_CARD, ...initialValues },
    onSubmit: (values, { setSubmitting }) => handleSubmit(values, () => setSubmitting(false)),
    validate,
    enableReinitialize: true,
    validateOnMount: !isTest,
  });

  return { formik, validate };
};

export default useFormAdvertiser;
