import React from 'react';
import { SnackbarProvider as NisSnackbarProvider } from 'notistack';
import { makeStyles } from '@mui/styles';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import CloseButton from 'modules/CloseButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((styles) => ({
  icon: {
    marginRight: styles.spacing(1),
    opacity: 0.9,
  },
  variantError: { backgroundColor: styles.palette.error.main },
  variantInfo: { backgroundColor: styles.palette.info.main },
  variantSuccess: { backgroundColor: styles.palette.success.main },
  variantWarning: { backgroundColor: styles.palette.warning.main },
}));

function SnackBarCloseButton(key) {
  return <CloseButton keyNotification={ key } />;
}

function SnackbarProvider(props) {
  const classes = useStyles();
  const MAX_SNACK_COUNT = 3;

  const iconVariant = {
    error: <ErrorIcon className={ classes.icon } fontSize="small" />,
    info: <InfoIcon className={ classes.icon } fontSize="small" />,
    success: <SuccessIcon className={ classes.icon } fontSize="small" />,
    warning: <WarningIcon className={ classes.icon } fontSize="small" />,
  };

  const { children } = props;

  const snackbarClasses = {
    variantError: classes.variantError,
    variantInfo: classes.variantInfo,
    variantSuccess: classes.variantSuccess,
    variantWarning: classes.variantWarning,
  };
  return (
    <NisSnackbarProvider
      action={ SnackBarCloseButton }
      iconVariant={ iconVariant }
      classes={ snackbarClasses }
      preventDuplicate
      maxSnack={ MAX_SNACK_COUNT }
      anchorOrigin={ {
        vertical: 'bottom',
        horizontal: 'center',
      } }
    >
      {children}
    </NisSnackbarProvider>
  );
}

SnackbarProvider.defaultProps = { children: null };

SnackbarProvider.propTypes = { children: PropTypes.node };

export default SnackbarProvider;
