import React, { memo } from 'react';
import { Typography } from '@mui/material';
import Link from 'modules/_Router/RouterLink/RouterLink';
import SignupForm from './SignupForm/SignupForm';
import classes from '../Auth.module.scss';
import useSignup from './useSignup';

function Signup() {
  const { signup } = useSignup();

  return (
    <>
      <Typography className={ classes.title }>
        Feature your brand inside thousands of homes.
      </Typography>
      <Typography className={ classes.subtitle }>
        Telly&apos;s self-serve advertising platform makes it easy to create, schedule,
        and run TV ads across thousands of Telly screens.
      </Typography>

      <SignupForm className={ classes.marginBottom } onSubmit={ signup } />

      <Typography className={ classes.marginBottom }>
        Already have an account? <Link to="/login">Sign in instead</Link>
      </Typography>
    </>
  );
}

export default memo(Signup);
