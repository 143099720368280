import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearLocalStorage, clearSessionStorage } from '../../../libs/storageLibs';
import loadingActions from '../../../actions/loadingActions';
import { apiSlice, useLogoutMutation } from '../../../api/apiSlice';
import types from '../../../actions/actionTypes/authActionTypes';
import { campaignApiSlice } from '../../../components/Campaigns/campaignApiSlice';
import { advertiserApiSlice } from '../../../components/Advertisers/advertiserApiSlice';
import { removeUserIdFromAnalitics } from '../../../libs/gtag';

const useProfile = ({ firstName = '', lastName = '' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  const logOut = useCallback(async (sendLogoutRequest = true, redirectTo = '/login') => {
    try {
      dispatch(loadingActions.increaseCounter());

      if (sendLogoutRequest) {
        await logout().unwrap();
      }
    } finally {
      clearLocalStorage();
      clearSessionStorage();
      removeUserIdFromAnalitics();

      dispatch(campaignApiSlice.util.resetApiState());
      dispatch(advertiserApiSlice.util.resetApiState());
      dispatch(apiSlice.util.resetApiState());

      navigate('/login');
      if (redirectTo) navigate(redirectTo);

      dispatch({
        type: types.SET_IS_AUTHENTICATED,
        payload: { isAuthenticated: false },
      });

      dispatch(loadingActions.decreaseCounter());
    }

    return true;
  }, [dispatch, logout, navigate]);

  const getFullName = () => `${firstName} ${lastName}`;

  return { getFullName, logOut };
};

export default useProfile;
