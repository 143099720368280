import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// lib
import { transformCreativeValues, getDataType, setAssetExpectedSize } from 'libs/creative/creativeModelUtils';

// Components
import FormCreative from '../../FormCreative';
import { useGetCreativeByIdFullMutation, useUpdateCreativeMutation } from '../../creativeApiSlice';

function EditCreative() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [updateCreative] = useUpdateCreativeMutation();
  const [getCreative, { data: creative = {} }] = useGetCreativeByIdFullMutation();
  const [creativeInitialValues, setCreativeInitialValues] = useState(creative);

  useEffect(() => {
    if (!id) return;
    getCreative({ id });
  }, [id, getCreative]);

  useEffect(() => {
    setCreativeInitialValues(creative);
  }, [creative]);

  const handleSubmit = useCallback(async (values, callbackFinally) => {
    try {
      const formData = await transformCreativeValues(values);
      const response = await updateCreative({ id: values.id, data: formData }).unwrap();
      navigate('/creatives');
      enqueueSnackbar(`Creative (id: ${response.id}) updated successfully`, { variant: 'success' });
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong! Please, try again.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      callbackFinally();
    }
  }, [enqueueSnackbar, navigate, updateCreative]);

  const initialValues = useMemo(() => ({
    ...creativeInitialValues,
    dataType: getDataType(creativeInitialValues),
    assets: creativeInitialValues.assets && creativeInitialValues.assets.map(setAssetExpectedSize),
  }), [creativeInitialValues]);

  return (
    <FormCreative handleSubmit={ handleSubmit } initialValues={ initialValues } isEditForm />
  );
}

export default memo(EditCreative);
