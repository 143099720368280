import React from 'react';
import PropTypes from 'prop-types';

// Libs
import cn from 'classnames';

// Styles
import classes from './OverlayPreloader.module.scss';

// Images
import Loader from '../../assets/images/loaders/loader-stratify.png';

function OverlayPreloader({ children, isLoading }) {
  return (
    <div className={ classes.wrapper }>
      { isLoading && (
      <div className={ classes.preloaderWrapper }>
        <img src={ Loader } alt="Preloader" />
      </div>
      )}

      <div className={ cn({ [classes.isLoading]: isLoading }) }>
        {children}
      </div>
    </div>
  );
}

OverlayPreloader.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default OverlayPreloader;
