import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';

import classes from './FormikCheckbox.module.scss';

function FormikCheckbox(props) {
  const { onChange, value, name, checked, disabled, label, required, color } = props;
  const { errors, touched, err } = props;

  const error = err || get(errors, name);

  const hasError = Boolean(get(touched, name) && error);

  const renderError = useMemo(() => hasError && (
    <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText>
  ), [error, hasError, name]);

  return (
    <div className={ classes.container }>
      <FormControlLabel
        control={ (
          <Checkbox
            { ...props }
            color={ color }
            required={ required }
            id={ name }
            checked={ value || checked }
            onChange={ onChange }
            value={ value }
            name={ name }
            disabled={ disabled }
          />
            ) }
        label={ label }
      />
      { renderError }
    </div>
  );
}

FormikCheckbox.defaultProps = {
  value: '',
  name: '',
  onChange: () => {},
  checked: false,
  disabled: false,
};

FormikCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormikCheckbox;
