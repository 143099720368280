import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Button, Grid, Stack } from '@mui/material';

// Constants
import dashboardMetrics from 'constants/dashboard-metrics';
import periodDictionary from 'constants/dictionary/periodDictionary';

// Modules
import Select from 'modules/_Factories/Select/Select';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';

// Libs
import { textFormat } from 'libs/textFormat';
import { separateByStatus } from 'libs/separateByStatus';

// Icons
import IconRefresh from '@mui/icons-material/RefreshOutlined';

// Components
import usePermissions from 'hooks/usePermissions';
import { useMain } from 'components/useMain';
import CardMetric from './CardMetric/CardMetric';
import ActivityLineChart from './ActivityLineChart/ActivityLineChart';

// Styles
import classes from './ActivityOverview.module.scss';
import {
  useGetShortAdvertiserListQuery,
  useGetShortCampaignListQuery,
} from '../../../api/apiSlice';
import PageTitle from '../../../modules/PageTitle/PageTitle';

function ActivityOverview({
  fetchData,
  total,
  previousTotal,
  data,
  period,
  advertisers,
  campaigns,
  handleFilterChange,
  resetFilters,
}) {
  const { user: { firstName } } = useMain();
  const [metric, setMetric] = useState(dashboardMetrics.IMPRESSIONS);
  const { data: shortCampaignList = [] } = useGetShortCampaignListQuery({ size: 10000, type: 'VIDEO' });
  const { data: shortAdvertiserList = [] } = useGetShortAdvertiserListQuery({ size: 10000 });
  const permissions = usePermissions();
  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList), [shortAdvertiserList]);

  const periods = useMemo(() => {
    const excludePeriods = [periodDictionary.types.CUSTOM, periodDictionary.types.LAST_90_DAYS];
    return periodDictionary.dictionary.filter((item) => !excludePeriods.includes(item.id));
  }, []);

  const metricsData = useMemo(() => [
    {
      id: 0,
      metric: dashboardMetrics.IMPRESSIONS,
      title: 'Impressions',
      subtitle: `previous period ${textFormat(previousTotal.impressions, 'number') || '-'}`,
      value: textFormat(total.impressions, 'number') || '-',
    },
    {
      id: 1,
      metric: dashboardMetrics.SPEND,
      title: 'Spend',
      subtitle: `previous period ${textFormat(previousTotal.spend, 'currency') || '-'} `,
      value: textFormat(total.spend, 'currency') || '-',
    },
    {
      id: 2,
      metric: dashboardMetrics.ECPM,
      title: 'CPM',
      subtitle: `previous period ${textFormat(previousTotal.ecpm, 'currency') || '-'} `,
      value: textFormat(total.ecpm, 'currency') || '-',
    },
  ], [previousTotal, total]);

  return (
    <div className={ classes.wrapper }>
      <h1 className={ classes.welcomeTitle }>
        Welcome Back, <span className={ classes.name }>{firstName}</span>!
      </h1>
      <PageTitle className="mb-5" title="Overview" />
      <Stack
        flexDirection="row"
        justifyContent="flex-start"
        gap={ 4 }
        flexWrap="wrap"
        className={ classes.cardsWrapper }
      >
        {metricsData.map((item) => (
          <CardMetric
            key={ item.id }
            title={ item.title }
            subTitle={ item.subtitle }
            value={ item.value }
            isActive={ item.metric === metric }
            metric={ item.metric }
            onClick={ setMetric }
          />
        ))}
      </Stack>

      <h2 className="pageTitle">Top Metrics</h2>
      <div className={ classes.chartsWrapper }>
        <Grid
          container
          spacing={ 1 }
          alignItems="center"
          justifyContent="space-between"
          className="mb-3 chartsWrapper"
        >
          <Grid item sm="auto">
            <Button
              variant="contained"
              color="primary"
              onClick={ resetFilters }
            >
              Reset
            </Button>
          </Grid>
          <Grid item sm="auto">
            <IconRefresh
              className={ classes.iconRefresh }
              onClick={ () => fetchData() }
              data-test="icon-refresh"
            />
          </Grid>
          <Grid item sm lg={ 3 }>
            <Select
              name="period"
              label="Period"
              value={ period }
              itemList={ periods }
              // Options
              showNoneItem={ false }
              // Events
              onChange={ (e) => { handleFilterChange(e.target.name, e.target.value); } }
              data-test="periods"
            />
          </Grid>
          {permissions.advertiserRead && (
            <Grid item sm lg={ 3.5 }>
              <SelectVirtualized
                multiple
                tree
                list={ advertiserOptions }
                name="advertisers"
                label="Advertisers"
                onChange={ (e) => handleFilterChange(e.target.name, e.target.value) }
                value={ advertisers }
              />
            </Grid>
          )}
          {permissions.campaignRead && (
            <Grid item sm lg={ 3.5 }>
              <SelectVirtualized
                multiple
                list={ shortCampaignList }
                name="campaigns"
                label="Campaigns"
                onChange={ (e) => handleFilterChange(e.target.name, e.target.value) }
                value={ campaigns }
              />
            </Grid>
          )}
        </Grid>
        <div className={ classes.chart }>
          <ActivityLineChart
            data={ data }
            xDataKey="item"
            yDataKey={ `metric.${metric}` }
            period={ period }
            metric={ metric }
          />
        </div>
      </div>
    </div>
  );
}

ActivityOverview.defaultProps = {
  total: {},
  previousTotal: {},
  data: [],
  fetchData: () => {},
};
ActivityOverview.propTypes = {
  fetchData: PropTypes.func,
  total: PropTypes.shape({
    impressions: PropTypes.number,
    spend: PropTypes.number,
    vastCompleteRate: PropTypes.number,
    ecpm: PropTypes.number,
  }),
  previousTotal: PropTypes.shape({
    impressions: PropTypes.number,
    spend: PropTypes.number,
    vastCompleteRate: PropTypes.number,
    ecpm: PropTypes.number,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.number,
      metrics: PropTypes.shape({
        impressions: PropTypes.number,
        spend: PropTypes.number,
        vastCompleteRate: PropTypes.number,
        ecpm: PropTypes.number,
      }),
    }),
  ),
  period: PropTypes.string.isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.number).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default memo(ActivityOverview);
