const DEFAULT_STATUSES = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
  //   TODO: remove
  { id: null, name: 'No status' },
];

export const separateByStatus = (list = [], statuses = DEFAULT_STATUSES) => {
  const listByStatus = statuses.reduce((accum, cur) => {
    const statusList = list.filter((item) => item.status === cur.id)
      .map((item) => ({ ...item, parent: false, parentId: cur.id }));
    return statusList.length ? [...accum, { ...cur, parent: true }, ...statusList] : accum;
  }, []);

  return listByStatus;
};
