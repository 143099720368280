import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid } from '@mui/material';

// Modules
import Logo from './Logo/Logo';
import Nav from './Nav/Nav';

// Styles
import classes from './Sidebar.module.scss';

function Sidebar() {
  return (
    <div className={ classes.wrapper }>
      <Grid container className={ classes.container } wrap="nowrap" direction="column">
        <Grid item>
          <Logo className={ classes.logo } to="/dashboard" />
        </Grid>
        <Grid container item xs sm spacing={ 4 } direction="column">
          <Nav />
        </Grid>
      </Grid>
    </div>
  );
}

Sidebar.defaultProps = { user: {} };
Sidebar.propTypes = { user: PropTypes.shape({}) };

export default Sidebar;
