import React from 'react';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const styles = {
  appBar: { position: 'relative' },
  flex: { flex: 1 },
  imgContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" { ...props } ref={ ref } />);

function CropImageDialog({ classes, onClose, open, children }) {
  return (
    <Dialog
      fullScreen
      open={ open }
      onClose={ onClose }
      TransitionComponent={ Transition }
    >
      <AppBar className={ classes.appBar }>
        <Toolbar>
          <Typography
            variant="h4"
            color="inherit"
            className={ classes.flex }
          >
            Crop Image
          </Typography>

          <IconButton color="inherit" onClick={ onClose } aria-label="Close" size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={ classes.imgContainer }>
        {children}
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(CropImageDialog);
