/* eslint-disable max-len */
import React, { memo, useCallback, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';

import { findAssetByTemplateStart, getAssetUrl, getValueByTypeof } from 'libs/creative/creativesUtils';

import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

import ExpandableCreative from 'modules/PreviewCreative/ExpandableCreative';
import UploadFile from '../../UploadFile';
import classes from './Options.module.scss';

const EXPANDABLE_CREATIVE_DATA_TYPES = CREATIVE_DATA_TYPES.dictionary
  .filter((item) => item.id !== CREATIVE_DATA_TYPES.types.STOCK_LIB);

const COLLAPSED_SIZE = CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.SIZE;
const COLLAPSED_TEMPLATE = CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.TEMPLATE;
const EXPANDED_SIZE = CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.SIZE;
const EXPANDED_TEMPLATE = CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.TEMPLATE;

function ExpandableAd({ assets, onChangeAssets, disabled, formik }) {
  const expandedAsset = findAssetByTemplateStart(assets, EXPANDED_TEMPLATE) || {};
  const collapsedAsset = findAssetByTemplateStart(assets, COLLAPSED_TEMPLATE) || {};

  const expandedUrl = getAssetUrl(expandedAsset) || expandedAsset.value;
  const collapsedUrl = getAssetUrl(collapsedAsset) || collapsedAsset.value;

  const updateAsset = useCallback((prevValue, currentValue) => {
    const updatedAssets = assets.map((item) => (item === prevValue ? currentValue : item));
    onChangeAssets(updatedAssets);
  }, [assets, onChangeAssets]);

  const onChangeExpanded = (value, inputType) => {
    formik.setFieldTouched('expandableCreative.extended');

    const newValue = inputType === CREATIVE_DATA_TYPES.types.CANVA ?
      value :
      getValueByTypeof(value);

    updateAsset(
      expandedAsset,
      {
        ...expandedAsset,
        ...newValue,
        inputType,
      },
    );
  };

  const onChangeCollapsed = (value, inputType) => {
    formik.setFieldTouched('expandableCreative.collapsed');

    const newValue = inputType === CREATIVE_DATA_TYPES.types.CANVA ?
      value :
      getValueByTypeof(value);

    updateAsset(
      collapsedAsset,
      {
        ...collapsedAsset,
        ...newValue,
        inputType,
      },
    );
  };

  const allowEditExpanded = useMemo(
    () => !expandedAsset.url?.includes('cloudfront') && formik.errors[EXPANDED_TEMPLATE] !== 'URL is invalid',
    [expandedAsset.url, formik.errors],
  );

  const allowEditCollapsed = useMemo(
    () => !collapsedAsset.url?.includes('cloudfront') && formik.errors[COLLAPSED_TEMPLATE] !== 'URL is invalid',
    [collapsedAsset.url, formik.errors],
  );

  return (
    <Grid container spacing={ 4 }>
      <Grid item xs={ 12 }>
        <Typography>This Ad Type will require 2 image assets: Expanded & Collapsed variants.</Typography>
      </Grid>
      <Grid item xs={ 8 }>
        <div className="mb-2">
          <UploadFile
            title={ `Please make sure your Expanded creative is in the following size: Width = ${EXPANDED_SIZE.width}px, Height = ${EXPANDED_SIZE.height}px` }
            dataTypeList={ EXPANDABLE_CREATIVE_DATA_TYPES }
            value={ expandedAsset.file || expandedAsset.url }
            expectedFileSize={ EXPANDED_SIZE }
            disabled={ disabled }
            allowEditImage={ allowEditExpanded }
            fileExtensions={ CREATIVE_DATA_TYPES.allowedImageTypes }
            error={ formik.touched.expandableCreative?.extended && formik.errors[EXPANDED_TEMPLATE] }
            onChangeValue={ onChangeExpanded }
            templateForCanva={ EXPANDED_TEMPLATE }
            advertiser={ formik.values.advertiser }
          />
        </div>
        {/* {!disabled && <CanvaButton className="mb-3" size={ EXPANDED_SIZE } />} */}
        <div className="mb-2">
          <UploadFile
            title={ `Please make sure your Collapsed creative is in the following size: Width = ${COLLAPSED_SIZE.width}px, Height = ${COLLAPSED_SIZE.height}px` }
            dataTypeList={ EXPANDABLE_CREATIVE_DATA_TYPES }
            value={ collapsedAsset.file || collapsedAsset.url }
            expectedFileSize={ COLLAPSED_SIZE }
            disabled={ disabled }
            allowEditImage={ allowEditCollapsed }
            fileExtensions={ CREATIVE_DATA_TYPES.allowedImageTypes }
            error={ formik.touched.expandableCreative?.collapsed && formik.errors[COLLAPSED_TEMPLATE] }
            onChangeValue={ onChangeCollapsed }
            templateForCanva={ COLLAPSED_TEMPLATE }
            advertiser={ formik.values.advertiser }
          />
        </div>
        {/* {!disabled && <CanvaButton size={ COLLAPSED_SIZE } />} */}
      </Grid>
      <Grid item xs={ 4 }>
        <ExpandableCreative expandedUrl={ expandedUrl } collapsedUrl={ collapsedUrl } className={ classes.collapsablePreviewWrapper } />
      </Grid>
    </Grid>
  );
}

export default memo(ExpandableAd);
