import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import classes from './StepContainer.module.scss';

function StepContainer({ title, subtitle, renderTitle, renderSubtitle, children, className, containerClassName }) {
  return (
    <div className={ className }>
      { renderTitle ? renderTitle() : title && (
      <Typography variant="h4" className={ classes.title }>{title}</Typography>
      )}
      { renderSubtitle ? renderSubtitle() : subtitle && (
      <h4 className={ classes.subtitle }>{subtitle}</h4>
      )}
      {children && (
      <div className={ classNames(classes.container, containerClassName) }>
        {children}
      </div>
      )}
    </div>
  );
}

StepContainer.defaultProps = {
  className: null,
  containerClassName: null,
  title: null,
  subtitle: null,
  children: null,
  renderSubtitle: null,
  renderTitle: null,
};

StepContainer.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  renderSubtitle: PropTypes.func,
  renderTitle: PropTypes.func,
  children: PropTypes.node,
};

export default memo(StepContainer);
