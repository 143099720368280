export class ContactsClass {
  country = null;
  email = '';
  notes = '';
  phone = '';
  responsiblePerson = null;
  skype = '';

  constructor(...args) {
    args.forEach((item) => {
      this.country = item.country || null;
      this.email = item.email || '';
      this.notes = item.notes || '';
      this.phone = item.phone || '';
      this.responsiblePerson = item.responsiblePerson || null;
      this.skype = item.skype || '';
    });
  }
}
