import { createApi } from '@reduxjs/toolkit/query/react';
import envConfig from '../config/config';
// eslint-disable-next-line import/no-cycle
import { axiosBaseQuery } from '../client/axios';
import types from '../actions/actionTypes/authActionTypes';
import {
  setLocalStorageData,
  setSessionStorageData,
  setUserDataToLocalStorage,
  setUserDataToSessionStorage,
} from '../libs/storageLibs';
import loadingActions from '../actions/loadingActions';
import { sortByKey } from '../libs/sortByKey';
import InsightClass from '../classes/insight/insightClass';
import { DISTRICTS_HASH_MAP } from '../constants/districtMapMatching';
import { UserClass } from '../classes/userClass';

const setProfileValuesToStorage = async ({ dispatch, rememberMe, tokenInstance, baseQuery }) => {
  if (rememberMe) {
    setLocalStorageData(tokenInstance, rememberMe);
  } else {
    setSessionStorageData(tokenInstance);
  }

  try {
    dispatch(loadingActions.increaseCounter());
    const { data: profile, error } = await baseQuery({
      url: 'users/_current',
      method: 'GET',
    });

    if (error) {
      const reslut = error.request?.response ? JSON.parse(error.request.response) : { message: '' };
      return Promise.reject(reslut.message || error);
    }

    if (rememberMe) {
      setUserDataToLocalStorage(profile);
    } else {
      setUserDataToSessionStorage(profile);
    }
    return profile;
  } finally {
    dispatch(loadingActions.decreaseCounter());
  }
};

export const apiSlice = createApi({
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    // Profile
    getProfile: build.query({
      query: () => ({ url: 'profile', method: 'GET' }),
      providesTags: ['Profile'],
    }),
    updateProfile: build.mutation({
      query: (updatedProfile) => ({
        url: 'profile',
        method: 'PUT',
        data: updatedProfile,
        withLoading: true,
      }),
      invalidatesTags: ['Profile'],
    }),
    updatePassword: build.mutation({
      query: (updatedPassword) => ({
        url: 'profile/password',
        method: 'PUT',
        data: updatedPassword,
        withLoading: true,
      }),
    }),
    // Dictionary
    getTimeZones: build.query({
      query: () => ({ url: 'timezones', method: 'GET' }),
      keepUnusedDataFor: 3600,
      transformResponse: (response) => ({ timeZones: response }),
    }),
    getCountries: build.query({
      query: () => ({ url: 'countries', method: 'GET' }),
      keepUnusedDataFor: 3600,
      transformResponse: (response) => ({
        countries: response.map((item) => ({
          ...item,
          name: `${item.name} (${item.alpha3})`,
          id: item.id,
        })),
      }),
    }),
    getDMAs: build.query({
      query: () => ({ url: 'dmas', method: 'GET' }),
      keepUnusedDataFor: 3600,
      transformResponse: (response) => ({
        DMAs: response.map((item) => ({
          ...item,
          name: `${item.name} (${item.code})`,
          id: item.code,
        })),
      }),
    }),
    // Auth
    signup: build.mutation({
      query: (signupRequest) => ({
        url: 'auth/signup',
        method: 'POST',
        data: { ...signupRequest, client: envConfig.clientId },
        withLoading: true,
      }),
    }),
    signupWithGoogle: build.mutation({
      query: ({ code, type, callbackUrl, timeZone = 'GMT' }) => ({
        url: 'auth/google/signup',
        method: 'POST',
        data: {
          code,
          callbackUrl,
          client: envConfig.clientId,
          timeZone,
          type,
        },
        withLoading: true,
      }),
    }),
    login: build.mutation({
      queryFn: async ({ rememberMe = true, ...credentials }, api, extraOptions, baseQuery) => {
        try {
          const { data, meta, error } = await baseQuery({
            url: 'auth/login',
            method: 'POST',
            data: {
              ...credentials,
              asMaster: false,
              client: envConfig.clientId,
            },
          });

          if (error) {
            const reslut = error.request?.response ? JSON.parse(error.request.response) : { message: '' };
            return Promise.reject(reslut.message || error);
          }

          const expires = meta.response.headers.get('expires');
          const expiresDate = new Date(expires || 0).getTime();
          const tokenInstance = { ...data, expires: expiresDate };
          await setProfileValuesToStorage({ dispatch: api.dispatch, rememberMe, tokenInstance, baseQuery });

          api.dispatch({
            type: types.SET_IS_AUTHENTICATED,
            payload: { isAuthenticated: true },
          });

          return { data: tokenInstance };
        } catch (error) {
          return Promise.reject(error);
        }
      },
    }),
    loginWithGoogle: build.mutation({
      queryFn: async ({ rememberMe = true, ...credentials }, api, extraOptions, baseQuery) => {
        try {
          const { data: loginData, error: errorLogin } = await baseQuery({
            url: 'auth/google/login',
            method: 'POST',
            data: { ...credentials },
          });

          if (errorLogin) {
            const reslut = errorLogin.request?.response ? JSON.parse(errorLogin.request.response) : { message: '' };
            return Promise.reject(reslut.message || errorLogin);
          }

          const { token, accounts } = loginData;

          const { data, meta, error: errorToken } = await baseQuery({
            url: 'auth/google/login/token',
            method: 'POST',
            data: {
              token,
              account: accounts[0].id,
            },
          });

          if (errorToken) {
            const reslut = errorToken.request?.response ? JSON.parse(errorToken.request.response) : { message: '' };
            return Promise.reject(reslut.message || errorToken);
          }

          const expires = meta.response.headers.get('expires');
          const expiresDate = new Date(expires || 0).getTime();
          const tokenInstance = { ...data, expires: expiresDate };

          await setProfileValuesToStorage({ dispatch: api.dispatch, rememberMe, tokenInstance, baseQuery });

          api.dispatch({
            type: types.SET_IS_AUTHENTICATED,
            payload: { isAuthenticated: true },
          });

          return { data: tokenInstance };
        } catch (error) {
          return Promise.reject(error);
        }
      },
    }),
    logout: build.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'GET',
        withLoading: false,
      }),
    }),
    resetPassword: build.mutation({
      query: (data) => ({
        url: 'auth/password-reset',
        method: 'POST',
        data,
        withLoading: true,
      }),
    }),
    setPassword: build.mutation({
      query: (data) => ({
        url: 'auth/password-set',
        method: 'POST',
        data,
        withLoading: true,
      }),
    }),
    checkResetPasswordToken: build.mutation({
      query: (token) => ({
        url: `auth/tokens/${token}`,
        method: 'GET',
        withLoading: true,
      }),
    }),
    // insights
    getInsightList: build.query({
      query: (data) => ({
        url: 'insights/ssp/topn',
        method: 'POST',
        data,
        withLoading: true,
      }),
      transformResponse: (insights) => sortByKey(insights
        .filter((insight) => insight.item)
        .map((insight) => new InsightClass({
          ...insight,
          ...(insight.item && {
            item: {
              ...insight.item,
              name: DISTRICTS_HASH_MAP[insight.item.id] || insight.item.name,
            },
          }),
        }))),
    }),
    // dashboard
    getStatsByStates: build.query({
      query: (params) => ({
        url: 'dashboard/topn',
        method: 'GET',
        params,
        withLoading: false,
      }),
      providesTags: ['statsByStates'],
    }),
    getAdvertisersActivity: build.query({
      query: (params) => ({
        url: 'dashboard',
        method: 'GET',
        params,
        withLoading: false,
      }),
      providesTags: ['advertisersActivity'],
    }),
    getTopAdvertisers: build.query({
      query: (params) => ({
        url: 'dashboard/topn',
        method: 'GET',
        params,
        withLoading: false,
      }),
      providesTags: ['topAdvertisers'],
    }),
    getTopCampaigns: build.query({
      query: (params) => ({
        url: 'dashboard/topn',
        method: 'GET',
        params,
        withLoading: false,
      }),
      providesTags: ['topCampaigns'],
    }),
    getTopCreatives: build.query({
      query: (params) => ({
        url: 'dashboard/topn',
        method: 'GET',
        params,
        withLoading: false,
      }),
      providesTags: ['topCreatives'],
    }),
    // user
    getCurrentUser: build.mutation({
      query: () => ({
        url: 'users/_current',
        method: 'GET',
        withLoading: true,
      }),
      transformResponse: (item) => ({
        ...new UserClass(item),
        name: `${item.firstName} ${item.lastName}`,
      }),
    }),
    getGlobalSettings: build.mutation({
      query: () => ({
        url: 'settings',
        method: 'GET',
        withLoading: false,
      }),
    }),
    checkUserEmail: build.mutation({
      query: (data) => ({
        url: 'users/_email',
        method: 'POST',
        data,
        withLoading: false,
      }),
    }),
    // short lists
    getShortAdvertiserList: build.query({
      query: (params) => ({
        url: 'short-info/advertisers',
        method: 'GET',
        params,
      }),
      transformResponse: (items) => sortByKey(items.payload.map((item) => ({ ...item }))),
      providesTags: ['Advertiser', 'Advertiser-short'],
    }),
    getShortCampaignList: build.query({
      query: (params) => ({
        url: 'short-info/campaigns',
        method: 'GET',
        params,
      }),
      transformResponse: (items) => sortByKey(items.payload.map((item) => ({ ...item }))),
      providesTags: ['Campaign', 'Campaign-short'],
    }),
    getShortCreativeList: build.query({
      query: (params) => ({
        url: 'short-info/creatives',
        method: 'GET',
        params,
      }),
      transformResponse: (items) => sortByKey(items.payload.map((item) => ({ ...item }))),
      providesTags: ['Creative', 'Creative-short'],
    }),
    getShortDSPEmbedded: build.query({
      query: (params) => ({
        url: 'short-info/dsps/embedded',
        method: 'GET',
        params,
      }),
    }),
    getAssetById: build.mutation({
      query: ({ id, withLoading = true }) => ({
        url: `assets/${id}`,
        method: 'GET',
        withLoading,
      }),
      providesTags: (result, error, arg) => [{ type: 'Asset', id: arg.id }],
    }),
    getAssetsList: build.query({
      query: ({ params, withLoading = true }) => ({
        url: 'assets',
        method: 'GET',
        withLoading,
        params,
      }),
      transformResponse: (items) => sortByKey(items.payload.map((item) => ({ ...item }))),
      providesTags: ['Asset'],
    }),
    getReportsData: build.mutation({
      query: (data = {}, withLoading = true) => ({
        url: 'reports/_data',
        method: 'POST',
        data,
        withLoading,
      }),
      providesTags: () => ['Reports'],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdatePasswordMutation,
  useGetTimeZonesQuery,
  useGetCountriesQuery,
  useGetDMAsQuery,
  useSignupMutation,
  useSignupWithGoogleMutation,
  useLoginMutation,
  useLoginWithGoogleMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
  useCheckResetPasswordTokenMutation,
  useGetInsightListQuery,
  useGetStatsByStatesQuery,
  useGetAdvertisersActivityQuery,
  useGetTopAdvertisersQuery,
  useGetTopCampaignsQuery,
  useGetTopCreativesQuery,
  useGetCurrentUserMutation,
  useGetGlobalSettingsMutation,
  useCheckUserEmailMutation,
  useGetShortAdvertiserListQuery,
  useGetShortCampaignListQuery,
  useGetShortCreativeListQuery,
  useGetShortDSPEmbeddedQuery,
  useGetAssetByIdMutation,
  useGetAssetsListQuery,
  useGetReportsDataMutation,
} = apiSlice;
