export default class AssetBaseClass {
  id = null;
  type = null;
  name = '';
  advertiser = null;
  template = null;

  constructor(item = {}) {
    this.id = item.id || null;
    this.type = item.type || null;
    this.name = item.name || '';
    this.advertiser = item.advertiser || null;
    this.template = item.template || null;
  }
}
