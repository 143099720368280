import React, { memo } from 'react';
import PropTypes from 'prop-types';

function ImageCreative({ url, className }) {
  return <img width="100%" height="100%" src={ url } className={ className } alt="creative" />;
}

ImageCreative.defaultProps = { className: null };

ImageCreative.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default memo(ImageCreative);
