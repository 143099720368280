import classNames from 'classnames';
import React, { memo } from 'react';
import classes from './PreviewCreative.module.scss';
import CREATIVE_TEMPLATES from '../../constants/dictionary/creativeTemplateDictionary';

const MAIN_IMAGE_SIZE = CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.SIZE;
function CustomNativeCreative({ mainUrl, logoUrl, title, description, buttonText, attributionText, className }) {
  return (
    <div className={ classNames(className, classes.customNativePreview) }>
      <div className={ classes.mainContainer }>
        {mainUrl ?
          <img src={ mainUrl } className={ classes.main } alt="main" />
          : <div className={ classes.mainPlaceholder }>Main asset `${MAIN_IMAGE_SIZE.width}x${MAIN_IMAGE_SIZE.height}`</div>}
      </div>
      <div className={ classes.logoContainer }>
        <div className={ classes.logo }>
          {logoUrl ?
            <img src={ logoUrl } className={ classes.logo } alt="logo" />
            : <div className={ classes.logoPlaceholder }>Logo asset</div>}
          <h5 className={ classes.title }>{title}</h5>
        </div>
        <p className={ classes.description }>
          {description}
        </p>
        <div className={ classes.button }>
          <button type="button">{buttonText}</button>
          <div className={ classes.attribution }>
            {attributionText}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(CustomNativeCreative);
