import { useCallback, useState } from 'react';

import useAdvertiserBalance from 'modules/AdvertiserBalance/useAdvertiserBalance';
import { useGetAdvertiserListMutation } from '../../advertiserApiSlice';

function materialQueryToParams(query) {
  const { orderBy, orderDirection, page, pageSize, search, balance, status } = query;
  return {
    ...(balance && { balanceStates: [balance] }),
    ...(page && { page }),
    ...(search && { search }),
    ...(pageSize && { size: pageSize }),
    ...(orderBy && { sortBy: orderBy.field }),
    ...(orderDirection && { sortOrder: orderDirection.toUpperCase() }),
    ...(status && { statuses: [status] }),
  };
}

const useAdvertiserList = (filters) => {
  const { fillAdvertiserBalance } = useAdvertiserBalance();

  const [refillBalanceData, setRefillBalanceData] = useState({
    isOpen: false,
    advertiserId: null,
  });
  const [billingHistoryData, setBillingHistoryData] = useState({
    isOpen: false,
    advertiserId: null,
  });

  const toggleBillingHistoryModal = useCallback(({ advertiserId = null } = {}) => {
    setBillingHistoryData((prevState) => ({
      isOpen: !prevState.isOpen,
      advertiserId,
    }));
  }, [setBillingHistoryData]);

  const toggleRefillBalanceModal = useCallback(({ advertiserId = null, isOpen = null } = {}) => {
    setRefillBalanceData((prevState) => ({
      isOpen: isOpen || !prevState.isOpen,
      advertiserId,
    }));
  }, [setRefillBalanceData]);

  const [getAdvertiserList, {
    data: {
      payload: advertisers = [],
      _meta = {},
    } = {},
  }] = useGetAdvertiserListMutation();

  const refillBalanceByAdvertiserId = useCallback(async (advertiserId, values) => {
    if (!advertiserId) return;

    await fillAdvertiserBalance(advertiserId, values);
    toggleRefillBalanceModal();
  }, [fillAdvertiserBalance, toggleRefillBalanceModal]);

  const requestAdvertisers = useCallback(async (query = {}) => {
    const { _meta: meta = {}, payload = [] } = await getAdvertiserList(materialQueryToParams({ ...query, ...filters })).unwrap();
    return {
      data: payload,
      page: meta.page,
      totalCount: meta.total,
    };
  }, [filters, getAdvertiserList]);

  return {
    advertisers,
    meta: _meta,
    requestAdvertisers,
    refillBalanceData,
    billingHistoryData,
    toggleRefillBalanceModal,
    toggleBillingHistoryModal,
    refillBalanceByAdvertiserId,
  };
};

export default useAdvertiserList;
