import React, { memo, useCallback, useState } from 'react';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';

import PropTypes from 'prop-types';
import { useGetAssetsListQuery, useGetAssetByIdMutation } from '../../../api/apiSlice';

function CanvaSelect({ onChange, advertiser, template }) {
  const params = {
    size: 10000,
    templates: `${template}_CANVA`,
    advertisers: advertiser,
  };

  const { data: assetsList = [] } = useGetAssetsListQuery({ params }, { refetchOnFocus: true });
  const [getAssetById] = useGetAssetByIdMutation();

  const [selected, setSelected] = useState();

  const handleSelect = useCallback(async (e) => {
    const id = e.target.value;
    setSelected(id);
    const { data: asset } = await getAssetById({ id });
    if (asset) onChange(asset);
  }, [getAssetById, onChange]);

  return (
    <SelectVirtualized
      list={ assetsList }
      label="Canva Design"
      onChange={ handleSelect }
      value={ selected }
    />
  );
}

CanvaSelect.defaultProps = {
  onChange: Function.prototype,
  template: null,
  advertiser: null,
  expectedSize: null,
};

CanvaSelect.propTypes = {
  onChange: PropTypes.func,
  template: PropTypes.string,
  advertiser: PropTypes.number,
  expectedSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default memo(CanvaSelect);
