import types from 'actions/actionTypes/advertiserActionTypes';

export const INITIAL_FILTERS = {
  search: '',
  status: '',
  balance: '',
};

const initialState = { filters: INITIAL_FILTERS };

const advertiserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    default:
      return state;
  }
};

export default advertiserReducer;
