import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@mui/material';
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import Select from 'modules/_Factories/Select';
import InputFile from 'modules/InputFile';
import Input from 'modules/_Factories/Input/Input';
import UnsplashSelect from 'modules/Unsplash/UnsplashSelect';
import CropImage, { CropImageDialog } from 'modules/CropImage';
import FormError from 'modules/FormError/FormError';
import CanvaSelect from 'modules/Canva/Select/CanvaSelect';
import classes from 'modules/InputFile/InputFile.module.scss';

const getDataType = (value) => {
  if (value && typeof value === 'string') return CREATIVE_DATA_TYPES.types.UPLOAD_FROM_URL;

  return CREATIVE_DATA_TYPES.types.UPLOAD_FILE;
};

function UploadFile({
  title,
  value,
  onChangeValue,
  fileExtensions,
  dataTypeList,
  disabled,
  expectedFileSize,
  allowEditImage,
  error,
  templateForCanva,
  advertiser,
}) {
  const [dataType, setDataType] = useState(getDataType(value));
  const [openCropImageDialog, setOpenCropImageDialog] = useState(false);

  const toggleCropImageDialog = useCallback(() => {
    setOpenCropImageDialog((prevValue) => !prevValue);
  }, [setOpenCropImageDialog]);

  const handleChangeDataType = (e) => {
    setDataType(e.target.value);
    onChangeValue(null, e.target.value);
  };

  const renderFieldByDataType = (type) => {
    switch (type) {
      case CREATIVE_DATA_TYPES.types.UPLOAD_FROM_URL: {
        return (
          <>
            <Input
              name="url"
              label="Creative URL"
              value={ value }
                // Options
              fullWidth
              required
              disabled={ disabled }
                // Events
              onChange={ (e) => onChangeValue(e.target.value, type) }
            />
            <div className={ classes.filename }>
              <span className={ classes.label }>Filename:</span>
              <span>{value ? value.substring(value.lastIndexOf('/') + 1) : ''}</span>
            </div>
          </>
        );
      }
      case CREATIVE_DATA_TYPES.types.STOCK_LIB:
        return (
          <UnsplashSelect
            label="Select Image"
            name="url"
            value={ value }
            onChange={ (e) => onChangeValue(e.target.value, type) }
          />
        );
      case CREATIVE_DATA_TYPES.types.CANVA:
        return (
          <CanvaSelect
            advertiser={ advertiser }
            template={ templateForCanva }
            expectedSize={ expectedFileSize }
            onChange={ (asset) => onChangeValue(asset, type) }
          />
        );
      case CREATIVE_DATA_TYPES.types.UPLOAD_FILE:
      default: {
        return (
          <InputFile
            labelButton="Add File"
            accept={ fileExtensions }
            name="file"
            value={ value }
            disabled={ disabled }
            onChange={ (e) => {
              const [file] = e.target.files;
              onChangeValue(file, type);
            } }
          />
        );
      }
    }
  };

  const saveCroppedImage = useCallback((croppedImgBlob) => {
    const croppedImgFile = new File(
      [croppedImgBlob],
      `Cropped Image - ${expectedFileSize?.width}x${expectedFileSize?.height}`,
      { type: croppedImgBlob.type },
    );
    onChangeValue(croppedImgFile, dataType);
    setDataType(CREATIVE_DATA_TYPES.types.UPLOAD_FILE);
    toggleCropImageDialog();
  }, [dataType, expectedFileSize?.height, expectedFileSize?.width, onChangeValue, toggleCropImageDialog]);

  const getImageUrl = () => {
    if (!value) return null;
    return value instanceof File ? URL.createObjectURL(value) : value;
  };

  const imgUrl = getImageUrl();

  return (
    <Grid container spacing={ 2 }>
      <Grid item container justifyContent="space-between" alignItems="center" xs={ 12 }>
        <Grid item xs={ 8 }>
          <Typography className={ classes.title }>{title}</Typography>
        </Grid>
        {!disabled && allowEditImage && imgUrl && (
          <Grid item>
            <Button
              color="primary"
              onClick={ toggleCropImageDialog }
            >Edit Image
            </Button>
            <CropImageDialog open={ openCropImageDialog } onClose={ toggleCropImageDialog }>
              <CropImage imgUrl={ imgUrl } onSave={ saveCroppedImage } defaultCropSize={ expectedFileSize } />
            </CropImageDialog>
          </Grid>
        )}
      </Grid>
      {!disabled && (
        <Grid item xs={ 12 }>
          <Select
            name="dataType"
            label="Upload Type"
            value={ dataType }
            itemList={ dataTypeList }
            // Options
            isFilter
            isFilterText="None"
            fullWidth
            required
            disabled={ disabled }
            showNoneItem={ false }
            // Events
            onChange={ handleChangeDataType }
          />
        </Grid>
      )}
      <Grid item xs={ 12 }>
        <div className="mb-3">
          {renderFieldByDataType(dataType)}
          <FormError error={ error } />
        </div>
      </Grid>
    </Grid>
  );
}

UploadFile.defaultProps = {
  dataTypeList: CREATIVE_DATA_TYPES.dictionary,
  fileExtensions: '*',
  disabled: false,
  value: null,
  title: null,
  expectedFileSize: null,
  allowEditImage: false,
  templateForCanva: null,
  advertiser: null,
};

UploadFile.propTypes = {
  dataTypeList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  fileExtensions: PropTypes.string,
  disabled: PropTypes.bool,
  allowEditImage: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  expectedFileSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  advertiser: PropTypes.number,
  templateForCanva: PropTypes.string,
};

export default memo(UploadFile);
