import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './CardMetric.module.scss';

function CardMetric({
  isActive,
  title,
  subTitle,
  value,
  onClick,
  metric,
}) {
  return (
    <div
      role="button"
      tabIndex={ 0 }
      className={ classNames(classes.card, { [classes.active]: isActive }) }
      onClick={ () => onClick(metric) }
      onKeyDown={ onClick }
      onMouseDown={ (e) => e.preventDefault() }
    >
      <div className={ classes.wrapper }>
        <div className={ classes.title }>{title}</div>
        <div className="subtitle-text">{subTitle}</div>
        <div className={ classes.value }>{value}</div>
      </div>
    </div>
  );
}

CardMetric.defaultProps = {
  onClick: () => { },
  isActive: false,
};

CardMetric.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(CardMetric);
