import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

// Libs
import { get } from 'lodash';
import { separateByStatus } from 'libs/separateByStatus';

// Modules
import Datepicker from 'modules/_Factories/Datepicker/Datepicker';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';
import Input from 'modules/_Factories/Input/Input';
import { StepContainer } from 'modules/Steps';

// Dictionaries
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';
import accountTypesDictionary from 'constants/dictionary/accountTypesDictionary';

import classes from './General.module.scss';
import classesContainer from '../../AddCampaign.module.scss';
import DaysTimeForm from './DaysTimeForm/DaysTimeForm';
import { UserClass } from '../../../../../classes/userClass';
import { useGetCurrentUserMutation } from '../../../../../api/apiSlice';

const now = new Date();

function General({ formik, shortAdvertiserList, editable }) {
  const [, { data: user = new UserClass() }] = useGetCurrentUserMutation({ fixedCacheKey: 'current-user' });

  const isAdvertiser = useCallback(() => user.type === accountTypesDictionary.types.ADVERTISER, [user]);

  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList)
    .map((item) => ({
      ...item,
      disableOption: item.id === 'INACTIVE' || item.parentId === 'INACTIVE',
    })), [shortAdvertiserList]);

  const isAudience = formik.values.systemType === CampaignTypes.types.AUDIENCE;

  const checkAllTime = (e) => {
    const { checked } = e.target;
    formik.setFieldValue('isAllTime', checked);
  };
  const checkCertainTime = (e) => {
    const { checked } = e.target;
    formik.setFieldValue('isAllTime', !checked);
  };

  return (
    <StepContainer
      title="Campaign Details"
      subtitle="Enter the details of your advertising campaign"
      containerClassName={ classesContainer.stepContainer }
    >
      <Grid container spacing={ 6 }>
        <Grid
          container
          item
          sm={ 12 }
          md={ 6 }
          spacing={ 2 }
          direction="column"
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography>Campaign Title</Typography>
            <Input
              name="name"
              placeholder="Campaign Title"
              value={ get(formik.values, 'name') || '' }
                // Options
              fullWidth
              required
              disabled={ !editable }
                // Events
              onChange={ formik.handleChange }
              onBlur={ formik.handleBlur }
              setFieldTouched={ formik.setFieldTouched }
                // Extra
              errors={ formik.errors }
              touched={ formik.touched }
            />
          </Grid>
          <Grid item>
            <Typography>Advertiser</Typography>
            <SelectVirtualized
              name="advertiser"
              placeholder="Advertiser"
              value={ get(formik.values, 'advertiser') || '' }
              list={ advertiserOptions }
              disabled={ !!formik.values.id || !editable || isAdvertiser() }
              onChange={ formik.handleChange }
              setFieldTouched={ formik.setFieldTouched }
              error={ formik.errors.advertiser }
              isTouched={ formik.touched.advertiser }
            />
          </Grid>
          <Grid item>
            <Typography>Start Date</Typography>
            <Datepicker
              name="start"
              placeholder="mm/dd/yyyy"
              minDate={ now }
              maxDate={ get(formik.values, 'end') }
              selected={ get(formik.values, 'start') }
              fullWidth
              required={ !isAudience }
              disabled={ !editable || get(formik.values, 'runningAfterApproval') }
              onChange={ (startValue) => formik.setFieldValue('start', startValue) }
              onReset={ () => formik.resetField('start') }
              setFieldTouched={ formik.setFieldTouched }
              errors={ formik.errors }
              touched={ formik.touched }
            />
          </Grid>
          <Grid item>
            <Typography>End Date</Typography>
            <Datepicker
              name="end"
              placeholder="mm/dd/yyyy"
              minDate={ get(formik.values, 'start') || now }
              selected={ get(formik.values, 'end') }
              fullWidth
              required={ !isAudience }
              disabled={ !editable }
              onChange={ (endValue) => formik.setFieldValue('end', endValue) }
              onReset={ () => formik.resetField('end') }
              setFieldTouched={ formik.setFieldTouched }
              errors={ formik.errors }
              touched={ formik.touched }
            />
          </Grid>
          <Grid item className={ classes.checkbox_container }>
            <Typography>Schedule</Typography>
            <FormControlLabel
              control={ <Checkbox color="secondary" checked={ formik.values.isAllTime } onChange={ checkAllTime } /> }
              label="Run all time"
            />
            <FormControlLabel
              control={ <Checkbox color="secondary" checked={ !formik.values.isAllTime } onChange={ checkCertainTime } /> }
              label="Choose certain days and times"
            />
          </Grid>
        </Grid>
        {!formik.values.isAllTime && (
        <Grid container item sm={ 12 } md={ 6 } className={ classes.pt }>
          <DaysTimeForm
            formik={ formik }
          />
        </Grid>
        )}
      </Grid>
    </StepContainer>
  );
}

General.defaultProps = { shortAdvertiserList: [] };

General.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      id: PropTypes.number,
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      systemType: PropTypes.string,
      timeZone: PropTypes.string,
    }),
    initialValues: PropTypes.shape({ status: PropTypes.string }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({ advertiser: PropTypes.string, timeZone: PropTypes.string }),
    touched: PropTypes.shape({ advertiser: PropTypes.bool, timeZone: PropTypes.bool }),
  }).isRequired,
  shortAdvertiserList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  editable: PropTypes.bool.isRequired,
};

export default General;
