import types from 'actions/actionTypes/insightActionTypes';

export const INITIAL_FILTERS = { period: 'FOR_1_DAYS', threshold: 10 };

const initialState = { filters: { period: 'FOR_1_DAYS', threshold: 10 } };

const insightReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...INITIAL_FILTERS,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default insightReducer;
