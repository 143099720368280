import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@mui/material';

// Modules
import { AltTab, AltTabs } from 'modules/Tabs/AltTabs';
import { a11yProps } from 'modules/Tabs';
import { TabPanel } from 'modules/Tabs/TabPanel';
import StepTransitions from 'modules/Steps/StepTransitions';

// Classes
import { AdvertiserClass } from 'classes/advertiser/advertiserClass';

// Hooks
import useStepMenu from 'hooks/useStepMenu';

// Components
import { useNavigate } from 'react-router-dom';
import General from './General';
import Balance from './Balance';

// Styles
import classes from './Steps.module.scss';
import { useCreateAdvertiserMutation } from '../../advertiserApiSlice';
import { emitGoogleEvent } from '../../../../libs/gtag';
import EVENTS from '../../../../constants/analitics';

const getMenuSteps = (isEditForm) => [
  { id: 0, name: 'General', touched: true, valid: isEditForm, visible: true },
  { id: 1, name: 'Balance', touched: isEditForm, valid: isEditForm, visible: true },
];

function Steps({ formik, permissionEdit, isEditForm }) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [menuSteps, setMenuSteps] = useState(getMenuSteps(formik.values.id, isEditForm));
  const { steps, setValuesStep, stepHasErrors, stepIsDisabled } = useStepMenu(menuSteps);

  const handleCancel = useCallback(() => {
    navigate('/advertisers');
  }, [navigate]);

  useEffect(() => {
    setMenuSteps(getMenuSteps(formik.values.id, isEditForm));
  }, [formik.values.id, isEditForm, setMenuSteps]);

  const globalFields = useMemo(() => ['name'], []);

  const stepFields = useMemo(() => [
    { fields: ['name', 'status'], visible: true },
    { fields: [], visible: true },
  ].filter((item) => item.visible).map((item) => item.fields), []);

  const hasErrorsActiveTab = stepHasErrors([...globalFields, ...stepFields[activeTab]], formik.errors);

  const [createAdvertiser] = useCreateAdvertiserMutation();

  const handleChangeTab = useCallback((index) => {
    setActiveTab(index);
    setValuesStep(index, { touched: true });
  }, [setActiveTab, setValuesStep]);

  const handleNextStep = useCallback(async () => {
    // This code needs to get advertiser's id that to refill balance
    if (steps[activeTab].valid && !formik.values.id) {
      const model = new AdvertiserClass(formik.values);
      const advertiser = await createAdvertiser(model).unwrap();
      emitGoogleEvent(EVENTS.addAdvertiser, {
        advertiser_id: advertiser.id,
        advertiser_name: advertiser.name,
      });
      formik.setFieldValue('id', advertiser.id);
    }
    if (steps[activeTab].valid) handleChangeTab(activeTab + 1);
    [...globalFields, ...stepFields[activeTab]].forEach((name) => formik.setFieldTouched(name, true));
  }, [formik, createAdvertiser, steps, activeTab, handleChangeTab, globalFields, stepFields]);

  const handlePrevStep = useCallback(() => {
    handleChangeTab(activeTab - 1);
  }, [handleChangeTab, activeTab]);

  useEffect(() => {
    setValuesStep(activeTab, { valid: !hasErrorsActiveTab });
  }, [activeTab, hasErrorsActiveTab, setValuesStep]);

  const getTabClass = useCallback((item, index) => {
    if (index === activeTab) return classes.active;
    if (item.valid && item.touched) return classes.valid;
    return null;
  }, [activeTab]);

  const TabPanelContentList = useMemo(() => {
    const tabList = [
      { id: 0, component: <General formik={ formik } permissionEdit={ permissionEdit } /> },
      { id: 1, component: <Balance formik={ formik } /> },
    ];
    return tabList.filter((tabItem) => steps.find((item) => item.id === tabItem.id));
  }, [steps, formik, permissionEdit]);

  return (
    <div className="grayContainer">
      <AppBar position="relative" color="inherit" elevation={ 0 } className={ classes.bar }>
        <AltTabs value={ activeTab } onChange={ (e, index) => handleChangeTab(index) } variant={ 1 }>
          { steps.map((item, i) => (
            <AltTab
              key={ item.id }
              disableRipple
              label={ item.name }
              disabled={ (hasErrorsActiveTab && i > activeTab) || stepIsDisabled(i) }
              variant={ 1 }
              className={ getTabClass(item, i) }
              { ...a11yProps(item.id) }
            />
          )) }
        </AltTabs>
      </AppBar>

      {TabPanelContentList.map((item, index) => (
        <TabPanel key={ item.id } value={ activeTab } index={ index }>{item.component}</TabPanel>
      ))}

      <StepTransitions
        handleNext={ handleNextStep }
        handlePrev={ handlePrevStep }
        activeTab={ activeTab }
        tabsLength={ stepFields.length }
        submit={ formik.handleSubmit }
        handleCancel={ handleCancel }
      />
    </div>
  );
}

Steps.defaultProps = {
  permissionEdit: true,
  isEditForm: false,
};

Steps.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
  }).isRequired,
  permissionEdit: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

export default memo(Steps);
