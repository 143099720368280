import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Libs
import { textFormat } from 'libs/textFormat';

// Modules
import InfoValue from 'modules/InfoValue';
import PageTitle from 'modules/PageTitle/PageTitle';
import ProfileHeader from 'modules/ProfileHeader/ProfileHeader';
import Steps from './Steps';

// Hooks
import useFormAdvertiser from './useFormAdvertiser';

// Styles
import classes from './FormAdvertiser.module.scss';

function FormAdvertiser({
  handleSubmit,
  initialValues,
  permissionEdit,
  isEditForm,
}) {
  const { formik } = useFormAdvertiser({ initialValues, handleSubmit });

  const pageTitle = formik.values?.id ? 'Edit Advertiser' : 'Add Advertiser';

  return (
    <>
      <ProfileHeader />
      <div className={ classNames(classes.header, 'mb-5') }>
        <PageTitle title={ pageTitle } showBreadcrumbs lastElementName={ formik.values?.id && formik.values.name } />

        {formik.values.id && (
          <InfoValue className="mr-3" label="Balance:" value={ textFormat(formik.values.balance, 'currency') } />
        )}
      </div>

      <Steps formik={ formik } permissionEdit={ permissionEdit } isEditForm={ isEditForm } />
    </>
  );
}

FormAdvertiser.defaultProps = {
  initialValues: {
    id: null,
    balance: 0,
    balanceModel: {
      amount: 0,
      notes: '',
    },
    name: '',
    domain: '',
    status: 'ACTIVE',
    contacts: {
      email: '',
      skype: '',
      phone: '',
    },
  },
  handleSubmit: Function.prototype,
  permissionEdit: true,
  isEditForm: false,
};

FormAdvertiser.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    balance: PropTypes.number,
    name: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    contacts: PropTypes.shape({
      email: PropTypes.string.isRequired,
      skype: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    }),
  }),
  handleSubmit: PropTypes.func,
  permissionEdit: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

export default memo(FormAdvertiser);
