import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SquareLogo } from 'assets/images/logos/logo.svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

function Logo({ className, to }) {
  return (
    <div className={ classNames(className) }>
      <Link to={ to }>
        <SquareLogo />
      </Link>
    </div>
  );
}

Logo.defaultProps = { className: '' };

Logo.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default Logo;
