// Libs
import { buildHashMap } from 'libs/buildHashMap';

const dayTypes = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
};
const dayDictionary = [
  { id: dayTypes.SUNDAY, name: 'Sun', fullName: 'Sunday' },
  { id: dayTypes.MONDAY, name: 'Mon', fullName: 'Monday' },
  { id: dayTypes.TUESDAY, name: 'Tue', fullName: 'Tuesday' },
  { id: dayTypes.WEDNESDAY, name: 'Wed', fullName: 'Wednesday' },
  { id: dayTypes.THURSDAY, name: 'Thu', fullName: 'Thursday' },
  { id: dayTypes.FRIDAY, name: 'Fri', fullName: 'Friday' },
  { id: dayTypes.SATURDAY, name: 'Sat', fullName: 'Saturday' },
];
const dayHashMap = buildHashMap(dayDictionary, 'id', 'fullName');

const intervalTypes = {
  AM: 'AM',
  PM: 'PM',
};
const intervalDictionary = [
  { id: dayTypes.AM, name: 'AM' },
  { id: dayTypes.PM, name: 'PM' },
];

export default {
  dayTypes,
  dayDictionary,
  dayHashMap,
  intervalTypes,
  intervalDictionary,
};
