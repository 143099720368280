import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

// Libs
import { format } from 'date-fns';
import { isNumber } from 'lodash';
import { textFormat } from 'libs/textFormat';

// Modules
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';
import MaterialTablePagination from 'modules/_MaterialTable/MaterialTablePagination/MaterialTablePagination';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';

function TableBillingHistory({ data, perPage }) {
  const pageSize = data.length > perPage ? perPage : data.length;

  const getRowData = useCallback((rowData, type, field) => {
    switch (type) {
      case 'row': return rowData[field];
      case 'group': return rowData;
      default: return null;
    }
  }, []);

  const columns = useMemo(() => [
    {
      title: 'Date',
      field: 'time',
      defaultSort: 'desc',
      render: (rowData, type) => {
        const result = getRowData(rowData, type, 'time');

        return (
          <MaterialTableText variant="light">
            {result ? format(result, 'MM/dd/yyyy hh:mm a') : '-'}
          </MaterialTableText>
        );
      },
    },
    {
      title: 'Type',
      field: 'amount',
      render: (rowData, type) => {
        const getType = (amount) => (amount >= 0 ? 'Credit' : 'Debit');
        const result = getRowData(rowData, type, 'amount');

        return (
          <MaterialTableText variant="regular">
            {isNumber(result) ? getType(result) : '-'}
          </MaterialTableText>
        );
      },
    },
    {
      title: 'Amount',
      field: 'amount',
      render: (rowData, type) => {
        const result = getRowData(rowData, type, 'amount');

        return (
          <MaterialTableText variant="regular">
            {isNumber(result) ? textFormat(Math.abs(result), 'currency') : '-'}
          </MaterialTableText>
        );
      },
    },
    {
      title: 'Balance',
      field: 'balance',
      render: (rowData, type) => {
        const result = getRowData(rowData, type, 'balance');

        return (
          <MaterialTableText>
            {result ? textFormat(result, 'currency') : '-'}
          </MaterialTableText>
        );
      },
    },
    {
      title: 'Comment',
      field: 'notes',
      render: (rowData, type) => {
        const result = getRowData(rowData, type, 'notes');

        return (
          <MaterialTableText variant="regular">
            {result || ''}
          </MaterialTableText>
        );
      },
    },
  ], [getRowData]);

  return (
    <MaterialTable
      columns={ columns }
      data={ data }
      options={ {
        draggable: false,
        toolbar: false,
        paging: true,
        showEmptyDataSourceMessage: false,
      } }
      icons={ { SortArrow: MaterialTablesSortIcon } }
      components={ {
        // eslint-disable-next-line react/no-unstable-nested-components
        Pagination: (PaginationProps) => <MaterialTablePagination { ...PaginationProps } perPage={ pageSize } />,
        Container: MaterialTableContainer,
      } }
    />
  );
}

TableBillingHistory.defaultProps = {
  data: [],
  perPage: 5,
};

TableBillingHistory.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
    time: PropTypes.number.isRequired,
    notes: PropTypes.string.isRequired,
  }).isRequired),
  perPage: PropTypes.number,
};

export default memo(TableBillingHistory);
