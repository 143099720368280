import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import classNames from 'classnames';
import classes from './RouterLink.module.scss';

function RouterLink({ to, children, className, target }) {
  return (
    <Link to={ to } target={ target } className={ classNames(classes.link, className) }>
      {children}
    </Link>
  );
}

RouterLink.defaultProps = { className: '' };

RouterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

export default RouterLink;
