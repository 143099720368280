const types = {
  IMAGE: 'IMAGE',
  TITLE: 'TITLE',
  VIDEO: 'VIDEO',
  DATA: 'DATA',
};

const dictionary = [
  { id: types.IMAGE, name: 'Image' },
  { id: types.TITLE, name: 'Title' },
  { id: types.VIDEO, name: 'Video' },
  { id: types.DATA, name: 'Data' },
];

export default {
  types,
  dictionary,
};
