import { MenuItem } from '@mui/material';
import React, { memo, useCallback, useRef, useState, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { debounce } from 'lodash';
import config from 'config/config';
import Link from 'modules/_Router/RouterLink/RouterLink';
import { useSnackbar } from 'notistack';
import AsyncSelect from 'modules/_Factories/AsyncSelect';
import classes from './UnsplashSelect.module.scss';

const INITIAL_LIST_DATA = {
  error: null,
  total: 0,
  total_pages: 0,
  results: [],
};

const UNSPLASH_REF_PARAMS = 'utm_source=Self_Serve&utm_medium=referral';
const UNSPLASH_REF_URL = `https://unsplash.com?${UNSPLASH_REF_PARAMS}`;
const UNSPLASH_SEARH_URL = 'https://api.unsplash.com/search/photos';

const handleUnslpashFetch = (url) => fetch(url)
  .then((res) => {
    if (res.status === 200) return res.json();
    if (res.status === 403) return { ...INITIAL_LIST_DATA, error: 'Rate Limit Exceeded' };
    if (res.status === 401) return { ...INITIAL_LIST_DATA, error: 'Unable to authorise Unsplash' };
    return { ...INITIAL_LIST_DATA, error: res.json()?.errors[0] || 'Something went wrong' };
  }).catch(() => ({ ...INITIAL_LIST_DATA, error: 'Something went wrong' }));

const getData = ({ page, search }) => {
  const perPage = 25;
  return handleUnslpashFetch(`${UNSPLASH_SEARH_URL}?client_id=${config.unsplashKey}&page=${page}&query=${search}&per_page=${perPage}`);
};

function UnsplashSelect({
  label,
  value,
  onChange,
  disabled,
}) {
  const [listData, setListData] = useState(INITIAL_LIST_DATA);
  const [selected, setSelected] = useState(null);
  const [unsplashAuthor, setUnsplashAuthor] = useState(null);

  const searchRef = useRef('');
  const selectedValueRef = useRef('');
  const handleSelectCallbackRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const debouncedSearchData = useMemo(() => debounce((searchValue) => {
    if (!searchValue) {
      setListData(INITIAL_LIST_DATA);
      return;
    }
    getData({ page: 1, search: searchValue }).then((res) => {
      setListData((prevValue) => ({
        ...prevValue,
        ...res,
        results: res.error ? [] : res.results,
        error: res.error || null,
      }));
    });
  }, 500), [setListData]);

  const handleSelectImage = useCallback(async (rowItem) => {
    if (disabled) return;
    const downloadUrl = `${rowItem?.links?.download_location}&client_id=${config.unsplashKey}`;
    const response = await handleUnslpashFetch(downloadUrl);
    const { url, error: responseError } = response;
    if (responseError) {
      if (responseError === 'Rate Limit Exceeded') {
        enqueueSnackbar('Rate Limit Exceeded. Please try again later.', { variant: 'error' });
      } else {
        enqueueSnackbar(responseError, { variant: 'error' });
      }
      if (handleSelectCallbackRef.current) handleSelectCallbackRef.current();
      return;
    }
    const author = {
      name: rowItem?.user?.username,
      link: rowItem?.user?.links?.html,
    };
    onChange({ target: { value: `${url}&w=400&fit=max` } });
    setUnsplashAuthor(author);
    setSelected(rowItem?.id);
    if (handleSelectCallbackRef.current) handleSelectCallbackRef.current();
  }, [disabled, enqueueSnackbar, onChange]);

  const loadMoreRows = useCallback(({ stopIndex }) => {
    const page = Math.ceil(stopIndex / 25);
    if (!searchRef.current || listData.results.length === listData.total || listData.results.length >= page * 25) return Promise.resolve();
    return getData({ search: searchRef.current, page }).then((res) => {
      setListData((prevValue) => ({
        ...prevValue,
        ...res,
        results: res.error ? [] : [...prevValue.results, ...res.results],
        error: res.error || null,
      }));
    });
  }, [setListData, listData]);

  const rowRenderer = useCallback(({ index, key, style }) => {
    const list = listData.results;
    const imageUrl = list[index]?.urls?.raw;
    const isChecked = value && value.includes(imageUrl);
    return (
      <MenuItem
        className={ cn(classes.menuItem, { [classes.menuItemChecked]: isChecked }) }
        style={ style }
        key={ key }
        value={ list[index]?.urls?.raw }
        onClick={ () => handleSelectImage(list[index]) }
      >
        {imageUrl && <img className={ classes.menuItemImage } src={ `${imageUrl}&w=40&h=30` } alt="" />}
        <span className={ classes.menuItemText }>{list[index]?.description || list[index]?.alt_description}</span>
      </MenuItem>
    );
  }, [listData, value, handleSelectImage]);

  useEffect(() => {
    const selectedItem = listData.results.find((rowItem) => rowItem.id === selected);
    if (selected && selectedValueRef.current && !selectedItem) return;
    selectedValueRef.current = selectedItem?.description || selectedItem?.alt_description;
  }, [listData.results, selected]);

  return (
    <>
      <AsyncSelect
        label={ label }
        listData={ listData }
        selectedValue={ selectedValueRef.current }
        rowRenderer={ rowRenderer }
        loadMoreRows={ loadMoreRows }
        debouncedSearchData={ debouncedSearchData }
        searchRef={ searchRef }
        handleSelectCallbackRef={ handleSelectCallbackRef }
      />
      {unsplashAuthor && (
        <div className={ classes.author }>Image by
          <Link
            to={ `${unsplashAuthor.link}?${UNSPLASH_REF_PARAMS}` || UNSPLASH_REF_URL }
            target="_blank"
            className={ classes.link }
          > {unsplashAuthor.name}
          </Link> on
          <Link
            to={ UNSPLASH_REF_URL }
            target="_blank"
            className={ classes.link }
          > Unsplash
          </Link>
        </div>
      )}
    </>
  );
}

UnsplashSelect.defaultProps = {
  filterText: 'none',
  onChange: () => {},
};

export default memo(UnsplashSelect);
