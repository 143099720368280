import { refreshAccessToken } from '../../client/interceptors/refreshTokenInterceptor';
import { getRefreshToken, getToken } from '../storageLibs';
import config from '../../config/config';

const { canvaBaseUrl, revenueServerUrl } = config;

export const redirectToCanva = (params) => {
  window.location.replace(`${canvaBaseUrl}apps/configured?${params.toString()}`);
};

export const handleAuth = async ({
  state,
  nonce,
  canvaUserId,
  brandId,
}) => {
  await refreshAccessToken();
  const authToken = getToken();
  const refreshToken = getRefreshToken();

  try {
    const res = await fetch(`${revenueServerUrl}canva/users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        authToken,
        refreshToken,
        userId: canvaUserId,
        brandId,
        state,
        nonce,
      }),
    });

    switch (res.status) {
      case 200: {
        const params = new URLSearchParams({
          success: 'true',
          state,
        });
        redirectToCanva(params);
        break;
      }
      case 401: {
        const params = new URLSearchParams({
          success: 'false',
          state,
          errors: 'invalid_nonce',
        });
        redirectToCanva(params);
        break;
      }
      default: {
        const params = new URLSearchParams({
          success: 'false',
          state,
          errors: `error_${res.status}`,
        });
        redirectToCanva(params);
        break;
      }
    }
  } catch (e) {
    const params = new URLSearchParams({
      success: 'false',
      state,
      errors: e.message,
    });
    redirectToCanva(params);
  }
};
