import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import usePermissions from 'hooks/usePermissions';
// Constants
import { Statuses } from 'constants/index';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
// Modules
import Select from 'modules/_Factories/Select/Select';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';

function Filters({ values, advertiserList, campaignList, onChange, onReset }) {
  const {
    status,
    type,
    advertisers,
    campaigns,
  } = values;

  const permissions = usePermissions();

  const handleChangeFilters = (name) => (value) => {
    onChange({ ...values, [name]: value });
  };

  return (
    <Grid container alignItems="center" justifyContent="space-around" spacing={ 2 }>
      <Grid item sm="auto">
        <Button
          variant="contained"
          color="primary"
          onClick={ onReset }
        >
          Reset
        </Button>
      </Grid>
      <Grid item xs sm />
      <Grid item xs={ 12 } sm={ 2 }>
        <Select
          isFilter
          itemList={ CREATIVE_TYPES.dictionary }
          label="Type"
          setValue={ handleChangeFilters('type') }
          value={ type }
        />
      </Grid>
      <Grid item xs={ 12 } sm={ 2 }>
        <Select
          isFilter
          itemList={ Statuses }
          label="Status"
          setValue={ handleChangeFilters('status') }
          value={ status }
        />
      </Grid>
      {permissions.advertiserRead && (
        <Grid item xs={ 12 } sm={ 2 }>
          <SelectVirtualized
            multiple
            tree
            list={ advertiserList }
            label="Advertisers"
            onChange={ (e) => handleChangeFilters('advertisers')(e.target.value) }
            value={ advertisers }
          />
        </Grid>
      )}
      {permissions.campaignRead && (
        <Grid item xs={ 12 } sm={ 2 }>
          <SelectVirtualized
            multiple
            tree
            list={ campaignList }
            label="Campaigns"
            onChange={ (e) => handleChangeFilters('campaigns')(e.target.value) }
            value={ campaigns }
          />
        </Grid>
      )}
    </Grid>
  );
}

Filters.defaultProps = {
  values: {
    search: '',
    status: '',
    type: '',
    advertisers: [],
    campaigns: [],
  },
  advertiserList: [],
  campaignList: [],
  onChange: Function.prototype,
  onReset: Function.prototype,
};

Filters.propTypes = {
  values: PropTypes.shape({
    search: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['ACTIVE', 'INACTIVE', '']).isRequired,
    type: PropTypes.oneOf(['VIDEO', 'NATIVE', '']).isRequired,
    advertisers: PropTypes.arrayOf(PropTypes.number).isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  advertiserList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
  })),
  campaignList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func,
  onReset: PropTypes.func,
};

export default memo(Filters);
