import React, { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import NativeCreativeClass from 'classes/creative/nativeCreativeClass';
import VideoCreativeClass from 'classes/creative/videoCreativeClass';

import StepTitle from '../StepTitle';
import CreativePreview from '../../Creatives/CreativePreview';
import classes from './Creatives.module.scss';

function Creatives({ creatives }) {
  const renderCreativeList = (creativeList) => {
    if (!creativeList.length) return null;

    return creativeList.map((item) => (
      <Grid key={ item.name + item.id } container item spacing={ 1 }>
        <Grid item sm={ 12 } key={ item }>
          {item.name && (
            <Typography variant="h6" className={ classes.titleText }>{item.name}</Typography>
          )}
          <CreativePreview className={ classes.preview } creative={ item } />
        </Grid>
      </Grid>
    ));
  };

  return (
    <section className={ classes.section }>
      <StepTitle name="Creative" className={ classes.title } />
      <Grid container spacing={ 3 } className={ classes.container }>
        {renderCreativeList(creatives)}
      </Grid>
    </section>
  );
}

Creatives.defaultProps = { creatives: [] };

Creatives.propTypes = {
  creatives: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape(NativeCreativeClass),
    PropTypes.shape(VideoCreativeClass),
  ])),
};

export default memo(Creatives);
