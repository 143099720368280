// Types
import types from 'actions/actionTypes/authActionTypes';

const setIsAuthenticated = (isAuthenticated) => (dispatch) => {
  dispatch({
    type: types.SET_IS_AUTHENTICATED,
    payload: { isAuthenticated },
  });

  return true;
};

export default { setIsAuthenticated };
