import { buildHashMap } from 'libs/buildHashMap';

const types = {
  NATIVE: 'NATIVE',
  VIDEO: 'VIDEO',
  // VAST_TAG: 'VAST_TAG',
};

const dictionary = [
  { id: types.NATIVE, name: 'Native Ad' },
  { id: types.VIDEO, name: 'Video Ad' },
  // { id: types.VAST_TAG, name: 'VAST Tag' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
