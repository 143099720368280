import { Menu, SvgIcon } from '@mui/material';
import React, { memo, useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { InfiniteLoader, List } from 'react-virtualized';
import cn from 'classnames';
import PropTypes from 'prop-types';
import classes from './AsyncSelect.module.scss';
import ClearableInput from '../Input/ClearableInput/ClearableInput';

const ITEM_HEIGHT = 96;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getcontentanchorel: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderRadius: '6px',
      marginTop: '16px',
    },
  },
};

function AsyncSelect(props) {
  const {
    label,
    error,
    disabled,
    className,
    filterText,
  } = props;

  const {
    listData,
    selectedValue,
    rowRenderer,
    loadMoreRows,
    debouncedSearchData,
    searchRef,
    handleSelectCallbackRef,
  } = props;

  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const fakeSelectRef = useRef(null);

  useEffect(() => {
    if (searchRef) searchRef.current = search;
    debouncedSearchData(search);
  }, [debouncedSearchData, search, searchRef]);

  const handleClick = useCallback(
    (event) => {
      if (disabled) return;
      setAnchorEl(event.currentTarget);
    },
    [disabled],
  );

  const handleClose = useCallback(
    () => {
      setAnchorEl(null);
      setSearch('');
    },
    [setAnchorEl],
  );

  const handleSelectCustom = useCallback(() => {
    setTimeout(handleClose, 0);
  }, [handleClose]);

  useEffect(() => {
    if (handleSelectCallbackRef) {
      handleSelectCallbackRef.current = handleSelectCustom;
    }
  }, [handleSelectCallbackRef, handleSelectCustom]);

  const hasError = useMemo(() => Boolean(error), [error]);

  const isRowLoaded = useCallback(({ index }) => index < listData.results.length && listData.results[index] !== null, [listData]);

  const rowCount = listData.total;

  const calculateHeight = () => {
    const height = rowCount * 35 + 90;
    return height > 300 ? 300 : height;
  };

  const handleChangeSearch = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const selectText = selectedValue || filterText;

  return (
    <div className={ cn([classes.wrapper, className, {
      [classes.opened]: Boolean(anchorEl),
      [classes.hasError]: hasError,
      [classes.isDisabled]: disabled,
    }]) }
    >
      <div
        role="button"
        tabIndex={ 0 }
        className={ cn([classes.fakeSelect]) }
        onClick={ handleClick }
        onKeyPress={ handleClick }
        ref={ fakeSelectRef }
      >
        <span className={ classes.fakeSelectText }>{label}</span>
        <span className={ cn({
          [classes.fakeSelectCounter]: label,
          [classes.fakeSelectText]: !label,
        }) }
        >
          {label ? `(${selectText})` : selectText}
        </span>
        <SvgIcon className={ classes.fakeSelectArrow }>
          <path d="M7 10l5 5 5-5z" />
        </SvgIcon>
      </div>

      {hasError &&
      <span className={ classes.fakeSelectHelperText }>{error}</span>}

      <Menu
        className={ classes.menu }
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ handleClose }
        onKeyDownCapture={ (e) => {
          e.stopPropagation();
        } }
        { ...MenuProps }
      >
        <div className={ classes.menuSearchWrapper }>
          <ClearableInput
            autoFocus
            placeholder="Search"
            value={ search }
            onClear={ handleClearSearch }
            label=""
            name="search"
            onChange={ handleChangeSearch }
            fullWidth
          />
          {listData.error && <div className={ classes.noResults }>{listData.error}</div>}
          {!listData.error && !search && <div className={ classes.noResults }>Start searching...</div>}
          {!listData.error && search && !rowCount && <div className={ classes.noResults }>No Results</div>}
        </div>

        {fakeSelectRef?.current && (
        <InfiniteLoader
          isRowLoaded={ isRowLoaded }
          loadMoreRows={ loadMoreRows }
          rowCount={ rowCount }
        >
          {({ onRowsRendered, registerChild }) => (
            <List
              ref={ registerChild }
              className={ classes.menuList }
              width={ fakeSelectRef?.current?.offsetWidth > 400 ? fakeSelectRef?.current?.offsetWidth : 400 }
              height={ calculateHeight() }
              onRowsRendered={ onRowsRendered }
              rowRenderer={ rowRenderer }
              rowCount={ rowCount }
              rowHeight={ 35 }
            />
          )}
        </InfiniteLoader>
        )}
      </Menu>
    </div>
  );
}

AsyncSelect.defaultProps = {
  filterText: 'none',
  onChange: () => {},
  listData: {
    error: null,
    total: 0,
    total_pages: 0,
    results: [],
  },
};

AsyncSelect.prototype = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  selectedValue: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  filterText: PropTypes.string,
  searchRef: PropTypes.shape({ current: PropTypes.string }),
  listData: PropTypes.shape({
    error: PropTypes.string,
    total: PropTypes.number,
    total_pages: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    results: PropTypes.array,
  }),
  handleSelect: PropTypes.func.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default memo(AsyncSelect);
