import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce, intersectionWith } from 'lodash';

// Constants
import { CampaignSteps } from 'constants/index';

// Classes
import { CampaignClass } from 'classes/campaign/campaignClass';
import { UserClass } from 'classes/userClass';

// Hooks
import { useSnackbar } from 'notistack';
import { useFormik, setNestedObjectValues } from 'formik';

// Libs
import {
  validateErrors,
  validateLength,
  validateMin,
  validateMax,
  validateRequired,
  validateTimeRange,
  validateCreatives,
  validateMaxDate,
} from 'libs/validateErrors';
import {
  getPeriodsInitialData,
  getCampaignModelAndFiles, checkTargeting,
} from 'libs/campaign/campaignUtils';
import { emitGoogleEvent } from 'libs/gtag';

// Dictionaries
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import DeliveryTypes from 'constants/dictionary/deliveryTypesDictionary';
import BiddingTypes from 'constants/dictionary/biddingTypesDictionary';
import accountTypesDictionary from 'constants/dictionary/accountTypesDictionary';
import EVENTS from 'constants/analitics';

// Actions
import {
  getFilteredStates as getFilteredStatesReq,
  getFilteredCongressionalDistricts as getFilteredCongressionalDistrictsReq,
  getFilteredCities as getFilteredCitiesReq,
} from 'actions/dictionaryActions';

// Selectors
import { useGetCurrentUserMutation, useGetDMAsQuery, useGetShortAdvertiserListQuery } from 'api/apiSlice';
import { useGetCampaignByIdMutation, useCreateCampaignMutation, useUpdateCampaignMutation, campaignApiSlice } from '../campaignApiSlice';

const useAddCampaign = ({ id, systemType, setEditable, isCopy }) => {
  // State
  const [innerId] = useState(id);
  const [isLoading, setIsLoading] = useState(true);

  // Hooks
  const [getCampaignById, { data: campaign = new CampaignClass() }] = useGetCampaignByIdMutation();
  const [createCampaign] = useCreateCampaignMutation();
  const [updateCampaign] = useUpdateCampaignMutation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // Selectors
  const [, { data: user = new UserClass() }] = useGetCurrentUserMutation({ fixedCacheKey: 'current-user' });
  const { data: { DMAs = [] } = {} } = useGetDMAsQuery();
  const { data: shortAdvertiserList = [] } = useGetShortAdvertiserListQuery({ size: 1 });

  // Formik
  const initialValues = useMemo(() => {
    const campaignCopy = { ...campaign };

    if (!campaignCopy.timeZone) campaignCopy.timeZone = user.timeZone;
    if (!campaignCopy.delivery) campaignCopy.delivery = DeliveryTypes.types.SMOOTH;
    if (!campaignCopy.bidding) campaignCopy.bidding = BiddingTypes.types.AUTO_BID;
    if (!campaignCopy.advertiser && user.type === accountTypesDictionary.types.ADVERTISER && shortAdvertiserList[0]) {
      campaignCopy.advertiser = shortAdvertiserList[0].id;
    }

    campaignCopy.periods = getPeriodsInitialData(campaign.periods);
    campaignCopy.countries = { values: [{ id: 'US' }] };

    const values = {
      ...campaignCopy,
      ...(isCopy && {
        id: null,
        name: `${campaign.name} copy`,
        status: CampaignStatuses.types.DRAFT,
      }),
      isAllTime: !campaign.periods.length,
      files: [],
    };

    return values;
  }, [campaign, user.timeZone, user.type, isCopy, shortAdvertiserList]);

  const initialStatus = useRef(initialValues.status);
  const isDisabled = useMemo(() => {
    switch (initialValues.status) {
      case CampaignStatuses.types.DRAFT: return false;
      case CampaignStatuses.types.PENDING: return false;
      case CampaignStatuses.types.PENDING_PUBLISHER_REVIEW: return false;
      case CampaignStatuses.types.APPROVED: return false;
      case CampaignStatuses.types.RUNNING: return false;
      case CampaignStatuses.types.PAUSED: return false;
      default: return true;
    }
  }, [initialValues.status]);

  const validate = (values) => {
    const requiredValues = [
      'name',
      'advertiser',
      'start',
      'end',
      'budget',
      'delivery',
      'bidding',
    ];

    const validation = { requiredValues };

    const periodErrors = {};
    Object.keys(values.periods).forEach((day) => {
      if (!values.periods[day].checked) return;
      const error = validateTimeRange(values.periods[day].start, values.periods[day].end);
      Object.assign(periodErrors, { [day]: error });
    });

    const isPeriodErrors = Object.values(periodErrors).some((day) => day);

    const nameError = validateLength(values.name, 'Title', 1, 100);

    const budgetError = validateMin(values.budget, 0.01, 'Budget') ||
      validateMax(values.budget, 1e9, 'Budget');
    const cpmError = (values.bidding === BiddingTypes.types.MANUAL &&
      (validateRequired(values.cpm, 'CPM') ||
      validateMin(values.cpm, 0.01, 'CPM') ||
      validateMax(values.cpm, 1e9, 'CPM'))
    );

    const startDateError = validateMaxDate(values.start);
    const endDateError = validateMaxDate(values.end);

    const initialCreative = initialValues.creativeFlights[0]?.creatives[0] || {};
    const creatives = values.creativeFlights[0].creatives || [];

    return {
      ...validateErrors(values, validation),
      ...(nameError && { name: nameError }),
      ...(budgetError && { budget: budgetError }),
      ...(cpmError && { cpm: cpmError }),
      ...validateCreatives({ creatives, id, initialCreative }),
      ...(isPeriodErrors && { periods: periodErrors }),
      ...(startDateError && { start: startDateError }),
      ...(endDateError && { end: endDateError }),
    };
  };

  const createFormData = (campaignModel) => {
    const { campaign: campaignValues, files } = campaignModel;
    const formData = new FormData();
    const blobCampaignModel = new Blob([JSON.stringify(campaignValues, null, 2)], { type: 'application/json' });
    formData.append('campaign', blobCampaignModel);
    if (files.length) {
      files.forEach((file) => {
        formData.append('files', file);
      });
    }

    return formData;
  };

  const processCreatingCampaign = async (values) => {
    const campaignModelAndFiles = getCampaignModelAndFiles(values);
    const formData = createFormData(campaignModelAndFiles);

    const createdCampaign = await createCampaign(formData).unwrap();
    return createdCampaign;
  };

  const processUpdatingCampaign = async (values) => {
    const campaignModelAndFiles = getCampaignModelAndFiles(values);
    const formData = createFormData(campaignModelAndFiles);

    return updateCampaign({ id: values.id, data: formData });
  };

  const submitHandler = async (values, { setSubmitting }) => {
    try {
      if (innerId && !isCopy) {
        await processUpdatingCampaign(values);
        enqueueSnackbar(`Campaign (id: ${innerId}) updated successfully`, { variant: 'success' });
      } else {
        const createdCampaign = await processCreatingCampaign(values);
        enqueueSnackbar(`Campaign (id: ${createdCampaign.id}) created successfully`, { variant: 'success' });

        emitGoogleEvent(EVENTS.publishCampaign, {
          campaign_id: createdCampaign.id,
          campaign_name: createdCampaign.name,
          advertiser_id: createdCampaign.advertiser,
          // advertiser_name: createdCampaign.advertiser.name,
          campaign_start_date: createdCampaign.start,
          campaign_end_date: createdCampaign.end,
          targeting: checkTargeting(values),
          bidding_type: createdCampaign.bidding,
          campaign_budget: createdCampaign.budget || null,
          campaign_cpm: createdCampaign.cpm || null,
        });
      }
    } catch (error) {
      // If error - set default campaign status
      values.status = initialStatus.current;
      const errorMessage = error.response?.data?.message || 'Something went wrong! Please, try again.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: submitHandler,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const { setFieldValue } = formik;

  // Add reset method TODO replace it to native formik method
  formik.resetField = (name) => {
    formik.setFieldValue(name, initialValues[name]);
  };

  // useEffect

  // revalidate on bidding cahnge
  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.bidding]);

  // reset creatives when advertiser changed
  useEffect(() => {
    if (formik.values.advertiser !== formik.values.creativeFlights[0]?.creatives[0]?.advertiser) {
      setFieldValue('creativeFlights[0].creatives', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.advertiser, setFieldValue]);

  // check copy errors,
  // if there is errors, set all fields touched
  useEffect(() => {
    if (!isCopy) return;
    const setFromikFieldsTouchedDebounced = debounce(async () => {
      const validationErrors = await formik.validateForm(initialValues);
      if (Object.keys(validationErrors).length > 0) {
        formik.setTouched(setNestedObjectValues(validationErrors, true));
      }
    }, [500]);
    setFromikFieldsTouchedDebounced();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, isCopy]);

  useEffect(() => {
    if (!initialValues.status) return;

    switch (initialValues.status) {
      case CampaignStatuses.types.CLOSED:
      case CampaignStatuses.types.FINISHED:
      case CampaignStatuses.types.CANCELED:
        setEditable(false);
        break;
      default:
        break;
    }
  }, [initialValues.status, setEditable]);

  useEffect(() => {
    (async () => {
      try {
        if (!innerId) return;
        await getCampaignById(innerId).unwrap();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [innerId, getCampaignById, setIsLoading]);

  useEffect(() => () => {
    CampaignSteps.forEach((item, i) => {
      if (i > 0) item.setTouched(false);
    });
    dispatch(campaignApiSlice.util.invalidateTags([{ type: 'Campaign', id: innerId }]));
  }, [dispatch, innerId]);

  // Validate Datepicker after reset action
  useEffect(() => {
    if (formik && systemType !== CampaignTypes.types.AUDIENCE) {
      const { values } = formik;
      if (!values.start) {
        formik.setFieldError('start', 'Start date is required');
      }
      if (!values.end) {
        formik.setFieldError('end', 'End date is required');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, systemType]);

  const { cities, states, dmas, districts, zips } = initialValues;
  // Should for receiving and show needed data by countries on Geography step
  useEffect(() => {
    if (!zips.values.length) return;

    const zipCodes = zips.values.map((zipId) => ({ id: zipId, name: zipId }));
    setFieldValue('zips.values', zipCodes);
  }, [setFieldValue, zips]);

  // Should for receiving and show needed data by DMA on Geography step
  useEffect(() => {
    if (!dmas.values.length) return;

    const intersectionDma = intersectionWith(DMAs, dmas.values, (a, b) => a.id === b);
    setFieldValue('dmas.values', intersectionDma);
  }, [setFieldValue, dmas, DMAs]);

  // Should for receiving and show needed data by states on Geography step
  useEffect(() => {
    if (!states.values.length) return;

    (async () => {
      const stateIds = states.values;
      const data = await getFilteredStatesReq({ id: stateIds })();
      const intersectionStates = intersectionWith(data, states.values, (a, b) => a.id === b).map((item) => ({
        ...item,
        name: `${item.name} (${item.id})`,
      }));
      setFieldValue('states.values', intersectionStates);
    })();
  }, [setFieldValue, states]);

  // Should for receiving and show needed data by congressional districts on Geography step
  useEffect(() => {
    if (!districts.values.length) return;

    (async () => {
      const districtIds = districts.values;
      const data = await getFilteredCongressionalDistrictsReq({ id: districtIds })();
      const intersectionDistricts = intersectionWith(data, districts.values, (a, b) => a.id === b).map((item) => ({
        ...item,
        name: `${item.name} (${item.id})`,
      }));
      setFieldValue('districts.values', intersectionDistricts);
    })();
  }, [setFieldValue, districts]);

  // Should for receiving and show needed data by cities on Geography step
  useEffect(() => {
    if (!cities.values.length) return;

    (async () => {
      const cityIds = cities.values;
      const data = await getFilteredCitiesReq({ id: cityIds })();
      const intersectionCities = intersectionWith(data, cities.values, (a, b) => a.code === b).map((item) => ({
        ...item,
        id: item.code,
        name: `${item.name} (${item.state ? item.state.name : item.code})`,
      }));

      setFieldValue('cities.values', intersectionCities);
    })();
  }, [setFieldValue, cities, dispatch]);

  return { formik, isLoading, validate, isDisabled };
};

export default useAddCampaign;
