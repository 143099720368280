import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createLogger } from 'redux-logger';

// Reducers
import authReducer from '../reducers/authReducer';
import campaignReducer from '../reducers/campaignReducer';
import creativeReducer from '../reducers/creativeReducer';
import advertiserReducer from '../reducers/advertiserReducer';
import dictionaryReducer from '../reducers/dictionaryReducer';
import reportReducer from '../reducers/reportReducer';
import loadingReducer from '../reducers/loadingReducer';
import insightReducer from '../reducers/insightReducer';

// eslint-disable-next-line import/no-cycle
import { apiSlice } from '../api/apiSlice';

const store = configureStore({
  reducer: {
    // Define a top-level state field named `todos`, handled by `todosReducer`
    advertisers: advertiserReducer,
    auth: authReducer,
    campaigns: campaignReducer,
    creatives: creativeReducer,
    dictionary: dictionaryReducer,
    reports: reportReducer,
    loading: loadingReducer,
    insights: insightReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({ serializableCheck: false });
    middlewares.push(apiSlice.middleware);
    if (process.env.NODE_ENV === 'development') {
      const logger = createLogger({
        collapsed: true,
        predicate: (getState, action) => !action.type.includes('LOADING'),
      });

      middlewares.push(logger);
    }

    return middlewares;
  },
});

setupListeners(store.dispatch);

export default store;
