import { useSnackbar } from 'notistack';
import { validateEmail, validateLength, validateRequired } from 'libs/validateErrors';

const useLoginForm = (login) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = { email: '', password: '' };

  const validate = (values) => {
    const email = validateRequired(values.email, 'Email') ||
        validateEmail(values.email, 'Email');
    const password = validateRequired(values.password, 'Password') ||
        validateLength(values.password, 'Password', 6, 20);

    return {
      ...(email && { email }),
      ...(password && { password }),
    };
  };

  const submitHandler = async (values, { setSubmitting, setFieldError }) => {
    try {
      await login(values);
    } catch (error) {
      if (error.response?.data?.message === 'Password is not valid.') {
        setFieldError('email', ' ');
        setFieldError('password', 'Invalid email or password. Please, try again.');
      } else {
        enqueueSnackbar(error.response?.data?.message || error?.message || 'Something went wrong.', { variant: 'error' });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return {
    validate,
    submitHandler,
    initialValues,
  };
};

export default useLoginForm;
