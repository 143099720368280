import types from 'actions/actionTypes/authActionTypes';

const initialState = { isAuthenticated: false };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_IS_AUTHENTICATED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
