import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Checkbox } from '@mui/material';

// Styles
import classes from './FilledCheckbox.module.scss';

function FilledCheckbox(props) {
  const { className, label, onChange, value, name, checked, disabled } = props;

  return (
    <label
      htmlFor={ name }
    >
      <Checkbox
        className={ className }
        id={ name }
        color="secondary"
        checked={ checked }
        onChange={ onChange }
        value={ value }
        name={ name }
        disabled={ disabled }
        disableRipple
      />
      <span className={ classes.label }>{ label }</span>
    </label>
  );
}

FilledCheckbox.defaultProps = {
  className: '',
  value: '',
  name: '',
  onChange: () => {},
  checked: false,
  disabled: false,
};

FilledCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FilledCheckbox;
