import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { emitGoogleEvent } from '../libs/gtag';
import EVENTS from '../constants/analitics';

export const useStripePaymentResult = () => {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const paymetStatus = searchParams.get('paymetStatus');
    if (paymetStatus === 'success') {
      const advertiserId = searchParams.get('advId');
      const amount = searchParams.get('amount');

      enqueueSnackbar('Payment successfull', { variant: 'success' });

      emitGoogleEvent(EVENTS.addPaymentInfo, {
        payment_type: 'credit_card',
        advertiser_id: advertiserId,
        balance_in_currency: amount,
      });
    }
    if (paymetStatus === 'cancel') {
      enqueueSnackbar('Payment canceled', { variant: 'warning' });
    }
  }, [enqueueSnackbar, searchParams]);
};
