// CRUD
import config from 'config/config';
import client from 'client/axios';

// Types
import { downloadFile } from 'libs/downloadFile';
import types from './actionTypes/advertiserActionTypes';

const { serverUrl, apiVersion } = config;

/**
 * Set filters
 */

const setFilters = (filters) => (dispatch) => dispatch({
  type: types.SET_FILTERS,
  payload: filters,
});

/**
 * GET Download biling history
 */
const downloadBillingHistory = (advertiserId, start, end, format = 'XLSX') => async () => {
  const { data } = await client.get(`${serverUrl}${apiVersion}advertisers/${advertiserId}/balancehistories/_download`, {
    params: {
      id: advertiserId,
      format,
      ...(start && { start }),
      ...(end && { end }),
    },
    responseType: 'blob',
  });

  downloadFile(data, `Telly-billing-history.${format.toLowerCase()}`);
};

/** ************************************************ EXPORTS ****************************************************** */
export default {
  downloadBillingHistory,
  setFilters,
};
