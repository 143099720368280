import React, { memo } from 'react';
import { Button, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import Input from 'modules/_Factories/Input/Input';
import Select from 'modules/_Factories/Select';
import TermAndConditionsCheckbox from 'modules/_Factories/TermsAndConditionsCheckbox';

import accountTypes from 'constants/dictionary/accountTypesDictionary';
import PhoneInput from 'react-phone-input-2';
import useSignupForm from './useSignupForm';

import classes from './SignupForm.module.scss';
import classesGeneral from '../../Auth.module.scss';

function SignupForm({ className, onSubmit }) {
  const { formik } = useSignupForm({ handleSubmit: onSubmit });

  return (
    <form className={ classNames(className) }>
      <div className={ classes.fieldWrapper }>
        <Typography className={ classesGeneral.inputName }>First Name</Typography>
        <Input
          name="firstName"
          placeholder="enter your first name"
          value={ formik.values.firstName }
          fullWidth
          required
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Typography className={ classesGeneral.inputName }>Last Name</Typography>
        <Input
          name="lastName"
          placeholder="enter your last name"
          value={ formik.values.lastName }
          fullWidth
          required
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Typography className={ classesGeneral.inputName }>Email</Typography>
        <Input
          name="email"
          placeholder="enter your email"
          value={ formik.values.email }
          fullWidth
          required
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Typography className={ classesGeneral.inputName }>Phone Number</Typography>
        <PhoneInput
          country="us"
          onChange={ (value) => formik.setFieldValue('phone', value) }
          value={ formik.values.phone }
          inputClass={ classes.inputClass }
          specialLabel=""
        />
        <Typography className={ classesGeneral.inputName }>Company Name</Typography>
        <Input
          name="companyName"
          placeholder="enter your company name"
          value={ formik.values.companyName }
          fullWidth
          required
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Typography className={ classesGeneral.inputName }>Are you an Agency or Brand/Business?</Typography>
        <Select
          className={ classesGeneral.select }
          fullWidth
          name="accountType"
          label="select account type"
          required
          showNoneItem={ false }
          itemList={ accountTypes.dictionary }
          value={ formik.values.accountType }
          onChange={ formik.handleChange }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
          isLightTheme
        />
        <TermAndConditionsCheckbox
          required
          name="privacyPolicy"
          value={ formik.values.privacyPolicy }
          handleChange={ formik.handleChange }
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </div>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        type="submit"
        size="large"
        disabled={ formik.isSubmitting }
        onClick={ formik.handleSubmit }
      >
        SIGN UP
      </Button>
    </form>
  );
}

SignupForm.defaultProps = { className: '' };

SignupForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(SignupForm);
