import { ContactsClass } from '../contactsClass';
import { AllowValuesClass } from '../allowValuesClass';

export class AdvertiserClass {
  id = null;
  name = '';
  balance = 0;
  contacts = new ContactsClass();
  domain = '';
  domains = new AllowValuesClass();
  ssps = new AllowValuesClass();
  status = '';
  notes = '';

  constructor(...args) {
    args.forEach((item) => {
      this.id = item.id || null;
      this.name = item.name || '';
      this.balance = item.balance || 0;
      this.contacts = item.contacts ? new ContactsClass(item.contacts) : new ContactsClass();
      this.domain = item.domain || '';
      this.domains = item.domains || new AllowValuesClass();
      this.ssps = item.ssps || new AllowValuesClass();
      this.status = item.status || '';
      this.notes = item.notes || '';
    });
  }
}
