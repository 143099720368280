import VideoCreativeClass from 'classes/creative/videoCreativeClass';
import ImageAssetClass from 'classes/asset/imageAssetClass';
import DataAssetClass from 'classes/asset/dataAssetClass';
import TitleAssetClass from 'classes/asset/titleAssetClass';
import AssetBaseClass from 'classes/asset/assetBaseClass';
import NativeCreativeClass from 'classes/creative/nativeCreativeClass';

import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

export const setAssetExpectedSize = (asset) => {
  const template = asset.template?.replace('_CANVA', '');
  switch (template) {
    case (CREATIVE_TEMPLATES.types.COLLAPSED.ASSETS.COLLAPSED.TEMPLATE): {
      asset.expectedSize = CREATIVE_TEMPLATES.types.COLLAPSED.ASSETS.COLLAPSED.SIZE;
      break;
    }
    case (CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.TEMPLATE): {
      asset.expectedSize = CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.SIZE;
      break;
    }
    case (CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.TEMPLATE): {
      asset.expectedSize = CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.SIZE;
      break;
    }
    case (CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.TEMPLATE): {
      asset.expectedSize = CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.SIZE;
      break;
    }
    default: {
      break;
    }
  }
  return asset;
};

export const buildVideoCreativeFormData = (values) => {
  const formData = new FormData();
  if (values.file) {
    const trimedFileName = values.file.name.replace(/(\r\n|\n|\r)/gm, '');
    values.fileName = trimedFileName;
    formData.append('files', values.file, trimedFileName);
  }
  const creativeModel = new VideoCreativeClass({ ...values, subType: null, template: CREATIVE_TEMPLATES.types.VIDEO.NAME });
  const blobCreativeModel = new Blob([JSON.stringify(creativeModel, null, 2)], { type: 'application/json' });
  formData.append('creative', blobCreativeModel);

  return formData;
};

export const buildNativeCreativeFormData = async (values) => {
  const { advertiser, assets } = values;
  const formData = new FormData();
  const models = {
    IMAGE: ImageAssetClass,
    DATA: DataAssetClass,
    TITLE: TitleAssetClass,
  };
  const assetsModels = assets.map((item) => {
    const { file, value, ...itemValues } = item;
    const ModelClass = models[item.type] || AssetBaseClass;
    const model = new ModelClass({
      ...itemValues,
      advertiser,
      value: (file || item.url) ? null : value,
    });
    if (file) {
      const trimedFileName = file.name.replace(/(\r\n|\n|\r)/gm, '');
      model.fileName = trimedFileName;
      formData.append('files', file, trimedFileName);
    }
    return model;
  });

  const creativeModel = new NativeCreativeClass({ ...values, assets: assetsModels });
  const blobCreativeModel = new Blob([JSON.stringify(creativeModel, null, 2)], { type: 'application/json' });
  formData.append('creative', blobCreativeModel);

  return formData;
};

export const transformCreativeValues = async (values) => {
  const { type } = values;
  switch (type) {
    case CREATIVE_TYPES.types.VIDEO: return buildVideoCreativeFormData(values);
    case CREATIVE_TYPES.types.NATIVE: return buildNativeCreativeFormData(values);
    default: return null;
  }
};

export const getDataType = (creativeModel) => {
  if (creativeModel.url) return CREATIVE_DATA_TYPES.types.UPLOAD_FROM_URL;
  return null;
};
