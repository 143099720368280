import AssetBaseClass from './assetBaseClass';

export default class DataAssetClass extends AssetBaseClass {
  type = 'DATA';
  dataType = null;
  value = null;
  url = null;
  expectedSize = null;
  fileName = null;

  constructor(item = {}) {
    super(item);
    this.dataType = item.dataType || null;
    this.value = item.value || null;
    this.url = item.url || null;
    this.expectedSize = item.expectedSize || null;
    this.fileName = item.fileName || null;
  }
}
