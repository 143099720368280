export class TabStep {
  id = null;
  name = null;
  subtitle = null;
  valid = false;
  touched = false;
  visible = true;

  constructor(...args) {
    args.forEach((item) => {
      this.id = [null, undefined].includes(item.id) ? null : item.id;
      this.name = [null, undefined].includes(item.name) ? null : item.name;
      this.subtitle = [null, undefined].includes(item.subtitle) ? null : item.subtitle;
      this.valid = [null, undefined].includes(item.valid) ? false : item.valid;
      this.touched = [null, undefined].includes(item.touched) ? false : item.touched;
      this.visible = [null, undefined].includes(item.visible) ? false : item.visible;
    });
  }

  setTouched(val) {
    this.touched = val || false;
  }

  setValid(val) {
    this.valid = val || false;
  }
}
