import React, { useMemo, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Tooltip } from '@mui/material';
import usePermissions from 'hooks/usePermissions';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/PreviewOutlined';

// Modules
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import MaterialTableBody from 'modules/_MaterialTable/MaterialTableBody/MaterialTableBody';
import OptionsButtonNav from 'modules/OptionsButtonNav/OptionsButtonNav';
import TableCell from 'modules/_Table/TableCell/TableCell';

// Dictionaries
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import MaterialTableToolbar from 'modules/_MaterialTable/MaterialTableToolbar/MaterialTableToolbar';
import MaterialTableAjaxPagination from 'modules/_MaterialTable/MaterialTableAjaxPagination/MaterialTableAjaxPagination';
import StatusField from 'modules/StatusField/StatusField';

const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

function TableCreatives({
  tableRef,
  pageRef,
  getCreativeList,
  updateStatus,
  openPreview,
  meta,
}) {
  const permissions = usePermissions();

  const columns = useMemo(() => [
    {
      title: 'ID',
      field: 'id',
      width: 120,
      minWidth: 120,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {rowData.id}
        </MaterialTableText>
      ),
    },
    {
      title: 'CREATIVE',
      field: 'name',
      defaultSort: 'asc',
      cellStyle: { wordBreak: 'break-all' },
      render: (rowData) => (
        <Link to={ `${rowData.id}` } className="link">
          <MaterialTableText>
            {rowData.name}
          </MaterialTableText>
        </Link>
      ),
    },
    {
      title: 'TYPE',
      field: 'type',
      width: 160,
      minWidth: 160,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {CREATIVE_TYPES.hashMap[rowData.type]}
        </MaterialTableText>
      ),
    },
    {
      title: 'ADVERTISER',
      field: 'advertiser',
      width: 160,
      minWidth: 160,
      maxWidth: 160,
      hidden: !permissions.advertiserRead,
      render: (rowData) => {
        const advertiser = rowData.advertiser?.name || '';
        return (
          <Tooltip disableInteractive title={ advertiser }>
            <div className="text-truncate">
              <MaterialTableText variant="light" nowrap>
                {advertiser}
              </MaterialTableText>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'CAMPAIGNS',
      field: 'campaignsCount',
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      hidden: !permissions.campaignRead,
      render: (rowData) => (
        <Link to={ `../campaigns?creative=${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.campaignsCount } />
        </Link>
      ),
    },
    {
      title: 'STATUS',
      field: 'status',
      width: 112,
      minWidth: 112,
      render: (rowData) => (
        <StatusField
          value={ rowData.status }
          handleChange={ (e, element) => (updateStatus(rowData.id, element.props.value)) }
        />
      ),
    },
    {
      title: '',
      field: 'options',
      sorting: false,
      width: 24,
      minWidth: 24,
      cellStyle: { textAlign: 'right' },
      render: (rowData) => {
        const items = [{ to: `${rowData.id}`, label: permissions.creativeWrite ? 'Edit' : 'Show', icon: EditIcon }];
        if (rowData.type === CREATIVE_TYPES.types.VIDEO) {
          items.push({ type: 'button', label: 'Creative Preview', icon: PreviewIcon, onClick: () => openPreview(rowData) });
        }
        return <OptionsButtonNav items={ items } />;
      },
    },
  ], [permissions, openPreview, updateStatus]);

  const dataCallback = useCallback((query) => getCreativeList({
    ...query,
    page: pageRef.current,
  }), [getCreativeList, pageRef]);

  const toolbarCallback = useCallback(
    (props) => (
      <MaterialTableToolbar tableRef={ tableRef } pageRef={ pageRef } entityName="Creatives" { ...props } />
    ),
    [tableRef, pageRef],
  );

  const paginationCallback = useCallback(
    (props) => (
      <MaterialTableAjaxPagination pageRef={ pageRef } { ...props } />
    ),
    [pageRef],
  );

  return (
    <MaterialTable
      columns={ columns }
      data={ dataCallback }
      tableRef={ tableRef }
      localization={ { body: { emptyDataSourceMessage: <h4>Sorry, we couldn&apos;t find any results matching.</h4> } } }
      options={ {
        draggable: false,
        pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
        pageSize: meta?.size || ROWS_PER_PAGE_OPTIONS[0],
        thirdSortClick: false,
      } }
      icons={ { SortArrow: MaterialTablesSortIcon } }
      components={ {
        Toolbar: toolbarCallback,
        Pagination: paginationCallback,
        Container: MaterialTableContainer,
        Body: MaterialTableBody,
      } }
    />
  );
}

TableCreatives.defaultProps = { openPreview: Function.prototype };

TableCreatives.propTypes = {
  tableRef: PropTypes.shape({}).isRequired,
  pageRef: PropTypes.shape({}).isRequired,
  getCreativeList: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  openPreview: PropTypes.func,
  meta: PropTypes.shape({
    perPage: PropTypes.number,
    total: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,
};

export default memo(TableCreatives);
