import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Classes
import { AdvertiserClass } from 'classes/advertiser/advertiserClass';
import { AdvertiserBalanceClass } from 'classes/advertiser/advertiserBalanceClass';
import {
  advertiserApiSlice,
  useGetAdvertiserByIdMutation,
  useUpdateAdvertiserMutation,
} from '../../advertiserApiSlice';
import useAdvertiserBalance from '../../../../modules/AdvertiserBalance/useAdvertiserBalance';

const useEditAdvertiser = (advertiserId) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fillAdvertiserBalance } = useAdvertiserBalance();

  const [updateAdvertiser] = useUpdateAdvertiserMutation();

  const [getAdvertiserById, { data: advertiser = new AdvertiserClass() }] = useGetAdvertiserByIdMutation();
  const clearAdvertiser = useCallback((id) => dispatch(
    advertiserApiSlice.util.invalidateTags([{ type: 'Advertiser', id }]),
  ), [dispatch]);

  React.useEffect(() => {
    if (!advertiserId) return null;

    getAdvertiserById(advertiserId);
    return () => clearAdvertiser(advertiserId);
  }, [getAdvertiserById, clearAdvertiser, advertiserId]);

  const handleSubmit = useCallback(async (values, callbackFinally) => {
    try {
      const model = new AdvertiserClass(values);
      const balanceModel = new AdvertiserBalanceClass(values.balanceModel);

      const { id } = await updateAdvertiser(model).unwrap();

      if (balanceModel.amount && balanceModel.amount > 0 && id) {
        const navigation = {
          successUrl: `${window.location.origin}/advertisers`,
          cancelUrl: `${window.location.origin}/advertisers`,
        };
        await fillAdvertiserBalance(id, { ...values, amount: balanceModel.amount }, navigation);
      }

      enqueueSnackbar(`Advertiser (id: ${id}) updated successfully`, { variant: 'success' });
      navigate('/advertisers');
    } catch (error) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    } finally {
      callbackFinally();
    }
  }, [enqueueSnackbar, fillAdvertiserBalance, navigate, updateAdvertiser]);

  const initialValues = useMemo(() => ({
    ...advertiser,
    balanceModel: new AdvertiserBalanceClass(),
  }), [advertiser]);

  return { initialValues, handleSubmit };
};

export default useEditAdvertiser;
