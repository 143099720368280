import axios from 'axios';
import { stringify } from 'qs';
import loadingActions from 'actions/loadingActions';
import envConfig from '../config/config';
// Interceptors
import { headersRequestHandler } from './interceptors/headersInterceptor';
// eslint-disable-next-line import/no-cycle
import { refreshRequestHandler, refreshResponseHandler } from './interceptors/refreshTokenInterceptor';

const instance = axios.create({
  baseURL: '',
  headers: {},
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
});

instance.interceptors.request.use(headersRequestHandler);
instance.interceptors.request.use(refreshRequestHandler);

instance.interceptors.response.use(null, refreshResponseHandler);

const { serverUrl, apiVersion } = envConfig;
const baseUrl = `${serverUrl}${apiVersion}`;

const axiosBaseQuery = async (args, api) => {
  const { url, method, data, params, headers, withLoading, meta = {} } = args;
  const { dispatch } = api;

  try {
    if (withLoading) dispatch(loadingActions.increaseCounter());
    const result = await instance({
      url: baseUrl + url,
      method,
      data,
      params,
      headers,
    });
    return { data: result.data, meta: { response: { headers: result.headers }, data: { ...meta } } };
  } catch (error) {
    return { error };
  } finally {
    if (withLoading) dispatch(loadingActions.decreaseCounter());
  }
};

export { axiosBaseQuery };
export default instance;
