import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// Libs
import { separateByStatus } from 'libs/separateByStatus';

// Material
import { Grid } from '@mui/material';

// Constants
import CREATIVE_STATUSES from 'constants/dictionary/creativeStatusesDictionary';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';

// Modules
import StepContainer from 'modules/Steps/StepContainer';
import Select from 'modules/_Factories/Select/Select';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';
import Input from 'modules/_Factories/Input/Input';

// selectors
import accountTypesDictionary from 'constants/dictionary/accountTypesDictionary';
import { UserClass } from '../../../../../classes/userClass';
import { useGetCurrentUserMutation, useGetShortAdvertiserListQuery } from '../../../../../api/apiSlice';

function General({ formik, isEditForm, isCampaignTab }) {
  const { setFieldValue } = formik;
  const [, { data: user = new UserClass() }] = useGetCurrentUserMutation({ fixedCacheKey: 'current-user' });
  const { data: shortAdvertiserList = [] } = useGetShortAdvertiserListQuery({ size: 10000 });
  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList), [shortAdvertiserList]);

  const helpText = 'Choose advertiser and creative type for your ad';

  useEffect(() => {
    if (user.type === accountTypesDictionary.types.ADVERTISER && !get(formik.values, 'advertiser') && shortAdvertiserList[0]) {
      setFieldValue('advertiser', shortAdvertiserList[0].id);
    }
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, shortAdvertiserList, user.type]);

  return (
    <StepContainer subtitle={ helpText }>
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } sm={ 6 }>
          <Input
            name="name"
            label="Name"
            value={ get(formik.values, 'name') || '' }
            // Options
            fullWidth
            required
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Select
            name="status"
            label="Status *"
            value={ get(formik.values, 'status') || '' }
            itemList={ CREATIVE_STATUSES.dictionary }
            // Options
            isFilter
            isFilterText="None"
            fullWidth
            required
            showNoneItem={ false }
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <SelectVirtualized
            name="advertiser"
            label="Advertiser *"
            value={ get(formik.values, 'advertiser') || '' }
            list={ advertiserOptions }
            // Options
            disabled={ isCampaignTab || isEditForm || user.type === accountTypesDictionary.types.ADVERTISER }
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            error={ formik.errors?.advertiser }
            isTouched={ formik.touched?.advertiser }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Select
            name="type"
            label="Type *"
            value={ get(formik.values, 'type') || '' }
            itemList={ CREATIVE_TYPES.dictionary }
            // Options
            isFilter
            fullWidth
            required
            disabled={ isEditForm }
            showNoneItem={ false }
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>
      </Grid>
    </StepContainer>
  );
}

General.defaultProps = { isEditForm: false, isCampaignTab: false };

General.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.shape({ advertiser: PropTypes.string }).isRequired,
    touched: PropTypes.shape({ advertiser: PropTypes.bool }).isRequired,
  }).isRequired,
  isEditForm: PropTypes.bool,
  isCampaignTab: PropTypes.bool,
};

export default memo(General);
