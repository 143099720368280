import React, { memo } from 'react';
import classNames from 'classnames';
import classes from './PreviewCreative.module.scss';
import CREATIVE_TEMPLATES from '../../constants/dictionary/creativeTemplateDictionary';

const COLLAPSED_SIZE = CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.SIZE;
const EXPANDED_SIZE = CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.SIZE;
function ExpandableCreative({ expandedUrl, collapsedUrl, classNamePreviewWrapper, classNamePreview }) {
  return (
    <div className={ classNames(classes.collapsablePreviewWrapper, classNamePreviewWrapper) }>
      <div className={ classNames(classes.collapsablePreview, classNamePreview) }>
        {expandedUrl ?
          <img src={ expandedUrl } className={ classes.expanded } alt="expanded" />
          : (
            <div className={ classes.expanded }>Expanded image asset -
              {EXPANDED_SIZE.width} x {EXPANDED_SIZE.height}
            </div>
          )}
        {collapsedUrl ?
          <img src={ collapsedUrl } className={ classes.collapsed } alt="collapsed" />
          : (
            <div className={ classes.collapsed }>Collapsed image asset -
              {COLLAPSED_SIZE.width} x {COLLAPSED_SIZE.height}
            </div>
          )}
      </div>
    </div>
  );
}

export default memo(ExpandableCreative);
