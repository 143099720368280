import React from 'react';
import PropTypes from 'prop-types';
import classes from './MaterialTableContainer.module.scss';

function MaterialTableContainer({ children, style }) {
  return (
    <div className={ classes.wrapper } style={ style }>
      { children }
    </div>
  );
}

MaterialTableContainer.defaultProps = { style: {} };
MaterialTableContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]).isRequired,
  style: PropTypes.shape({}),
};

export default MaterialTableContainer;
