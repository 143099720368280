const primaryColorText = '#8692d0';
const backgroundColorDarkGray = '#2F3349';
const colorGrayDark = '#888888';
const colorGray = '#bbbbbb';
const colorGrayDarkest = '#2f2f2f';
const basicRedColor = '#F9423A';
const primaryColor = '#F9F9F9';

export {
  primaryColorText,
  backgroundColorDarkGray,
  basicRedColor,
  primaryColor,
  colorGrayDarkest,
  colorGrayDark,
  colorGray,
};
