import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function useGetParameters(names) {
  const { search } = useLocation();

  const params = useMemo(() => {
    const query = new URLSearchParams(search);

    return names.reduce((accum, name) => {
      const value = query.get(name);
      if (!value) return accum;
      return { ...accum, [name]: value };
    }, {});
  }, [search, names]);

  return params;
}

useGetParameters.defaultProps = { names: [] };

useGetParameters.propTypes = { names: PropTypes.arrayOf([PropTypes.string]) };

export default useGetParameters;
