import React, { memo, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { isEqual } from 'lodash';

// Constants
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

// classes
import ImageAssetClass from 'classes/asset/imageAssetClass';
import TitleAssetClass from 'classes/asset/titleAssetClass';
import DataAssetClass from 'classes/asset/dataAssetClass';

import Select from 'modules/_Factories/Select';
import CustomNativeAd from './CustomNativeAd';
import CollapsableAd from './CollapsableAd';
import ExpandableAd from './ExpandableAd';

const getAssetsDepenedingOnTemplate = (template) => {
  switch (template) {
    case CREATIVE_TEMPLATES.types.COLLAPSED.NAME: {
      return [
        new ImageAssetClass({
          name: CREATIVE_TEMPLATES.types.COLLAPSED.ASSETS.COLLAPSED.TITLE,
          expectedSize: CREATIVE_TEMPLATES.types.COLLAPSED.ASSETS.COLLAPSED.SIZE,
          imageType: CREATIVE_TEMPLATES.types.COLLAPSED.CODE,
          template: CREATIVE_TEMPLATES.types.COLLAPSED.ASSETS.COLLAPSED.TEMPLATE,
        }),
      ];
    }
    case CREATIVE_TEMPLATES.types.EXPANDABLE.NAME: {
      return [
        new ImageAssetClass({
          name: CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.TITLE,
          expectedSize: CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.SIZE,
          imageType: CREATIVE_TEMPLATES.types.EXPANDABLE.CODE,
          template: CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.TEMPLATE,
        }),
        new ImageAssetClass({
          name: CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.TITLE,
          expectedSize: CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.SIZE,
          imageType: CREATIVE_TEMPLATES.types.EXPANDABLE.CODE,
          template: CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.TEMPLATE,
        }),
      ];
    }
    case CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.NAME: {
      return [
        new ImageAssetClass({
          name: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.TITLE,
          expectedSize: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.SIZE,
          template: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.TEMPLATE,
          imageType: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.CODE,
        }),
        new ImageAssetClass({
          name: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.LOGO.TITLE,
          template: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.LOGO.TEMPLATE,
          imageType: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.LOGO.CODE,
        }),
        new TitleAssetClass({
          name: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.TITLE.TITLE,
          template: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.TITLE.TEMPLATE,
          text: '',
        }),
        new DataAssetClass({
          name: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.DESCRIPTION.TITLE,
          template: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.DESCRIPTION.TEMPLATE,
          dataType: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.DESCRIPTION.CODE,
          value: '',
        }),
        new DataAssetClass({
          name: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.CTA.TITLE,
          template: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.CTA.TEMPLATE,
          dataType: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.CTA.CODE,
          value: '',
        }),
        new DataAssetClass({
          name: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.ATTRIBUTION.TITLE,
          template: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.ATTRIBUTION.TEMPLATE,
          dataType: CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.ATTRIBUTION.CODE,
          value: '',
        }),
      ];
    }
    default: return [];
  }
};

const isEqualAssets = (assets, expectedAssets) => {
  if (assets.length !== expectedAssets.length) return false;

  // eslint-disable-next-line
  for (const expectedAsset of expectedAssets) {
    const isMatch = assets.find((item) => {
      switch (expectedAsset.type) {
        case 'IMAGE': {
          const size = expectedAsset.expectedSize || expectedAsset.size || null;
          const itemSize = size !== null ? (item.expectedSize || item.size) : null;
          return isEqual(size, itemSize) && item.template && item.template.startsWith(expectedAsset.template);
        }
        case 'DATA': return item.template && item.template.startsWith(expectedAsset.template);
        case 'TITLE': return item.type === expectedAsset.type;
        default: return false;
      }
    });
    if (!isMatch) return false;
  }

  return true;
};

function NativeOptions({ formik, isEditForm }) {
  const { setFieldValue, values } = formik;
  const { template, assets } = values;

  useEffect(() => {
    const expectedAssets = getAssetsDepenedingOnTemplate(template);

    if (isEqualAssets(assets, expectedAssets)) return;

    setFieldValue('assets', expectedAssets);
  }, [template, setFieldValue, assets]);

  const handleChangeAssets = useCallback((newAssets) => {
    setFieldValue('assets', newAssets);
  }, [setFieldValue]);

  const renderTemplate = useCallback(() => {
    switch (template) {
      case CREATIVE_TEMPLATES.types.COLLAPSED.NAME: {
        return (
          <CollapsableAd
            formik={ formik }
            assets={ assets }
            onChangeAssets={ handleChangeAssets }
            disabled={ isEditForm }
          />
        );
      }
      case CREATIVE_TEMPLATES.types.EXPANDABLE.NAME: {
        return (
          <ExpandableAd
            formik={ formik }
            assets={ assets }
            onChangeAssets={ handleChangeAssets }
            disabled={ isEditForm }
          />
        );
      }
      case CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.NAME: {
        return (
          <CustomNativeAd
            formik={ formik }
            assets={ assets }
            onChangeAssets={ handleChangeAssets }
            disabled={ isEditForm }
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [assets, formik, handleChangeAssets, isEditForm, template]);

  return (
    <Grid container alignItems="flex-start" spacing={ 4 }>
      <Grid item xs={ 12 }>
        <Select
          name="template"
          label="Template"
          value={ template }
          itemList={ CREATIVE_TEMPLATES.dictionary }
          // Options
          isFilter
          isFilterText="None"
          fullWidth
          required
          disabled={ isEditForm }
          showNoneItem={ false }
          // Events
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          // Extra
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </Grid>
      <Grid item xs={ 12 }>
        { renderTemplate() }
      </Grid>
    </Grid>
  );
}

export default memo(NativeOptions);
