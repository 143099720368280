import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import StepContainer from 'modules/Steps/StepContainer';
import AdvertiserBalance from 'modules/AdvertiserBalance';
import useBalance from './useBalance';

function Balance({ formik }) {
  const { id: advertiserId } = formik.values;
  const { replenishAdvertiserBalance } = useBalance();

  return (
    <StepContainer>
      <Grid container>
        <Grid item xs={ 12 } sm={ 6 }>
          <AdvertiserBalance
            advertiserId={ advertiserId }
            onPayment={ replenishAdvertiserBalance }
            setAdvertiserFieldValue={ formik.setFieldValue }
          />
        </Grid>
      </Grid>
    </StepContainer>
  );
}

Balance.propTypes = { formik: PropTypes.shape({ values: PropTypes.shape({ id: PropTypes.number }) }).isRequired };

export default memo(Balance);
