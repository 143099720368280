import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import { useCheckResetPasswordTokenMutation } from '../../../api/apiSlice';

const useResetPassword = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [checkResetPasswordToken] = useCheckResetPasswordTokenMutation();

  const [isValidToken, setIsValidToken] = useState(true);

  const token = query.get('token');

  useEffect(() => {
    if (token) {
      checkResetPasswordToken(token)
        .unwrap()
        .then(() => setIsValidToken(true))
        .catch(() => setIsValidToken(false));
    } else {
      navigate('/login');
    }
  }, [checkResetPasswordToken, navigate, token]);

  return { isValidToken, token };
};

export default useResetPassword;
