import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Material
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Store
import store from './store/store';

// Styles
import theme from './theme/theme';
import 'assets/styles/defaults.scss';
import 'assets/styles/bootstrap.scss';
import 'assets/styles/main.scss';
import 'react-phone-input-2/lib/material.css';

// Components
import SnackbarProvider from './modules/SnackbarProvider';
import App from './App';

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={ store }>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ theme }>
        <LocalizationProvider dateAdapter={ AdapterDayjs }>
          <CssBaseline />
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
);
