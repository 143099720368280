import { createSelector } from 'reselect';

const filteredStates = (state) => state.dictionary.filteredStates;
const filteredCities = (state) => state.dictionary.filteredCities;
const filteredCounties = (state) => state.dictionary.filteredCounties;
const filteredCongressionalDistricts = (state) => state.dictionary.filteredCongressionalDistricts;

const filteredStatesSelector = createSelector(
  filteredStates,
  (items) => items.map((item) => ({
    ...item,
    name: `${item.name} (${item.id})`,
    id: item.id,
  })),
);

const filteredCountiesSelector = createSelector(
  filteredCounties,
  (items) => items.map((item) => ({
    ...item,
    name: `${item.name} (${item.id})`,
    id: item.id,
  })),
);

const filteredCitiesSelector = createSelector(
  filteredCities,
  (items) => items.map((item) => ({
    ...item,
    name: `${item.name} (${item.state ? item.state.name : item.code})`,
    id: item.code,
  })),
);

const filteredCongressionalDistrictsSelector = createSelector(
  filteredCongressionalDistricts,
  (items) => items.map((item) => ({
    ...item,
    name: item.id,
    id: item.id,
  })),
);

export default {
  filteredStatesSelector,
  filteredCitiesSelector,
  filteredCongressionalDistrictsSelector,
  filteredCountiesSelector,
};
