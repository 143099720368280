import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

// Material
import { Menu, MenuItem } from '@mui/material';

// Assets
import { ReactComponent as ProfileIcon } from 'assets/images/icons/profile.svg';

// Hooks
import usePopover from 'hooks/usePopover';
import useProfile from './useProfile';

// Styles
import classes from './Profile.module.scss';

function Profile({ user }) {
  const { logOut } = useProfile(user);
  const { isOpen, anchorEl, handleClick, handleClose } = usePopover();
  const { pathname } = useLocation();

  return (
    <>
      <div
        role="button"
        className={ classes.wrapper }
        onClick={ handleClick }
        onKeyPress={ handleClick }
        tabIndex={ 0 }
        onMouseDown={ (e) => e.preventDefault() }
      >
        <div className={ classes.iconWrapper }>
          <ProfileIcon />
        </div>
      </div>
      <Menu
        open={ isOpen }
        anchorEl={ anchorEl }
        onClose={ handleClose }
        getcontentanchorel={ null }
        anchorOrigin={ {
          vertical: 'bottom',
          horizontal: 'right',
        } }
        transformOrigin={ {
          vertical: 'top',
          horizontal: 'right',
        } }
      >
        <MenuItem
          autoFocus
          tabIndex={ 0 }
          component={ NavLink }
          to="/profile"
          selected={ pathname.includes('/profile') }
          className={ ({ isActive }) => (isActive ? 'active' : '') }
          onClick={ handleClose }
        >
          Profile
        </MenuItem>
        <MenuItem onClick={ logOut } tabIndex={ 0 }>
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}

Profile.defaultProps = { user: {} };
Profile.propTypes = { user: PropTypes.shape({ firstName: PropTypes.string, lastName: PropTypes.string }) };

export default Profile;
