import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material
import { IconButton, Menu, MenuItem } from '@mui/material';

// Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Hooks
import usePopover from 'hooks/usePopover';

// Styles
import classes from './OptionsButtonNav.module.scss';

function OptionsButtonNav(props) {
  const { defaultTo, items, disabled } = props;
  const { isOpen, anchorEl, handleClick, handleClose } = usePopover();

  const getMenuItem = useCallback((item, customProps) => {
    const handleOnClick = (e, cbItem) => {
      handleClose(e);
      if (cbItem.callbackFn) cbItem.callbackFn({ cbItem });
    };

    if (!item) return null;

    switch (item.type) {
      case 'button':
        return (
          <MenuItem
            { ...customProps }
            key={ item.label }
            onClick={ () => {
              item.onClick();
              handleClose();
            } }
            onKeyPress={ () => {
              item.onClick();
              handleClose();
            } }
            tabIndex={ 0 }
          >
            { item.icon ? <div className={ classes.icon }><item.icon /></div> : <div /> }
            <span className={ classes.label }>{ item.label }</span>
          </MenuItem>
        );

      case 'staleButton':
        return (
          <MenuItem
            key={ item.label }
            component={ (item.to || defaultTo) ? NavLink : null }
            to={ item.to || defaultTo }
            state={ item.state || {} }
            onClick={ (e) => handleOnClick(e, item) }
            onKeyPress={ (e) => handleOnClick(e, item) }
            tabIndex={ 0 }
          >
            { item.icon ? <div className={ classes.icon }><item.icon /></div> : <div /> }
            <span className={ classes.label }>{ item.label }</span>
          </MenuItem>
        );

      default:
        return (
          <MenuItem
            { ...customProps }
            tabIndex={ 0 }
            key={ item.label }
            component={ (item.to || defaultTo) ? NavLink : null }
            to={ item.to || defaultTo }
            state={ item.state || {} }
            className={ ({ isActive }) => (isActive ? 'active' : '') }
            onClick={ (e) => handleOnClick(e, item) }
            onKeyPress={ (e) => handleOnClick(e, item) }
          >
            { item.icon ? <div className={ classes.icon }><item.icon /></div> : <div /> }
            <span className={ classes.label }>{ item.label }</span>
          </MenuItem>
        );
    }
  }, [defaultTo, handleClose]);

  return (
    <>
      <IconButton
        className={ classes.optionsIconButton }
        aria-label="options"
        tabIndex={ 0 }
        onClick={ (e) => !disabled && handleClick(e) }
        onKeyPress={ (e) => !disabled && handleClick(e) }
        size="large"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={ isOpen }
        anchorEl={ anchorEl }
        onClose={ handleClose }
        getcontentanchorel={ null }
        anchorOrigin={ {
          vertical: 'bottom',
          horizontal: 'right',
        } }
        transformOrigin={ {
          vertical: 'top',
          horizontal: 'right',
        } }
      >
        { items.map((item, index) => (
          (index === 0) ? (
            getMenuItem(item, { autoFocus: true })
          ) : (
            getMenuItem(item)
          )
        )) }
      </Menu>
    </>
  );
}

OptionsButtonNav.defaultProps = {
  items: [],
  defaultTo: null,
  disabled: false,
};

OptionsButtonNav.propTypes = {
  defaultTo: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    label: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.number]),
    type: PropTypes.oneOf(['button', 'staleButton']),
  })),
  disabled: PropTypes.bool,
};

export default OptionsButtonNav;
