import React, { memo, useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Libs
import { separateByStatus } from 'libs/separateByStatus';
import { hasPermission } from 'libs/storageLibs';
import { transformParams, strToArrayNumber, strToArrayString } from 'libs/query-string';

// Constants
import userPermissionsDictionary from 'constants/dictionary/userPermissionsDictionary';
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import { INITIAL_FILTERS } from 'reducers/campaignReducer';
import { setFilters as setFiltersReq } from 'actions/campaignActions';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';
import Select from 'modules/_Factories/Select/Select';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';
import ProfileHeader from 'modules/ProfileHeader/ProfileHeader';
// eslint-disable-next-line
import CampaignTable from './CampaignTable/CampaignTable';

// Hooks
import useGetParameters from 'hooks/useGetParameters';
import useQueryParams from 'hooks/useQueryParams';
import usePermissions from 'hooks/usePermissions';
import useCampaigns from './useCampaigns';
import { useGetShortAdvertiserListQuery, useGetShortCreativeListQuery } from '../../../api/apiSlice';

const getParamNames = (permissions) => {
  const paramNames = ['search', 'status'];
  if (permissions.advertiserRead) paramNames.push('advertiser');
  if (permissions.creativeRead) paramNames.push('creative');

  return paramNames;
};

function CampaignList() {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.campaigns.filters);
  const setFilters = useCallback((values) => dispatch(setFiltersReq(values)), [dispatch]);

  const tableRef = useRef();
  const pageRef = useRef(0);
  const refFilters = useRef(filters);

  const permissions = usePermissions();
  const PARAM_NAMES = useMemo(() => getParamNames(permissions), [permissions]);
  const params = useGetParameters(PARAM_NAMES);
  const { debouncedReplaceQueryParams, replaceQueryParams } = useQueryParams();
  const { meta, requestCampaigns, changeCampaignStatus } = useCampaigns(filters);
  const { data: shortAdvertiserList = [] } = useGetShortAdvertiserListQuery({ size: 10000 });
  const { data: shortCreativeList = [] } = useGetShortCreativeListQuery({ size: 10000 });

  const [editable] = useState(hasPermission([userPermissionsDictionary.types.CAMPAIGN_WRITE]));
  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList), [shortAdvertiserList]);
  const creativeOptions = useMemo(() => separateByStatus(shortCreativeList), [shortCreativeList]);

  const { creative, advertiser, status } = filters;

  useEffect(() => {
    const config = {
      advertiser: strToArrayNumber,
      creative: strToArrayNumber,
      search: (val) => val,
      status: strToArrayString,
    };

    if (Object.keys(params).length) {
      const filterValues = { ...INITIAL_FILTERS, ...transformParams(params, config) };
      setFilters(filterValues);
      refFilters.current = filterValues;
    } else {
      replaceQueryParams(refFilters.current);
    }
  }, [params, setFilters, replaceQueryParams]);

  const refreshCampaignList = useCallback(() => {
    if (!tableRef.current) return;
    tableRef.current.onQueryChange();
  }, []);

  const updateFilters = useCallback((values) => {
    pageRef.current = 0;
    refFilters.current = values;
    setFilters(values);
    debouncedReplaceQueryParams(values, refreshCampaignList);
  }, [setFilters, debouncedReplaceQueryParams, refreshCampaignList]);

  const handleChangeFilters = (name) => (value) => {
    const values = { ...filters, [name]: value };
    updateFilters(values);
  };

  const resetFilters = useCallback(() => {
    updateFilters(INITIAL_FILTERS);
  }, [updateFilters]);

  const updateStatus = useCallback(async (values, campaignStatus) => {
    await changeCampaignStatus(values, campaignStatus);
    refreshCampaignList();
  }, [changeCampaignStatus, refreshCampaignList]);

  // reset filters on leave
  useEffect(() => () => {
    pageRef.current = 0;
    refFilters.current = INITIAL_FILTERS;
    setFilters(INITIAL_FILTERS);
  }, [setFilters]);

  return (
    <>
      <ProfileHeader filters={ filters } onChangeFilters={ handleChangeFilters } />
      <Grid container spacing={ 2 } justifyContent="space-between" className="mb-5">
        <Grid item>
          <PageTitle title="Campaigns Management" showBreadcrumbs />
        </Grid>
        { editable && (
        <Grid item xs="auto" sm="auto">
          <Link to="create" className="buttonLink">
            <Button variant="contained" color="primary">
              Add Campaign
            </Button>
          </Link>
        </Grid>
        )}
      </Grid>

      <Grid container className="grayContainer">
        <Grid container item spacing={ 3 } alignItems="center" justifyContent="space-around" className="mb-3">
          <Grid item sm="auto">
            <Button
              variant="contained"
              color="primary"
              onClick={ resetFilters }
            >
              Reset
            </Button>
          </Grid>

          <Grid item xs sm />

          <Grid item xs={ 4 } sm={ 2 }>
            <Select
              multiple
              isFilter
              itemList={ CampaignStatuses.dictionary }
              label="Status"
              setValue={ handleChangeFilters('status') }
              value={ status }
            />
          </Grid>

          {permissions.advertiserRead && (
            <Grid item xs={ 4 } sm={ 2 }>
              <SelectVirtualized
                multiple
                tree
                list={ advertiserOptions }
                label="Advertisers"
                onChange={ (e) => handleChangeFilters('advertiser')(e.target.value) }
                value={ advertiser }
              />
            </Grid>
          )}
          {permissions.creativeRead && (
            <Grid item xs={ 4 } sm={ 2 }>
              <SelectVirtualized
                multiple
                tree
                list={ creativeOptions }
                label="Creatives"
                onChange={ (e) => handleChangeFilters('creative')(e.target.value) }
                value={ creative }
              />
            </Grid>
          )}
        </Grid>

        <Grid container item spacing={ 5 }>
          <Grid item xs={ 12 } sm={ 12 }>
            <CampaignTable
              tableRef={ tableRef }
              pageRef={ pageRef }
              getCampaignList={ requestCampaigns }
              changeCampaignStatus={ updateStatus }
              meta={ meta }
              shortAdvertiserList={ shortAdvertiserList }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(CampaignList);
