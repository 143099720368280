import React, { memo } from 'react';
import { Button, Grid } from '@mui/material';
import { get } from 'lodash';
import Input from 'modules/_Factories/Input/Input';
import Select from 'modules/_Factories/Select';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import Options from 'components/Creatives/FormCreative/Steps/Options';
import classes from './CreativeForm.module.scss';

function CreativeForm({ formik }) {
  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } sm={ 6 }>
        <Input
          name="name"
          label="Name"
          value={ get(formik.values, 'name') || '' }
            // Options
          fullWidth
          required
            // Events
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
            // Extra
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </Grid>

      <Grid item xs={ 12 } sm={ 6 }>
        <Select
          name="type"
          label="Type *"
          value={ get(formik.values, 'type') || '' }
          itemList={ CREATIVE_TYPES.dictionary }
            // Options
          isFilter
          fullWidth
          required
          showNoneItem={ false }
            // Events
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
            // Extra
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <Options formik={ formik } isEditForm={ false } />
      </Grid>
      <Grid item xs={ 12 } className={ classes.buttonContainer }>
        <Button
          variant="contained"
          size="medium"
          onClick={ formik.handleSubmit }
        >
          <span>Save Creative</span>
        </Button>
      </Grid>
    </Grid>
  );
}

export default memo(CreativeForm);
