import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Hooks
import useAuth from 'hooks/useAuth';

function ProtectedRoute({ children, requiredPermissions, redirectTo }) {
  const { isAuthenticated } = useAuth({});

  const checkPermissions = useCallback((reqPerm) => {
    if (reqPerm.length) {
      return hasPermission(reqPerm);
    }
    return true;
  }, []);

  const renderRoute = useMemo(() => (
    checkPermissions(requiredPermissions) ? (
      children
    ) : (
      <Navigate to="/404-not-found" replace />
    )
  ), [children, checkPermissions, requiredPermissions]);

  return (
    isAuthenticated ? (
      renderRoute
    ) : (
      <Navigate to={ redirectTo } replace />
    )
  );
}

ProtectedRoute.defaultProps = {
  redirectTo: '/signup',
  requiredPermissions: [],
  children: null,
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
  redirectTo: PropTypes.string,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default ProtectedRoute;
