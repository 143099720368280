import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';

function TenantList({ list, selectAccount }) {
  const columns = useMemo(() => [
    {
      title: 'Tenant Name',
      field: 'name',
      width: 180,
      minWidth: 180,
      defaultSort: 'asc',
      render: (rowData) => (
        <MaterialTableText variant="light">
          {rowData.name}
        </MaterialTableText>
      ),
    },
    {
      title: 'User ID',
      field: 'id',
      width: 120,
      minWidth: 120,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {rowData.id}
        </MaterialTableText>
      ),
    },
  ], []);

  const handleRowClick = useCallback((row, rowData) => {
    selectAccount(rowData);
  }, [selectAccount]);

  return (
    <MaterialTable
      columns={ columns }
      data={ list }
      options={ {
        draggable: false,
        toolbar: false,
        paging: false,
        showEmptyDataSourceMessage: false,
      } }
      style={ { minWidth: '500px' } }
      onRowClick={ handleRowClick }
      icons={ { SortArrow: MaterialTablesSortIcon } }
      components={ { Container: MaterialTableContainer } }
    />
  );
}

TenantList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    domain: PropTypes.string,
  })).isRequired,
  selectAccount: PropTypes.func.isRequired,
};

export default memo(TenantList);
