import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@mui/material';
import classes from './MainTabs.module.scss';

function MainTabs({ value, onChange, children, className, ...TabsProps }) {
  const customClasses = { indicator: classes.indicator };

  return (
    <Tabs
      { ...TabsProps }
      value={ value }
      onChange={ onChange }
      classes={ customClasses }
      className={ className }
      centered
    >
      {children}
    </Tabs>
  );
}

MainTabs.defaultProps = { className: '' };

MainTabs.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default MainTabs;
