import { buildHashMap } from 'libs/buildHashMap';

const types = {
  VIDEO: {
    NAME: 'VIDEO',
    CODE: 'VIDEO',
    ASSET: { TITLE: 'Video Ad Unit', TEMPLATE: 'VIDEO', SIZE: { width: 514, height: 290 }, ratio: { width: 16, height: 9 } },
  },
  EXPANDABLE: {
    NAME: 'EXPANDABLE',
    CODE: 502,
    ASSETS: {
      EXPANDED: { TITLE: 'Expanded Ad', TEMPLATE: '502_EXPANDED', SIZE: { width: 1920, height: 360 } },
      COLLAPSED: { TITLE: 'Collapsed Ad', TEMPLATE: '502_COLLAPSED', SIZE: { width: 514, height: 290 } },
    },
  },
  COLLAPSED: {
    NAME: 'COLLAPSED',
    CODE: 501,
    ASSETS: { COLLAPSED: { TITLE: 'Collapsed Ad', TEMPLATE: '501_COLLAPSED', SIZE: { width: 514, height: 290 } } },
  },
  CUSTOM_NATIVE: {
    NAME: 'CUSTOM_NATIVE',
    CODE: 0,
    ASSETS: {
      MAIN: { TITLE: 'Custom Native Ad', TEMPLATE: 'MAIN', CODE: 1, SIZE: { width: 150, height: 250 } },
      LOGO: { TITLE: 'Logo Image', TEMPLATE: 'LOGO', CODE: 2, SIZE: null },
      TITLE: { TITLE: 'Title', TEMPLATE: 'TITLE', CODE: 3 },
      DESCRIPTION: { TITLE: 'Description', TEMPLATE: 'DESCRIPTION', CODE: 4 },
      CTA: { TITLE: 'Call To Action', TEMPLATE: 'CTA', CODE: 5 },
      ATTRIBUTION: { TITLE: 'Attribution', TEMPLATE: 'ATTRIBUTION', CODE: 6 },
    },
  },
};

const dictionary = [
  { id: types.EXPANDABLE.NAME, name: 'Expandable Ad' },
  { id: types.COLLAPSED.NAME, name: 'Collapsed Ad' },
  // TODO: enable later
  // { id: types.CUSTOM_NATIVE.NAME, name: 'Custom Native Ad' },
];

const templateToTitleMap = buildHashMap(
  [types.VIDEO.ASSET,
    types.COLLAPSED.ASSETS.COLLAPSED,
    types.EXPANDABLE.ASSETS.EXPANDED,
    types.EXPANDABLE.ASSETS.COLLAPSED,
    types.CUSTOM_NATIVE.ASSETS.MAIN,
    types.CUSTOM_NATIVE.ASSETS.LOGO,
    types.CUSTOM_NATIVE.ASSETS.TITLE,
    types.CUSTOM_NATIVE.ASSETS.DESCRIPTION,
    types.CUSTOM_NATIVE.ASSETS.CTA,
    types.CUSTOM_NATIVE.ASSETS.ATTRIBUTION],
  'TEMPLATE',
  'TITLE',
);

export default {
  types,
  dictionary,
  templateToTitleMap,
};
