import isEmail from 'validator/lib/isEmail';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useResetPasswordMutation } from 'api/apiSlice';

const useForgotPasswordForm = () => {
  const [resetPassword] = useResetPasswordMutation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = { email: '' };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Email is invalid';
    }

    return errors;
  };

  const submitHandler = async (values, { setSubmitting, setFieldError }) => {
    try {
      await resetPassword(JSON.stringify(values.email));
      enqueueSnackbar(
        'A link to reset password has been sent to the specified e-mail address',
        { variant: 'success' },
      );
    } catch (e) {
      if (e.response.status === 404) {
        setFieldError('email', 'Wrong email. Please, try again.');
      } else if (e.response.status === 429) {
        setFieldError('email', 'Reset password limit has been reached.');
      } else {
        setFieldError('email', 'Something went wrong. Try again later.');
      }
    }

    setSubmitting(false);
  };

  const forgotPasswordForm = useFormik({
    initialValues,
    onSubmit: submitHandler,
    validate,
  });

  return { forgotPasswordForm, validate };
};

export default useForgotPasswordForm;
