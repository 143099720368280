import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// Material
import { Link, Typography, Breadcrumbs } from '@mui/material';

// Constants
import { NavLinks } from 'constants/index';

// Dictionaries
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';

// Styles
import classes from './RouterBreadcrumbs.module.scss';

const breadcrumbNameMap = {};
NavLinks.forEach((item) => {
  breadcrumbNameMap[item.id] = item.name;
  if (item.children) {
    breadcrumbNameMap[item.children.id] = item.children.name;
  }
});
CampaignTypes.dictionary.forEach((item) => { breadcrumbNameMap[`/campaigns/create/${item.link}`] = item.name; });

// eslint-disable-next-line react/jsx-props-no-spreading
function LinkRouter(props) {
  return <Link { ...props } component={ RouterLink } underline="hover" />;
}

function RouterBreadcrumbs(props) {
  const { currentPath, lastElementName } = props;
  const pathnames = currentPath && currentPath.split && currentPath.split('/').filter((item) => item);

  return (
    <Breadcrumbs aria-label="breadcrumb" className={ classes.breadcrumbs }>
      { pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="inherit" key={ to } className={ classes.breadcrumbsLast }>
            { lastElementName || breadcrumbNameMap[to] }
          </Typography>
        ) : (
          <LinkRouter color="inherit" to={ to } key={ to }>
            { breadcrumbNameMap[to] }
          </LinkRouter>
        );
      }) }
    </Breadcrumbs>
  );
}

RouterBreadcrumbs.defaultProps = { lastElementName: null };

RouterBreadcrumbs.propTypes = {
  currentPath: PropTypes.string.isRequired,
  lastElementName: PropTypes.string,
};

export default RouterBreadcrumbs;
