const {
  REACT_APP_API_VERSION,
  REACT_APP_COPYRIGHT,
  REACT_APP_NAME,
  REACT_APP_SERVER_URL,
  REACT_APP_DOMAIN,
  REACT_APP_CLIENT_ID,
  REACT_APP_VERSION,
  REACT_APP_UNSPLASH_ACCESS_KEY,
  REACT_APP_CANVA_UI_ID,
  REACT_APP_CANVA_BASE_URL,
  REACT_APP_REVENUE_SERVER_URL,
  REACT_APP_ENV,
} = process.env;

const config = {
  apiVersion: REACT_APP_API_VERSION,
  copyright: REACT_APP_COPYRIGHT,
  name: REACT_APP_NAME,
  serverUrl: REACT_APP_SERVER_URL,
  appDomain: REACT_APP_DOMAIN,
  clientId: REACT_APP_CLIENT_ID,
  appVersion: REACT_APP_VERSION,
  unsplashKey: REACT_APP_UNSPLASH_ACCESS_KEY,
  canvaUI: REACT_APP_CANVA_UI_ID,
  canvaBaseUrl: REACT_APP_CANVA_BASE_URL,
  revenueServerUrl: REACT_APP_REVENUE_SERVER_URL,
  environment: REACT_APP_ENV,
};

export default config;
