import React, { useState } from 'react';

import cn from 'classnames';
import { Box, IconButton, Link, Menu, MenuItem, Typography } from '@mui/material';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// Images
import pizzaHutImage from 'assets/images/landing/Telly_Hero.gif';
import analyzeIcon from 'assets/images/landing/analyze.svg';
import createIcon from 'assets/images/landing/create.svg';
import launchIcon from 'assets/images/landing/launch.svg';
// key features images
import f1 from 'assets/images/landing/Picture_1.png';
import f2 from 'assets/images/landing/Picture_2.png';
import f3 from 'assets/images/landing/Picture_3.png';
import f4 from 'assets/images/landing/Picture_4.png';
// red section images
import red from 'assets/images/landing/Telly.png';
// rows images
import brand from 'assets/images/landing/brand.png';
import data from 'assets/images/landing/data.png';
import measurement from 'assets/images/landing/measurement.png';

import phone from 'assets/images/landing/phone.png';
// featured on images
import wsj from 'assets/images/landing/wsj.svg';
import variety from 'assets/images/landing/variety.svg';
import verge from 'assets/images/landing/verge.svg';

import { useNavigate } from 'react-router-dom';
import Logo from '../Auth/Logo/Logo';

// Styles
import classes from './Landing.module.scss';
import useAuth from '../../hooks/useAuth';
import GetStartedBtn from './modules/GetStartedBtn';

/* eslint-disable jsx-a11y/anchor-is-valid */
function Landing() {
  const { isAuthenticated } = useAuth({});
  const navigate = useNavigate();

  if (isAuthenticated) {
    navigate('/dashboard');
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={ classes.main_container }>
      <nav className={ classes.nav }><Logo to="/" />
        <div className={ classes.menu_container }>
          <IconButton className={ classes.menu_icon_btn } onClick={ handleOpen }>
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={ anchorEl }
            open={ open }
            onClose={ handleClose }
            MenuListProps={ { 'aria-labelledby': 'basic-button' } }
            transformOrigin={ { horizontal: 'right', vertical: 'top' } }
            anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
          >
            <MenuItem onClick={ () => navigate('/login') }>Login</MenuItem>
            <MenuItem onClick={ () => navigate('/signup') }>Sign up</MenuItem>
          </Menu>
        </div>
        <div className={ classes.btn_container }>
          <Link
            className={ classes.btn_link_ghost }
            href="/login"
          >Login
          </Link>
          <Link
            className={ classes.btn_link_primary }
            href="/signup"
          >Sign up
          </Link>
        </div>
      </nav>
      <main className={ classes.main }>
        <section className={ classes.start_container }>
          <Typography
            component="h1"
            className={ classes.start_title }
          >Your brand, on a whole new screen.
          </Typography>
          <Typography className={ classes.start_text }>
            With Telly’s Self-Serve Ad Manager, you can reach valuable and engaged audiences in the living room.
            Our intuitive platform is designed for everyone - from small businesses to top-tier agencies. Get ready to revolutionize how you
            connect with your audience.
          </Typography>
          <Box className={ classes.flex_row_center } mt={ 3 }>
            <GetStartedBtn />
          </Box>
          <Box mt={ 5 } className={ classes.flex_row_center }>
            <img src={ pizzaHutImage } alt="ad" className={ classes.start_image } />
          </Box>
        </section>
        <section className={ classes.how_it_works_container }>
          <Typography className={ classes.text_title } sx={ { textAlign: 'center' } }>
            How It Works
          </Typography>
          <Typography className={ classes.text_body_md } sx={ { textAlign: 'center' } }>
            Let Telly guide you through a seamless advertising experience.
          </Typography>
          <Typography className={ cn(classes.text_body_md, classes.text_red) } sx={ { textAlign: 'center' } }>
            Launching your first campaign is as easy as 1-2-3.
          </Typography>
        </section>
        {/* Cards section */}
        <section className={ classes.cards_container }>
          <div className={ classes.card }>
            <div className={ classes.flex_col_center }>
              <img src={ createIcon } alt="icon" />
              <Typography className={ classes.card_text_title }>
                Create
              </Typography>
              <Typography className={ classes.card_text_body }>
                Sign-up to upload your ads or use our built-in Creative Wizard maker to craft compelling ads.
              </Typography>
            </div>
            <Link href="/login" className={ classes.card_text_link }>
              Upload or Design Your Creatives &#10142;
            </Link>
          </div>
          <div className={ classes.card }>
            <div className={ classes.flex_col_center }>
              <img src={ launchIcon } alt="icon" />
              <Typography className={ classes.card_text_title }>
                Launch
              </Typography>
              <Typography className={ classes.card_text_body }>
                Choose from a variety of targeting options to reach your desired audience, set your campaign budget and pacing, and launch!
              </Typography>
            </div>
            <Link href="/login" className={ classes.card_text_link }>
              Launch Your Campaign &#10142;
            </Link>
          </div>
          <div className={ classes.card }>
            <div className={ classes.flex_col_center }>
              <img src={ analyzeIcon } alt="icon" />
              <Typography className={ classes.card_text_title }>
                Analyze
              </Typography>
              <Typography className={ classes.card_text_body }>
                Gain valuable insights with real-time data to refine and perfect your strategy.
              </Typography>
            </div>
            <Link href="/login" className={ classes.card_text_link }>
              Get Reporting Insights &#10142;
            </Link>
          </div>
        </section>
        {/* Key features section */}
        <section className={ classes.features_section }>
          <div className={ classes.features_container }>
            <Typography className={ classes.text_title_md }>
              Key Features
            </Typography>
            <ul className={ classes.features_list }>
              <li className={ classes.text_body }>
                <span className={ classes.feature_subtitle }>Stunning Ad Units on the Big Screen: </span>
                Choose from a variety of display, video, animated, rich media ad formats and units.
              </li>
              <li className={ classes.text_body }>
                <span className={ classes.feature_subtitle }>Seamless Stripe integration: </span>
                Easily and secure manage your campaign budgets with built-in Stripe integration.
              </li>
              <li className={ classes.text_body }>
                <span className={ classes.feature_subtitle }>Built-In Creative Maker: </span>
                Bring your vision to life. Upload your existing creatives or use our in-house Creative Wizard
                to design captivating ads that capture attention.
              </li>
              <li className={ classes.text_body }>
                <span className={ classes.feature_subtitle }>Campaign Setup & Management: </span>
                Customize your campaign your way. From setting budgets to scheduling, take control of every aspect
                of your advertising journey.
              </li>
              <li className={ classes.text_body }>
                <span className={ classes.feature_subtitle }>Campaign Reporting & Insights: </span>
                Stay ahead with real-time analytics. Understand your audience with detailed reports and
                optimize your campaigns for maximum impact.
              </li>
              <li className={ classes.text_body }>
                <span className={ classes.feature_subtitle }>Rich Targeting: </span>
                Reach your perfect audience. Tailor your campaigns with our advanced targeting options, from our unbeatable
                first-party data, from demographics to psychographics and more.
              </li>
            </ul>
          </div>
          <div className={ classes.features_img_container }>
            <div>
              <img src={ f1 } alt="promo" id={ classes.f1 } className="mr-4" />
              <img src={ f2 } alt="promo" id={ classes.f2 } />
            </div>
            <div>
              <img src={ f3 } alt="promo" id={ classes.f3 } className="mr-4" />
              <img src={ f4 } alt="promo" id={ classes.f4 } />
            </div>
          </div>
        </section>
        {/*   Red section */}
        <section className={ classes.red_section }>
          <Box className={ classes.red_container }>
            <Typography className={ classes.text_sub_title } sx={ { textAlign: 'center' } }>
              Your business deserves to be seen.
            </Typography>
            <Typography className={ classes.text_sub_title_2 } sx={ { textAlign: 'center' } }>
              Sign up now and launch your first campaign in minutes.
            </Typography>
          </Box>
          <img src={ red } alt="Telly" className={ classes.red_image } />
        </section>
        {/*   Brand section */}
        <section className={ classes.brand_container }>

          <div className={ classes.brand_row }>
            <img src={ brand } alt="promo" />
            <div className={ classes.brand_row_text_container }>
              <Typography className={ classes.text_title_md }>
                Brand awareness meets <span className={ classes.text_red }>Brand performance.</span>
              </Typography>
              <div className={ classes.text_secondary }>
                <Typography className={ classes.text_body } mt={ 2 }>
                  Finally, interactive TV ad conversion: Drive direct and measurable transactions from Telly advertisements.
                  Leverage Telly’s built-in performance driven capabilities to drive actionable engagement on your ads, including:
                </Typography>
                <ul className={ classes.text_body }>
                  <li>Built-In Web Browser to enable ad  clickthroughs and conversions on device, at the press of a remote</li>
                  <li>Cross-screen engagement using QR Code that redirects to a mobile or in-app purchase.</li>
                </ul>
              </div>
              <Box mt={ 3 }>
                <GetStartedBtn />
              </Box>
            </div>
          </div>

          <div className={ classes.brand_row }>
            <div className={ classes.brand_row_text_container }>
              <Typography className={ classes.text_title_md }>
                Unparalleled <span className={ classes.text_red }>first-party data.</span>
              </Typography>
              <div className={ classes.text_secondary }>
                <Typography mt={ 2 }>
                  Hyper-addressable targeting that goes beyond household to the individual, powered by unparalleled,
                  extensive first-party data (location, viewing and content data, demographics, psychographics, and more)
                </Typography>
                <ul>
                  <li>Deep individual profiles</li>
                  <li>Geographic + Demographic</li>
                  <li>Psychographic + Behavioral Triggers</li>
                  <li>Viewing Data</li>
                  <li>Sentiment, Intent, Preferences and Opinions</li>
                </ul>
              </div>
              <Box mt={ 3 }>
                <GetStartedBtn />
              </Box>
            </div>
            <img src={ data } alt="promo" />
          </div>

          <div className={ classes.brand_row }>
            <img src={ measurement } alt="promo" />
            <div className={ classes.brand_row_text_container }>
              <Typography className={ classes.text_title_md }>
                Measurement <span className={ classes.text_red }>that works.</span>
              </Typography>
              <div className={ classes.text_secondary }>
                <Typography mt={ 2 }>
                  Reach and frequency, attention, completion, tune-in, media efficiency and on-Telly transactional data
                </Typography>
                <Typography mt={ 2 }>
                  Accurately capture viewership + co-viewing
                </Typography>
                <Typography mt={ 2 }>
                  Real time survey research for brand recall, market insights, A/B testing and more
                </Typography>
              </div>
              <Box mt={ 3 }>
                <GetStartedBtn />
              </Box>
            </div>
          </div>
        </section>
        {/*   Grey section */}
        <section className={ classes.grey_section }>
          <div className={ classes.grey_container }>
            <div>
              <Typography className={ classes.text_title_md }>
                Ready to get started?
              </Typography>
              <Typography className={ classes.text_body } mt={ 1.5 }>
                Sign up now and set up your first campaign in minutes.
              </Typography>
              <Box mt={ 3 }>
                <GetStartedBtn />
              </Box>
              {/* <Box mt={ 3 }> */}
              {/*   <Link */}
              {/*     className={ classes.btn_link_secondary } */}
              {/*     href="#" */}
              {/*   >View Ad Specs */}
              {/*   </Link> */}
              {/* </Box> */}
            </div>
            <img src={ phone } alt="promo" />
          </div>
        </section>
        {/*   Featured on section */ }
        <section className={ classes.featured_container }>
          <Typography className={ cn(classes.text_title_md, classes.text_red) }>
            Featured On
          </Typography>
          <div className={ classes.featured_cards_container }>
            <div className={ classes.featured_card }>
              <img src={ verge } alt="the verge" />
            </div>
            <div className={ classes.featured_card }>
              <img src={ wsj } alt="the wallstreet journal" />
            </div>
            <div className={ classes.featured_card }>
              <img src={ variety } alt="variety" />
            </div>
          </div>
        </section>
        {/*   business section */}
        <section className={ classes.business_container }>
          <div>
            <Typography className={ classes.text_title_md } sx={ { textAlign: 'center' } }>
              Your business
            </Typography>
            <Typography className={ cn(classes.text_title_md, classes.text_red) } sx={ { textAlign: 'center' } }>
              deserves to be seen.
            </Typography>
          </div>
          <Typography className={ cn(classes.text_body, classes.text_secondary) } sx={ { textAlign: 'center' } }>
            Ready to elevate your brand? Sign up now and begin your campaign.
          </Typography>
          <div className={ classes.business_btn_container }>
            <Link
              className={ cn(classes.btn_link_primary, classes.btn_full) }
              href="/signup"
            >Advertise on Telly
            </Link>
            {/* <Link */}
            {/*   className={ classes.btn_link_secondary } */}
            {/*   href="#" */}
            {/* >View Ad Specs */}
            {/* </Link> */}
          </div>
        </section>
      </main>
      <footer className={ classes.footer }>
        <div className={ classes.footer_row }>
          <div className={ classes.footer_column }>
            <Typography className={ classes.text_body }>Company</Typography>
            <Link href="https://www.freetelly.com/about" className={ cn(classes.text_body_sm, classes.footer_link) }>About</Link>
            <Link href="https://www.freetelly.com/press" className={ cn(classes.text_body_sm, classes.footer_link) }>Press</Link>
          </div>
          <div className={ classes.footer_column }>
            <Typography className={ classes.text_body }>Resources</Typography>
            {/* <Link href="#" className={ cn(classes.text_body_sm, classes.footer_link) }>Ad Specs Sheet</Link> */}
            <Link
              href="https://telly.zendesk.com/hc/en-us/sections/19949534769556-Tutorials"
              className={ cn(classes.text_body_sm, classes.footer_link) }
            >Tutorials
            </Link>
            <Link
              href="https://telly.zendesk.com/hc/en-us/sections/13828612941076-FAQ"
              className={ cn(classes.text_body_sm, classes.footer_link) }
            >FAQs
            </Link>
          </div>
          <div className={ classes.footer_column }>
            <Typography className={ classes.text_body }>Support</Typography>
            <Link href="https://www.freetelly.com/contact" className={ cn(classes.text_body_sm, classes.footer_link) }>Contact Us</Link>
            <Link href="https://telly.zendesk.com/hc/en-us" className={ cn(classes.text_body_sm, classes.footer_link) }>Help Center</Link>
          </div>
        </div>
        <div className={ classes.footer_last_row }>
          <div className={ classes.footer_policies }>
            <Typography className={ classes.text_body_sm }>Telly @ 2023</Typography>
            <Link href="/terms" className={ cn(classes.text_body, classes.footer_link) }>Terms of Service</Link>
            <Link href="/policies" className={ cn(classes.text_body, classes.footer_link) }>Privacy Policy</Link>
          </div>
          <div className={ classes.footer_socials }>
            <IconButton href="https://twitter.com/itsfreetelly">
              <XIcon />
            </IconButton>
            <IconButton href="https://www.instagram.com/tellytv/">
              <InstagramIcon />
            </IconButton>
            <IconButton href="https://www.linkedin.com/company/telly-inc">
              <LinkedInIcon />
            </IconButton>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Landing;
