import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import classNames from 'classnames';

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Styles
import classes from './StepTransitions.module.scss';

function StepTransitions({
  handlePrev,
  handleNext,
  activeTab,
  tabsLength,
  submit,
  showFinish,
  saveText,
  handleCancel,
  disableSaveButton,
}) {
  const renderFinishButton = () => {
    const isFinish = showFinish && (activeTab + 1) === (tabsLength - 1);
    const isSave = (activeTab + 1) === tabsLength;

    if (isSave) {
      return (
        <Button
          variant="contained"
          size="medium"
          className={ classes.button }
          onClick={ submit }
          disabled={ disableSaveButton }
        >
          <span>{saveText}</span>
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        size="medium"
        className={ classes.button }
        onClick={ handleNext }
      >
        <span>{isFinish ? 'Finish' : 'Next'}</span>
        <ArrowForwardIcon className="ml-2" />
      </Button>
    );
  };

  return (
    <div className={ classes.tabStepper }>
      <Button
        variant="contained"
        size="medium"
        className={ classNames(classes.button, { [classes.hidden]: activeTab === 0 }) }
        onClick={ handlePrev }
      >
        <ArrowBackIcon className="mr-2" />
        <span>Prev</span>
      </Button>
      <div>
        <Button
          variant="text"
          size="medium"
          className={ classNames(classes.button, 'mr-2') }
          onClick={ handleCancel }
        >
          Cancel
        </Button>
        {renderFinishButton()}
      </div>
    </div>
  );
}

StepTransitions.defaultProps = {
  activeTab: 0,
  tabsLength: 0,
  submit: Function.prototype,
  handlePrev: Function.prototype,
  handleNext: Function.prototype,
  handleCancel: Function.prototype,
  saveText: 'Save',
  showFinish: false,
  disableSaveButton: false,
};

StepTransitions.propTypes = {
  activeTab: PropTypes.number,
  tabsLength: PropTypes.number,
  submit: PropTypes.func,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  handleCancel: PropTypes.func,
  saveText: PropTypes.string,
  showFinish: PropTypes.bool,
  disableSaveButton: PropTypes.bool,
};

export default memo(StepTransitions);
