import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Modules
import Input from 'modules/_Factories/Input/Input';

function InputPassword({
  name,
  onChange,
  onBlur,
  value,
  fullWidth,
  labelBackgroundColor,
  autoComplete,
  errors,
  touched,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Input
      { ...props }
      type={ showPassword ? 'text' : 'password' }
      value={ value }
      name={ name }
      onChange={ onChange }
      onBlur={ onBlur }
      fullWidth={ fullWidth }
      labelBackgroundColor={ labelBackgroundColor }
      autoComplete={ autoComplete }
      errors={ errors }
      touched={ touched }
      endAdornment={ (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={ togglePassword }
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ) }
    />
  );
}

InputPassword.defaultProps = {
  fullWidth: false,
  labelBackgroundColor: '#f9f9f9',
  autoComplete: 'true',
  errors: {},
  touched: {},
};

InputPassword.propTypes = {
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  labelBackgroundColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoComplete: PropTypes.string,
};

export default InputPassword;
