import CreativeClass from './creativeClass';

export default class VideoCreativeClass extends CreativeClass {
  url = null;
  mime = null;
  bitrate = null;
  duration = null;
  size = null;
  content = null;
  fileName = null;

  constructor(item) {
    super(item);
    this.url = item.url || null;
    this.mime = item.mime || null;
    this.bitrate = item.bitrate || null;
    this.duration = item.duration || null;
    this.size = item.size || null;
    this.content = item.content || null;
    this.fileName = item.fileName || null;
  }
}
