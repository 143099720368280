import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import classes from './InputAdornmentButton.module.scss';

const InputAdornmentButton = forwardRef(
  (
    {
      children,
      onClick,
      className,
      onTouchStart,
      onTouchEnd,
      onMouseOver,
      onMouseLeave,
      onFocus,
      onBlur,
      disableRipple,
    },
    ref,
  ) => (
    <IconButton
      ref={ ref }
      className={ classNames(classes.button, className) }
      onClick={ onClick }
      onTouchStart={ onTouchStart }
      onTouchEnd={ onTouchEnd }
      onMouseOver={ onMouseOver }
      onMouseLeave={ onMouseLeave }
      onFocus={ onFocus }
      onBlur={ onBlur }
      disableRipple={ disableRipple }
      size="large"
    >
      {children}
    </IconButton>
  ),
);

InputAdornmentButton.defaultProps = {
  className: '',
  onTouchStart: () => {},
  onTouchEnd: () => {},
  onMouseOver: () => {},
  onMouseLeave: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
  disableRipple: false,
};

InputAdornmentButton.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disableRipple: PropTypes.bool,
};

export default InputAdornmentButton;
