import types from 'actions/actionTypes/campaignActionTypes';

export const INITIAL_FILTERS = {
  advertiser: [],
  search: '',
  status: [],
  creative: [],
};

const initialState = { filters: INITIAL_FILTERS };

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    default:
      return state;
  }
};

export default campaignReducer;
