import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Hooks
import useAuth from './useAuth';
import { useGetCurrentUserMutation } from '../api/apiSlice';
import { UserClass } from '../classes/userClass';

function useUser({ initialState = false } = {}) {
  const [getCurrentUser, { data: user = new UserClass() }] = useGetCurrentUserMutation({ fixedCacheKey: 'current-user' });
  const { isAuthenticated } = useAuth({});

  useEffect(() => {
    if (initialState && isAuthenticated) {
      getCurrentUser();
    }
  }, [getCurrentUser, initialState, isAuthenticated]);

  return {
    user,
    getCurrentUser,
  };
}

useUser.defaultProps = { initialState: false };

useUser.propTypes = { initialState: PropTypes.bool };

export { useUser };
