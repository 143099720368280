import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import BALANCE_OPERATIONS from 'constants/dictionary/balanceOperationDictionary';
import PAYMENT_METHODS from 'constants/dictionary/paymentMethodDictionary';

// Classes
import { AdvertiserBalanceClass } from 'classes/advertiser/advertiserBalanceClass';

import usePermissions from 'hooks/usePermissions';
import { useGetGlobalSettingsMutation } from '../../api/apiSlice';
import {
  useUpdateAdvertiserBalanceMutation,
  useRequestAdvertiserBalanceMutation,
  useFillAdvertiserBalanceByCardMutation,
} from '../../components/Advertisers/advertiserApiSlice';
import { emitGoogleEvent } from '../../libs/gtag';
import EVENTS from '../../constants/analitics';

const useAdvertiserBalance = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [, { data: settings = {} }] = useGetGlobalSettingsMutation({ fixedCacheKey: 'settings' });
  const { advertiserBalanceManual } = usePermissions();

  const [updateAdvertiserBalance] = useUpdateAdvertiserBalanceMutation();
  const [requestAdvertiserBalance] = useRequestAdvertiserBalanceMutation();
  const [fillAdvertiserBalanceByCard] = useFillAdvertiserBalanceByCardMutation();

  const updateBalance = useCallback(async (advertiserId, values) => {
    try {
      if (!advertiserId) return null;

      const balanceModel = new AdvertiserBalanceClass({
        ...values,
        amount: values.operation === BALANCE_OPERATIONS.types.ADD ? +values.amount : -values.amount,
      });
      const result = await updateAdvertiserBalance({ id: advertiserId, data: balanceModel }).unwrap();

      enqueueSnackbar('Balance updated successfully', { variant: 'success' });

      emitGoogleEvent(EVENTS.addPaymentInfo, {
        payment_type: 'update_balance',
        advertiser_id: advertiserId,
        balance_in_currency: balanceModel.amount,
      });

      return result;
    } catch (e) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
      return null;
    }
  }, [updateAdvertiserBalance, enqueueSnackbar]);

  const requestBalance = useCallback(async (advertiserId, values) => {
    try {
      if (!advertiserId) return null;
      const balanceModel = new AdvertiserBalanceClass({
        ...values,
        amount: values.operation === BALANCE_OPERATIONS.types.ADD ? +values.amount : -values.amount,
      });
      const result = await requestAdvertiserBalance({ id: advertiserId, data: balanceModel }).unwrap();

      enqueueSnackbar('Balance requested successfully', { variant: 'success' });

      emitGoogleEvent(EVENTS.addPaymentInfo, {
        payment_type: 'request_balance',
        advertiser_id: advertiserId,
        balance_in_currency: balanceModel.amount,
      });

      return result;
    } catch (e) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
      return null;
    }
  }, [requestAdvertiserBalance, enqueueSnackbar]);

  const payByCard = useCallback(async (advertiserId, values, navigation) => {
    try {
      if (!advertiserId) return;
      const succesQueryParams = `?paymetStatus=success&advId=${advertiserId}&amount=${values.amount}`;
      const cancelQueryParams = '?paymetStatus=cancel';
      const defaultSuccesUrl = `${window.location.origin}${location.pathname}${succesQueryParams}`;
      const defaultCancelUrl = `${window.location.origin}${location.pathname}${cancelQueryParams}`;

      const data = {
        amount: values.amount,
        successUrl: navigation?.successUrl ? `${navigation.successUrl}${succesQueryParams}` : defaultSuccesUrl,
        cancelUrl: navigation?.cancelUrl ? `${navigation.cancelUrl}${cancelQueryParams}` : defaultCancelUrl,
      };
      const paymentLink = await fillAdvertiserBalanceByCard({ id: advertiserId, data }).unwrap();

      window.location.href = paymentLink;
    } catch (e) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    }
  }, [fillAdvertiserBalanceByCard, enqueueSnackbar, location.pathname]);

  const fillAdvertiserBalance = useCallback(async (advertiserId, values, navigation) => {
    const { paymentMethod } = values;
    if (paymentMethod === PAYMENT_METHODS.types.PAY_BY_CARD) {
      return payByCard(advertiserId, values, navigation);
    }

    if (advertiserBalanceManual) return updateBalance(advertiserId, values);

    return requestBalance(advertiserId, values);
  }, [payByCard, updateBalance, requestBalance, advertiserBalanceManual]);

  return {
    fillAdvertiserBalance,
    enablePaymentByCard: settings.stripeEnabled,
  };
};

export default useAdvertiserBalance;
