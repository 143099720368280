import { useEffect } from 'react';
import { useUser } from 'hooks/useUser';
import { useGetGlobalSettingsMutation } from 'api/apiSlice';
import { setUserIdForAnalitics } from '../libs/gtag';

const useMain = () => {
  const { user } = useUser({ initialState: true });
  const [getGlobalSettings] = useGetGlobalSettingsMutation({ fixedCacheKey: 'settings' });

  useEffect(() => {
    if (user && user.type) {
      getGlobalSettings();
      setUserIdForAnalitics(user.id);
    }
  }, [getGlobalSettings, user]);

  return { user };
};

export { useMain };
