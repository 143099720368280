import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Tabs } from '@mui/material';

// Styles
import classes from './AltTabs.module.scss';

function AltTabs(props) {
  const { value, onChange, children, variant, ...TabsProps } = props;

  const customClasses = { indicator: classes.indicator };

  return (
    <Tabs
      { ...TabsProps }
      value={ value }
      onChange={ onChange }
      classes={ customClasses }
    >
      { children }
    </Tabs>
  );
}

AltTabs.defaultProps = { variant: 0 };

AltTabs.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
  variant: PropTypes.number,
};

export default AltTabs;
