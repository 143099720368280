import React from 'react';
import Router from 'router/Router';

// Hooks
import useAuth from './hooks/useAuth';

// Components
import GlobalPreloader from './modules/GlobalPreloader/GlobalPreloader';
import { useGetTimeZonesQuery } from './api/apiSlice';

function App() {
  useGetTimeZonesQuery();
  const { isChecked } = useAuth({ initialState: true });
  return (
    <>
      <GlobalPreloader />
      { isChecked && <Router /> }
    </>
  );
}

export default App;
