import { useCallback } from 'react';
import { useGetCreativeListMutation } from '../../creativeApiSlice';

function materialQueryToParams(query) {
  const { orderBy, orderDirection, page, pageSize, search, type, status, advertisers, campaigns } = query;
  return {
    ...(page && { page }),
    ...(search && { search }),
    ...(pageSize && { size: pageSize }),
    ...(orderBy && { sortBy: orderBy.field }),
    ...(orderDirection && { sortOrder: orderDirection.toUpperCase() }),
    ...(type && { types: [type] }),
    ...(status && { statuses: [status] }),
    advertisers,
    campaigns,
  };
}

const useCreativeList = (filters) => {
  const [
    getCreativeList,
    {
      data: {
        payload: creatives = [],
        _meta = {},
      } = {},
    }] = useGetCreativeListMutation();

  const requestCreatives = useCallback(async (query = {}) => {
    const params = materialQueryToParams({ ...query, ...filters });
    const { _meta: meta = {}, payload = [] } = await getCreativeList({ params }).unwrap();
    return {
      data: payload,
      page: meta.page,
      totalCount: meta.total,
    };
  }, [filters, getCreativeList]);

  return { creatives, meta: _meta, requestCreatives };
};

export default useCreativeList;
