import React, { memo } from 'react';
import ReactRouterPrompt from 'react-router-prompt';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import classes from './Prompt.module.scss';

function Prompt({ when, message }) {
  return (
    <ReactRouterPrompt
      when={ when }
    >
      {({ isActive, onConfirm, onCancel }) => isActive && (
        <div className={ classes.overlayBox }>
          <div className={ classes.container }>
            <p>{message}</p>
            <div className={ classes.buttons }>
              <Button type="button" onClick={ onCancel }>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={ onConfirm }
                sx={ { width: '92px' } }
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      )}
    </ReactRouterPrompt>
  );
}

Prompt.defaultProps = { message: 'Are you sure you want to leave?' };

Prompt.propTypes = {
  message: PropTypes.string,
  when: PropTypes.bool.isRequired,
};

export default memo(Prompt);
