// CRUD
import types from './actionTypes/creativeActionTypes';

/**
 * Set filters
 */
const setFilters = (filters) => (dispatch) => dispatch({
  type: types.SET_FILTERS,
  payload: filters,
});

export { setFilters };
