import React from 'react';

// Material
import { Grid, Typography } from '@mui/material';

// Components
import BackgroundImage from 'modules/BackgroundImage/BackgroundImage';
import Logo from './Logo/Logo';

// Styles
import classes from './Auth.module.scss';

function Auth({ children }) {
  return (
    <Grid container direction="row" className={ classes.container }>
      <Grid item md={ 8 } className={ classes.leftCol }>
        <BackgroundImage />
      </Grid>
      <Grid item sm={ 12 } md={ 4 } className={ classes.rightCol }>
        <Logo className={ classes.logo } to="/" />
        { children }
        <div className={ classes.footer }>
          <Typography className={ classes.copyright }>&copy; 2024 Telly. All rights reserved.</Typography>
          <a className={ classes.link } href="https://www.telly.com/wpp" target="_blank" rel="noreferrer">
            &nbsp;Website Privacy Policy
          </a>
        </div>
      </Grid>
    </Grid>
  );
}

export default Auth;
