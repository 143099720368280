import { apiSlice } from '../../api/apiSlice';
import { CampaignClass } from '../../classes/campaign/campaignClass';
import { CampaignListClass } from '../../classes/campaign/campaignListClass';

export const campaignApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCampaignById: build.mutation({
      query: (id) => ({
        url: `campaigns/${id}`,
        method: 'GET',
        withLoading: true,
      }),
      transformResponse: (response) => ({ ...new CampaignClass(response) }),
      providesTags: (result, error, arg) => [{ type: 'Campaign', id: arg }],
    }),
    getCampaignList: build.mutation({
      query: (params) => ({
        url: 'campaigns',
        method: 'GET',
        withLoading: true,
        params,
      }),
      transformResponse: (campaignsData) => ({
        ...campaignsData,
        payload: campaignsData?.payload?.map((item) => new CampaignListClass(item)),
      }),
      providesTags: (result = []) => [
        'Campaign',
        ...result.map(({ id }) => ({ type: 'Campaign', id })),
      ],
    }),
    createCampaign: build.mutation({
      query: (data) => ({
        url: 'campaigns',
        method: 'POST',
        withLoading: true,
        data,
      }),
      invalidatesTags: ['Campaign'],
    }),
    updateCampaign: build.mutation({
      query: ({ id, data }) => ({
        url: `campaigns/${id}`,
        method: 'PUT',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Campaign', id: arg.id }],
    }),
    updateCampaignStatus: build.mutation({
      query: ({ id, data }) => ({
        url: `campaigns/${id}/status`,
        method: 'PUT',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Campaign', id: arg.id }],
    }),
  }),
});

export const {
  useGetCampaignListMutation,
  useGetCampaignByIdMutation,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useUpdateCampaignStatusMutation,
} = campaignApiSlice;
