import NativeCreativeClass from '../classes/creative/nativeCreativeClass';
import VideoCreativeClass from '../classes/creative/videoCreativeClass';
import CreativeClass from '../classes/creative/creativeClass';

export const getCreativeModel = (creativeType) => {
  const models = {
    NATIVE: NativeCreativeClass,
    VIDEO: VideoCreativeClass,
  };

  return models[creativeType] || CreativeClass;
};
