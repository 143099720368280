import React from 'react';
import { Typography } from '@mui/material';
import classes from './Styles.module.scss';
import Logo from '../Auth/Logo/Logo';

function Policies() {
  return (
    <div className={ classes.mainContainer }>
      <div>
        <Logo className={ classes.logo } to="/login" />
      </div>
      <div>
        <Typography variant="h1">Telly Advertising Guidelines</Typography>
        <div className={ classes.effectiveDate }>Effective Date: October 3, 2023</div>
        <Typography className={ classes.mb1 }>
          These Guidelines are not an exhaustive list of prohibitions, but rather represent Telly’s platform principles.
          Telly reserves the right to reject, suspend, or remove from its platform any ad in its sole discretion,
          regardless of whether a basis for removal is listed here. These Guidelines do not limit Telly’s discretion in any way.
          These Guidelines also do not absolve advertisers of the requirement that ads must comply with all applicable laws, rules,
          or regulations in any relevant jurisdiction. This includes laws, rules, and regulations that relate to the placement of ads
          through online behavioral advertising.
        </Typography>
        <Typography className={ classes.mb2 }>
          Ads that do not meet the standards set forth in these Guidelines may be subject, at Telly’s discretion,
          to platform-wide blocks (and may also be prohibited from purchasing ad inventory on the Telly platform through
          other channels as well). Buyers that repeatedly violate these Guidelines may have their platform access restricted or
          revoked. Buyers that purchase or place ads on behalf of their own affiliates or customers must ensure that the ads they
          purchase or place comply with these Guidelines.
        </Typography>
        <Typography variant="h2" className={ classes.mb2 }>Prohibited Ad and Landing Page Related Content and Behavior</Typography>
        <Typography variant="h4" className={ classes.mb1 }>Unacceptable Content</Typography>
        <Typography>Content that is illegal or otherwise considered unacceptable including:</Typography>
        <ol className={ classes.listDisc }>
          <li><em>Illegal.</em> Ads that constitute, facilitate, or promote illegal products, services or activities.
          </li>
          <li><em>Discriminatory or inflammatory content.</em> Content that promotes or incites hatred against, discrimination of,
            or disparagement of an individual or group on the basis of their race or ethnic origin, religion, disability,
            age, nationality, veteran status, sexual orientation, gender, gender identity, or other characteristic that is associated with
            systemic discrimination or marginalization, or is a protected class.
          </li>
          <li><em>Misinformation.</em> Ads that include content debunked by third party fact-checkers.
          </li>
          <li><em>Animal cruelty.</em> Ads that promote cruelty or gratuitous violence towards animals.</li>
        </ol>
        <Typography variant="h4" className={ classes.mb1 }>Deceptive Content</Typography>
        <Typography>Content that may deceive or mislead people including:</Typography>
        <ol className={ classes.listDisc }>
          <li><em>Spyware or malware.</em> Ads must not contain spyware, malware or any software that results in an unexpected
            or deceptive experience.
          </li>
          <li><em>Non-existent functionality.</em> Ads that display fake errors or warnings to induce user action, including,
            for example, warnings about viruses, missing codecs, and corrupt disks.
          </li>
        </ol>
        <Typography variant="h4" className={ classes.mb1 }>Dangerous Content</Typography>
        <Typography>Content that may negatively impact people’s health and safety including:</Typography>
        <ol className={ classes.listDisc }>
          <li><em>Unsafe substances.</em>
            <ol className={ classes.listCircle }>
              <li>Ads may not sell or promote illegal drugs or paraphernalia, or ways to pass a drug test.</li>
              <li>Cannabis ads will be reviewed on a case-by-case basis and are subject to removal by Telly in its sole discretion
                for any reason. Telly generally focuses on the following in its review: creatives for cannabis must only include
                lifestyle images (meaning no flower, paraphernalia, etc.); can only be served in states where cannabis is legal
                recreationally; CBD creatives can run nationwide as long as the product contains less than 0.3% THC.
                Once THC content is higher, it will be handled as cannabis creative.
              </li>
            </ol>
          </li>
          <li><em>Weapons, Ammunition or Explosives.</em> Ads must not promote the sale or use of weapons, ammunition or explosives.
            This includes ads for weapon modification or accessories.
          </li>
        </ol>
        <Typography variant="h4" className={ classes.mb1 }>Objectionable Content</Typography>
        <Typography>Content that may lead to negative experiences including:</Typography>
        <ol className={ classes.listDisc }>
          <li><em>Grammar and Profanity.</em> Ads must not contain profanity or incorrect grammar and punctuation. Symbols,
            numbers and letters must be used properly, without the intention of circumventing our ad review
            process or other enforcement systems.
          </li>
          <li><em>Low Quality or Disruptive Content.</em> Ads must not contain content leading to external destination (landing)
            pages that provide an unexpected or disruptive experience. This includes misleading ad positioning, such as
            overly sensationalized headlines or prompts for users to inauthentically interact with the ad, and leading people
            to landing pages that contain minimal original content and a majority of unrelated or low quality ad content.
          </li>
          <li><em>Sensational Content.</em> Ads must not contain shocking, sensational or excessively violent content including violent
            language,
            gruesome or disgusting imagery, or graphic images or accounts of physical trauma or advocates harm to oneself or others.
          </li>
          <li><em>Commercial Exploitation of Crises and Controversial Events.</em> Ads must not contain content that exploits crises or
            controversial
            events for commercial purposes.
          </li>
          <li><em>Sale of Body Parts.</em> Ads must not promote the sale of human body parts or fluids.</li>
          <li><em>Adult Content.</em> Ads must not contain adult content. This includes nudity, depictions of people in explicit or
            suggestive positions, or activities that are overly suggestive or sexually provocative. This includes promoting
            escort services, “mail-order brides,” or similar services.
          </li>
          <li><em>Harassment.</em> Ads that harass, intimidate, or bully an individual or group of individuals.</li>
        </ol>
        <Typography variant="h4" className={ classes.mb1 }>Content-specific restrictions</Typography>
        <Typography>Content associated with certain kinds of businesses or products including:</Typography>
        <ol className={ classes.listDisc }>
          <li><em>Online gambling.</em> Ads may not promote online gambling in jurisdictions where online gambling is illegal or otherwise
            restricted. Gambling ads may be allowed if they target jurisdictions where the type of gambling advertised is legal; have a
            landing page or contain language required by law, such as a landing page that displays information about responsible gambling.
          </li>
          <li><em>Alcohol.</em> Ads that promote or reference alcohol must comply with all applicable local laws, required or established
            industry
            codes, guidelines, licenses and approvals, and include age and country targeting criteria consistent with applicable local laws.
            Advertisers must follow all applicable laws, including targeting their ads in accordance with legal requirements.
          </li>
          <li><em>Adult Products or Services.</em> Ads must not promote the sale or use of adult products or services, except for ads for
            family
            planning and contraception. Ads for contraceptives must focus on the contraceptive features of the product, and not on sexual
            pleasure or sexual enhancement.
          </li>
          <li><em>Pharmaceutical ads based on sensitive information (e.g. medical conditions or diagnoses).</em> Ad may not be shown in a
            manner that
            is unfair, deceptive, or does not follow appropriate laws or industry standards.
          </li>
          <li><em>Solicitation of funds or donations.</em> Ads may not solicit funds by people or organizations who are not politicians,
            political
            parties, or tax-exempt charities.
          </li>
          <li><em>Electoral or political advertising.</em> Ads and any party involved in purchasing ad space, targeting ads and showing them
            must
            comply with applicable federal and state laws regarding political advertising, including required disclosure obligations
            regarding the source of political advertisements and maintaining certain archives.
          </li>
        </ol>
        <Typography variant="h4" className={ classes.mb1 }>Intellectual Property Infringement</Typography>
        <Typography>Content that infringes upon or violates the intellectual property rights of a third party or Telly:</Typography>
        <ol className={ classes.listDisc }>
          <li><em>Third Party Infringement.</em> Ads may not show content that infringes upon or violates the rights of any third party or
            Telly,
            including copyright, trademark, privacy, publicity, or other personal or proprietary rights. Examples include celebrity images
            and representation as well as using the name of an unaffiliated publisher in such a way that would imply a relationship with the
            ad or landing page and such unaffiliated publisher.
          </li>
        </ol>
        <Typography variant="h4" className={ classes.mb1 }>Prohibited Ad and Landing Page Behavior</Typography>
        <Typography>Ads and landing pages may not:</Typography>
        <ol className={ classes.listDisc }>
          <li>Automatically redirect to other apps or sites.
          </li>
          <li>Direct people to non-functional landing pages (including landing page content that interferes with a person’s ability to
            navigate away from the page).
          </li>
          <li>Contain content leading to external landing pages that provide an unexpected or disruptive experience.</li>
          <li>Lead to sites or apps that are under construction, parked domains, or are otherwise not functional or have disabled the
            browser’s back button.
          </li>
          <li>Behave differently from when viewed for a compliance audit by Telly or a partner, such as different images, different
            click-through behavior, or different landing page experiences.
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Policies;
