import { useState, useMemo, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import { TabStep } from 'classes/tabStep';

const useStepMenu = (menu = []) => {
  const [steps, setSteps] = useState(menu);
  const initialSteps = useMemo(() => menu.map((item) => new TabStep(item)), [menu]);

  useEffect(() => {
    setSteps(initialSteps);
  }, [initialSteps, setSteps]);

  const setValuesStep = useCallback((index, props) => {
    setSteps((prevSteps) => prevSteps.map((item, i) => (i === index ? { ...item, ...props } : item)));
  }, [setSteps]);

  const stepHasErrors = useCallback((listFields = [], errors = {}) => listFields.some((item) => get(errors, item)), []);

  const stepIsDisabled = useCallback((index) => {
    if (steps[index - 1]) {
      return !steps[index - 1].valid || !steps[index - 1].touched;
    }
    return false;
  }, [steps]);

  return { steps, setValuesStep, stepHasErrors, stepIsDisabled };
};

export default useStepMenu;
