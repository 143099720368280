import React, { memo, useState, useMemo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AppBar } from '@mui/material';

// Modules
import { AltTab, AltTabs } from 'modules/Tabs/AltTabs';
import { a11yProps } from 'modules/Tabs';
import { TabPanel } from 'modules/Tabs/TabPanel';
import StepTransitions from 'modules/Steps/StepTransitions';

// Hooks
import useStepMenu from 'hooks/useStepMenu';

// Steps
import General from './General';
import Options from './Options';

// Styles
import classes from './Steps.module.scss';

const getMenuSteps = (isEditForm) => [
  { id: 0, name: 'General', touched: true, valid: isEditForm, visible: true },
  { id: 1, name: 'Creative', touched: isEditForm, valid: isEditForm, visible: true },
];

function Steps({ formik, isEditForm, isCampaignTab, handleCancel: customHandleCancel }) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const menuStepRef = useRef(getMenuSteps(isEditForm));
  const { steps, setValuesStep, stepHasErrors, stepIsDisabled } = useStepMenu(menuStepRef.current);

  const stepFields = useMemo(() => [
    ['name', 'type', 'advertiser', 'status'],
    ['dataType', 'url', 'sizeType', 'size.width', 'size.height'],
  ], []);

  const hasErrorsActiveTab = stepHasErrors(stepFields[activeTab], formik.errors);

  const handleChangeTab = useCallback((index) => {
    setActiveTab(index);
    setValuesStep(index, { touched: true });
  }, [setActiveTab, setValuesStep]);

  const handleNextStep = useCallback(() => {
    if (!hasErrorsActiveTab) handleChangeTab(activeTab + 1);
    stepFields[activeTab].forEach((name) => formik.setFieldTouched(name, true));
  }, [hasErrorsActiveTab, handleChangeTab, activeTab, stepFields, formik]);

  const handlePrevStep = useCallback(() => {
    handleChangeTab(activeTab - 1);
  }, [handleChangeTab, activeTab]);

  useEffect(() => {
    menuStepRef.current = getMenuSteps(isEditForm);
  }, [isEditForm]);

  useEffect(() => {
    setValuesStep(activeTab, { valid: !hasErrorsActiveTab });
  }, [activeTab, hasErrorsActiveTab, setValuesStep]);

  const getTabClass = useCallback((item, index) => {
    if (index === activeTab) return classes.active;
    if (item.valid && item.touched) return classes.valid;
    return null;
  }, [activeTab]);

  const handleCancel = useCallback(() => {
    navigate('/creatives');
  }, [navigate]);

  return (
    <div className="grayContainer">
      <AppBar position="relative" color="inherit" elevation={ 0 } className={ classes.bar }>
        <AltTabs value={ activeTab } onChange={ (e, index) => handleChangeTab(index) } variant={ 1 }>
          { steps.map((item, i) => (
            <AltTab
              key={ item.id }
              disableRipple
              label={ item.name }
              disabled={ (hasErrorsActiveTab && i > activeTab) || stepIsDisabled(i) }
              variant={ 1 }
              className={ getTabClass(item, i) }
              { ...a11yProps(item.id) }
            />
          )) }
        </AltTabs>
      </AppBar>

      <TabPanel value={ activeTab } index={ 0 }>
        <General formik={ formik } isEditForm={ isEditForm } isCampaignTab={ isCampaignTab } />
      </TabPanel>
      <TabPanel value={ activeTab } index={ 1 }>
        <Options formik={ formik } isEditForm={ isEditForm } />
      </TabPanel>
      <StepTransitions
        handleNext={ handleNextStep }
        handlePrev={ handlePrevStep }
        activeTab={ activeTab }
        tabsLength={ stepFields.length }
        submit={ formik.handleSubmit }
        handleCancel={ customHandleCancel || handleCancel }
        disableSaveButton={ formik.isSubmitting }
        saveText="Save Creative"
      />
    </div>
  );
}

Steps.defaultProps = { isEditForm: false, isCampaignTab: false };

Steps.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
  }).isRequired,
  isEditForm: PropTypes.bool,
  isCampaignTab: PropTypes.bool,
};

export default memo(Steps);
