import { lighten } from '@mui/material';
import { withStyles } from '@mui/styles';

// Material
import LinearProgress from '@mui/material/LinearProgress';

export default withStyles({
  root: {
    height: 8,
    borderRadius: 4,
    backgroundColor: lighten('#6A237C', 0.85),
  },
  bar: {
    borderRadius: 4,
    backgroundColor: '#6A237C',
  },
})(LinearProgress);
