/* eslint-disable max-len */
import React, { memo, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { getAssetUrl, getValueByTypeof } from 'libs/creative/creativesUtils';

import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

import ImageAssetClass from 'classes/asset/imageAssetClass';

import CollapsableCreative from 'modules/PreviewCreative/CollapsableCreative';
import UploadFile from '../../UploadFile';
import classes from './Options.module.scss';

const COLLAPSED_CREATIVE_DATA_TYPES = CREATIVE_DATA_TYPES.dictionary
  .filter((item) => item.id !== CREATIVE_DATA_TYPES.types.STOCK_LIB);

const COLLAPSED_SIZE = CREATIVE_TEMPLATES.types.COLLAPSED.ASSETS.COLLAPSED.SIZE;
const COLLAPSED_TEMPLATE = CREATIVE_TEMPLATES.types.COLLAPSED.ASSETS.COLLAPSED.TEMPLATE;

function CollapsableAd({ assets, onChangeAssets, disabled, formik }) {
  const collapsedAsset = assets[0] || {};

  const collapsedUrl = getAssetUrl(collapsedAsset) || collapsedAsset.value;

  const updateAsset = useCallback((prevValue, currentValue) => {
    const updatedAssets = assets.map((item) => (item === prevValue ? currentValue : item));
    onChangeAssets(updatedAssets);
  }, [assets, onChangeAssets]);

  const onChange = (value, inputType) => {
    formik.setFieldTouched('collapsedCreative');

    const newValue = inputType === CREATIVE_DATA_TYPES.types.CANVA ?
      value :
      getValueByTypeof(value);

    updateAsset(
      collapsedAsset,
      {
        ...collapsedAsset,
        ...newValue,
        inputType,
      },
    );
  };

  const allowEditCollapsed = useMemo(
    () => !collapsedAsset.url?.includes('cloudfront') && formik.errors[COLLAPSED_TEMPLATE] !== 'URL is invalid',
    [collapsedAsset.url, formik.errors],
  );

  return (
    <Grid container spacing={ 4 }>
      <Grid item xs={ 8 }>
        <UploadFile
          title={ `Please make sure your Collapsed creative is in the following size: Width = ${COLLAPSED_SIZE.width}px, Height = ${COLLAPSED_SIZE.height}px` }
          dataTypeList={ COLLAPSED_CREATIVE_DATA_TYPES }
          value={ collapsedAsset.file || collapsedAsset.url || collapsedAsset.value }
          expectedFileSize={ COLLAPSED_SIZE }
          disabled={ disabled }
          allowEditImage={ allowEditCollapsed }
          fileExtensions={ CREATIVE_DATA_TYPES.allowedImageTypes }
          error={ formik.touched.collapsedCreative && formik.errors[COLLAPSED_TEMPLATE] }
          onChangeValue={ onChange }
          templateForCanva={ COLLAPSED_TEMPLATE }
          advertiser={ formik.values.advertiser }
        />
        {/* {!disabled && <CanvaButton size={ COLLAPSED_SIZE } />} */}
      </Grid>
      <Grid item xs={ 4 }>
        <CollapsableCreative collapsedUrl={ collapsedUrl } className={ classes.collapsablePreviewWrapper } />
      </Grid>
    </Grid>
  );
}

CollapsableAd.defaultProps = {
  onChangeAssets: Function.prototype,
  disabled: false,
  assets: [],
};

CollapsableAd.propTypes = {
  onChangeAssets: PropTypes.func,
  disabled: PropTypes.bool,
  assets: PropTypes.arrayOf(PropTypes.shape(ImageAssetClass)),
};

export default memo(CollapsableAd);
