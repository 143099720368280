import React, { memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import AdvertiserList from './routes/AdvertiserList';
import AddAdvertiser from './routes/AddAdvertiser';
import EditAdvertiser from './routes/EditAdvertiser';

function Advertisers() {
  return (
    <Routes>
      <Route path="" element={ <AdvertiserList /> } />
      <Route path="add/*" element={ <AddAdvertiser /> } />
      <Route path=":id" element={ <EditAdvertiser /> } />
    </Routes>
  );
}

export default memo(Advertisers);
