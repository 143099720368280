import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Icons
import IconRemove from '@mui/icons-material/DeleteForeverOutlined';

// Styles
import classes from './Chip.module.scss';

function Chip(props) {
  const { option, labelKey, valueKey, onClick, disabled, getContent } = props;

  const removeOption = (e) => {
    e.stopPropagation();
    onClick(option[valueKey]);
  };

  return (
    <div
      className={ classNames(classes.wrapper, { [classes.disabledWrapper]: disabled }) }
      role="button"
      tabIndex={ 0 }
      onKeyPress={ () => getContent(option[labelKey]) }
      onClick={ () => getContent(option[labelKey]) }
    >
      <span
        className={ classes.iconWrapper }
        onKeyPress={ !disabled && removeOption }
        onClick={ !disabled && removeOption }
      >
        <IconRemove color="secondary" className={ classNames(classes.icon, { disabledIcon: disabled }) } />
      </span>
      <span className={ classNames(classes.label, { disabled }) }>
        { option[labelKey] }
      </span>
    </div>
  );
}

Chip.defaultProps = {
  disabled: false,
  getContent: Function.prototype,
};
Chip.propTypes = {
  option: PropTypes.shape({}).isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  getContent: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Chip;
