import CreativeClass from './creativeClass';

export default class NativeCreativeClass extends CreativeClass {
  assets = [];

  constructor(item) {
    super(item);
    this.assets = item.assets || [];
  }
}
