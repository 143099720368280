import { get } from 'lodash';
import { checkUrl } from 'actions/commonActions';
// Validators
import isEmail from 'validator/lib/isEmail';
import { regExpNumber } from 'constants/regExp';
import dayjs from 'dayjs';

const getErrorText = (key, validatorType) => {
  let text = '';

  switch (validatorType) {
    case 'required':
      text = 'is required';
      break;
    case 'invalid':
      text = 'is invalid';
      break;
    default:
      break;
  }

  const errorsHashMap = {
    advertiser: `Advertiser ${text}`,
    budget: `Campaign Budget ${text}`,
    impsTarget: `Campaign impressions ${text}`,
    cpm: `Bid price ${text}`,
    delivery: `Budget delivery ${text}`,
    domain: `Domain ${text}`,
    email: `Email ${text}`,
    end: `End date ${text}`,
    fullName: `Full name ${text}`,
    name: `Name ${text}`,
    start: `Start date ${text}`,
    status: `Status ${text}`,
    timeZone: `Time zone ${text}`,
    type: `Type ${text}`,
    thirdPartyImpressionBeacons: `Third Party Impression Beacons ${text}`,
  };

  return errorsHashMap[key];
};

export const validateErrors = (actualValues, validation) => {
  const { requiredValues, shouldBeValidValues } = validation;
  const errors = {};

  const checkKey = (key, validatorType) => {
    const path = key.split('.');

    switch (validatorType) {
      case 'required': {
        const value = get(actualValues, path);
        if ((!value && value !== 0) || (Array.isArray(value) && !value.length)) {
          errors[key] = getErrorText(path[path.length - 1], validatorType);
        }
        break;
      }
      case 'invalid':
        if (key.includes('email')) {
          if (!isEmail(get(actualValues, path))) {
            errors[key] = getErrorText(path[path.length - 1], validatorType);
          }
        }
        break;
      default:
        break;
    }
  };

  if (shouldBeValidValues) shouldBeValidValues.forEach((value) => checkKey(value, 'invalid'));
  if (requiredValues) requiredValues.forEach((value) => checkKey(value, 'required'));

  return errors;
};

export const validateFileSize = (file, sizeMB) => {
  if (!file) return null;
  if (file.size / 1048576 > sizeMB) return `File size must be less than ${sizeMB} MB`;
  return null;
};

export const validateRequired = (value, name) => {
  if (value) return null;
  return name ? `${name} is required` : 'Required';
};

export const validateLength = (value, name, min, max) => {
  if (value.trim().length >= min && value.trim().length <= max) return null;
  return `${name} length must be between ${min} and ${max} symbols`;
};

export const validatePasswordsMatch = (password, confirmedPassword) => {
  if (password === confirmedPassword) return null;
  return 'Password do not match';
};

export const validateEmail = (value, name) => {
  if (!value || isEmail(value)) return null;
  return name ? `${name} is invalid` : 'Invalid';
};

export const validateFileExtension = (fileName, acceptableExtensions) => {
  const splitted = fileName.split('.') || [];
  const extension = splitted[splitted.length - 1];
  return acceptableExtensions.includes(extension) ? null : 'File extension is not supported';
};

export const validateUrl = (url) => {
  if (!url) return null;
  const regex = /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gmi;
  const isValidUrl = url.match(regex) !== null;
  return isValidUrl ? null : 'URL is invalid';
};

export const validateUrlContentType = async (url, acceptableMimeTypes) => {
  try {
    const data = await checkUrl({ url })();
    const contentType = data['Content-Type'];

    if (!contentType || !acceptableMimeTypes.includes(contentType)) return 'Content-Type is invalid';

    return data;
  } catch (error) {
    return 'URL is invalid';
  }
};

export const validateMin = (value, min, name) => {
  if (typeof value !== 'number') return null;
  if (value < min) return `${name} must be greater than ${min}`;
  return null;
};

export const validateMax = (value, max, name) => {
  if (typeof value !== 'number') return null;
  if (value > max) return `${name} must be lesser than ${max}`;
  return null;
};

export const validateDomain = (value, name) => {
  const domainRegExp = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})?$/;
  if (value && !value.match(domainRegExp)) return name ? `${name} is invalid` : 'Invalid';

  return null;
};

export const validateNumber = (value, name) => {
  if (value && !regExpNumber.test(value)) return name ? `${name} is invalid` : 'Invalid number';

  return null;
};

export const validateMaxDate = (date, maxDate = new Date('1/1/2100')) => {
  if (date && date > maxDate) {
    return 'Please enter valid date';
  }

  return null;
};

export const validateTimeRange = (start, end) => {
  if (!start || !end) {
    return 'Time is required';
  }
  if (start === 'Invalid Date' || end === 'Invalid Date') {
    return 'Enter valid times';
  }
  const startTime = dayjs(start, 'HH:mm:ss');
  const endTime = dayjs(end, 'HH:mm:ss');
  if (startTime > endTime) {
    return 'Enter valid times';
  }
  return '';
};

export const validateCreatives = ({ creatives, initialCreative, id }) => {
  if (!creatives.length) return { creatives: { adCreatives: 'Please add creative' } };

  if (id && initialCreative && initialCreative.template !== creatives[0]?.template) {
    return { creatives: { adCreatives: `Please add creative with ${initialCreative.template} template` } };
  }

  return null;
};
