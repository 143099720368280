import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useDynamicBackground = () => {
  const [img, setImg] = useState('/images/login-bg.webp');
  const location = useLocation();
  const { pathname } = location;

  const isLoginRoute = {
    path: '/login',
    image: '/images/signin.jpg',
  };

  const isSignupRoute = {
    path: '/signup',
    image: '/images/signup.jpg',
  };

  const isForgotPasswordRoute = {
    path: '/forgot-password',
    image: '/images/reset-bg.jpg',
  };

  const isResetPasswordRoute = {
    path: '/reset-password',
    image: '/images/reset-bg.jpg',
  };

  const findRouteMatch = useCallback(() => {
    if (pathname.includes(isLoginRoute.path)) {
      setImg(isLoginRoute.image);
    } else if (pathname.includes(isSignupRoute.path)) {
      setImg(isSignupRoute.image);
    } else if (pathname.includes(isForgotPasswordRoute.path)) {
      setImg(isForgotPasswordRoute.image);
    } else if (pathname.includes(isResetPasswordRoute.path)) {
      setImg(isResetPasswordRoute.image);
    } else {
      setImg(isLoginRoute.image);
    }
  }, [
    pathname,
    isLoginRoute.path,
    isLoginRoute.image,
    isSignupRoute.path,
    isSignupRoute.image,
    isForgotPasswordRoute.path,
    isForgotPasswordRoute.image,
    isResetPasswordRoute.path,
    isResetPasswordRoute.image,
  ]);

  useEffect(() => {
    findRouteMatch();
  }, [findRouteMatch]);

  return img;
};

export default useDynamicBackground;
