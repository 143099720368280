import React from 'react';
import PropTypes from 'prop-types';

// Material
import { FormControl, FormHelperText, TextField as MUITextField } from '@mui/material';

function TextField(props) {
  const { label, name, value, type, autoFocus, autoComplete, disabled } = props;
  // Events
  const { onChange, onBlur, onKeyPress, onClick, onFocus, onKeyDown } = props;
  // Options
  const { fullWidth, className, required, rowsNumber } = props;
  // Extra
  const { errors, touched } = props;

  const isTouched = (inputName, touchedInstance) => {
    const splittedName = inputName.split('.');
    if (touchedInstance && touchedInstance[splittedName[0]]) {
      if (splittedName.length > 1) {
        return touchedInstance[splittedName[0]][splittedName[1]];
      }
    }
    return touchedInstance[splittedName[0]];
  };
  const error = errors[name];
  const hasError = Boolean(isTouched(name, touched) && errors[name]);

  return (
    <FormControl variant="outlined" fullWidth={ fullWidth } className={ className } disabled={ disabled }>
      <MUITextField
        id={ name }
        label={ required ? `${label} *` : label }
        type={ type }
        value={ value }
        name={ name }
        disabled={ disabled }
        onChange={ onChange }
        onBlur={ onBlur }
        onKeyPress={ onKeyPress }
        onClick={ onClick }
        onFocus={ onFocus }
        onKeyDown={ onKeyDown }
        autoFocus={ autoFocus }
        autoComplete={ autoComplete }
        multiline
        minRows={ rowsNumber }
        placeholder={ `${label}...` }
        // Options
        fullWidth={ fullWidth }
        // Extra
        error={ hasError }
      />

      { hasError && <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText> }
    </FormControl>
  );
}

TextField.defaultProps = {
  value: '',
  type: 'text',
  autoComplete: 'true',
  autoFocus: false,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  onFocus: () => {},
  onKeyPress: () => {},
  onKeyDown: () => {},
  // Options
  fullWidth: false,
  className: '',
  required: false,
  rowsNumber: 3,
  // Extra
  errors: {},
  touched: {},
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  // Options
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  rowsNumber: PropTypes.number,
  // Extra
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
};

export default TextField;
