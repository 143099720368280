// Libs
import { toNumber } from 'lodash';
import { convertIsoToDate, getIsoDate } from 'libs/dateConvertors';

// Dictionaries
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import DeliveryTypes from 'constants/dictionary/deliveryTypesDictionary';
import BiddingTypes from 'constants/dictionary/biddingTypesDictionary';
import { CustomFilterClass } from '../customFilterClass';

export class CampaignClass {
  id = null;
  advertiser = null;
  name = '';
  status = CampaignStatuses.types.DRAFT;
  start = '';
  end = '';
  timeZone = 'GMT';
  budget = null;
  delivery = DeliveryTypes.types.ASAP;
  frequency = null;
  bidding = BiddingTypes.types.MANUAL;
  cpm = 16;
  countries = new CustomFilterClass();
  states = new CustomFilterClass();
  dmas = new CustomFilterClass();
  cities = new CustomFilterClass();
  districts = new CustomFilterClass();
  zips = new CustomFilterClass();
  screenTypes = [];
  appChannels = [];
  periods = [];
  creativeFlights = [{ id: null, creatives: [] }];

  constructor(...args) {
    args.forEach((item) => {
      this.id = item.id || null;
      this.advertiser = item.advertiser || null;
      this.name = item.name || '';
      this.status = item.status || CampaignStatuses.types.DRAFT;
      this.start = this.getDate(item.start);
      this.end = this.getDate(item.end);
      this.timeZone = item.timeZone || null;
      this.budget = this.getNumericValue(item.budget);
      this.delivery = item.delivery || DeliveryTypes.types.ASAP;
      this.frequency = item.frequency || null;
      this.bidding = item.bidding || BiddingTypes.types.MANUAL;
      this.cpm = this.getNumericValue(item.cpm) || 16;
      this.countries = this.checkValues(item.countries);
      this.states = this.checkValues(item.states);
      this.dmas = this.checkValues(item.dmas);
      this.cities = this.checkValues(item.cities);
      this.districts = this.checkValues(item.districts);
      this.zips = this.checkValues(item.zips);
      this.screenTypes = item.screenTypes || [];
      this.appChannels = item.appChannels || [];
      this.periods = item.periods || [];
      this.creativeFlights = item.creativeFlights || [{ id: null, creatives: [] }];
    });
  }

  getDate(date) {
    if (typeof date === 'string') {
      return convertIsoToDate(date);
    } if (date instanceof Date) {
      return getIsoDate(date);
    }
    return null;
  }

  getNumericValue(val) {
    if (val === null) {
      return '';
    }
    if (typeof val === 'string') {
      return toNumber(val) ? toNumber(val) : null;
    }
    return val;
  }

  checkValues(allowValuesInstance) {
    if (allowValuesInstance) {
      if (allowValuesInstance.length) {
        return new CustomFilterClass({ values: allowValuesInstance });
      }
      if (allowValuesInstance.values.length) {
        return [...allowValuesInstance.values];
      }
      return null;
    }
    return new CustomFilterClass();
  }
}
