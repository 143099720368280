import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import useAuth from 'hooks/useAuth';
import { handleAuth } from 'libs/canva/utils';
import { jwtDecode } from 'jwt-decode';

const useCanvaRedirect = () => {
  const { isAuthenticated } = useAuth({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const uuid = Cookies.get('uuid') || crypto.randomUUID();
    // expires after 90 days
    Cookies.set('uuid', uuid, {
      sameSite: 'none',
      secure: true,
      path: '/',
      expires: 90,
    });

    const canvaUserToken = searchParams.get('canva_user_token');
    const nonce = searchParams.get('nonce');
    const state = searchParams.get('state');
    const decoded = jwtDecode(canvaUserToken);

    const canvaUserId = decoded.userId;
    const { brandId } = decoded;

    if (isAuthenticated) {
      if (canvaUserId && nonce && state) {
        handleAuth({ nonce, state, canvaUserId, brandId });
      } else {
        navigate('/dashboard');
      }
    }
  }, [isAuthenticated, navigate, searchParams]);
};

export default useCanvaRedirect;
