const types = {
  AUTO_BID: 'AUTO_BID',
  MANUAL: 'MANUAL',
};

const dictionary = [
  { id: types.AUTO_BID, name: 'Auto Bid' },
  { id: types.MANUAL, name: 'Manual' },
];

export default {
  types,
  dictionary,
};
