import React from 'react';

import { Typography } from '@mui/material';
import classes from './Styles.module.scss';
import Logo from '../Auth/Logo/Logo';

function Terms() {
  return (
    <div className={ classes.mainContainer }>
      <div>
        <Logo className={ classes.logo } to="/login" />
      </div>
      <div>
        <Typography variant="h1">Self-Serve Advertising Platform Terms</Typography>
        <div className={ classes.effectiveDate }>Effective Date: October 3, 2023</div>
        <Typography>
          The following terms (“Self-Serve Advertising Platform Terms” or “Terms”) are entered into by Telly, Inc. (“Telly”) and the
          entity executing these Terms or that accepts these Terms electronically (“you”). The Terms apply to your use of Telly’s
          Self-Serve Ad Platform (such as the self-service advertising interfaces) for creation, submission and/or delivery of any
          advertising or other commercial or sponsored activity or content (collectively, “Self-Serve Ad Interfaces”) and any order
          you place through the Self-Serve Ad Interfaces (“Order”).
        </Typography>
        <ol>
          <li>When you place an Order, you will tell us the type of advertising inventory you want to buy, the amount you
            want to spend and the timeline in which you want to buy. If we accept your Order, we will deliver your ads as
            advertising inventory becomes available. Subject to Section 5, when serving your ad, we use commercially
            reasonable efforts to deliver the ads to the audience you specify or to achieve the outcome you select, though
            we cannot guarantee in every instance that your ad will reach its intended audience or achieve the outcome you
            select.
          </li>
          <li>
            Your ads must comply with all applicable laws, regulations, and guidelines,
            as well as our <a href="/policies">Policies</a>. Failure to
            comply may result in a variety of consequences, including the cancellation of ads you have placed and
            termination of your account. You are solely responsible for all: (i) ads, (ii) ads trafficking or targeting decisions
            (“Targets”), (iii) destinations to which ads direct viewers (e.g., landing pages, mobile applications) along with the
            related URLs, waypoints, and redirects (“Destinations”), and (iv) services and products advertised on
            Destinations (collectively, “Services”). In connection with the Self-Serve Ad Interfaces, Telly will comply with the
            Telly Privacy Policies available at www.freetelly.com (as modified from time to time). To the extent Self-Serve
            Ad Interface use is within scope, Telly and you agree, as applicable, to the (i) Telly Self-Serve Ads Data
            Protection Terms at <a href="/acp">this page</a>).
            You will not, and will not authorize any third party to, (i) generate automated,
            unauthorized or otherwise invalid impressions, inquiries, clicks or conversions, (ii) conceal conversions where
            they are required to be disclosed, (iii) use any automated means or form of scraping or data extraction to access,
            query or otherwise collect Telly advertising-related information from any except as expressly permitted by Telly,
            (iv) use data to build or enhance user profiles or device graphs for planning, activation or targeting; or (v) attempt
            to interfere with the functioning of the Self-Serve Ad Interfaces
          </li>
          <li>
            You (a) will not provide ads that contain or connect to malware, spyware, unwanted software or any other
            malicious code or knowingly breach or circumvent any Self-Serve Ad Interface security measure, (b) may utilize
            an ad server solely for serving or tracking ads under Self-Serve Ad Interfaces that permit third-party ad serving
            and, if required, only if the ad server has been authorized by Telly to participate in the Self-Serve Ad Interfaces.
            Telly will implement your ad server tags so that they are functional.
          </li>
          <li>
            We may reject or remove any ad for any reason.
          </li>
          <li>You will pay for your Orders in accordance with the following:
            <ol className={ classes.listLetters }>
              <li>
                You will pay all amounts specified in each Order you place, along with any applicable taxes. The
                amount you owe for each Order will be calculated based on our measurement and tracking
                mechanisms. All advertising transactions deemed by us to be executed using the Self-Serve Ad
                Interfaces are final and binding, and you will be ultimately responsible for any and all payment
                obligations for your advertising transactions. You will have no recourse against us for (i) any advertising
                transaction that does or does not occur based on erroneous bidding / targeting terms entered by you
                or any other party to your advertising transactions, and (ii) any discrepancy between our billing
                measurements (including impression and click count) and any alternative measurements produced by
                you or a third party. Our billing measurements will be the sole basis for determining the amounts owed
              </li>
              <li>
                By placing an Order, you authorize us to obtain your personal and/or business credit report from a
                credit bureau, either when you place an Order or at any time thereafter.
              </li>
              <li>
                You are responsible for maintaining the security of your account, and you understand that you will be
                charged for any Orders placed on or through your account.
              </li>
              <li>
                If you are making direct debit payments, you agree that we can charge you any amount that falls within
                the range you agreed to upon signup. We will notify you in advance if any charge will exceed the
                agreed-upon range.
              </li>
              <li>
                If you are making direct debit payments, you agree that we can charge you any amount that falls within
                the range you agreed to upon signup. We will notify you in advance if any charge will exceed the
                agreed-upon range.
              </li>
              <li>
                The amounts we charge you may be subject to and include applicable taxes and levies, including
                without limitation withholding taxes. You are responsible for bearing and remitting any taxes that apply
                to your transactions. You will indemnify and hold us harmless from and against any claim arising out of
                your failure to do so.
              </li>
              <li>
                If your payment method fails or your account is past due, we may take additional steps to collect past
                due amounts. You will pay all expenses associated with such collection, including reasonable attorneys&apos;
                fees. Past due amounts will accrue interest at 1% per month or the lawful maximum, whichever is less.
              </li>
              <li>
                You will fall under one of two categories depending on your payment method: invoiced or non-invoiced
                client. Invoiced clients are those for whom Telly sets a maximum spending limit and issues invoices on a
                periodic basis for payment in accordance with the applicable invoicing terms. Non-invoiced clients are
                those who must make payments at the time of purchase itself. In its sole discretion, Telly may classify
                clients as invoiced clients based on factors such as ad spend and creditworthiness.
              </li>
            </ol>
          </li>
          <li>
            You understand that, from time to time, we run tests on our Self-Serve Ad Interfaces and related systems, which
            may impact your use and experience thereof, including campaign performance. You acknowledge and agree
            that we may test as notified in these Terms, including to assess formatting, relevance, pricing, reporting,
            targeting, and delivery.
          </li>
          <li>We will determine the size, placement, and positioning of your ads.</li>
          <li>Scheduling of delivery is subject to availability and may not be continuous.</li>
          <li>We do not guarantee the reach or performance that your ads will receive, such as the number of people who will
            see your ads or the number of interactions your ads will get.
          </li>
          <li>Where applicable, we cannot control how interactions are generated on your ads. We have systems that attempt
            to detect and filter certain activity, but we are not responsible for interaction fraud, technological issues, or other
            potentially invalid interaction activity that may affect the cost of running ads
          </li>
          <li>
            Our license to deliver your ad will end when we have completed your Order. You understand, however, that:
            <ol className={ classes.listLetters }>
              <li>
                Once displayed, ads are public information.
              </li>
              <li>
                If your ad requires certain reporting (e.g. for political ads) Telly may display (at no cost to you) and
                provide access to the ad content and creative, and information about the ad campaign (such as total
                spend and delivery data, and targeting information) for a period of seven years from the completion of
                your order.
              </li>
              <li>
                You consent that Telly may disclose your advertising content, and all information associated with your
                advertising, to a governmental entity or body if Telly believes that disclosure would assist in a lawful
                investigation.
              </li>
            </ol>
          </li>
          <li>
            We will provide you with reports about the kinds of people seeing your ads and how your ads are performing. We
            may provide a business, and all those who advertise for the business, with information about the number of ads
            being run for the business across the Telly and any applicable restrictions on those ads.
          </li>
          <li>
            You will not issue any press release or make public statements about your relationship with Telly without our
            prior written permission.
          </li>
          <li>
            If you are placing ads on someone else&apos;s behalf, you must have permission to place those ads, and agree as
            follows:
            <ol className={ classes.listLetters }>
              <li>
                You represent and warrant that you have the authority to and will bind the advertiser to these
                Self-Serve Ad Terms.
              </li>
              <li>
                If the advertiser you represent violates these Self-Serve Ad Terms, we may hold you responsible for
                that violation.
              </li>
              <li>
                You agree that we may provide campaign reporting information to the end advertiser for whom you
                placed a campaign.
              </li>
            </ol>
          </li>
          <li>
            Warranty, Rights, and Obligations. You warrant that (a) you hold, and grant Telly, its affiliates and partners, the
            rights in ads, Destinations, and Targets for Telly and partners to operate the Telly Self-Serve Ad
            Interfaces and (b) all information and authorizations provided by you are complete, correct and current. You
            authorize Telly and its affiliates to automate retrieval and analysis of, and create test credentials to
            access, Destinations for the purposes of the Self-Serve Ad Interfaces.  By providing any mobile or other
            telephone number to Telly in connection with the Self-Serve Ad Interfaces, you authorize Telly, its affiliates and
            their agents to call and send text messages (for which standard message and data rates may apply) to the
            provided telephone numbers, including by an automatic telephone dialing system, for purposes of the Self-Serve
            Ad Interfaces. You further authorize Telly, its affiliates and their agents to send electronic mail to you for
            purposes of the Self-Serve Ad Interfaces.
          </li>
          <li>
            Disclaimers. (a) TO THE FULLEST EXTENT PERMITTED BY LAW AND SUBJECT TO 16(b) BELOW, EACH PARTY
            ON BEHALF OF ITSELF EXCLUDES ALL IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION FOR
            NON-INFRINGEMENT, SATISFACTORY QUALITY, MERCHANTABILITY AND FITNESS FOR ANY PURPOSE.  TO
            THE FULLEST EXTENT PERMITTED BY LAW AND SUBJECT TO 16(b) BELOW, THE SELF-SERVE AD
            INTERFACES AND TELLY, ITS AFFILIATES, AND ANY PARTNER PROPERTIES ARE PROVIDED “AS IS” AND AT
            YOUR AND YOUR ADVERTISERS’ OPTION AND RISK AND NONE OF TELLY, ITS AFFILIATES OR TELLY’S
            PARTNERS MAKE ANY GUARANTEE OR REPRESENTATION IN CONNECTION WITH THE SELF-SERVE AD
            INTERFACES OR SELF-SERVE AD INTERFACE RESULTS. (b) CERTAIN LAWS OF THE JURISDICTION IN WHICH
            YOU RESIDE MAY CONFER RIGHTS AND REMEDIES AND IMPLY TERMS INTO THESE TERMS THAT CANNOT
            BE EXCLUDED. THOSE RIGHTS, REMEDIES, AND IMPLIED TERMS ARE NOT EXCLUDED BY THESE TERMS. TO
            THE EXTENT THAT THE RELEVANT LAWS PERMIT TELLY TO LIMIT THEIR OPERATION, TELLY’S LIABILITY
            UNDER THOSE LAWS WILL BE LIMITED AT ITS OPTION, TO THE SUPPLY OF THE SERVICES AGAIN, OR
            PAYMENT OF THE COST OF HAVING THE SERVICES SUPPLIED AGAIN.
          </li>
          <li>
            Limitation of Liability. EXCEPT FOR SECTION 17 AND YOUR BREACHES OF SECTIONS 3(A), TO THE FULLEST
            EXTENT PERMITTED BY LAW BUT ALWAYS SUBJECT TO SECTION 16(b):  (a) NO PARTY MAY BE HELD LIABLE
            UNDER OR IN CONNECTION WITH THESE TERMS (WHETHER IN CONTRACT, TORT, INCLUDING, WITHOUT
            LIMITATION, NEGLIGENCE OR OTHERWISE) FOR ANY: (I) LOSS OF PROFIT; (II) LOSS OF ANTICIPATED
            SAVINGS: (III) LOSS OF BUSINESS OPPORTUNITY; (IV) LOSS OF OR CORRUPTION OF DATA; (V) LOSS OR
            DAMAGE RESULTING FROM THIRD PARTY CLAIMS; OR (VI) INDIRECT, SPECIAL OR CONSEQUENTIAL LOSSES;
            SUFFERED OR INCURRED BY THE OTHER PARTY (WHETHER OR NOT SUCH LOSSES WERE IN
            CONTEMPLATION OF THE PARTIES AT THE DATE THESE TERMS WERE ACCEPTED BY YOU);  AND (b)
            SUBJECT TO SECTION 16(a) OTHER THAN YOUR PAYMENT OBLIGATIONS UNDER THESE TERMS, EACH
            PARTY’S AGGREGATE LIABILITY TO THE OTHER FROM ANY GIVEN EVENT OR SERIES OF CONNECTED
            EVENTS UNDER OR IN CONNECTION WITH THESE TERMS, IS LIMITED TO THE GREATER OF: (I) THE AMOUNT
            PAYABLE TO TELLY BY YOU UNDER THE TERMS IN THE TWO MONTHS IMMEDIATELY PRECEDING THE
            MONTH IN WHICH THE EVENT (OR FIRST IN A SERIES OF CONNECTED EVENTS) OCCURRED; AND (II)
            $10,000.
          </li>
          <li>
            Indemnification. You will defend, and indemnify Telly, its partners, agents, affiliates, and licensors (each an
            “Indemnified Person”) against all liabilities, damages, losses, costs, fees (including legal fees), and expenses
            relating to any third-party allegation or legal proceeding to the extent arising out of or related to Ads, Targets,
            Destinations, Services, use or any breach of these Terms by you, except in relation to each Indemnified Person,
            to the extent that the third party claim or liability arises as a direct result of: (a) that Indemnified Person’s
            negligence or misconduct; or (b) that Indemnified Person’s breach of the Terms.  Partners are intended
            third-party beneficiaries of this Section.
          </li>
          <li>
            DISPUTE RESOLUTION AGREEMENT.
            <ol className={ classes.listLetters }>
              <li>
                Negotiation. In the event any dispute arises out of or in connection with these Terms  (each, a
                “Dispute”), the parties will make good faith efforts to resolve the Dispute within 60 days of written
                notice of the Dispute from the other party. If the parties are unable or unwilling to resolve the Dispute in
                that time, the Dispute will be finally determined by arbitration administered by the International Centre
                for Dispute Resolution (“ICDR”) under its International Arbitration Rules (“Rules”).  This agreement to
                arbitrate is intended to be broadly interpreted and, among other claims, applies to any claims brought
                by or against (i) Telly, Telly affiliates that provide the Self-Serve Ad Interfaces to you or your
                advertisers, Telly parent companies, and the respective officers, directors, employees, agents,
                predecessors, successors, and assigns of these entities and (ii) you or your advertiser(s), the
                respective affiliates and parent companies of you or your advertisers, and the respective officers,
                directors, employees, agents, predecessors, successors, and assigns of these entities.
              </li>
              <li>
                Arbitration Procedures. The place of arbitration will be New York, NY. The arbitration will be before a
                tribunal of three arbitrators. The claimant(s) will select one arbitrator of the tribunal in accordance with
                the Rules, and the respondent(s) will jointly select the second arbitrator in accordance with the Rules.
                If the parties fail to agree on the third arbitrator (the “Chairman”) within 20 days of the confirmation of
                the second, the ICDR will appoint the Chairman in accordance with the Rules. The arbitration will be
                conducted in English. Except as may be otherwise required by law, the existence, contents or results of
                any arbitration, including any documents or evidence produced, will be strictly confidential. The
                decision of the arbitrators will be final and binding and judgment upon any awards rendered by the
                arbitrators may be entered in any court of competent jurisdiction thereof.  The arbitrators will not be
                bound by rulings in other arbitrations in which you or you advertiser(s) is not a
                party. The arbitrators will have the power to grant declaratory or injunctive relief, whether interim or
                final, only in favor of the individual party seeking relief and only to the extent necessary to provide relief
                warranted by that party’s individual claim without affecting other Telly users or other customers or
                advertisers, and any provisional measures ordered by the arbitrators may be enforced by any court of
                competent jurisdiction. Nothing in these Terms will prevent either party from seeking individualized
                provisional or preliminary relief from any court of competent jurisdiction, and any such application to a
                court for individualized provisional or preliminary relief will not be deemed incompatible with the
                agreement to arbitrate or a waiver of the right to arbitrate.
              </li>
            </ol>
          </li>
          <li>
            Miscellaneous. (a) ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR THE SELF-SERVE
            ADVERTISING INTERFACES WILL BE GOVERNED BY NEW YORK LAW, EXCLUDING ITS CONFLICT OF LAWS
            RULES, EXCEPT TO THE EXTENT THAT NEW YORK LAW IS CONTRARY TO OR PREEMPTED BY FEDERAL
            UNITED STATES LAW. (b) EXCEPT AS PROVIDED IN SECTION 19, SOLELY IN THE EVENT THAT SECTION 19 IS
            NOT ENFORCED AS TO A PARTICULAR CLAIM OR DISPUTE, ALL CLAIMS ARISING OUT OF OR RELATING TO
            THESE TERMS OR THE PROGRAMS WILL BE LITIGATED EXCLUSIVELY IN THE FEDERAL OR STATE COURTS
            OF NEW YORK COUNTY, NEW YORK; THE PARTIES CONSENT TO PERSONAL JURISDICTION IN THESE
            COURTS.  (c) These Terms are the parties’ entire agreement relating to their subject matter and supersede any
            prior or contemporaneous agreements on those subjects. (d) You may not make any public statement regarding
            the relationship contemplated by these Terms (except when required by law).   (e) All notices of termination or
            breach, or under Section 19, must be in writing and addressed to the other party’s Legal Department (or if it is
            not known if the other party has a Legal Department then to the other party&apos;s primary contact or other address
            on file). The email address for notices being sent to Telly’s Legal Department is notices@freetelly.com.  All other
            notices to you will be in writing and sent to an email address associated with your account.  All other notices to
            Telly will be in writing and addressed to your primary contact at Telly or other method made available by
            Telly.   Notice will be treated as given on receipt, as confirmed by written or electronic means.  These notice
            requirements do not apply to legal service of process, which is instead governed by applicable law. (f) Except for
            modifications to these Terms by Telly under Section 19, any amendment must be agreed to by both parties
            and must expressly state that it is amending these Terms.  Neither party will be treated as having waived any
            rights by not exercising (or by delaying the exercise of) any rights under these Terms. If any provision of these
            Terms is found unenforceable, that provision will be severed and the balance of the Terms will remain in full force
            and effect.  (g) Neither party may assign any part of these Terms without the written consent of the other party
            save that (A) Telly may assign all or part of its rights and/or obligations under these Terms to an affiliate, and (B)
            you may assign all of its rights and obligations under these Terms to an affiliate but only where (I) the assignee
            agrees in writing to be bound by these Terms, (II) you remain liable for obligations under these Terms if the
            assignee defaults on them, and (III) you have notified Telly of the assignment.  Any other attempt to transfer or
            assign is void.   (h) Except as expressly listed in Sections 18 and 19, there are no third-party beneficiaries to these
            Terms.  (i) These Terms do not create any agency, partnership or joint venture among the parties.  (j) Section 5
            and 15 through 21 will survive termination of these Terms.  (k) Except for payment obligations, no party or
            its affiliates are liable for failure or delay in performance to the extent caused by circumstances beyond its
            reasonable control. (l) Some Self-Serve Ad Interface features are identified as “Beta” or as otherwise
            unsupported or confidential (collectively, “Beta Features”). You may not disclose any information from Beta
            Features or the terms or existence of any non-public Beta Features
          </li>
          <li>
            We may ask you to review and accept supplemental terms that apply to your use of a specific feature or
            functionality made available through the Self-Serve Ad Interfaces. To the extent those supplemental terms
            conflict with these Terms, the supplemental terms will govern with respect to your use of the specific feature or
            functionality to the extent of the conflict. We may change or update these Terms from time to time and your
            continued use of the Self-Serve Ad Interfaces constitutes acceptance of those changes.
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Terms;
