import { useFormik } from 'formik';
import { validateEmail, validateLength, validateRequired } from 'libs/validateErrors';

const FORM_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyName: '',
  timeZone: 'GMT',
  accountType: '',
  privacyPolicy: false,
};

// there is a bug with formik and react testing library,
// react testing library throws act error when validateOnMount is true in test
const useSignupForm = ({ initialValues, handleSubmit, isTest = false }) => {
  const validate = (values) => {
    const firstName = validateRequired(values.firstName, 'First Name') ||
        validateLength(values.firstName, 'First Name', 1, 20);

    const lastName = validateRequired(values.lastName, 'Last Name') ||
        validateLength(values.lastName, 'Last Name', 1, 20);

    const companyName = validateRequired(values.companyName, 'Company Name') ||
      validateLength(values.companyName, 'Company Name', 1, 30);

    const email = validateRequired(values.email, 'Email') ||
        validateEmail(values.email, 'Email');

    const phone = validateRequired(values.phone, 'Phone Number');

    const timeZone = validateRequired(values.timeZone, 'Time Zone');

    const accountType = validateRequired(values.accountType, 'Account Type');

    const privacyPolicy = validateRequired(values.privacyPolicy, 'Terms and Conditions');

    return {
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(companyName && { companyName }),
      ...(email && { email }),
      ...(phone && { phone }),
      ...(timeZone && { timeZone }),
      ...(accountType && { accountType }),
      ...(privacyPolicy && { privacyPolicy }),
    };
  };

  const formik = useFormik({
    initialValues: {
      ...FORM_VALUES,
      ...initialValues,
    },
    onSubmit: (values, { setSubmitting }) => handleSubmit(values, () => setSubmitting(false)),
    validate,
    enableReinitialize: true,
    validateOnMount: !isTest,
    validateOnBlur: false,
  });

  return {
    validate,
    formik,
  };
};

export default useSignupForm;
