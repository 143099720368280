import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Material
import { Paper, IconButton, InputBase } from '@mui/material';

// Icons
import SearchIcon from '@mui/icons-material/Search';

// Libs
import { handleStateChange } from 'libs/handleStateChange';

// Styles
import classes from './SearchFilterInput.module.scss';

function SearchFilterInput({ setSearchFilter, ...props }) {
  const searchInput = useRef(null);
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.ctrlKey && e.keyCode === 191) {
        searchInput.current.focus();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <Paper elevation={ 0 } className={ classes.searchWrapper }>
      <IconButton className={ classes.searchIconButton } aria-label="search" size="large">
        <SearchIcon fontSize="large" color="primary" />
      </IconButton>

      <InputBase
        inputRef={ searchInput }
        onChange={ (e) => handleStateChange(e, setSearchFilter) }
        className={ classes.searchInput }
        { ...props }
      />
    </Paper>
  );
}

SearchFilterInput.defaultProps = {};

SearchFilterInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
};

export default SearchFilterInput;
