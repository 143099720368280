import ItemsPerPage from 'modules/ItemsPerPage/itemsPerPage';
import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

function MaterialTableToolbar({ tableRef, entityName, pageRef }) {
  const pageSizeOptions = tableRef?.current?.props?.options?.pageSizeOptions || [];
  const pageSize = tableRef?.current?.state?.pageSize || 10;
  const total = tableRef?.current?.state?.query?.totalCount || 0;

  const changeRowsPerPage = useCallback((value) => {
    if (pageRef.current) pageRef.current = 0;
    tableRef?.current?.onChangeRowsPerPage({ target: { value } });
  }, [tableRef, pageRef]);

  return (
    <Grid container justifyContent="space-between" alignItems="center" className="mb-3">
      <Grid item>
        <ItemsPerPage
          possibleValuesPerPage={ pageSizeOptions }
          activePerPage={ pageSize }
          onClickPerPage={ changeRowsPerPage }
        />
      </Grid>
      <Grid item>
        <span>Total {entityName}: {total}</span>
      </Grid>
    </Grid>
  );
}

MaterialTableToolbar.propTypes = {
  tableRef: PropTypes.shape({}).isRequired,
  pageRef: PropTypes.shape({}).isRequired,
  entityName: PropTypes.string.isRequired,
};

export default memo(MaterialTableToolbar);
