import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import CampaignList from './CampaignList';
import AddCampaign from './AddCampaign/AddCampaign';

function Campaigns() {
  return (
    <Routes>
      <Route path="create/*" element={ <AddCampaign /> } />
      <Route path=":id" element={ <AddCampaign /> } />
      <Route path="" element={ <CampaignList /> } />
    </Routes>
  );
}

export default Campaigns;
