import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// Utils
import classNames from 'classnames';

// Modules
import MaterialTableText from '../MaterialTableText/MaterialTableText';

// Styles
import classes from './MaterialTableTotal.module.scss';

function MaterialTableTotal({ columns, total, showTotal }) {
  const renderText = useCallback((item, index) => {
    if (item.renderTotal) {
      return item.renderTotal(total);
    } if (index === 0) {
      return <MaterialTableText fontWeight="bold">Total</MaterialTableText>;
    }
    return '';
  }, [total]);

  const renderTotal = useMemo(() => (
    <tbody className={ classNames('MuiTableBody-root', classes.wrapper) }>
      <tr className={ classNames('MuiTableRow-root') }>
        { columns.map((item, index) => (
          <td className={ classNames('MuiTableCell-root') } key={ item.field }>
            {renderText(item, index)}
          </td>
        )) }
      </tr>
    </tbody>
  ), [columns, renderText]);

  return showTotal && renderTotal;
}

MaterialTableTotal.defaultProps = {
  columns: [],
  total: {},
  showTotal: false,
};

MaterialTableTotal.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({ field: PropTypes.string })),
  total: PropTypes.shape({}),
  showTotal: PropTypes.bool,
};

export default MaterialTableTotal;
