import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useSnackbar } from 'notistack';

import { transformCreativeValues } from 'libs/creative/creativeModelUtils';

// Material
import { Grid } from '@mui/material';

// Modules
import { StepContainer } from 'modules/Steps';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';
import FormError from 'modules/FormError/FormError';

// Constants
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

// Classes
import NativeCreativeClass from 'classes/creative/nativeCreativeClass';
import VideoCreativeClass from 'classes/creative/videoCreativeClass';
import useCreatives from './useCreatives';

// Components
import CreativeListTitle from './CreativeListTitle';
import CreativePreview from './CreativePreview';
import AddCreative from './AddCreative/AddCreative';

import classes from './Creatives.module.scss';
import classesContainer from '../../AddCampaign.module.scss';
import { useCreateCreativeMutation } from '../../../../Creatives/creativeApiSlice';

const initCreatives = (formik) => (formik.values.creativeFlights[0]?.creatives?.length ?
  formik.values.creativeFlights[0].creatives.map((item) => item.id)[0]
  : null);

function Creatives({ formik, editable, getCreativeList }) {
  const { groupedCreatives, creativeList } = useCreatives(formik);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCreativeId, setSelectedCreativeId] = useState(() => initCreatives(formik));

  const [createCreative] = useCreateCreativeMutation();

  const { setFieldValue, values, setFieldTouched } = formik;
  const { creativeFlights = [] } = values;
  const { creatives = [] } = creativeFlights[0];

  const createCreativeRequest = useCallback(async (creativeFormValues, callbackFinally) => {
    try {
      const formData = await transformCreativeValues(creativeFormValues);
      const newCreative = await createCreative(formData).unwrap();
      return newCreative;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong! Please, try again.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
      return null;
    } finally {
      callbackFinally();
    }
  }, [createCreative, enqueueSnackbar]);

  const handleChangeCreative = useCallback((e) => {
    setFieldTouched('creatives.adCreatives', true);
    const selectedId = e.target.value;
    const selectedCreative = creativeList
      .find((item) => item.id === selectedId);

    setFieldValue(
      'creativeFlights[0].creatives',
      selectedCreative ? [{ ...selectedCreative, advertiser: selectedCreative.advertiser.id }] : null,
    );

    setSelectedCreativeId(selectedId);
  }, [creativeList, setFieldTouched, setFieldValue]);

  const addCreative = useCallback(async (newCreativeValues, callbackFinally) => {
    const newCreative = await createCreativeRequest(newCreativeValues, callbackFinally);

    if (newCreative) {
      setFieldValue('creativeFlights[0].creatives', [newCreative]);
      setSelectedCreativeId(newCreative.id);
      getCreativeList();
    }

    return newCreative;
  }, [createCreativeRequest, setFieldValue, getCreativeList]);

  const creativeInitialValues = useMemo(() => ({
    name: '',
    advertiser: values.advertiser,
    status: 'ACTIVE',
    type: CREATIVE_TYPES.types.NATIVE,
    dataType: CREATIVE_DATA_TYPES.types.UPLOAD_FILE,
    url: '',
    file: null,
    content: '',
    size: null,
    template: CREATIVE_TEMPLATES.types.COLLAPSED.NAME,
    subType: CREATIVE_TEMPLATES.types.COLLAPSED.CODE,
    assets: [],
  }), [values.advertiser]);

  const subtitle = editable ?
    'You can select one creative from previously created or create a new one'
    : 'In order to add new creative pause campaign and submit it for approval with new creative';

  return (
    <StepContainer
      title="Upload your creative"
      subtitle={ subtitle }
      containerClassName={ classesContainer.stepContainer }
    >
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <SelectVirtualized
            tree
            list={ groupedCreatives }
            label="Creative"
            onChange={ handleChangeCreative }
            value={ selectedCreativeId }
            disabled={ !editable }
          />
          <FormError error={ formik.touched.creatives?.adCreatives && formik.errors.creatives?.adCreatives } />
        </Grid>
        <Grid item xs={ 12 }>
          <CreativeListTitle
            title="Creative:"
            selectedLength={ (creatives || []).length }
          />
        </Grid>

        <Grid item container xs={ 12 } spacing={ 2 }>
          {creatives.map((creative) => (
            <Grid key={ creative.name + creative.id } item xs={ 4 } lg={ 3 }>
              <CreativePreview className={ classes.creativePreviewWrapper } creative={ creative } />
            </Grid>
          ))}
        </Grid>

        { editable && (
        <AddCreative
          addCreative={ addCreative }
          creativeInitialValues={ creativeInitialValues }
        />
        )}
      </Grid>
    </StepContainer>
  );
}

Creatives.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      creatives: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(NativeCreativeClass),
        PropTypes.instanceOf(VideoCreativeClass),
      ])),
    }),
    handleChange: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  getCreativeList: PropTypes.func.isRequired,
};

export default Creatives;
