import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Hooks
import { StepContainer } from 'modules/Steps';

// Components
import { Grid, Typography } from '@mui/material';
import Input from 'modules/_Factories/Input/Input';
import dayjs from 'dayjs';
import NativeCreativeClass from 'classes/creative/nativeCreativeClass';
import VideoCreativeClass from 'classes/creative/videoCreativeClass';
import Creatives from './Creatives';
import classesContainer from '../../AddCampaign.module.scss';

function Review({ values }) {
  const {
    name,
    start,
    end,
    budget,
    creativeFlights = [],
  } = values;

  const { creatives = [] } = creativeFlights[0];

  return (
    <StepContainer
      title="Review your campaign"
      subtitle="Сheck entered data and request for approval or save and do it later"
      containerClassName={ classesContainer.stepContainer }
    >
      <Grid container spacing={ 3 }>
        <Grid
          item
          container
          sm={ 12 }
          md={ 6 }
          spacing={ 3 }
          direction="column"
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography>Campaign Title</Typography>
            <Input
              value={ name }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item>
            <Typography>Budget</Typography>
            <Input
              value={ budget }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item>
            <Typography>Start Date</Typography>
            <Input
              value={ dayjs(start).format('MM/DD/YYYY') }
              fullWidth
              disabled
            />
          </Grid>
          <Grid item>
            <Typography>End Date</Typography>
            <Input
              value={ dayjs(end).format('MM/DD/YYYY') }
              fullWidth
              disabled
            />
          </Grid>
        </Grid>

        {/* Creatives */}
        {!!creatives.length && (
          <Grid item container sm={ 12 } md={ 6 }>
            <Creatives creatives={ creatives } />
          </Grid>
        )}
      </Grid>
    </StepContainer>
  );
}

Review.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    status: PropTypes.string.isRequired,
    creatives: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape(NativeCreativeClass),
      PropTypes.shape(VideoCreativeClass),
    ])),
    budget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zips: PropTypes.shape({}),
    cities: PropTypes.shape({}),
    districts: PropTypes.shape({}),
    countries: PropTypes.shape({}),
    dmas: PropTypes.shape({}),
    states: PropTypes.shape({}),
  }).isRequired,
};

export default memo(Review);
