import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid, Typography } from '@mui/material';

// Libs
import { get } from 'lodash';

// Modules
import Input from 'modules/_Factories/Input/Input';
import { StepContainer } from 'modules/Steps';

// Dictionaries
import classesContainer from '../../AddCampaign.module.scss';

function BudgetAndConstraints({ formik, editable }) {
  const fields = { budget: { name: 'budget', label: 'Campaign Budget' } };

  const budget = get(formik.values, fields.budget.name);
  const budgetValue = budget !== undefined ? budget : '';

  return (
    <StepContainer
      title="Budget your campaign"
      subtitle="You can provide the total daily budget"
      containerClassName={ classesContainer.stepContainer }
    >
      <Grid container spacing={ 4 }>
        <Grid container item spacing={ 4 }>
          <Grid item xs={ 12 } sm={ 6 }>
            <Typography>{fields.budget.label}</Typography>
            <Input
              type="number"
              step="0.01"
              name={ fields.budget.name }
              placeholder={ fields.budget.label }
              value={ budgetValue }
              // Options
              fullWidth
              required
              disabled={ !editable }
              // Events
              onChange={ formik.handleChange }
              onBlur={ formik.handleBlur }
              setFieldTouched={ formik.setFieldTouched }
              // Extra
              errors={ formik.errors }
              touched={ formik.touched }
            />
          </Grid>
        </Grid>
      </Grid>

    </StepContainer>
  );
}

BudgetAndConstraints.defaultProps = { };

BudgetAndConstraints.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({ budget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

export default BudgetAndConstraints;
