import { isEmpty, isEqual } from 'lodash';
import ASSETS_TYPES from 'constants/dictionary/creativeAssetsTypeDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';
import { validateFileSize, validateLength, validateRequired } from '../validateErrors';

const VIDEO_RATIO = CREATIVE_TEMPLATES.types.VIDEO.ASSET.ratio;

const floor = (width, height) => Math.floor((width / height) * 10) / 10;
export const findAssetByTemplateStart = (assets, template) => assets.find((asset) => asset.template?.startsWith(template));

export const findAssetBySize = (assets, size) => assets.find((item) => {
  const itemSize = item.expectedSize || item.size;
  return isEqual(itemSize, size);
});

export const getAssetUrl = (asset) => {
  if (!asset) return null;
  return asset.file ? URL.createObjectURL(asset.file) : asset.url;
};

export const getValueByTypeof = (value) => {
  if (typeof value === 'string') {
    return {
      file: null,
      url: value,
      value: null,
    };
  }
  if (typeof value === 'object') {
    return {
      file: value,
      url: null,
      value: null,
    };
  }
  return null;
};

export function resolveVideoSizeCallback(resolve, cleanUpCallback) {
  const height = this.videoHeight;
  const width = this.videoWidth;

  cleanUpCallback();

  resolve({ height, width });
}

export function resolveImageSizeCallback(resolve, cleanUpCallback) {
  const height = this.naturalHeight;
  const width = this.naturalWidth;

  cleanUpCallback();

  resolve({ height, width });
}

export function resolveUrlError(resolve, cleanUpCallback) {
  cleanUpCallback();
  resolve({ error: 'URL is invalid' });
}

export const validateVideoSize = async (video) => {
  if (video.file || video.url) {
    const result = await new Promise((resolve) => {
      const creativeUrl = video.file ? URL.createObjectURL(video.file) : video.url;
      let mediaElement = document.createElement('video');
      const cleanUpCallback = () => {
        URL.revokeObjectURL(creativeUrl);
        mediaElement = null;
      };
      mediaElement.addEventListener('loadedmetadata', function callback() {
        resolveVideoSizeCallback.call(this, resolve, cleanUpCallback);
      }, false);
      mediaElement.addEventListener('error', () => resolveUrlError(resolve, cleanUpCallback));
      // start download meta-datas
      mediaElement.src = creativeUrl;
    });

    if (result.error) {
      return result.error;
    }

    if (floor(result.width, result.height) !== floor(VIDEO_RATIO.width, VIDEO_RATIO.height)) {
      return `Please add asset with aspect ratio ${VIDEO_RATIO.width}:${VIDEO_RATIO.height}`;
    }
  }
  return null;
};

export const validateAssetSizeAndUrl = async (asset) => {
  let error = null;
  if (asset.file || asset.url) {
    error = validateFileSize(asset.file, asset.type === ASSETS_TYPES.types.VIDEO ? 100 : 10);
    const result = await new Promise((resolve) => {
      const creativeUrl = asset.file ? URL.createObjectURL(asset.file) : asset.url;
      let mediaElement = null;
      const cleanUpCallback = () => {
        URL.revokeObjectURL(creativeUrl);
        mediaElement = null;
      };
      if (asset.type === ASSETS_TYPES.types.VIDEO) {
        mediaElement = document.createElement('video');
        mediaElement.addEventListener('loadedmetadata', function callback() {
          resolveVideoSizeCallback.call(this, resolve, cleanUpCallback);
        }, false);
        mediaElement.addEventListener('error', () => resolveUrlError(resolve, cleanUpCallback));
        // start download meta-datas
        mediaElement.src = creativeUrl;
      }
      mediaElement = new Image();
      mediaElement.addEventListener('load', function callback() {
        resolveImageSizeCallback.call(this, resolve, cleanUpCallback);
      }, false);
      mediaElement.addEventListener('error', () => resolveUrlError(resolve, cleanUpCallback));
      // start download meta-datas
      mediaElement.src = creativeUrl;
    });

    if (result.error) {
      return result.error;
    }
    if (asset.expectedSize) {
      if (asset.expectedSize.width !== result.width || asset.expectedSize.height !== result.height) {
        return `Please add asset with size ${asset.expectedSize.width}x${asset.expectedSize.height}`;
      }
    }
  }
  return error;
};

export const validateCustomNative = (assets) => {
  const assetsError = {};

  assets.forEach((item) => {
    let error = null;
    const template = item.template?.replace('_CANVA', '');
    switch (template) {
      case (CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.TITLE.TEMPLATE): {
        error = validateRequired(item.text, item.name) || validateLength(item.text, item.name, 1, 10);
        break;
      }
      case (CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.DESCRIPTION.TEMPLATE): {
        error = validateRequired(item.value, item.name) || validateLength(item.value, item.name, 1, 20);
        break;
      }
      case (CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.CTA.TEMPLATE):
      {
        error = validateRequired(item.value, item.name) || validateLength(item.value, item.name, 1, 15);
        break;
      }
      case (CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.ATTRIBUTION.TEMPLATE): {
        error = validateRequired(item.value, item.name) || validateLength(item.value, item.name, 1, 20);
        break;
      }
      default: break;
    }
    if (error) Object.assign(assetsError, { [item.template]: error });
  });

  return !isEmpty(assetsError) && assetsError;
};
