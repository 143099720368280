import React, { memo } from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

function CloseButton({ keyNotification }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={ () => closeSnackbar(keyNotification) }
      size="large"
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}

CloseButton.propTypes = { keyNotification: PropTypes.number.isRequired };

export default memo(CloseButton);
