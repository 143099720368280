export const dataKeys = {
  INT_IMPS: 'impressions',
  INT_GROSS_IMP_PRICE: 'spend',
  INT_VAST_COMPLETE_RATE: 'vastCompleteRate',
  INT_GROSS_CPM: 'ecpm',
};

export const mapTopResponse = (array, mapKeys) => {
  // eslint-disable-next-line no-prototype-builtins
  const result = array.filter((item) => mapKeys.hasOwnProperty(item.metric)).reduce((obj, item) => {
    const key = mapKeys[item.metric];
    obj[key] = item;
    return obj;
  }, {});
  return result;
};

export function processDataTop(arr, mapKeys) {
  const updatedDataTop = { ...arr };

  updatedDataTop.rows = updatedDataTop.rows.map((row) => ({
    ...row,
    metrics: mapTopResponse(row.metrics, mapKeys),
  }));

  return updatedDataTop.rows;
}
