import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import classes from './StepTitle.module.scss';

function StepTitle({ name }) {
  return (
    <Grid container spacing={ 3 } alignItems="center" className={ classes.wrapper }>
      <Grid item xs="auto" sm="auto" className={ classes.title }>
        {name}
      </Grid>
      <Grid item xs sm>
        <Divider />
      </Grid>
    </Grid>
  );
}

StepTitle.defaultProps = { name: '' };

StepTitle.propTypes = { name: PropTypes.string };

export default memo(StepTitle);
