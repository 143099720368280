import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import google from 'constants/google';
import { getTimeZone } from 'libs/getTimeZone';
import { useSignupWithGoogleMutation, useLoginWithGoogleMutation, useGetTimeZonesQuery } from 'api/apiSlice';
import loadingActions from 'actions/loadingActions';

const useGoogleAuth = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: { timeZones = [] } = {} } = useGetTimeZonesQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signupWithGoogle] = useSignupWithGoogleMutation();
  const [loginWithGoogle] = useLoginWithGoogleMutation();

  const signup = useCallback(async (code, type) => {
    try {
      const timeZone = await getTimeZone(timeZones);
      await signupWithGoogle({ code, type, callbackUrl: google.callbackUrl, timeZone }).unwrap();
      enqueueSnackbar('Signed up with Google', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.response.data.message || e.message, { variant: 'error' });
    } finally {
      window.sessionStorage.removeItem(google.sessionStorageKey);
      setSearchParams('');
    }
  }, [setSearchParams, signupWithGoogle, timeZones]);

  const login = useCallback(async (code) => {
    try {
      dispatch(loadingActions.increaseCounter());

      await loginWithGoogle({ code, callbackUrl: google.callbackUrl }).unwrap();
      navigate(0);
    } catch (e) {
      enqueueSnackbar(e.response?.data?.message || e.message || 'Something went wrong', { variant: 'error' });
    } finally {
      dispatch(loadingActions.decreaseCounter());
      setSearchParams('');
    }
  }, [dispatch, loginWithGoogle, navigate, setSearchParams]);

  useEffect(() => {
    const code = searchParams.get('code');
    const type = window.sessionStorage.getItem(google.sessionStorageKey);

    if (code && type) {
      signup(code, type);
      setSearchParams('');
    }
    if (code && !type) {
      login(code);
      setSearchParams('');
    }
  }, [login, searchParams, setSearchParams, signup]);
};

export default useGoogleAuth;
