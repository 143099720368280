import { Box, Stack } from '@mui/material';
import { backgroundColorDarkGray } from 'constants/colors';
import SearchFilterInput from 'modules/_Factories/Input/SearchFilterInput/SearchFilterInput';
import React from 'react';
import { useUser } from 'hooks/useUser';
import Profile from './Profile/Profile';

export default function ProfileHeader({ filters, onChangeFilters }) {
  const { user } = useUser({});

  return (
    <Box sx={ {
      width: '100%',
      height: '62px',
      backgroundColor: backgroundColorDarkGray,
      padding: '12px 24px',
      mb: '32px',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
    } }
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={ { width: '100%' } }
      >
        <Box sx={ { width: '100%' } }>
          {filters && (
          <SearchFilterInput
            value={ filters.search }
            setSearchFilter={ onChangeFilters('search') }
            placeholder="Search (Ctrl + /)"
          />
          )}
        </Box>
        <Profile user={ user } />
      </Stack>
    </Box>
  );
}
