import React, { useCallback, useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';

// Modules
import Modal from 'modules/Modal/Modal';
import ItemsPerPage from 'modules/ItemsPerPage/itemsPerPage';

// Libs
import { textFormat } from 'libs/textFormat';

// Components
import DownloadIcon from '@mui/icons-material/Download';
import ModalHeader from './ModalHeader';
import Filters from './Filters';
import TableBillingHistory from './TableBillingHistory';
import RefillBalanceModal from '../RefillBalanceModal';
import useBillingHistoryModal from './useBillingHistoryModal';

// Styles
import classes from './BillingHistoryModal.module.scss';

const possibleValuesPerPage = [5, 10, 20, 50];

function BillingHistoryModal({ handleClose, advertiserId, isOpen }) {
  const defaultFilters = useMemo(() => ({
    startDate: null,
    endDate: null,
    operation: null,
  }), []);

  const [perPage, setPerPage] = useState(possibleValuesPerPage[0]);
  const [filters, setFilters] = useState(defaultFilters);
  const [isOpenRefillBalanceModal, setIsOpenRefillBalanceModal] = useState(false);

  const {
    balanceHistory,
    advertiser,
    handleDownload,
    refillBalanceByAdvertiserId,
  } = useBillingHistoryModal({ advertiserId, filters });

  const toggleRefillBalanceModal = useCallback(() => {
    setIsOpenRefillBalanceModal((prevState) => !prevState);
  }, [setIsOpenRefillBalanceModal]);

  const resetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [setFilters, defaultFilters]);

  const refillBalance = useCallback((advId, values) => {
    refillBalanceByAdvertiserId(advId, values);
    toggleRefillBalanceModal();
  }, [toggleRefillBalanceModal, refillBalanceByAdvertiserId]);

  return (
    <Modal open={ isOpen } onClose={ handleClose }>
      <div className={ classes.wrapper }>
        <ModalHeader
          balance={ textFormat(advertiser.balance, 'currency') }
          advertiserId={ advertiserId }
        />

        <Grid container alignItems="center" justifyContent="space-between" className="mb-4" spacing={ 2 }>
          <Grid item lg={ 8 } md={ 12 }>
            <Filters filters={ filters } onChange={ setFilters } />
          </Grid>
          <Grid item container lg={ 4 } md={ 12 } spacing={ 2 }>
            <Grid item>
              <Button size="large" onClick={ resetFilters }>Reset</Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                color="primary"
                variant="contained"
                className={ classes.refillButton }
                onClick={ toggleRefillBalanceModal }
              >
                Refill Balance
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={ 3 } justifyContent="space-between" alignItems="center">
          <Grid item className="mb-3">
            <ItemsPerPage
              possibleValuesPerPage={ possibleValuesPerPage }
              activePerPage={ perPage }
              onClickPerPage={ setPerPage }
            />
          </Grid>
          <Grid item onClick={ handleDownload } className={ classes.download }>
            <DownloadIcon />
            <span>Download</span>
          </Grid>
        </Grid>

        <TableBillingHistory data={ balanceHistory } perPage={ perPage } />
      </div>

      <RefillBalanceModal
        isOpen={ isOpenRefillBalanceModal }
        handleClose={ toggleRefillBalanceModal }
        handleSubmit={ refillBalance }
        advertiserId={ advertiserId }
      />
    </Modal>
  );
}

BillingHistoryModal.defaultProps = {
  handleClose: Function.prototype,
  advertiserId: null,
  isOpen: false,
};

BillingHistoryModal.propTypes = {
  handleClose: PropTypes.func,
  advertiserId: PropTypes.number,
  isOpen: PropTypes.bool,
};

export default memo(BillingHistoryModal);
