export default class CreativeClass {
  id = null;
  advertiser = null;
  type = null;
  name = null;
  status = null;
  template = null;
  subType = null;

  constructor(item) {
    this.id = item.id || null;
    this.advertiser = item.advertiser || null;
    this.type = item.type || null;
    this.name = item.name || null;
    this.status = item.status || null;
    this.template = item.template || null;
    this.subType = item.subType || null;
  }
}
