import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid } from '@mui/material';

// Constants
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';

// Modules
import StepContainer from 'modules/Steps/StepContainer';
import PreviewCreative from 'modules/PreviewCreative';
import NativeOptions from './NativeOptions';
import UploadFile from '../../UploadFile';

import { getAcceptableFileExtensions } from '../../useFormCreative';

function Options({ formik, isEditForm }) {
  const { type, url, file } = formik.values;
  const { setFieldValue } = formik;
  const isVideo = type === CREATIVE_TYPES.types.VIDEO;
  const [creativeDataTypes, setCreativeDataTypes] = useState([]);

  useEffect(() => {
    const dataTypes = isVideo ?
      CREATIVE_DATA_TYPES.dictionary.filter((item) => item.id !== CREATIVE_DATA_TYPES.types.STOCK_LIB)
      : CREATIVE_DATA_TYPES.dictionary;
    setCreativeDataTypes(dataTypes);
  }, [isVideo, setCreativeDataTypes]);

  const getHelperText = (creativeType) => {
    const helperTextOptions = {
      [CREATIVE_TYPES.types.NATIVE]: 'Build your own Telly ads',
      [CREATIVE_TYPES.types.VIDEO]: 'Upload MP4, M4V or MOV video file up to 100MB',
    };
    return helperTextOptions[creativeType];
  };

  const handleChangeCreative = useCallback((value, dataType) => {
    formik.setFieldTouched('videoCreative');
    if (dataType) {
      setFieldValue('dataType', dataType);
    }
    if (typeof value === 'string') {
      setFieldValue('file', null);
      setFieldValue('url', value);
    } else if (typeof value === 'object') {
      setFieldValue('url', null);
      setFieldValue('file', value);
    }
  }, [formik, setFieldValue]);

  const helpText = getHelperText(formik.values.type);
  const fileExtensions = getAcceptableFileExtensions(type).map((item) => `.${item}`).join(',');
  const creativeUrl = file ? URL.createObjectURL(file) : url;

  // VIDEO TYPE
  const renderOptionsContent = () => (
    <Grid container alignItems="flex-start" spacing={ 4 }>
      <Grid container item xs={ 8 } spacing={ 4 }>
        <Grid item xs={ 12 }>
          <UploadFile
            title="Upload asset"
            dataTypeList={ creativeDataTypes }
            fileExtensions={ fileExtensions }
            value={ formik.values.file || formik.values.url }
            allowEditImage={ formik.values.type !== CREATIVE_TYPES.types.VIDEO }
            onChangeValue={ handleChangeCreative }
            error={ formik.touched.videoCreative && (formik.errors.file || formik.errors.url) }
            disabled={ isEditForm }
          />
        </Grid>
      </Grid>
      {creativeUrl && (
      <Grid item xs={ 4 }>
        <PreviewCreative
          url={ creativeUrl }
          type={ isVideo ? 'video' : 'image' }
        />
      </Grid>
      )}
    </Grid>
  );

  return (
    <StepContainer subtitle={ helpText }>
      {type === CREATIVE_TYPES.types.NATIVE ?
        <NativeOptions formik={ formik } isEditForm={ isEditForm } />
        : renderOptionsContent()}
    </StepContainer>
  );
}

Options.defaultProps = { isEditForm: false };

Options.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      type: PropTypes.string,
      dataType: PropTypes.string,
      file: PropTypes.shape({}),
    }),
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
  }).isRequired,
  isEditForm: PropTypes.bool,
};

export default memo(Options);
