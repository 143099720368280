import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import classes from './CreativeListTitle.module.scss';

function CreativeListTitle({ title, selectedLength }) {
  return (
    <Grid container spacing={ 4 } justifyContent="space-between" className={ classes.title }>
      <Grid item>
        <Typography variant="h6" className={ classes.titleText }>{ title }</Typography>
        <span className={ classes.subtitleText }>{ selectedLength ? 'Creative uploaded' : 'Сreative is not uploaded yet' }</span>
      </Grid>
    </Grid>
  );
}

CreativeListTitle.defaultProps = {
  title: null,
  selectedLength: 0,
};

CreativeListTitle.propTypes = {
  title: PropTypes.string,
  selectedLength: PropTypes.number,
};

export default memo(CreativeListTitle);
