import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid } from '@mui/material';
import MaterialTable from 'material-table';

// Constants
import dashboardTop from 'constants/dashboard-top';
import userPermissionsDictionary from 'constants/dictionary/userPermissionsDictionary';

// Libs
import { textFormat } from 'libs/textFormat';
import { hasPermission } from 'libs/storageLibs';

// Modules
import { MainTabs, MainTab } from 'modules/Tabs/MainTabs';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';

// Styles
import classNames from 'classnames';
import classes from './Top.module.scss';
import { dataKeys, processDataTop } from '../utils';

function Top({ topAdvertisers, topCampaigns, topCreatives }) {
  const topTabs = [
    {
      value: dashboardTop.TOP_ADVERTISERS,
      label: 'Top Advertisers',
      visible: hasPermission([userPermissionsDictionary.types.ADVERTISER_READ]),
    },
    {
      value: dashboardTop.TOP_CAMPAIGNS,
      label: 'Top Campaigns',
      visible: hasPermission([userPermissionsDictionary.types.CAMPAIGN_READ]),
    },
    {
      value: dashboardTop.TOP_CREATIVES,
      label: 'Top Creatives',
      visible: hasPermission([userPermissionsDictionary.types.CREATIVE_READ]),
    },
  ].filter((item) => item.visible);

  const [top, setTop] = useState(topTabs[0]?.value);

  const topData = {
    [dashboardTop.TOP_ADVERTISERS]: {
      data: topAdvertisers.length !== 0 && topAdvertisers?.rows?.length !== 0 ? processDataTop(topAdvertisers, dataKeys) : [],
      columnNameTitle: 'Advertiser',
    },
    [dashboardTop.TOP_CAMPAIGNS]: {
      data: topCampaigns.length !== 0 && topCampaigns?.rows?.length !== 0 ? processDataTop(topCampaigns, dataKeys) : [],
      columnNameTitle: 'Campaign',
    },
    [dashboardTop.TOP_CREATIVES]: {
      data: topCreatives.length !== 0 && topCreatives?.rows?.length !== 0 ? processDataTop(topCreatives, dataKeys) : [],
      columnNameTitle: 'Creative',
    },
  };

  if (!topData[top]?.data) return null;

  const columns = [
    {
      title: 'ID',
      field: 'dimension.id',
      render: (item) => <MaterialTableText variant="light" noWrap>{ item && item.dimension && item.dimension.id }</MaterialTableText>,
    },
    {
      title: topData[top].columnNameTitle,
      field: 'dimension.name',
      render: (item) => <MaterialTableText variant="regular" noWrap>{ item && item.dimension && item.dimension.name }</MaterialTableText>,
    },
    {
      title: 'Impressions',
      field: 'metrics.impressions.value',
      defaultSort: 'desc',
      render: (item) => (
        <MaterialTableText
          variant="light"
          noWrap
        >
          { textFormat(item && item.metrics.impressions.value, 'number')}
        </MaterialTableText>
      ),
    },
    {
      title: 'Spend',
      field: 'metrics.spend.value',
      render: (item) => (
        <MaterialTableText
          variant="regular"
          noWrap
        >
          {textFormat(item && item.metrics.spend.value, 'currency')}
        </MaterialTableText>
      )
      ,
    },
    {
      title: 'VCR',
      field: 'metrics.vastCompleteRate.value',
      render: (item) => (
        <MaterialTableText
          variant="light"
          noWrap
        >
          {textFormat(item && item.metrics && item.metrics.vastCompleteRate.value, 'percent')}
        </MaterialTableText>
      ),
    },
    {
      title: 'CPM',
      field: 'metrics.ecpm.value',
      render: (item) => (
        <MaterialTableText
          variant="regular"
          noWrap
        >
          {textFormat(item && item.metrics && item.metrics.ecpm.value, 'currency')}
        </MaterialTableText>
      ),
    },
  ];

  const { data } = topData[top];

  return (
    <>
      <h2 className="pageTitle">Daily Activity</h2>
      <Grid container className={ classNames(classes.wrapper, 'grayContainer') }>
        <Grid item md>
          <MainTabs value={ top } onChange={ (event, value) => setTop(value) } className={ classes.tabs }>
            {topTabs.map((tab) => (
              <MainTab key={ tab.value } disableRipple label={ tab.label } value={ tab.value } />
            ))}
          </MainTabs>

          <div className={ classes.materialTableWrapper }>
            <MaterialTable
              title="Toolbar Overriding Preview"
              columns={ columns }
              data={ data }
              options={ {
                toolbar: false,
                paging: false,
                draggable: false,
                thirdSortClick: false,
              } }
              icons={ { SortArrow: MaterialTablesSortIcon } }
              components={ {
                // eslint-disable-next-line react/no-unstable-nested-components
                Container: (props) => <MaterialTableContainer { ...props } />,
              } }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

Top.defaultProps = {
  topAdvertisers: [],
  topCampaigns: [],
  topCreatives: [],
};

Top.propTypes = {
  topAdvertisers: PropTypes.arrayOf(
    PropTypes.shape({
      dimension: PropTypes.shape({}).isRequired,
      metrics: PropTypes.shape([]).isRequired,
    }),
  ),
  topCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      dimension: PropTypes.shape({}).isRequired,
      metrics: PropTypes.shape([]).isRequired,
    }),
  ),
  topCreatives: PropTypes.arrayOf(
    PropTypes.shape({
      dimension: PropTypes.shape({}).isRequired,
      metrics: PropTypes.shape([]).isRequired,
    }),
  ),
};

export default Top;
