import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Router
import ProtectedRoute from 'router/ProtectedRoute';

// Modules
import Header from 'modules/Header/Sidebar';
import Footer from 'modules/Footer/Footer';

// Utils
import lazyLoad from 'utils/lazyLoad';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';

// Hooks
import { useStripePaymentResult } from 'hooks/useStripePaymentResult';
import { useMain } from './useMain';

// Api
import { useGetCountriesQuery, useGetDMAsQuery } from '../api/apiSlice';

// Components
import Dashboard from './Dashboard/Dashboard';
import Advertisers from './Advertisers/Advertisers';
import Campaigns from './Campaigns/Campaigns';

import Creatives from './Creatives';
// Styles
import classes from './Main.module.scss';

const ReportsPromise = import('./Reports/Reports');
// const InsightsPromise = import('./Insights/Insights');
const ProfilePromise = import('./Profile/Profile');
const NotFoundPromise = import('./NotFound/NotFound');

const LazyReports = lazyLoad(React.lazy(() => ReportsPromise));
// const LazyInsights = lazyLoad(React.lazy(() => InsightsPromise));
const LazyProfile = lazyLoad(React.lazy(() => ProfilePromise));
const LazyNotFound = lazyLoad(React.lazy(() => NotFoundPromise));

function Main() {
  useGetDMAsQuery();
  useGetCountriesQuery();
  useStripePaymentResult();

  const { user } = useMain();

  return (
    <div className={ classes.container }>
      <Header user={ user } />
      <main className={ classes.main }>
        <Routes>
          <Route
            path="dashboard"
            element={ (
              <ProtectedRoute requiredPermissions={ [UserPermissions.types.DASHBOARD_READ] }>
                <Dashboard />
              </ProtectedRoute>
              ) }
          />
          <Route
            path="advertisers/*"
            element={ (
              <ProtectedRoute requiredPermissions={ [UserPermissions.types.ADVERTISER_READ, UserPermissions.types.VIEW_ADVERTISERS_TAB] }>
                <Advertisers />
              </ProtectedRoute>
              ) }
          />
          <Route
            path="campaigns/*"
            element={ (
              <ProtectedRoute requiredPermissions={ [UserPermissions.types.CAMPAIGN_READ] }>
                <Campaigns />
              </ProtectedRoute>
              ) }
          />
          <Route
            path="creatives/*"
            element={ (
              <ProtectedRoute requiredPermissions={ [UserPermissions.types.CREATIVE_READ] }>
                <Creatives />
              </ProtectedRoute>
              ) }
          />
          <Route
            path="reports/*"
            element={ (
              <ProtectedRoute requiredPermissions={ [UserPermissions.types.REPORT_READ] }>
                <LazyReports />
              </ProtectedRoute>
              ) }
          />
          {/* <Route */}
          {/*   path="insights/*" */}
          {/*   element={ ( */}
          {/*     <ProtectedRoute requiredPermissions={ [UserPermissions.types.INSIGHTS] }> */}
          {/*       <LazyInsights /> */}
          {/*     </ProtectedRoute> */}
          {/*     ) } */}
          {/* /> */}
          <Route
            path="profile"
            element={ (
              <ProtectedRoute>
                <LazyProfile />
              </ProtectedRoute>
              ) }
          />
          <Route
            path="404-not-found"
            element={ (
              <ProtectedRoute>
                <LazyNotFound />
              </ProtectedRoute>
              ) }
          />
          <Route path="" element={ <Navigate replace to="dashboard" /> } />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default Main;
