import AssetBaseClass from './assetBaseClass';

export default class ImageAssetClass extends AssetBaseClass {
  type = 'IMAGE';
  imageType = null;
  url = null;
  size = null;
  mime = null;
  expectedSize = null;
  fileName = null;

  constructor(item = {}) {
    super(item);
    this.imageType = item.imageType || null;
    this.url = item.url || null;
    this.size = item.size || null;
    this.mime = item.mime || null;
    this.expectedSize = item.expectedSize || null;
    this.fileName = item.fileName || null;
  }
}
