import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

// Modules
import Datepicker from 'modules/_Factories/Datepicker/Datepicker';
import Select from 'modules/_Factories/Select/Select';

// Dictionaries
import balanceOperationDictionary from 'constants/dictionary/balanceOperationDictionary';
import { backgroundColorDarkGray } from 'constants/colors';

function Filters({ filters, onChange }) {
  const { startDate, endDate, operation } = filters;

  const handleChange = useCallback((name, value) => {
    onChange({ ...filters, [name]: value });
  }, [filters, onChange]);

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 4 }>
        <Datepicker
          name="startDate"
          label="Start date"
          fullWidth
          maxDate={ endDate || new Date() }
          selected={ startDate }
          labelBackgroundColor={ backgroundColorDarkGray }
          onChange={ (value) => handleChange('startDate', value) }
          onReset={ () => handleChange('startDate', null) }
        />
      </Grid>

      <Grid item xs={ 4 }>
        <Datepicker
          name="endDate"
          label="End date"
          fullWidth
          minDate={ startDate }
          maxDate={ new Date() }
          selected={ endDate }
          labelBackgroundColor={ backgroundColorDarkGray }
          onChange={ (value) => handleChange('endDate', value) }
          onReset={ () => handleChange('endDate', null) }
        />
      </Grid>

      <Grid item xs={ 4 }>
        <Select
          name="operation"
          label="Transaction type"
          value={ operation || '' }
          itemList={ balanceOperationDictionary.dictionary }
          fullWidth
          onChange={ (e) => handleChange('operation', e.target.value) }
        />
      </Grid>
    </Grid>
  );
}

Filters.defaultProps = {
  filters: {
    startDate: null,
    endDate: null,
    operation: null,
  },
  onChange: Function.prototype,
};

Filters.propTypes = {
  filters: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    operation: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default memo(Filters);
