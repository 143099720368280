import { useEffect, useCallback, useRef, useMemo } from 'react';
import { useFormik } from 'formik';
import { debounce, isEmpty } from 'lodash';
import {
  validateRequired,
  validateFileExtension,
  validateUrlContentType,
  validateFileSize,
  validateUrl,
  validateLength,
} from 'libs/validateErrors';
import { validateVideoSize, validateAssetSizeAndUrl, validateCustomNative } from 'libs/creative/creativesUtils';

import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';
import { MIME_TYPES_VIDEO } from 'constants/mimeTypes';

export const getAcceptableFileExtensions = (type) => {
  switch (type) {
    case CREATIVE_TYPES.types.NATIVE: return ['jpg', 'png'];
    case CREATIVE_TYPES.types.VIDEO:
    default: return ['mp4', 'm4v', 'mov'];
  }
};

const creativeAssetsFields = {
  customNative: {
    main: null,
    logo: null,
    title: '',
    description: '',
    cta: '',
    attribution: '',
  },
  videoCreative: null,
  collapsedCreative: null,
  expandableCreative: {
    collapsed: null,
    extended: null,
  },
};

const useFormCreative = ({ initialValues, handleSubmit }) => {
  // expand initial values to correctly track errors and touched fields
  const expandedInitialValues = useMemo(() => ({ ...creativeAssetsFields, ...initialValues }), [initialValues]);
  const validateUrlFunction = useRef(null);
  const formikUtils = useRef(null);
  const isValidating = useRef(false);

  const validateCustomFile = useCallback((file, acceptableExtensions) => {
    const errorRequired = validateRequired(file, 'File');
    const errorExtension = file ? validateFileExtension(file.name, acceptableExtensions) : null;
    return errorRequired || errorExtension;
  }, []);

  const debouncedValidateUrl = useMemo(() => debounce((url, mimeTypes) => validateUrlContentType(url, mimeTypes)
    .then((res) => {
      if (!res) return;
      if (res['Content-Type']) {
        validateUrlFunction.current.error = null;
        formikUtils.current.setFieldValue('mime', res['Content-Type']);
      } else {
        validateUrlFunction.current.error = res;
        formikUtils.current.setFieldError('url', res);
      }
    }), 500), [validateUrlFunction, formikUtils]);

  // TODO: logic connected with validation url should will be refactor
  const customValidateUrl = useCallback((url, mimeTypes) => {
    if (!url || !validateUrlFunction.current) return null;

    const isChangeValue = validateUrlFunction.current.lastValue !== url;
    if (isChangeValue) {
      validateUrlFunction.current.lastValue = url;
      validateUrlFunction.current.call(url, mimeTypes);
      return null;
    }

    return validateUrlFunction.current?.error;
  }, [validateUrlFunction]);

  const validateContentByDataType = useCallback((values, types) => {
    let file = null;
    let url = null;

    switch (values.dataType) {
      case CREATIVE_DATA_TYPES.types.UPLOAD_FROM_URL: {
        url = validateRequired(values.url, 'Creative URL') || customValidateUrl(values.url, types);
        break;
      }
      case CREATIVE_DATA_TYPES.types.UPLOAD_FILE:
      default: {
        file = validateCustomFile(values.file, getAcceptableFileExtensions(values.type)) || validateFileSize(values.file, 100);
        break;
      }
    }

    return {
      ...(file && { file }),
      ...(url && { url }),
    };
  }, [customValidateUrl, validateCustomFile]);

  const validateVideo = useCallback(async (values) => {
    const errors = validateContentByDataType(values, MIME_TYPES_VIDEO);

    const sizeError = await validateVideoSize(values);
    if (sizeError) Object.assign(errors, { file: sizeError });

    return errors;
  }, [validateContentByDataType]);

  const validateCreativesAssetsDebounced = useMemo(() => debounce(async (assets) => {
    const assetsError = {};

    const promises = assets.map(async (asset) => {
      let error = null;
      const template = asset.template?.replace('_CANVA', '');
      if (!asset.url && !asset.file && !asset.fileName && !asset.value && !asset.text) {
        error = formikUtils.current.errors[template] || `${CREATIVE_TEMPLATES.templateToTitleMap[template]} is required`;
      } else if (asset.url) error = validateUrl(asset.url) || error;
      if (!error) {
        error = await validateAssetSizeAndUrl(asset);
      }
      if (error) Object.assign(assetsError, { [template]: error });
    });

    await Promise.all(promises);

    if (!isEmpty(assetsError)) {
      formikUtils.current.setErrors({ ...formikUtils.current.errors, ...assetsError });
    }
    isValidating.current = false;
  }, 500), [formikUtils, isValidating]);

  const validate = useCallback(async (values) => {
    const name = validateRequired(values.name, 'Name') || validateLength(values.name, 'Name', 1, 30);
    const advertiser = validateRequired(values.advertiser, 'Advertiser');
    const status = validateRequired(values.status, 'Status');
    const type = validateRequired(values.type, 'Type');

    if (values.type === CREATIVE_TYPES.types.NATIVE && values.assets.length) {
      isValidating.current = true;
      validateCreativesAssetsDebounced.cancel();
      validateCreativesAssetsDebounced(values.assets);
    }
    return {
      ...(name && { name }),
      ...(advertiser && { advertiser }),
      ...(status && { status }),
      ...(type && { type }),
      ...(values.type === CREATIVE_TYPES.types.VIDEO && await validateVideo(values)),
      ...(values.template === CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.NAME && validateCustomNative(values.assets)),
    };
  }, [validateCreativesAssetsDebounced, validateVideo]);

  // TODO: form validation need refactoring
  const formik = useFormik({
    initialValues: expandedInitialValues,
    onSubmit: (values, { setSubmitting }) => {
      if (!isValidating.current) {
        handleSubmit(values, () => setSubmitting(false));
        setSubmitting(false);
      } else {
        setTimeout(() => {
          if (formikUtils.current.isValid) {
            handleSubmit(values, () => setSubmitting(false));
          }
          setSubmitting(false);
        }, 1000);
      }
    },
    validate,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const { setFieldValue, setFieldError, values, errors, setErrors, isValid } = formik;

  // This useEffect for debounced Url function
  useEffect(() => {
    validateUrlFunction.current = {
      lastValue: validateUrlFunction.current?.lastValue || null,
      error: validateUrlFunction.current?.error || null,
      call: debouncedValidateUrl,
    };
  }, [debouncedValidateUrl]);

  // This useEffect for using formik methods in validate functions
  useEffect(() => {
    formikUtils.current = {
      setFieldValue,
      setFieldError,
      errors,
      setErrors,
      isValid,
    };
  }, [setFieldValue, setFieldError, errors, setErrors, isValid]);

  // set correct values when changing creative's template
  useEffect(() => {
    setFieldValue(
      'subType',
      CREATIVE_TEMPLATES.types[values.template]?.CODE || '',
    );
  }, [values.template, setFieldValue]);

  // set correct values when changing creative's type
  useEffect(() => {
    setFieldValue('template', values.type === CREATIVE_TEMPLATES.types.VIDEO.NAME ?
      CREATIVE_TEMPLATES.types.VIDEO.NAME
      : CREATIVE_TEMPLATES.types.COLLAPSED.NAME);
  }, [values.type, setFieldValue]);

  // set correct values when changing creative's dataType
  useEffect(() => {
    setFieldValue('file', expandedInitialValues.file);
    setFieldValue('url', expandedInitialValues.url);
  }, [values.dataType, expandedInitialValues, setFieldValue]);

  return { formik };
};

export default useFormCreative;
