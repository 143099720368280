import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Material
import { Grid, Typography } from '@mui/material';

// Modules
import RouterBreadcrumbs from 'modules/_Router/RouterBreadcrumbs/RouterBreadcrumbs';

function PageTitle(props) {
  const { title, showBreadcrumbs, lastElementName, className } = props;
  const { pathname } = useLocation();

  return (
    <Grid container className={ className }>
      <Grid item md>
        <Typography variant="h1" className="mb-3">{ title }</Typography>
        {showBreadcrumbs && <RouterBreadcrumbs currentPath={ pathname } lastElementName={ lastElementName } />}
      </Grid>
    </Grid>
  );
}

PageTitle.defaultProps = {
  title: '',
  lastElementName: '',
  className: '',
  showBreadcrumbs: null,
};

PageTitle.propTypes = {
  title: PropTypes.string,
  lastElementName: PropTypes.string,
  className: PropTypes.string,
  showBreadcrumbs: PropTypes.bool,
};

export default PageTitle;
