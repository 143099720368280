// CRUD
import crud from 'actions';

// Types
import types from './actionTypes/campaignActionTypes';

/** *********************************************** POST **************************************************** */

/**
 * CREATE Creative
 */
const parseFile = (formData) => crud.post('parse/_lines', formData, true);

/** ************************************************ PUT ****************************************************** */
/**
 * Set filters
 */
const setFilters = (filters) => (dispatch) => dispatch({
  type: types.SET_FILTERS,
  payload: filters,
});

/** ************************************************ EXPORTS ****************************************************** */
export {
  parseFile,
  setFilters,
};
