import React from 'react';
import { Typography } from '@mui/material';
import classes from './Styles.module.scss';
import Logo from '../Auth/Logo/Logo';

function ACP() {
  return (
    <div className={ classes.mainContainer }>
      <div>
        <Logo className={ classes.logo } to="/login" />
      </div>
      <div>
        <Typography variant="h1">
          TELLY SELF-SERVE ADVERTISING
          STATE PRIVACY LAW DATA PROCESSING ADDENDUM
        </Typography>
        <div className={ classes.effectiveDate }>Effective Date: October 3, 2023</div>
        <ol>
          <li>
            <Typography><u> Introduction</u></Typography>
            <Typography>
              The following terms (“Self-Serve Advertising Platform Terms” or “Terms”) are entered into by Telly, Inc. (“Telly”) and the
              entity executing these Terms or that accepts these Terms electronically (“you”). The Terms apply to your use of Telly’s
              Self-Serve Ad Platform (such as the self-service advertising interfaces) for creation, submission and/or delivery of any
              advertising or other commercial or sponsored activity or content (collectively, “Self-Serve Ad Interfaces”) and any order
              you place through the Self-Serve Ad Interfaces (“Order”).
            </Typography>
            <Typography>
              This Addendum sets forth the terms under State Privacy Laws pursuant to which either Telly or a Telly advertising
              Customer (the “Disclosing Party”) may transmit, disclose, or otherwise make available Personal Data to the other Party
              (the “Receiving Party”) for Advertising Purposes (defined below). This Addendum will be effective as of the effective date
              of the Terms (“Effective Date”); provided, however, the relevant obligations apply only to the extent (i) Personal Data is
              subject to the State Privacy Laws; and (ii) a State Privacy Law has taken effect.
            </Typography>
          </li>
          <li>
            <u> Definitions.</u> For purposes of this Addendum, the following terms will have the meaning ascribed below:
            <ol className={ classes.listLetters }>
              <li>Advertising Purposes” means all Restricted Purposes in addition to (i) activities that constitute
                Targeted Advertising or Cross-Context Behavioral Advertising under State Privacy Laws, including any
                processing that involves displaying ads to a Consumer that are selected based on the Consumer’s
                cross-context behaviors, (ii) creating or supplementing user profiles for such purposes.
              </li>
              <li>“CCPA” means the California Consumer Privacy Act of 2018, as amended, including as amended by the
                California Privacy Rights Act of 2020, and any regulations promulgated thereunder.
              </li>
              <li>“Data Breach” means “breach of the security of the system,” “security breach,” “breach of security,”
                “breach of system security,” and other analogous terms referenced in State Privacy Laws.
              </li>
              <li>“Joint Processor” means a Processor engaged by one or more Controllers to Process Personal Data in
                a manner that requires combining Personal Data collected across such Businesses, such as for certain
                measurement activities or capping the frequency of ads shown to a Consumer across sites or services
                not owned or controlled by the same Business.
              </li>
              <li>“Restricted Processing” means Processing only for Restricted Purposes.</li>
              <li>“Restricted Processing Signal” means any flag or signal indicating that a Consumer has opted out of
                the Sale, Sharing, or Processing for purposes of Targeted Advertising of their Personal Data, including
                without limitation those flags or signals sent through the IAB CCPA Compliance Framework, Global
                Privacy Platform, or other signaling system agreed to by the Parties.
              </li>
              <li>“Restricted Purposes” means advertising-related Processing that qualifies as a Business Purpose,
                including Processing for purposes of auditing; security and integrity; debugging; short term, transient
                uses; analytics; providing advertising or marketing services that do not include Cross-Contextual
                Behavioral Advertising, Targeted Advertising, or profiling; internal research; and efforts to improve
                quality and safety. Restricted Purposes includes first-party advertising, contextual advertising,
                frequency capping, measurement, fraud detection and prevention, and ensuring and measuring
                viewability, each only to the extent such activity (i) is permissible for a Processor to perform under the
                applicable State Privacy Laws; and (ii) does not result in a Sale or Sharing of Personal Data or constitute
                Processing of Personal Data for Targeted Advertising purposes.
              </li>
              <li>
                “State Privacy Laws” means the CCPA, the Colorado Privacy Act, the Connecticut Act Concerning
                Personal Data Privacy and Online Monitoring of 2022, the Indiana Consumer Data Protection Act, the
                Iowa Act Relating to Consumer Data Protection of 2023, the Montana Consumer Data Privacy Act, the
                Tennessee Information Protection Act, the Utah Consumer Privacy Act of 2022, and the Virginia
                Consumer Data Protection Act, in each case as amended and including any regulations promulgated
                thereunder.
              </li>
              <li>“Business,” “Business Purpose,” “Commercial Purpose,” “Consumer,” “Controller,” “Cross-Context
                Behavioral Advertising,” “Deidentified,” “De-identified Data,” “Personal Data,” “Personal Information,”
                “Process(-ing)” “Processor,” “Sale,” “Sell,” “Service Provider,” “Share,” “Targeted Advertising” and
                “Third Party” shall have the meanings ascribed to them in State Privacy Laws.
              </li>
              <li>References in this Addendum to “Controller,” “Personal Data,” and “Processor” include “Business,”
                “Personal Information,” and “Service Provider” respectively.
              </li>
            </ol>
          </li>
          <li><u> Roles.</u> With respect to the Processing of Personal Data, each Party acts as a Controller, unless a Restricted
            Processing Signal is present, in which case Receiving Party acts as a Processor and Processes the Personal
            Data on behalf of Disclosing Party (which may operate as either the Controller or a Processor to another
            Controller). Where Disclosing Party, as a Processor on behalf of a Controller, provides Personal Data to Receiving
            Party, the Disclosing Party will ensure that the Controller on whose behalf it is providing Personal Data has
            agreed to the obligations set forth in Section 4 herein.
          </li>
          <li>
            <u>Mutual Processing Obligations.</u> Each Party will:
            <ol className={ classes.listLetters }>
              <li>Comply with its respective obligations under State Privacy Laws with respect to the Processing of
                Personal Data.
              </li>
              <li>Provide Consumers with a clear and conspicuous ability to opt out of the Sale, Sharing, or Processing
                of their Personal Data for purposes of Targeted Advertising, in compliance with State Privacy Laws. If a
                Consumer opts out, Disclosing Party will (i) not Process such Consumer’s Personal Data for Targeted
                Advertising purposes and (ii) will either (a) not disclose such Consumer’s Personal Data to any Third
                Party; or (b) transmit a Restricted Processing Signal in conjunction with any disclosures of such
                Consumer’s Personal Data to any Third Party.
              </li>
              <li>Not modify any Restricted Processing Signal received from a Disclosing Party.</li>
              <li>Transmit all Restricted Processing Signals received in conjunction with Personal Data to any recipients
                of such Personal Data.
              </li>
              <li>Comply with requirements set out in State Privacy Laws for processing Deidentified Data, including by:
                <ol className={ classes.listDisc }>
                  <li>Not attempting to re-identify any such data;
                  </li>
                  <li>Using reasonable administrative, technical, and organizational measures to prevent any
                    re-identification of any such data or any inadvertent release of any such data; and
                  </li>
                  <li>Publicly committing both to maintain and use the Deidentified Data in de-identified form and
                    not to attempt to re-identify any such data.
                  </li>
                </ol>
              </li>
              <li>To the extent acting as a Disclosing Party:
                <ol className={ classes.listDisc }>
                  <li>Provide all notices and obtain any consents required by State Privacy Laws necessary to
                    permit each Party to Process Personal Data in accordance with this Addendum; and
                  </li>
                  <li>To the extent providing Personal Data originally collected by another Controller, (i)
                    contractually obligate such Controller to provide all notices and obtain any consents required
                    by State Privacy Laws necessary to permit each Party to Process Personal Data in
                    accordance with this Addendum and (ii) take reasonable steps to ensure compliance with
                    such contractual obligations
                  </li>
                </ol>
              </li>
              <li>To the extent acting as a the Receiving Party, comply with:
                <ol className={ classes.listDisc }>
                  <li>Section 5 (CCPA Third Party Terms) when Processing Personal Data subject to the CCPA and
                    without a Restricted Processing Signal present.
                  </li>
                  <li>Section 6 (Processor Obligations), when Processing Personal Data received with a Restricted
                    Processing Signal present.
                  </li>
                </ol>
              </li>
            </ol>
            <li>
              <u>CCPA Third Party Terms</u>
              <ol className={ classes.listLetters }>
                <li>Applicability. This Section 5 (CCPA Third Party Terms) applies only when the Receiving Party
                  Processes Personal Data from the Disclosing Party (i) that is subject to the CCPA; and (ii) no Restricted
                  Processing Signal is present.
                </li>
                <li>Purpose Limitations. Disclosing Party makes Personal Data available to Receiving Party only for
                  Advertising Purposes. Receiving Party will Process Personal Data only for such Advertising Purposes,
                  and in accordance with its obligations and any restrictions in the Terms.
                </li>
                <li>CCPA Compliance; Notification of Determination of Noncompliance. Receiving Party will comply with
                  applicable obligations under the CCPA, including by providing an appropriate level of privacy protection
                  as required by the CCPA, and will notify Disclosing Party without undue delay if Receiving Party
                  determines it can no longer meet its obligations under the CCPA.
                </li>
                <li>Verification of CCPA Compliance. Upon Disclosing Party’s reasonable request, Receiving Party will
                  provide the following to Disclosing Party to demonstrate Receiving Party’s Processing of Personal Data
                  consistent with Disclosing Party’s obligations under the CCPA:
                  <ol className={ classes.listDisc }>
                    <li>sA copy of a certificate issued for security verification reflecting the outcome of an audit
                      conducted by an independent third-party auditor; or
                    </li>
                    <li>Any other information the Parties agree is reasonably necessary for Disclosing Party to verify
                      Receiving Party’s Processing is consistent with Disclosing Party’s obligations under the
                      CCPA, such as an attestation.
                    </li>
                  </ol>
                </li>
                <li>Unauthorized Use Remediation. If Disclosing Party reasonably believes that Receiving Party is
                  engaged in the unauthorized use of Personal Data provided by Disclosing Party, Disclosing Party may
                  notify Receiving Party of such belief using the contact information provided in the Terms, and the
                  Parties will work together in good faith to stop or remediate the allegedly unauthorized use of such
                  Personal Data, as necessary.
                </li>
                <li>Onward Disclosure Obligations. To the extent permitted by the Advertising Purposes and the Terms, if
                  Receiving Party makes an onward disclosure of Personal Data provided to it by Disclosing Party,
                  including through any Sale or Sharing of the Personal Data, Receiving Party will impose terms that are
                  substantially similar to the terms imposed on Receiving Party by Section 4 (Mutual Processing
                  Obligations) and this Section 5 (CCPA Third Party Terms).
                </li>
              </ol>
            </li>
            <li>
              <u>Processor Obligations</u>
              <ol className={ classes.listLetters }>
                <li>Applicability. This Section 6 (Processor Obligations) applies only to the extent Receiving Party
                  Processes Personal Data with a Restricted Processing Signal present
                </li>
                <li>Purpose Limitations. Receiving Party will Process Personal Data in accordance with its obligations in
                  the Terms and only for Restricted Purposes, as further described in Attachment 1. Receiving Party will
                  not:
                  <ol className={ classes.listDisc }>
                    <li>Process Personal Data for Targeted Advertising purposes; or</li>
                    <li>Sell or Share Personal Data.</li>
                  </ol>
                </li>
                <li>Assistance. Receiving Party will assist Disclosing Party, or the Controller on whose behalf Disclosing
                  Party is acting, with State Privacy Laws compliance by:
                  <ol className={ classes.listDisc }>
                    <li>Assisting the Disclosing Party in responding to Consumer requests made pursuant to State
                      Privacy Laws, provided that Disclosing Party must provide to Receiving Party all information
                      necessary for it to provide such assistance or respond to a Consumer request when required
                      by State Privacy Laws;
                    </li>
                    <li>Contributing to data protection impact assessments where required by State Privacy Laws;</li>
                    <li>Offering reasonable notice and assistance to Disclosing Party in the event Receiving Party
                      experiences a Data Breach, including to help Disclosing Party satisfy its Data Breach
                      notification obligations under State Privacy Laws; and
                    </li>
                    <li>Implementing reasonable security procedures and practices appropriate to the nature of the
                      Personal Data and designed to protect such Personal Data from unauthorized or illegal
                      access, destruction, use, modification, or disclosure in accordance with State Privacy Laws.
                    </li>
                  </ol>
                </li>
                <li>Confidentiality. Receiving Party will treat Personal Data from Disclosing Party as confidential and
                  subject each person that Processes such Personal Data to an appropriate obligation of confidentiality.
                </li>
                <li>Further Disclosures. If Receiving Party further discloses Personal Data provided by Disclosing Party,
                  Receiving Party will:
                  <ol className={ classes.listDisc }>
                    <li>Ensure it has in place a written Terms with any such recipient that obligates the recipient to
                      comply with terms at least as protective as the terms set out in this Section 6 (Processor
                      Obligations);
                    </li>
                    <li>Ensure any Restricted Processing Signal is transmitted with the Personal Data to the recipient;
                      and
                    </li>
                    <li>To the extent required by State Privacy Laws, provide Disclosing Party notice of the planned
                      transmission to any subcontractor and an opportunity to object.
                    </li>
                  </ol>
                </li>
                <li>Deletion and Return of Personal Data. Upon the earlier of any request by Disclosing Party or without
                  undue delay following termination of the Terms, Data Recipient will delete, return, or de-identify in
                  accordance with State Privacy Laws Personal Data provided to Receiving Party by Disclosing Party,
                  unless retention of the Personal Data is required by applicable law.
                </li>
                <li>Audits. Upon Disclosing Party’s reasonable request, Receiving Party will provide the following to
                  Disclosing Party to enable Disclosing Party to audit Receiving Party’s compliance with this Section 6
                  (Processor Obligations):
                  <ol className={ classes.listDisc }>
                    <li>A copy of a certificate issued within 12 months of the Disclosing Party’s Request reflecting the
                      outcome of an audit conducted by an independent and qualified third-party auditor using an
                      appropriate and accepted control standard or framework and audit procedure; or
                    </li>
                    <li>Any other information or attestation the Parties agree is reasonably necessary for Disclosing
                      Party to verify that Receiving Party’s Processing is consistent with Disclosing Party’s
                      obligations under the CCPA.
                    </li>
                  </ol>
                </li>
                <li>Additional CCPA Processing Obligations. If Personal Data provided to Receiving Party by Disclosing
                  Party is subject to the CCPA, in addition to the obligations set out in Sections 6.1 - 6.7 above, Receiving
                  Party will:
                  <ol className={ classes.listDisc }>
                    <li>Not retain, use, or disclose the Personal Data outside of the direct business relationship with
                      Disclosing Party or for any purpose, including Commercial Purposes, other than the
                      Restricted Purposes, unless otherwise permitted by the CCPA.
                    </li>
                    <li>Upon notice from Disclosing Party of its reasonable belief that Receiving Party is Processing
                      Personal Data in an unauthorized manner, cooperate with Disclosing Party in good faith to
                      stop or remediate the allegedly unauthorized use of such Personal Data, as necessary, such
                      as by providing documentation verifying certain practices.
                    </li>
                    <li>Notify the Disclosing Party without undue delay if Receiving Party determines it can no longer
                      meet its obligations under the CCPA.
                    </li>
                    <li>Except to Process for the Restricted Purposes or as otherwise permitted by the CCPA, not
                      combine the Personal Data provided to Receiving Party by Disclosing Party with Personal
                      Data received from or on behalf of another person or source or that Receiving Party collects
                      from its own interactions with Consumers. Notwithstanding the foregoing and to the extent
                      permitted by the CCPA, Receiving Party may combine Personal Data from Disclosing Party
                      with Personal Data provided to Receiving Party from an independent Business to the extent
                      (i) Receiving Party is a Joint Processor to both Disclosing Party and the independent
                      Business; and (ii) such independent Business has executed with Receiving Party terms
                      substantially similar to the terms imposed on Receiving Party under this Section 6 (Processor
                      Obligations).
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <u>Miscellaneous</u>
              <ol className={ classes.listLetters }>
                <li>Conflicts. Except as provided in Section 5.2, if there is any inconsistency or conflict between this
                  Addendum and the Terms, then this Addendum will govern, regardless of whether any language in the
                  Terms purports to state that the Terms is the controlling document. The provisions of this Addendum
                  may not be amended, except by an Terms to specifically amend this Addendum in writing signed by
                  the Parties.
                </li>
                <li>Counterparts. This Addendum may be executed in several counterparts (including delivery via
                  facsimile or electronic mail), each of which will be deemed to be an original but all of which together will
                  constitute one and the same instrument.
                </li>
                <li>Amendment. If the NAI issues updates its Template State Laws Processing Addendum (“Template”) to
                  account for changes in State Privacy Laws, new laws concerning privacy or data security, or changes
                  in the legal landscape based on enforcement or guidance related to State Privacy Laws, the Parties
                  agree such updates to the Template will apply to this Addendum automatically as of the date such
                  updates take effect to the Template.
                </li>
                <li>Survival. This Addendum will survive any expiration or termination of the Terms.</li>
              </ol>
            </li>
          </li>
        </ol>
        <Typography variant="h4">ATTACHMENT 1: DESCRIPTION OF PROCESSING</Typography>
        <ol>
          <li>
            <u>Description of Processing</u>
            <ol className={ classes.listLetters }>
              <li>Nature and Purpose of Processing. Data Recipient Processes the Personal Data provided to Data
                Recipient by Data Provider for the Restricted Purposes, as further described in this Addendum,
                including for frequency capping, measurement, fraud detection and prevention, and ensuring and
                measuring viewability.
              </li>
              <li>Types of Personal Data Processed. IP address, advertising identifiers.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default ACP;
