import React, { useEffect, memo } from 'react';
import LoaderImage from 'assets/images/loaders/loader-stratify.png';
import useLoading from 'hooks/useLoading';
import classes from './GlobalPreloader.module.scss';

function GlobalPreloader() {
  const { isLoading } = useLoading();

  useEffect(() => {
    const { body } = document;
    const loadingClassName = 'globalPreloaderLoading';

    if (isLoading) {
      body.classList.add(loadingClassName);
    } else {
      body.classList.remove(loadingClassName);
    }
  }, [isLoading]);

  return isLoading && (
  <div className={ classes.globalPreloader }>
    <img className={ classes.globalPreloaderImage } src={ LoaderImage } alt="loader" />
  </div>
  );
}

export default memo(GlobalPreloader);
