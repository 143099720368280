import { buildHashMap } from 'libs/buildHashMap';

const types = {
  UPLOAD_FROM_URL: 'UPLOAD_FROM_URL',
  UPLOAD_FILE: 'UPLOAD_FILE',
  STOCK_LIB: 'STOCK_LIB',
  CANVA: 'CANVA',
};

const analiticsDictionary = {
  UPLOAD_FROM_URL: 'link_to_file',
  UPLOAD_FILE: 'upload_file',
  STOCK_LIB: 'link_to_file',
  CANVA: 'canva_file',
};

const dictionary = [
  { id: types.UPLOAD_FROM_URL, name: 'Link to File' },
  { id: types.UPLOAD_FILE, name: 'Upload File' },
  { id: types.STOCK_LIB, name: 'Select File from Stock Library' },
  // { id: types.CANVA, name: 'Select Design from Canva' },
];

const hashMap = buildHashMap(dictionary);

const allowedImageTypes = '.jpeg,.jpg,.png';

export default {
  types,
  dictionary,
  hashMap,
  allowedImageTypes,
  analiticsDictionary,
};
