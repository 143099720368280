import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { validateLength, validatePasswordsMatch, validateRequired } from 'libs/validateErrors';
import { useSetPasswordMutation } from 'api/apiSlice';

const useResetPasswordForm = (token) => {
  const navigate = useNavigate();
  const [setPassword] = useSetPasswordMutation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = { password: '', confirmedPassword: '' };

  const validate = (values) => {
    const password = validateRequired(values.password, 'Password') ||
        validateLength(values.password, 'Password', 6, 20);
    const confirmedPassword = validateRequired(values.confirmedPassword, 'Confirm password') ||
        validateLength(values.confirmedPassword, 'Confirm password', 6, 20) ||
        validatePasswordsMatch(values.password, values.confirmedPassword);

    return {
      ...(password && { password }),
      ...(confirmedPassword && { confirmedPassword }),
    };
  };

  const submitHandler = async ({ password }, { setSubmitting }) => {
    try {
      const data = {
        password,
        token,
      };
      await setPassword(data).unwrap();
      enqueueSnackbar(
        'Password successfully saved',
        {
          variant: 'success',
          onEntered: () => navigate('/login'),
        },
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    setSubmitting(false);
  };

  const resetPasswordForm = useFormik({
    initialValues,
    onSubmit: submitHandler,
    validate,
  });

  return { resetPasswordForm, validate, submitHandler };
};

export { useResetPasswordForm };
