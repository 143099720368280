import { apiSlice } from '../../api/apiSlice';
import { CreativeListClass } from '../../classes/creative/creativeListClass';
import { getCreativeModel } from '../../libs/getCreativeModel';

export const creativeApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCreativeByIdFull: build.mutation({
      query: ({ id, withLoading = true }) => ({
        url: `creatives/${id}/full`,
        method: 'GET',
        withLoading,
      }),
      transformResponse: (response) => {
        if (!(response && response.length)) return {};
        const type = response && response[0]?.type;
        const Model = getCreativeModel(type);
        return new Model(response[0]);
      },
      providesTags: (result, error, arg) => [{ type: 'Creative', id: arg.id }],
    }),
    getCreativeList: build.mutation({
      query: ({ params, meta, withLoading = true }) => ({
        url: 'creatives',
        method: 'GET',
        withLoading,
        params,
        meta,
      }),
      transformResponse: (creativesData, meta) => {
        // for campaign
        if (meta.data.useFor === 'creativesForCampaign') {
          return creativesData?.payload?.map((creative) => {
            const Model = getCreativeModel(creative.type);
            return new Model(creative);
          });
        }

        // for creative
        return {
          ...creativesData,
          payload: creativesData?.payload?.map((item) => new CreativeListClass(item)),
        };
      },
      providesTags: (result = []) => [
        'Creative',
        ...result.map(({ id }) => ({ type: 'Creative', id })),
      ],
    }),
    createCreative: build.mutation({
      query: (data) => ({
        url: 'creatives',
        method: 'POST',
        withLoading: true,
        data,
      }),
      invalidatesTags: ['Creative', 'Creative-short'],
    }),
    updateCreative: build.mutation({
      query: ({ id, data }) => ({
        url: `creatives/${id}`,
        method: 'PUT',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Creative', id: arg.id }, 'Creative-short'],
    }),
    updateCreativeStatus: build.mutation({
      query: ({ id, data }) => ({
        url: `creatives/${id}/status`,
        method: 'PUT',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Creative', id: arg.id }, 'Creative-short'],
    }),
  }),
});

export const {
  useGetCreativeListMutation,
  useGetCreativeByIdFullMutation,
  useCreateCreativeMutation,
  useUpdateCreativeMutation,
  useUpdateCreativeStatusMutation,
} = creativeApiSlice;
