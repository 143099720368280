import daysDictionary from '../../constants/dictionary/dayPartTargetingDictionary';
import { CampaignClass } from '../../classes/campaign/campaignClass';

export const parseDaysPartValues = (periods) => {
  const array = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(periods)) {
    if (value.checked) {
      array.push({
        dayOfWeek: key,
        start: value.start,
        end: value.end,
      });
    }
  }

  return array;
};

export const transformTargetingProfileValues = (targeting) => {
  const transform = (arr) => arr.map((item) => item.id || item.code);

  const geoTargeting = ['countries', 'states', 'dmas', 'cities', 'districts', 'zips'];

  geoTargeting.forEach((name) => {
    if (!targeting[name]?.values?.length) return;

    targeting[name] = {
      ...targeting[name],
      values: transform(targeting[name].values),
    };
  });

  return targeting;
};

export const getPeriodsInitialData = (campaignPeriods) => {
  const periods = {};
  daysDictionary.dayDictionary.forEach((element) => {
    periods[element.id] = {
      checked: false,
      start: '00:00:00',
      end: '23:00:00',
    };
  });
  if (!campaignPeriods.length) return periods;

  campaignPeriods.forEach((item) => {
    periods[item.dayOfWeek] = {
      checked: true,
      start: item.start,
      end: item.end,
    };
  });

  return periods;
};

export const getCampaignModelAndFiles = (values) => {
  const {
    countries: targetingCountries,
    states,
    dmas,
    cities,
    districts,
    zips,
    periods,
    isAllTime,
    files,
    ...campaignValues
  } = values;

  const targetingProfileValues = { countries: targetingCountries, states, dmas, cities, districts, zips };

  const campaignModel = new CampaignClass({
    ...campaignValues,
    ...transformTargetingProfileValues(targetingProfileValues),
    periods: isAllTime ? [] : parseDaysPartValues(periods),
  });

  return { campaign: campaignModel, files };
};

export const checkTargeting = (values) => {
  const {
    states,
    dmas,
    cities,
    districts,
    zips,
  } = values;

  const isTargetingEnabled = [states, dmas, cities, districts, zips].some((item) => item?.values?.length);

  return isTargetingEnabled;
};
