import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import Input from '../Input';

function ClearableInput({
  label,
  name,
  type,
  onChange,
  onBlur,
  onClear,
  value,
  fullWidth,
  labelBackgroundColor,
  autoComplete,
  errors,
  touched,
  ...props
}) {
  return (
    <Input
      { ...props }
      label={ label }
      type={ type }
      value={ value }
      name={ name }
      onChange={ onChange }
      onBlur={ onBlur }
      fullWidth={ fullWidth }
      labelBackgroundColor={ labelBackgroundColor }
      autoComplete={ autoComplete }
      errors={ errors }
      touched={ touched }
      onClear={ onClear }
      endAdornment={ (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear"
            onClick={ onClear }
            edge="end"
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      ) }
    />
  );
}

ClearableInput.defaultProps = {
  fullWidth: false,
  autoComplete: 'true',
  labelBackgroundColor: 'none',
  label: '',
  type: 'text',
  errors: {},
  touched: {},
  onBlur: () => { },
};

ClearableInput.propTypes = {
  onClear: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  labelBackgroundColor: PropTypes.string,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
};

export default ClearableInput;
