import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

// constants
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

import { findAssetByTemplateStart, getAssetUrl } from 'libs/creative/creativesUtils';

// Components
import VideoCreative from 'modules/PreviewCreative/VideoCreative';
import ExpandableCreative from 'modules/PreviewCreative/ExpandableCreative';
import CollapsableCreative from 'modules/PreviewCreative/CollapsableCreative';
import CustomNativeCreative from 'modules/PreviewCreative/CustomNativeCreative';
import ImageCreative from 'modules/PreviewCreative/ImageCreative';

import classes from './CreativePreview.module.scss';
import { useGetCreativeByIdFullMutation } from '../../../../../Creatives/creativeApiSlice';

function CreativePreview({ creative: initialCreative, className }) {
  const [creative, setCreative] = useState(initialCreative);
  const [getCreative] = useGetCreativeByIdFullMutation();

  const { type, template, assets, id } = creative;

  const fetchCreativeData = useCallback(async (creativeId) => {
    const creativeData = await getCreative({ id: creativeId, withLoading: false }).unwrap();
    setCreative({ ...creativeData });
  }, [getCreative]);

  useEffect(() => {
    if (id && !assets?.length) {
      fetchCreativeData(id);
    }
  }, [assets?.length, fetchCreativeData, id]);

  const renderPreview = () => {
    if (type === CREATIVE_TYPES.types.VIDEO) {
      return (
        <VideoCreative url={ creative.url } className={ classes.videoCreative } />
      );
    }

    if (type === CREATIVE_TYPES.types.NATIVE) {
      if (template === CREATIVE_TEMPLATES.types.EXPANDABLE.NAME) {
        const expandedAsset = findAssetByTemplateStart(assets, CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.EXPANDED.TEMPLATE) || {};
        const collapsedAsset = findAssetByTemplateStart(assets, CREATIVE_TEMPLATES.types.EXPANDABLE.ASSETS.COLLAPSED.TEMPLATE) || {};

        const expandedUrl = getAssetUrl(expandedAsset);
        const collapsedUrl = getAssetUrl(collapsedAsset);

        return (
          <ExpandableCreative
            classNamePreviewWrapper={ classes.collapsablePreviewWrapper }
            classNamePreview={ classes.collapsablePreview }
            expandedUrl={ expandedUrl }
            collapsedUrl={ collapsedUrl }
          />
        );
      }

      if (template === CREATIVE_TEMPLATES.types.COLLAPSED.NAME) {
        const [collapsedAsset] = assets;
        const collapsedUrl = collapsedAsset?.value || getAssetUrl(collapsedAsset);

        return (
          <CollapsableCreative
            classNamePreviewWrapper={ classes.collapsablePreviewWrapper }
            classNamePreview={ classes.collapsablePreview }
            collapsedUrl={ collapsedUrl }
          />
        );
      }

      if (template === CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.NAME) {
        const mainAsset = findAssetByTemplateStart(assets, CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.MAIN.TEMPLATE) || {};
        const logoAsset = findAssetByTemplateStart(assets, CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.LOGO.TEMPLATE) || {};
        const titleAsset = findAssetByTemplateStart(assets, CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.TITLE.TEMPLATE) || {};
        const descriptionAsset = findAssetByTemplateStart(assets, CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.DESCRIPTION.TEMPLATE) || {};
        const ctaAsset = findAssetByTemplateStart(assets, CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.CTA.TEMPLATE) || {};
        const attributionAsset = findAssetByTemplateStart(assets, CREATIVE_TEMPLATES.types.CUSTOM_NATIVE.ASSETS.ATTRIBUTION.TEMPLATE) || {};

        return (
          <CustomNativeCreative
            className={ classes.customNativePreviewWrapper }
            mainUrl={ mainAsset.url }
            logoUrl={ logoAsset.url }
            title={ titleAsset.text || 'Title will be here' }
            description={ descriptionAsset.value || 'Description will be here' }
            buttonText={ ctaAsset.value || 'CTA will be here' }
            attributionText={ attributionAsset.value || 'Attribution will be here' }
          />
        );
      }

      const displayAsset = (assets && assets[0]) || {};
      const url = getAssetUrl(displayAsset);

      return (
        <ImageCreative url={ url } className={ classes.imageCreative } />
      );
    }

    return null;
  };

  return (
    <div className={ classNames(classes.preview, className, { [classes.placeholder]: !creative }) }>
      { creative && renderPreview() }
    </div>
  );
}

export default memo(CreativePreview);
