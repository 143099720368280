const EVENTS = {
  login: 'login',
  signUp: 'sign_up',
  addAdvertiser: 'add_new_advertiser',
  addCreative: 'add_new_creative',
  publishCampaign: 'complete_campaign_setup',
  addPaymentInfo: 'add_payment_info',
};

export default EVENTS;
