import React, { memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import CreativeList from './routes/CreativeList';
import AddCreative from './routes/AddCreative';
import EditCreative from './routes/EditCreative';

function Creatives() {
  return (
    <Routes>
      <Route path="" element={ <CreativeList /> } />
      <Route path="add" element={ <AddCreative /> } />
      <Route path=":id" element={ <EditCreative /> } />
    </Routes>
  );
}

export default memo(Creatives);
