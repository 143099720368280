// Libs
import { buildHashMap } from 'libs/buildHashMap';

const types = {
  ASSET_READ: 'ASSET_READ',
  ASSET_WRITE: 'ASSET_WRITE',
  ADVERTISER_BALANCE_MANUAL: 'ADVERTISER_BALANCE_MANUAL',
  ADVERTISER_READ: 'ADVERTISER_READ',
  ADVERTISER_WRITE: 'ADVERTISER_WRITE',
  ADUNIT_READ: 'ADUNIT_READ',
  ADUNIT_WRITE: 'ADUNIT_WRITE',
  CAMPAIGN_APPROVAL: 'CAMPAIGN_APPROVAL',
  CAMPAIGN_READ: 'CAMPAIGN_READ',
  CAMPAIGN_WRITE: 'CAMPAIGN_WRITE',
  CREATIVE_READ: 'CREATIVE_READ',
  CREATIVE_WRITE: 'CREATIVE_WRITE',
  DASHBOARD_READ: 'DASHBOARD_READ',
  DEAL_READ: 'DEAL_READ',
  DEAL_WRITE: 'DEAL_WRITE',
  DSP_READ: 'DSP_READ',
  DSP_WRITE: 'DSP_WRITE',
  EXCHANGE_READ: 'EXCHANGE_READ',
  EXCHANGE_WRITE: 'EXCHANGE_WRITE',
  INVENTORY_READ: 'INVENTORY_READ',
  INVENTORY_WRITE: 'INVENTORY_WRITE',
  PUBLISHER_READ: 'PUBLISHER_READ',
  PUBLISHER_WRITE: 'PUBLISHER_WRITE',
  REPORT_READ: 'REPORT_READ',
  REPORT_WRITE: 'REPORT_WRITE',
  ROLE_READ: 'ROLE_READ',
  ROLE_WRITE: 'ROLE_WRITE',
  SETTINGS_READ: 'SETTINGS_READ',
  SETTINGS_WRITE: 'SETTINGS_WRITE',
  SPECIFIC_METRICS: 'SPECIFIC_METRICS',
  USER_READ: 'USER_READ',
  USER_WRITE: 'USER_WRITE',
  VIEW_ALL_ENTITIES: 'VIEW_ALL_ENTITIES',
  REPORT_CD: 'REPORT_CD',
  REPORT_COUNTY: 'REPORT_COUNTY',
  REPORT_CITY: 'REPORT_CITY',
  TARGET_CD: 'TARGET_CD',
  TARGET_COUNTY: 'TARGET_COUNTY',
  INSIGHTS: 'INSIGHTS',
  VIEW_ADVERTISERS_TAB: 'VIEW_ADVERTISERS_TAB',
};

const dictionary = [
  { id: types.ASSET_READ, name: 'Assets Read' },
  { id: types.ASSET_WRITE, name: 'Assets Write' },
  { id: types.ADVERTISER_BALANCE_MANUAL, name: 'Advertisers Balance Manual' },
  { id: types.ADVERTISER_READ, name: 'Advertisers Read' },
  { id: types.ADVERTISER_WRITE, name: 'Advertisers Write' },
  { id: types.ADUNIT_READ, name: 'Ad Units Read' },
  { id: types.ADUNIT_WRITE, name: 'Ad Units Write' },
  { id: types.CAMPAIGN_APPROVAL, name: 'Campaign Approval' },
  { id: types.CAMPAIGN_READ, name: 'Campaigns Read' },
  { id: types.CAMPAIGN_WRITE, name: 'Campaigns Write' },
  { id: types.CREATIVE_READ, name: 'Creatives Read' },
  { id: types.CREATIVE_WRITE, name: 'Creatives Write' },
  { id: types.DASHBOARD_READ, name: 'Dashboard Read' },
  { id: types.DEAL_READ, name: 'Deals Read' },
  { id: types.DEAL_WRITE, name: 'Deals Write' },
  { id: types.DSP_READ, name: 'Demand Partners Read' },
  { id: types.DSP_WRITE, name: 'Demand Partners Write' },
  { id: types.EXCHANGE_READ, name: 'Supply Partners Read' },
  { id: types.EXCHANGE_WRITE, name: 'Supply Partners Write' },
  { id: types.INVENTORY_READ, name: 'Sites/Apps Read' },
  { id: types.INVENTORY_WRITE, name: 'Sites/Apps Write' },
  { id: types.PUBLISHER_READ, name: 'Publishers Read' },
  { id: types.PUBLISHER_WRITE, name: 'Publishers Write' },
  { id: types.REPORT_READ, name: 'Reports Read' },
  { id: types.REPORT_WRITE, name: 'Reports Write' },
  { id: types.ROLE_READ, name: 'User Role Read' },
  { id: types.ROLE_WRITE, name: 'User Role Write' },
  { id: types.SETTINGS_READ, name: 'Settings Read' },
  { id: types.SETTINGS_WRITE, name: 'Settings Write' },
  { id: types.SPECIFIC_METRICS, name: 'Specific Metrics' },
  { id: types.USER_READ, name: 'Users Read' },
  { id: types.USER_WRITE, name: 'Users Write' },
  { id: types.REPORT_CD, name: 'Report Congressional District' },
  { id: types.REPORT_COUNTY, name: 'Report County' },
  { id: types.REPORT_CITY, name: 'Report City' },
  { id: types.TARGET_CD, name: 'Target Congressional District' },
  { id: types.TARGET_COUNTY, name: 'Target County' },
  { id: types.INSIGHTS, name: 'Insights' },
  { id: types.VIEW_ALL_ENTITIES, name: 'View All Entities' },
  { id: types.VIEW_ADVERTISERS_TAB, name: 'View Advertisers tab' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
