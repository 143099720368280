import React from 'react';

// Material
import { Typography } from '@mui/material';

// Modules
import Link from 'modules/_Router/RouterLink/RouterLink';
import Modal from 'modules/Modal/Modal';

// Hooks
import useCanvaRedirect from 'hooks/useCanvaRedirect';

// Components
import TenantList from './TenantList';
import LoginForm from './LoginForm/LoginForm';

// Hooks local
import useLogin from './useLogin';
import useGoogleAuth from '../Google/useGoogleAuth';

// Styles
import classesGeneral from '../Auth.module.scss';

function Login() {
  useGoogleAuth();
  useCanvaRedirect();

  const {
    loginWrapper,
    selectAccount,
    tenantData,
    handleCloseTenantModal,
  } = useLogin();

  return (
    <>
      <Typography className={ classesGeneral.title }>
        Showcase your brand in a whole new way!
      </Typography>

      <LoginForm login={ loginWrapper } className={ classesGeneral.marginBottom } />

      <div className={ classesGeneral.marginBottom }>
        <Typography>Don&apos;t have an account? <Link to="/signup">Sign up instead</Link>
        </Typography>
      </div>

      {/* <Divider */}
      {/*   sx={ { '&::before, &::after': { borderColor: colorGray } } } */}
      {/*   className={ classesGeneral.marginBottom } */}
      {/* > */}
      {/*   OR */}
      {/* </Divider> */}

      {/* <GoogleLoginButton /> */}

      <Modal open={ tenantData.openModal } onClose={ handleCloseTenantModal }>
        <Typography variant="h4">Please select account you want to login</Typography>
        <TenantList list={ tenantData.accounts } selectAccount={ selectAccount } />
      </Modal>
    </>
  );
}

export default Login;
