import React from 'react';
import { Typography } from '@mui/material';
import Link from 'modules/_Router/RouterLink/RouterLink';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import classesGeneral from '../Auth.module.scss';
import classes from './ForgotPassword.module.scss';

function ForgotPassword() {
  return (
    <>
      <Typography className={ classesGeneral.title }>
        Forgot Password
      </Typography>
      <Typography className={ classesGeneral.subtitle }>
        Enter your account email, we will send you a link to reset your password.
      </Typography>

      <ForgotPasswordForm className={ classesGeneral.form } />

      <div className={ classes.link }>
        <Link to="/login">Back to login</Link>
      </div>
    </>
  );
}

export default ForgotPassword;
