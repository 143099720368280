import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { format, endOfDay, isAfter, isBefore } from 'date-fns';

// Actions
import advertiserActions from 'actions/advertiserActions';

// Dictionaries
import balanceOperationDictionary from 'constants/dictionary/balanceOperationDictionary';

import useAdvertiserBalance from 'modules/AdvertiserBalance/useAdvertiserBalance';
import { advertiserApiSlice, useGetAdvertiserByIdMutation, useGetBalanceHistoryMutation } from '../../../advertiserApiSlice';
import { AdvertiserClass } from '../../../../../classes/advertiser/advertiserClass';

const useBillingHistoryModal = ({ advertiserId, filters }) => {
  const dispatch = useDispatch();
  const [getAdvertiserById, { data: advertiser = new AdvertiserClass() }] = useGetAdvertiserByIdMutation();
  const [getBalanceHistory, { data: balanceHistory = [] }] = useGetBalanceHistoryMutation();
  const { fillAdvertiserBalance } = useAdvertiserBalance();

  const clearBalanceHistory = useCallback(() => dispatch(
    advertiserApiSlice.util.invalidateTags(['AdvertiserBalance']),
  ), [dispatch]);
  const clearAdvertiserById = useCallback((id) => dispatch(
    advertiserApiSlice.util.invalidateTags([{ type: 'Advertiser', id }]),
  ), [dispatch]);
  const downloadBillingHistory = useCallback(
    (advId, start, end) => dispatch(advertiserActions.downloadBillingHistory(advId, start, end)),
    [dispatch],
  );

  const refillBalanceByAdvertiserId = useCallback(async (advId, values) => {
    if (!advId) return;

    await fillAdvertiserBalance(advId, values);
    await Promise.all([
      getBalanceHistory(advId),
      getAdvertiserById(advId),
    ]);
  }, [
    fillAdvertiserBalance,
    getBalanceHistory,
    getAdvertiserById,
  ]);

  const handleDownload = useCallback(() => {
    const start = filters.startDate ? format(filters.startDate, 'Y-MM-dd') : null;
    const end = filters.endDate ? format(filters.endDate, 'Y-MM-dd') : null;
    downloadBillingHistory(advertiserId, start, end);
  }, [downloadBillingHistory, advertiserId, filters.startDate, filters.endDate]);

  const filterByDateRange = useCallback((data, startDate, endDate) => {
    let filteredData = data;
    if (startDate) {
      filteredData = filteredData.filter((item) => isAfter(endOfDay(new Date(item.time)), startDate));
    }
    if (endDate) {
      filteredData = filteredData.filter((item) => isBefore(new Date(item.time), endOfDay(endDate)));
    }

    return filteredData;
  }, []);

  const filterByOperation = useCallback((data, operationType) => {
    switch (operationType) {
      case balanceOperationDictionary.types.ADD:
        return data.filter((item) => item.amount >= 0);
      case balanceOperationDictionary.types.WRITE_OFF:
        return data.filter((item) => item.amount < 0);
      default:
        return data;
    }
  }, []);

  useEffect(() => {
    if (advertiserId) {
      getBalanceHistory(advertiserId);
      getAdvertiserById(advertiserId);
    }

    return () => {
      clearBalanceHistory();
      clearAdvertiserById(advertiserId);
    };
  }, [
    advertiserId,
    clearAdvertiserById,
    clearBalanceHistory,
    getAdvertiserById,
    getBalanceHistory,
  ]);

  const filteredBalanceHistory = useMemo(() => {
    const { startDate, endDate, operation } = filters;
    const filteredData = filterByOperation(balanceHistory, operation);
    return filterByDateRange(filteredData, startDate, endDate);
  }, [balanceHistory, filterByDateRange, filterByOperation, filters]);

  return {
    advertiser,
    balanceHistory: filteredBalanceHistory,
    handleDownload,
    refillBalanceByAdvertiserId,
  };
};

export default useBillingHistoryModal;
