// React
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { Autocomplete as MUIAutocomplete } from '@mui/material';

// Modules
import SearchInput from 'modules/_Factories/Input/SearchInput/SearchInput';
import FilledCheckbox from 'modules/_Factories/Checkbox/FilledCheckbox/FilledCheckbox';
import Chip from 'modules/_Factories/Chip/Chip';
import AllowOptions from 'modules/AllowOptions';

// Hooks
import useAutocomplete from './useAutocomplete';

// Styles
import classes from './Autocomplete.module.scss';

function Autocomplete(props) {
  const { value, allowValue, options, optionValueKey, optionLabelKey } = props;
  // Text
  const { name, noOptionsText, placeholder, label, helperText, helperTextUnderInput } = props;
  // Options
  const { disabled, allowOptions } = props;
  // Events
  const { onChange, onChangeAllow, onChangeSearchValue } = props;
  // Extra
  const { loading } = props;

  const classList = {
    listbox: classes.listbox,
    paper: classes.paper,
    option: classes.option,
  };

  const {
    searchValue,
    removeItem,
    handlerOnChangeSearchValue,
    handlerOnClearSearchValue,
    isTouchedOptionsText,
  } = useAutocomplete({ onChangeSearchValue });

  return (
    <>
      <MUIAutocomplete
        value={ value }
        inputValue={ searchValue }
        noOptionsText={ isTouchedOptionsText ? noOptionsText : helperText }
        getOptionLabel={ (option) => `${option.id} (${option.name})` }
        renderInput={ ({
          InputProps: { InputProps, ref },
          InputLabelProps,
          inputProps,
          ...additionalProps
        }) => {
          const renderProps = {
            ...InputProps, // MUI Input props
            ...InputLabelProps, // MUI Input label props (optional)
            ...additionalProps, // props line disabled, size, id
            inputProps, // native input props
            innerRef: ref, // ref to MUI Input
          };

          return (
            <SearchInput
              { ...renderProps }
              name={ name }
              helperText={ value && value.length ? '' : helperTextUnderInput }
              placeholder={ placeholder }
              label={ label || '' }
              // Options
              fullWidth
              // Events
              onChange={ handlerOnChangeSearchValue }
              onClear={ handlerOnClearSearchValue }
            />
          );
        } }
        // Options
        classes={ classList }
        options={ options }
        loading={ loading }
        renderOption={ (renderOptionProps, option, { selected }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li { ...renderOptionProps }>
            <FilledCheckbox
              checked={ selected }
              label={ option[optionLabelKey] }
            />
          </li>
        ) }
        isOptionEqualToValue={ (option, state) => option[optionValueKey] === state[optionValueKey] }
        multiple
        disableClearable
        disableCloseOnSelect
        disabled={ disabled }
        // Events
        onChange={ (event, newValue) => onChange(event, newValue, name) }
      />

      {allowOptions && (
        <div className={ classNames({ 'mt-4': helperTextUnderInput }) }>
          <AllowOptions
            allow={ allowValue }
            onChangeAllow={ onChangeAllow }
            onClear={ () => onChange(null, [], name) }
          />
        </div>
      )}

      <div className={ classNames(classes.chipList, { 'mb-3': !helperTextUnderInput && !value.length }) }>
        { value.map((item) => (
          <Chip
            key={ item[optionValueKey] }
            labelKey={ optionLabelKey }
            valueKey={ optionValueKey }
            values={ value }
            // Options
            option={ item }
            disabled={ disabled }
            // Events
            onClick={ (key) => !disabled && onChange(null, removeItem(value, optionValueKey, key), name) }
          />
        )) }
      </div>
    </>
  );
}

Autocomplete.defaultProps = {
  optionValueKey: 'id',
  optionLabelKey: 'name',
  noOptionsText: 'No matches found',
  placeholder: 'Search',
  label: null,
  helperText: 'Start typing to see results',
  helperTextUnderInput: 'Start typing to see results',
  loading: false,
  allowValue: true,
  // Options
  disabled: false,
  allowOptions: false,
  // Events
  onChange: () => {},
  onChangeAllow: () => {},
  onChangeSearchValue: () => {},
};

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allowValue: PropTypes.bool,
  name: PropTypes.string.isRequired,
  optionValueKey: PropTypes.string,
  optionLabelKey: PropTypes.string,
  noOptionsText: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  helperTextUnderInput: PropTypes.string,
  loading: PropTypes.bool,
  // Options
  disabled: PropTypes.bool,
  allowOptions: PropTypes.bool,
  // Events
  onChange: PropTypes.func,
  onChangeAllow: PropTypes.func,
  onChangeSearchValue: PropTypes.func,
};

export default Autocomplete;
