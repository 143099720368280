import { apiSlice } from '../../api/apiSlice';
import { AdvertiserClass } from '../../classes/advertiser/advertiserClass';
import { AdvertiserListClass } from '../../classes/advertiser/advertiserListClass';

export const advertiserApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAdvertiserById: build.mutation({
      query: (id) => ({
        url: `advertisers/${id}`,
        method: 'GET',
        withLoading: true,
      }),
      transformResponse: (response) => ({ ...new AdvertiserClass(response) }),
      providesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg }],
    }),
    getAdvertiserList: build.mutation({
      query: (params) => ({
        url: 'advertisers',
        method: 'GET',
        withLoading: true,
        params,
      }),
      transformResponse: (advertisersData) => ({
        ...advertisersData,
        payload: advertisersData?.payload?.map((item) => new AdvertiserListClass(item)),
      }),
      providesTags: (result = []) => [
        'Advertiser',
        ...result.map(({ id }) => ({ type: 'Advertiser', id })),
      ],
    }),
    createAdvertiser: build.mutation({
      query: (data) => ({
        url: 'advertisers',
        method: 'POST',
        withLoading: true,
        data,
      }),
      invalidatesTags: ['Advertiser', 'Advertiser-short'],
    }),
    updateAdvertiser: build.mutation({
      query: (data) => ({
        url: `advertisers/${data.id}`,
        method: 'PUT',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }, 'Advertiser-short'],
    }),
    updateAdvertiserStatus: build.mutation({
      query: ({ id, data }) => ({
        url: `advertisers/${id}/status`,
        method: 'PUT',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }, 'Advertiser-short'],
    }),
    updateAdvertiserBalance: build.mutation({
      query: ({ id, data }) => ({
        url: `advertisers/${id}/balance`,
        method: 'PUT',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }],
    }),
    requestAdvertiserBalance: build.mutation({
      query: ({ id, data }) => ({
        url: `advertisers/${id}/request-balance`,
        method: 'PUT',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }],
    }),
    fillAdvertiserBalanceByCard: build.mutation({
      query: ({ id, data }) => ({
        url: `advertisers/${id}/balance/stripe`,
        method: 'POST',
        withLoading: true,
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Advertiser', id: arg.id }],
    }),
    getBalanceHistory: build.mutation({
      query: (id) => ({
        url: `advertisers/${id}/balancehistories`,
        method: 'GET',
        withLoading: true,
      }),
      providesTags: ['AdvertiserBalance'],
    }),
  }),
});

export const {
  useGetAdvertiserListMutation,
  useGetAdvertiserByIdMutation,
  useCreateAdvertiserMutation,
  useUpdateAdvertiserMutation,
  useUpdateAdvertiserStatusMutation,
  useUpdateAdvertiserBalanceMutation,
  useRequestAdvertiserBalanceMutation,
  useFillAdvertiserBalanceByCardMutation,
  useGetBalanceHistoryMutation,
} = advertiserApiSlice;
