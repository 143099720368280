import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

// Hooks
import useAuth from 'hooks/useAuth';

function PublicRoute({ children, redirectTo, shouldRedirect }) {
  const { isAuthenticated } = useAuth({});

  return (isAuthenticated && shouldRedirect) ? <Navigate to={ redirectTo } replace /> : children;
}

PublicRoute.defaultProps = { redirectTo: '/dashboard', shouldRedirect: true };

PublicRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.func]).isRequired,
  redirectTo: PropTypes.string,
  shouldRedirect: PropTypes.bool,
};

export default PublicRoute;
