import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import InfoValue from 'modules/InfoValue';
import classes from './ModalHeader.module.scss';

function ModalHeader({ balance, advertiserId }) {
  return (
    <div className={ classes.header }>
      <Typography variant="h3">Billing History</Typography>
      <div className={ classes.values }>
        <InfoValue label="Balance:" value={ balance } />
        <InfoValue label="Advertiser ID:" value={ advertiserId } />
      </div>
    </div>
  );
}

ModalHeader.defaultProps = {
  balance: null,
  advertiserId: null,
};

ModalHeader.propTypes = {
  balance: PropTypes.string,
  advertiserId: PropTypes.number,
};

export default memo(ModalHeader);
