import { useDispatch } from 'react-redux';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Actions
import AccountClass from 'classes/accountClass';
import config from 'config/config';
import loadingActions from 'actions/loadingActions';
import { useGetCurrentUserMutation, useLoginMutation } from 'api/apiSlice';
import { emitGoogleEvent, setUserIdForAnalitics } from 'libs/gtag';
import EVENTS from '../../../constants/analitics';

const getHashValue = (hash, key) => {
  const value = hash.split('#')
    .reduce((accum, cur) => [...accum, cur.split('=')], [])
    .find((item) => item[0] === key)?.[1];

  return value;
};

const useLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [login] = useLoginMutation();
  const [getCurrentUser] = useGetCurrentUserMutation();

  const [tenantData, setTenantData] = useState({
    accounts: [],
    openModal: false,
  });

  const lastEnteredCredentials = useRef(null);
  const accountId = useRef(null);

  useEffect(() => {
    const user = getHashValue(location.hash, 'user');
    accountId.current = user;
  }, [location.hash]);

  const handleCloseTenantModal = useCallback(() => {
    setTenantData((prevData) => ({ ...prevData, openModal: false }));
  }, [setTenantData]);

  const loginWrapper = useCallback(async (credentials) => {
    try {
      lastEnteredCredentials.current = credentials;

      dispatch(loadingActions.increaseCounter());
      const tokenInstance = await login({ ...credentials, account: accountId.current }).unwrap();

      const { data: user = {} } = await getCurrentUser({ fixedCacheKey: 'current-user' });
      setUserIdForAnalitics(user.id);
      emitGoogleEvent(EVENTS.login, { user_id: user.id });

      return tokenInstance;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      dispatch(loadingActions.decreaseCounter());
    }
  }, [dispatch, getCurrentUser, login]);

  const selectAccount = useCallback((account) => {
    const domain = AccountClass.getDomain({ ...account, domain: config.appDomain });
    if (domain.includes(window.location.host)) {
      accountId.current = account.id;
      loginWrapper(lastEnteredCredentials.current);
    } else {
      window.location.href = `${domain}/login#user=${account.id}`;
    }
  }, [loginWrapper]);

  return {
    loginWrapper,
    selectAccount,
    tenantData,
    handleCloseTenantModal,
  };
};

export default useLogin;
