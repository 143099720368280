import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Constants
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import ASSETS_TYPES from 'constants/dictionary/creativeAssetsTypeDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';

// Hooks
import classNames from 'classnames';
import ProfileHeader from 'modules/ProfileHeader/ProfileHeader';
import useFormCreative from './useFormCreative';

// Components
import Steps from './Steps';

// Styles
import classes from './FormCreative.module.scss';

function FormCreative({
  handleSubmit,
  initialValues,
  permissionEdit,
  isEditForm,
}) {
  const { formik } = useFormCreative({ initialValues, handleSubmit });

  const pageTitle = formik.values?.id ? 'Edit Creative' : 'Add Creative';
  return (
    <div>
      <ProfileHeader />
      <div className={ classNames(classes.header, 'mb-5') }>
        <PageTitle title={ pageTitle } showBreadcrumbs lastElementName={ formik.values?.id && formik.values.name } />
      </div>

      <Steps formik={ formik } isEditForm={ isEditForm } permissionEdit={ permissionEdit } />
    </div>
  );
}

FormCreative.defaultProps = {
  initialValues: {
    name: '',
    advertiser: null,
    status: 'ACTIVE',
    type: CREATIVE_TYPES.types.NATIVE,
    dataType: CREATIVE_DATA_TYPES.types.UPLOAD_FILE,
    url: '',
    file: null,
    template: CREATIVE_TEMPLATES.types.COLLAPSED.NAME,
    subType: CREATIVE_TEMPLATES.types.COLLAPSED.CODE,
    assets: [],
  },
  handleSubmit: Function.prototype,
  permissionEdit: true,
  isEditForm: false,
};

FormCreative.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    advertiser: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string,
    subType: PropTypes.string,
    dataType: PropTypes.string,
    url: PropTypes.string,
    file: PropTypes.instanceOf(File),
    assets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.oneOf(Object.values(ASSETS_TYPES.types)),
      name: PropTypes.string,
      template: PropTypes.string,
      dataType: PropTypes.number,
      imageType: PropTypes.number,
      expectedSize: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
      url: PropTypes.string,
    })),
  }),
  handleSubmit: PropTypes.func,
  permissionEdit: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

export default memo(FormCreative);
