import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { FormControl, Select as MUISelect, MenuItem, Checkbox, ListItemText, FormHelperText } from '@mui/material';

// Modules
import SearchFilterInput from 'modules/_Factories/Input/SearchFilterInput/SearchFilterInput';

// Icons
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Libs
import { debounce } from 'lodash';
import { handleStateChange } from 'libs/handleStateChange';
import { buildHashMap } from 'libs/buildHashMap';

// Styles
import classes from './Select.module.scss';

const ITEM_HEIGHT = 96;
const ITEM_PADDING_TOP = 8;
const MenuProps = {

  getcontentanchorel: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: '16rem',
      borderRadius: '6px',
      marginTop: '16px',
    },
  },
};

function Select(props) {
  // Standard props
  const { name, label, itemList, value } = props;
  // Options (isFilter option shows select control in "table filter" mode)
  const {
    isFilter,
    isFilterText,
    multiple,
    required,
    disabled,
    fullWidth,
    className,
    showNoneItem,
    isSearch,
    isLoading,
    itemsLimit,
    onChangeSearch,
    isLightTheme,
  } = props;
  // Events
  const { onChange, setFieldTouched, setValue } = props;
  // Extra
  const { errors, touched, err } = props;

  const [search, setSearch] = useState('');

  const debounceSearch = debounce(setSearch, 150);

  useEffect(() => {
    if (!onChangeSearch) return;
    onChangeSearch(search);
  }, [onChangeSearch, search]);

  const extendedMenuProps = useMemo(() => {
    if (!isLightTheme) return MenuProps;
    return {
      ...MenuProps,
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          maxWidth: '16rem',
          borderRadius: '6px',
          marginTop: '16px',
          backgroundColor: 'white',
          color: 'black',
        },
      },
    };
  }, [isLightTheme]);

  const isTouched = (inputName, touchedInstance) => {
    const splittedName = inputName.split('.');
    if (touchedInstance && touchedInstance[splittedName[0]]) {
      if (splittedName.length > 1) {
        return touchedInstance[splittedName[0]][splittedName[1]];
      }
    }
    return touchedInstance[splittedName[0]];
  };
  const error = err || errors[name];
  const hasError = Boolean(isTouched(name, touched) && error);

  const itemListHashName = buildHashMap(itemList);

  const addClassNames = useCallback(
    () => classNames(classes.labelTitle, { [classes.filled]: !!value, [classes.error]: hasError, [classes.disabled]: disabled }),
    [value, disabled, hasError],
  );

  const getRenderValue = (selected, isMultiple, isFilterSelect) => {
    const concatNames = (items) => items.reduce((cur, next) => `${cur} ${itemListHashName[next]},`, '');
    if (isFilterSelect) {
      return (
        <>
          <span className={ addClassNames() }>
            {label}
          </span>
          <span className={ classNames(classes.labelValue, { [classes.error]: hasError, [classes.disabled]: disabled }) }>
            {
              isMultiple ?
                ` (${selected.length ? selected.length : isFilterText})`
                : ` (${selected ? itemListHashName[selected] : isFilterText})`
            }
          </span>

        </>
      );
    }

    return (
      <>
        { isMultiple && selected.length && (
          <span className={ addClassNames() }>
            { concatNames(selected) }
          </span>
        )}
        { isMultiple && !selected.length && (
          <span className={ addClassNames() }>
            { label }
          </span>
        )}

        { !isMultiple && selected && (
          <span className={ addClassNames() }>
            { itemListHashName[selected] }
          </span>
        )}
        { !isMultiple && !selected && (
          <span className={ addClassNames() }>
            { label }
          </span>
        )}
        { !isMultiple && !selected && required && (
          <span className={ addClassNames() }>
            &nbsp;*
          </span>
        )}
      </>
    );
  };

  const filteredItemList = useMemo(() => {
    let filteredItems = itemList;
    if (isSearch && !onChangeSearch) {
      filteredItems = filteredItems.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
    }
    return itemsLimit ? filteredItems.slice(0, itemsLimit) : filteredItems;
  }, [isSearch, onChangeSearch, search, itemList, itemsLimit]);

  const handleCloseDropdown = useCallback(() => {
    setFieldTouched(name, true);
    setSearch('');
  }, [name, setSearch, setFieldTouched]);

  const getMessage = useCallback(() => {
    if (isLoading) return 'Loading...';
    if (!filteredItemList.length) return 'No match found';
    return null;
  }, [isLoading, filteredItemList]);

  return filteredItemList && filteredItemList.length && (
    <FormControl variant="outlined" fullWidth={ fullWidth } className={ classNames(classes.wrapper, className) }>
      <MUISelect
        variant="outlined"
        displayEmpty
        value={ value }
        name={ name }
        renderValue={ (selected) => getRenderValue(selected, multiple, isFilter) }
        MenuProps={ extendedMenuProps }
        // Options
        multiple={ multiple }
        disabled={ disabled }
        fullWidth={ fullWidth }
        // Events
        onChange={ onChange || ((e) => handleStateChange(e, setValue)) }
        onKeyDownCapture={ (e) => {
          e.stopPropagation();
        } }
        onClose={ handleCloseDropdown }
        // Extra
        error={ hasError }
      >
        {isSearch && (
          <ListItemText className={ classes.menuItemText } onClickCapture={ (e) => e.stopPropagation() }>
            <SearchFilterInput placeholder="Search" setSearchFilter={ debounceSearch } />
          </ListItemText>
        )}

        {(isSearch || isLoading) && (
          <ListItemText className={ classes.menuItemText }>
            {getMessage()}
          </ListItemText>
        )}

        { !multiple && showNoneItem && (
          <MenuItem key="None" value="" className={ classes.menuItemWrapper } title={ isFilter ? 'All' : 'None' }>
            <ListItemText className={ classes.menuItemTextReset } primary={ isFilter ? 'All' : 'None' } />
          </MenuItem>
        )}
        { filteredItemList.map((option) => (
          <MenuItem key={ option.id } value={ option.id } className={ classes.menuItemWrapper } title={ option.name }>
            { multiple && (
              <Checkbox
                className={ classes.menuItemCheckbox }
                color="secondary"
                checked={ value.indexOf(option.id) > -1 }
                checkedIcon={ <CheckBoxOutlinedIcon /> }
              />
            ) }
            <ListItemText className={ classes.menuItemText } primary={ option.name } />
          </MenuItem>
        )) }
      </MUISelect>

      { hasError && <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText> }
    </FormControl>
  );
}

Select.defaultProps = {
  label: '',
  name: '',
  value: null,
  // Options
  isFilter: false,
  isFilterText: 'All',
  isSearch: false,
  isLoading: false,
  isLightTheme: false,
  itemsLimit: null,
  multiple: false,
  required: false,
  disabled: false,
  fullWidth: false,
  className: '',
  showNoneItem: true,
  // Events
  onChange: null,
  onChangeSearch: null,
  setFieldTouched: () => null,
  setValue: () => null,
  // Extra
  errors: {},
  touched: {},
  err: null,
};

Select.propTypes = {
  label: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  // Options
  isFilter: PropTypes.bool,
  isFilterText: PropTypes.string,
  isSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLightTheme: PropTypes.bool,
  itemsLimit: PropTypes.number,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  showNoneItem: PropTypes.bool,
  // Events
  onChange: PropTypes.func,
  onChangeSearch: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setValue: PropTypes.func,
  // Extra
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  err: PropTypes.string,
};

export default Select;
