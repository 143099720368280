import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { Button, Typography } from '@mui/material';

// Modules
import ClearableInput from 'modules/_Factories/Input/ClearableInput/ClearableInput';

// Hooks
import useForgotPasswordForm from './useForgotPasswordForm';

// Styles
import classes from './ForgotPasswordForm.module.scss';
import classesGeneral from '../../Auth.module.scss';

function ForgotPasswordForm({ className }) {
  const { forgotPasswordForm } = useForgotPasswordForm();

  return (
    <form onSubmit={ forgotPasswordForm.handleSubmit } className={ classNames(className) }>
      <div className={ classes.fieldWrapper }>
        <Typography className={ classesGeneral.inputName }>Email</Typography>
        <ClearableInput
          name="email"
          onClear={ () => forgotPasswordForm.setFieldValue('email', '') }
          onChange={ forgotPasswordForm.handleChange }
          onBlur={ forgotPasswordForm.handleChange }
          value={ forgotPasswordForm.values.email }
          fullWidth
          placeholder="email"
              // Extra
          errors={ forgotPasswordForm.errors }
          touched={ forgotPasswordForm.touched }
        />
      </div>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        type="submit"
        size="large"
        disabled={ forgotPasswordForm.isSubmitting }
      >
        RESET PASSWORD
      </Button>
    </form>
  );
}

ForgotPasswordForm.defaultProps = { className: '' };

ForgotPasswordForm.propTypes = { className: PropTypes.string };

export default ForgotPasswordForm;
