import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../_Table/Pagination/Pagination';
import classes from './MaterialTablePagination.module.scss';

function MaterialTablePagination({ count, rowsPerPage, onPageChange, onRowsPerPageChange, perPage }) {
  useEffect(() => {
    const event = { target: { value: perPage } };

    onRowsPerPageChange(event);
  }, [onRowsPerPageChange, perPage]);

  const countPages = useMemo(() => {
    let result = Math.ceil(count / rowsPerPage);
    if (!Number.isFinite(result)) {
      result = 0;
    }

    return result;
  }, [count, rowsPerPage]);
  const showPagination = useMemo(() => countPages > 1, [countPages]);

  const handlerOnChangePage = useCallback((e, page) => {
    onPageChange(e, page - 1);
  }, [onPageChange]);

  return (
    <td className={ classes.wrapper }>
      { showPagination && <Pagination count={ countPages } defaultPage={ 1 } onChange={ handlerOnChangePage } /> }
    </td>
  );
}

MaterialTablePagination.defaultProps = {
  onRowsPerPageChange: () => {},
  perPage: () => {},
};
MaterialTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func,
  perPage: PropTypes.number,
};

export default MaterialTablePagination;
