/* eslint-disable camelcase */
import config from '../config/config';

const { gtag } = window;

export const emitGoogleEvent = (eventName, params) => {
  if (config.environment === 'production' && eventName) {
    const user_id = window.sessionStorage.getItem('userId') || window.localStorage.getItem('userId');

    gtag('event', eventName, {
      // Event-level parameters
      ...(user_id && { user_id }),
      ...params,
    });
  }
};

export const setUserIdForAnalitics = (userId) => {
  if (userId) {
    gtag('set', 'user_id', userId);
  }
};

export const removeUserIdFromAnalitics = () => {
  gtag('set', { user_id: null });
};
