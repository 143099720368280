import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';

// Material
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

// Styles
import classes from './Input.module.scss';

function Input(props) {
  const { label, name, value, placeholder, type, autoFocus, autoComplete, disabled, multiline } = props;
  // Events
  const { onChange, onBlur, onKeyPress, onClick, onFocus, onKeyDown } = props;
  // Options
  const { labelBackgroundColor, fullWidth, startAdornment, endAdornment, className, required, rows } = props;
  // Extra
  const { errors, touched, err } = props;

  const CustomizedInputLabel = withStyles(() => ({
    outlined: {
      backgroundColor: labelBackgroundColor,
      padding: '0 .25rem',
    },
  }))(InputLabel);

  const error = err || get(errors, name);

  const hasError = Boolean(get(touched, name) && error);

  const renderLabel = useMemo(() => label && (
    <CustomizedInputLabel error={ hasError } htmlFor={ name }>
      <>
        <span>{ label }</span>
        { required && <span> *</span> }
      </>
    </CustomizedInputLabel>
  ), [hasError, label, name, required]);

  const renderError = useMemo(() => hasError && (
    <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText>
  ), [error, hasError, name]);

  return (
    <FormControl
      variant="outlined"
      fullWidth={ fullWidth }
      className={ classNames(classes.wrapper, className) }
      disabled={ disabled }
    >
      { renderLabel }

      <OutlinedInput
        { ...props }
        id={ name }
        type={ type }
        value={ value }
        name={ name }
        disabled={ disabled }
        placeholder={ placeholder }
        autoFocus={ autoFocus }
        autoComplete={ autoComplete }
        // Options
        fullWidth={ fullWidth }
        startAdornment={ startAdornment }
        endAdornment={ endAdornment }
        multiline={ multiline }
        rows={ rows }
        // Events
        onChange={ onChange }
        onBlur={ onBlur }
        onKeyPress={ onKeyPress }
        onClick={ onClick }
        onFocus={ onFocus }
        onKeyDown={ onKeyDown }
        // Extra
        error={ hasError }
      />

      { renderError }
    </FormControl>
  );
}

Input.defaultProps = {
  label: '',
  value: '',
  name: '',
  placeholder: undefined,
  type: 'text',
  autoComplete: 'true',
  autoFocus: false,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  onFocus: () => {},
  onKeyPress: () => {},
  onKeyDown: () => {},
  // Options
  fullWidth: false,
  labelBackgroundColor: 'none',
  startAdornment: null,
  endAdornment: null,
  className: '',
  required: false,
  multiline: false,
  rows: 3,
  // Extra
  errors: {},
  touched: {},
  err: null,
};

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  // Options
  labelBackgroundColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  endAdornment: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.any]),
  className: PropTypes.string,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  // Extra
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  err: PropTypes.string,
};

export default Input;
