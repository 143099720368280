import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { AppBar, Grid, Typography, StepButton } from '@mui/material';

// Modules
import { TabPanel } from 'modules/Tabs/TabPanel';
import StepTransitions from 'modules/Steps/StepTransitions';

// Hooks
import useStepMenu from 'hooks/useStepMenu';
import usePermissions from 'hooks/usePermissions';

// Constants
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';

// Components
import { useNavigate } from 'react-router-dom';
import General from './General/General';
import Geography from './Geography/Geography';
import BudgetAndConstraints from './BudgetAndConstraints/BudgetAndConstraints';
import Creatives from './Creatives/Creatives';
import Review from './Review/Review';

import classes from './Steps.module.scss';
import { useGetCreativeListMutation } from '../../../Creatives/creativeApiSlice';
import { useGetShortAdvertiserListQuery } from '../../../../api/apiSlice';

const getMenuSteps = (permissions, isEditForm) => [
  { id: 0, name: 'Campaign Details', subtitle: '', touched: true, valid: isEditForm, visible: true },
  { id: 1, name: 'Ad Copy', subtitle: '', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 2, name: 'Targeting', subtitle: '', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 3, name: 'Budget', subtitle: '', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 4, name: 'Review', touched: isEditForm, valid: true, visible: false },
];

function Steps({
  formik,
  editable,
  isEditForm,
  isDisabled,
  handlePublishCampaign,
}) {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const menuStepRef = useRef(getMenuSteps(permissions, isEditForm));

  const [getCreativeListReq] = useGetCreativeListMutation({ fixedCacheKey: 'creatives-for-campaign' });
  const { data: shortAdvertiserList = [] } = useGetShortAdvertiserListQuery({ size: 10000 });

  const [activeTab, setActiveTab] = useState(0);
  const { steps, setValuesStep, stepHasErrors, stepIsDisabled } = useStepMenu(menuStepRef.current);

  const stepTabs = useMemo(() => steps.filter((item) => item.visible), [steps]);

  const globalFields = useMemo(() => ['name'], []);

  const stepFields = useMemo(() => [
    { fields: ['name', 'advertiser', 'start', 'end', 'periods'], visible: true }, // Details
    { fields: ['creatives.adCreatives'], visible: true }, // Ad copy
    {
      fields: [
        'countries.values',
        'states.values',
        'cities.values',
        'dmas.values',
        'districts.values',
        'zips.values',
      ],
      visible: true,
    }, // targeting
    {
      fields: [
        'budget',
        'bidding',
        'cpm',
        'delivery',
      ],
      visible: true,
    }, // Budget
    { fields: [], visible: true }, // Review
  ].filter((item) => item.visible).map((item) => item.fields), []);

  const hasErrorsActiveTab = stepHasErrors([...globalFields, ...stepFields[activeTab]], formik.errors);

  const handleChangeTab = useCallback((index) => {
    setActiveTab(index);
    setValuesStep(index, { touched: true });
  }, [setActiveTab, setValuesStep]);

  const handleNextStep = useCallback(() => {
    if (!hasErrorsActiveTab) handleChangeTab(activeTab + 1);
    [...globalFields, ...stepFields[activeTab]].forEach((name) => formik.setFieldTouched(name, true));
  }, [hasErrorsActiveTab, handleChangeTab, activeTab, globalFields, stepFields, formik]);

  const handlePrevStep = useCallback(() => {
    handleChangeTab(activeTab - 1);
  }, [handleChangeTab, activeTab]);

  const handleCancel = useCallback(() => {
    navigate('/campaigns');
  }, [navigate]);

  useEffect(() => {
    setValuesStep(activeTab, { valid: !hasErrorsActiveTab });
  }, [activeTab, hasErrorsActiveTab, setValuesStep]);

  useEffect(() => {
    menuStepRef.current = getMenuSteps(permissions, isEditForm);
  }, [isEditForm, permissions]);

  const disabledCreatives = [
    CampaignStatuses.types.CANCELED,
    CampaignStatuses.types.CLOSED,
    CampaignStatuses.types.APPROVED,
  ].includes(formik.initialValues.status) ||
    (formik.initialValues.status === CampaignStatuses.types.RUNNING && !permissions.campaignApproval);

  const getCreativeList = useCallback(async () => {
    if (!formik.values.advertiser) return;

    getCreativeListReq({
      params: {
        advertisers: [formik.values.advertiser],
        size: 10000,
      },
      withLoading: false,
      meta: { useFor: 'creativesForCampaign' },
    });
  }, [formik.values.advertiser, getCreativeListReq]);

  useEffect(() => {
    getCreativeList();
  }, [getCreativeList]);

  const TabPanelContentList = useMemo(() => {
    const tabList = [
      {
        name: 'General',
        element: <General
          shortAdvertiserList={ shortAdvertiserList }
          formik={ formik }
          editable={ editable }
        />,
      },
      {
        name: 'Creatives',
        element: <Creatives
          formik={ formik }
          editable={ !disabledCreatives }
          getCreativeList={ getCreativeList }
        />,
      },
      {
        name: 'Geography',
        element: <Geography
          shortAdvertiserList={ shortAdvertiserList }
          formik={ formik }
          editable={ editable }
        />,
      },
      {
        name: 'BudgetAndConstraints',
        element: <BudgetAndConstraints
          formik={ formik }
          editable={ editable }
        />,
      },
      {
        name: 'Review',
        element: <Review values={ formik.values } />,
      },
    ];

    return tabList.filter((_, index) => steps.find((item) => item.id === index));
  }, [formik, getCreativeList, disabledCreatives, editable, shortAdvertiserList, steps]);

  return (
    <Grid container className="pb-5">
      <Grid item xs={ 12 }>
        <AppBar position="static" color="inherit" elevation={ 0 } className={ classes.bar }>
          <Stepper activeStep={ activeTab } connector={ <ArrowForwardIosIcon /> }>
            {stepTabs.map((item, index) => {
              const optional = (
                <Typography variant="caption">
                  { item.subtitle }
                </Typography>
              );

              return (
                <Step key={ item.id }>
                  <StepButton
                    optional={ optional }
                    disabled={ (hasErrorsActiveTab && index > activeTab) || stepIsDisabled(index) }
                    onClick={ () => handleChangeTab(index) }
                    className={ classes.step }
                    icon={ (
                      <div className={ classes.iconWrapper }>
                        <SquareRoundedIcon
                          className={ classNames(classes.icon, { [classes.active]: index === activeTab }) }
                        />
                        <span className={ classes.text }>{index + 1}</span>
                      </div>
) }
                  >
                    {item.name}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </AppBar>
      </Grid>
      <Grid item xs={ 12 }>
        <div className={ classes.content }>
          {TabPanelContentList.map((item, index) => (
            <TabPanel key={ item.name } value={ activeTab } index={ index }>{item.element}</TabPanel>
          ))}
        </div>
        <div className={ classes.step_buttons }>
          <StepTransitions
            handleNext={ handleNextStep }
            handlePrev={ handlePrevStep }
            activeTab={ activeTab }
            tabsLength={ menuStepRef.current.length }
            submit={ handlePublishCampaign }
            handleCancel={ handleCancel }
            showFinish
            saveText="Send Campaign for Approval"
            disableSaveButton={ isDisabled }
          />
        </div>
      </Grid>
    </Grid>
  );
}

Steps.propTypes = {
  handlePublishCampaign: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      status: PropTypes.string,
      advertiser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    initialValues: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      status: PropTypes.string,
      advertiser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    resetField: PropTypes.func,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  isEditForm: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default Steps;
