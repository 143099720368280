import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

const removeItem = (values, optionValueKey, valueKey) => values.filter((item) => item[optionValueKey] !== valueKey);

const useAutocomplete = (props) => {
  // Callback function
  const { onChangeSearchValue } = props;

  // State
  const [searchValue, setSearchValue] = useState('');
  const [isTouchedOptionsText, setIsTouchedOptionsText] = useState(false);

  const handleDebounce = (e) => {
    setIsTouchedOptionsText(true);
    onChangeSearchValue(e);
  };

  // TODO fix lint, try React.useMemo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChangeSearchValue = useCallback(debounce(handleDebounce, 300), [onChangeSearchValue]);

  const handlerOnChangeSearchValue = (e) => {
    e.persist();
    setSearchValue(e.target.value);

    if (onChangeSearchValue) {
      debouncedOnChangeSearchValue(e);
    }
  };

  const handlerOnClearSearchValue = (e) => {
    setSearchValue('');
    setIsTouchedOptionsText(false);
    if (onChangeSearchValue) {
      onChangeSearchValue(e);
    }
  };

  return {
    setSearchValue,
    searchValue,
    removeItem,
    handlerOnChangeSearchValue,
    handlerOnClearSearchValue,
    isTouchedOptionsText,
  };
};

export default useAutocomplete;
