import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

// Classes
import { AdvertiserClass } from 'classes/advertiser/advertiserClass';
import { AdvertiserBalanceClass } from 'classes/advertiser/advertiserBalanceClass';
import useAdvertiserBalance from 'modules/AdvertiserBalance/useAdvertiserBalance';
import { useUpdateAdvertiserMutation } from '../../advertiserApiSlice';

const useAddAdvertiser = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { fillAdvertiserBalance } = useAdvertiserBalance();
  const [updateAdvertiser] = useUpdateAdvertiserMutation();

  const handleSubmit = useCallback(async (values, callbackFinally) => {
    try {
      const model = new AdvertiserClass(values);
      const balanceModel = new AdvertiserBalanceClass(values.balanceModel);

      const { id } = await updateAdvertiser(model).unwrap();

      if (balanceModel.amount && balanceModel.amount > 0 && id) {
        const navigation = {
          successUrl: `${window.location.origin}/advertisers`,
          cancelUrl: `${window.location.origin}/advertisers`,
        };
        await fillAdvertiserBalance(id, { ...values, amount: balanceModel.amount }, navigation);
      }
      navigate('/advertisers');
      enqueueSnackbar(`Advertiser (id: ${id}) created successfully`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    } finally {
      callbackFinally();
    }
  }, [enqueueSnackbar, fillAdvertiserBalance, navigate, updateAdvertiser]);

  return { handleSubmit };
};

export default useAddAdvertiser;
