// eslint-disable-next-line import/no-cycle
import store from 'store/store';

// Config
import config from 'config/config';

// History
import { createBrowserHistory } from 'history';

// Types
import types from 'actions/actionTypes/authActionTypes';

// Libs
import {
  getRefreshToken,
  getToken,
  tokenHasExpired,
  isRememberMe,
  setLocalStorageData,
  setSessionStorageData,
  clearLocalStorage,
  clearSessionStorage,
} from 'libs/storageLibs';
import { removeUserIdFromAnalitics } from 'libs/gtag';

// eslint-disable-next-line import/no-cycle
import client from '../axios';

const history = createBrowserHistory();
const { serverUrl, apiVersion } = config;
const subscribers = [];
const isAlreadyFetchingAccessToken = { state: false };

export const logout = async (sendLogoutRequest = true, redirectTo = '/login') => {
  try {
    if (sendLogoutRequest) {
      await client.get(`${serverUrl}${apiVersion}auth/logout`);
    }
    clearLocalStorage();
    clearSessionStorage();
    removeUserIdFromAnalitics();

    if (redirectTo) {
      history.push(redirectTo);
    }

    store.dispatch({
      type: types.SET_IS_AUTHENTICATED,
      payload: { isAuthenticated: false },
    });

    return true;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const isAuthenticationUrl = (link) => link && link.includes('api') && link.includes('auth/');

export const addSubscriber = (callback) => {
  subscribers.push(callback);
};

export const onAccessTokenFetched = (accessToken) => {
  subscribers.forEach((callback) => callback(accessToken));
  subscribers.length = 0;
};

export const isTokenExpiredError = (errorResponse) => {
  const isAuthUrl = isAuthenticationUrl(errorResponse?.config?.url);
  return errorResponse.status === 401 && !isAuthUrl;
};

export const refreshAccessToken = async () => {
  try {
    const { data, headers } = await client.get(`${serverUrl}${apiVersion}auth/refresh`);
    const expiresDate = new Date(headers.expires || 0).getTime();
    const tokenInfo = { ...data, expires: expiresDate };

    if (isRememberMe()) {
      setLocalStorageData(tokenInfo);
    } else {
      setSessionStorageData(tokenInfo);
    }

    return true;
  } catch (e) {
    await logout(false);
    return Promise.reject(e);
  }
};

export const resetTokenAndReattemptRequestOnError = async (error) => {
  try {
    const errorResponse = error.response;
    const resetToken = getRefreshToken();

    if (!resetToken) {
      await logout(false);
      return Promise.reject(error);
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        errorResponse.config.headers['X-Authorization'] = `Bearer ${accessToken}`;

        resolve(client(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken.state) {
      isAlreadyFetchingAccessToken.state = true;
      const tokenInfo = await refreshAccessToken();

      if (!tokenInfo) {
        return Promise.reject(error);
      }

      const newToken = tokenInfo.token;

      isAlreadyFetchingAccessToken.state = false;
      onAccessTokenFetched(newToken);
    }

    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
};

const resetTokenAndReattemptRequestOnRequest = async (request) => {
  try {
    if (!getRefreshToken()) {
      return await logout();
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        request.headers['X-Authorization'] = `Bearer ${accessToken}`;
        resolve(request);
      });
    });

    if (!isAlreadyFetchingAccessToken.state) {
      isAlreadyFetchingAccessToken.state = true;
      const tokenInfo = await refreshAccessToken();
      if (!tokenInfo) {
        return Promise.reject(request);
      }

      const newToken = tokenInfo.token;

      isAlreadyFetchingAccessToken.state = false;
      onAccessTokenFetched(newToken);
    }

    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const refreshRequestHandler = (request) => {
  if (getToken() && tokenHasExpired() && !isAuthenticationUrl(request.url)) {
    return resetTokenAndReattemptRequestOnRequest(request);
  }
  return request;
};

export const refreshResponseHandler = (error) => {
  const errorResponse = error.response;
  const { isAuthenticated } = store.getState().auth;

  if (isAuthenticated && errorResponse && isTokenExpiredError(errorResponse)) {
    return resetTokenAndReattemptRequestOnError(error);
  }
  return Promise.reject(error);
};
