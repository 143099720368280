import { Link } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import classes from '../Landing.module.scss';

function GetStartedBtn() {
  return (
    <Link
      className={ cn(classes.btn_link_primary, classes.btn_full) }
      href="/signup"
    >Get Started
    </Link>
  );
}

export default GetStartedBtn;
