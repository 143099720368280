import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  getFilteredCongressionalDistricts as getFilteredCongressionalDistrictsReq,
  clearFilteredCongressionalDistricts as clearFilteredCongressionalDistrictsReq,
} from 'actions/dictionaryActions';

// Selectors
import dictionarySelectors from 'selectors/dictionarySelectors';

const useFilterableCongressionalDistricts = ({ limit = 100 } = {}) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState({
    filter: '',
    state: [],
    limit,
  });

  const [loading, setLoading] = useState(false);

  const updateSearchParams = useCallback((newParams) => {
    setSearchParams((prevState) => ({
      ...prevState,
      ...newParams,
    }));
  }, [setSearchParams]);

  const getFilteredCongressionalDistricts = useCallback(async (params) => {
    setLoading(true);
    await dispatch(getFilteredCongressionalDistrictsReq(params));
    setLoading(false);
  }, [dispatch]);

  const clearFilteredCongressionalDistricts = useCallback(() => dispatch(
    clearFilteredCongressionalDistrictsReq(),
  ), [dispatch]);

  useEffect(() => {
    getFilteredCongressionalDistricts(searchParams);

    return () => {
      clearFilteredCongressionalDistricts();
    };
  }, [clearFilteredCongressionalDistricts, getFilteredCongressionalDistricts, searchParams]);

  const filteredCongressionalDistricts = useSelector(dictionarySelectors.filteredCongressionalDistrictsSelector);

  return {
    updateSearchParams,
    filteredCongressionalDistricts,
    loading,
  };
};

export default useFilterableCongressionalDistricts;
